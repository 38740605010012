import { SummaryService } from "./../../services/investor/summary.service";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ToasterService, Toast } from "angular2-toaster";
import {
  SendISPReminder,
  SendISP,
  SendISPCompany,
  InvestorSummaryList2
} from "ClientApp/app/models/SendISPReminder";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";

@Component({
  selector: "app-summary-emails",
  templateUrl: "./summary-emails.component.html",
  styleUrls: ["./summary-emails.component.css"]
})
export class SummaryEmailsComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  ISPModel = new SendISPReminder();
  _ISPModel = new SendISP();
  companyModel = new SendISPCompany();
  dynamicEventModel=new InvestorSummaryList2;
  response: any;
  emails: any = [];
  // compEmail!: string;
  // compId!: number;
  companyList: Array<{ CompId: number; Email: string }> = [];
  selectedArr: Array<SendISP> = [];
  conferenceDetail: any;
  cInfo: any;
  ConferenceName: any;
  confID: any;
  dataTable: any;
  btnMessage: string | undefined;
  ISPBtnMessage: string | undefined;
  SlideBtnMessage: string | undefined;
  LogoBtnMessage: string | undefined;
  processMessage: string | undefined;
  selectedValue: any;
  selectedPageSize: any;
  selectedFilterPrevVal: string = "";
  selectedSortPrevVal: any = [[0, "asc"]];
  selectedPagePrevVal: any = 0;
  emailTypes:any;
  constructor(
    public cService: ConferenceService,
    public summaryService: SummaryService,
    private toasterService: ToasterService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadDefault();
    this.ISPBtnMessage = ConferenceConfig.SUMMARY_EMAIL_ISP_BTN_MESSAGE;
    this.SlideBtnMessage = ConferenceConfig.SUMMARY_EMAIL_SLIDE_BTN_MESSAGE;
    this.LogoBtnMessage = ConferenceConfig.SUMMARY_EMAIL_LOGO_BTN_MESSAGE;
    this.processMessage = ConferenceConfig.BTN_PROCESS_MESSAGE;

    this.loadCustomActiveEmailTypes();
  }

  loadDefault() {
    // Select List companies, Get Company Id and List emails
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
    //console.log(this.conferenceItem);
    //this.loadEmails();
  }
   loadCustomActiveEmailTypes(){
     debugger
    return this.summaryService.getNewEnabledEmailTypes().subscribe((data: any) => {
      console.log(data);
     this.emailTypes=data.Info;
  });
   }
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      this._conferenceId = this.conferenceItem.ConferenceId;
      this.confID = this.conferenceItem.ConferenceID;
      this.getConferenceDetails(this.confID);
      //this.conferenceModel.conferenceId = this._conferenceId;
      //console.dir(this.conferenceItem);
      // console.dir(this.conferenceItem.ConferenceId);
      // console.dir(this._conferenceId);
      //setTimeout(() => {
      // this.summaryForm.patchValue({
      //   conferenceId: this.conferenceItem
      // });
      //console.log(this.conferenceItem.conferenceId);
      //   this._conferenceId = 1; //this.conferenceItem.conferenceId;
      //   this.loadEmails(this._conferenceId);
      // }, 1000);
    });
  }

  getConferenceDetails(conferenceId: any) {
    //console.log(conferenceId);
    this._conferenceId = conferenceId;
    return this.cService
      .getConferenceById(conferenceId)
      .subscribe((data: {}) => {
        this.conferenceDetail = data;
        this.cInfo = this.conferenceDetail.ConferenceCategory;
        this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.confID = this.conferenceDetail.Conference.ConferenceId;
        this.loadEmails(this.confID);
      });
  }

  onChangeConference(id: number) {
    //console.log("Inside " + id);
    // if (this.dataTable) {
    //   this.dataTable.destroy();
    // }
      this.selectedArr = [];
      this.selectedFilterPrevVal = "";
      this.selectedSortPrevVal = [[0, "asc"]];
      this.selectedPagePrevVal = 0;
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  // Get Emails list
  loadEmails(id: any) {
    return this.summaryService.getEmailList(id).subscribe((data: {}) => {
        this.emails = data;
        this.selectedArr = [];
        this.enablePagination();
    });
  }

  sendISP() {
    this.ISPModel.Info = this.selectedArr;
    this.companyModel.Conference_Id = this.confID;
    this.ISPModel.CompanyDetails = this.companyModel;
    //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
    if (this.selectedArr.length > 0) {
      //console.log(this.ISPModel);
      this.ISPBtnMessage = this.processMessage;
      return this.summaryService
        .sendISPReminder(this.ISPModel)
        .subscribe(result => {
          // This code will be executed when the HTTP call returns successfully
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };
            this.selectedFilterPrevVal = this.dataTable.search();
            this.selectedSortPrevVal = this.dataTable.order();
            this.selectedPagePrevVal = this.dataTable.page();

              this.toasterService.pop(toast);
              if (this.dataTable) {
                  this.dataTable.destroy();
              }
              this.loadEmails(this.confID);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
          this.ISPBtnMessage = ConferenceConfig.SUMMARY_EMAIL_ISP_BTN_MESSAGE;
        });
    } else {
      var toast: Toast = {
        type: "error",
        title: "Please select company.",
        showCloseButton: true
      };
      this.toasterService.pop(toast);
    }
  }

  sendSlideDeck() {
    this.ISPModel.Info = this.selectedArr;
    this.companyModel.Conference_Id = this.confID;
    this.ISPModel.CompanyDetails = this.companyModel;
    //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
    if (this.selectedArr.length > 0) {
      //console.log(this.ISPModel);
      this.SlideBtnMessage = this.processMessage;
      return this.summaryService
        .sendSlideDeskReminder(this.ISPModel)
        .subscribe(result => {
          // This code will be executed when the HTTP call returns successfully
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
          this.SlideBtnMessage =
            ConferenceConfig.SUMMARY_EMAIL_SLIDE_BTN_MESSAGE;

          this.selectedFilterPrevVal = this.dataTable.search();
          this.selectedSortPrevVal = this.dataTable.order();
          this.selectedPagePrevVal = this.dataTable.page();
          
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          this.loadEmails(this.confID);
        });
    } else {
      var toast: Toast = {
        type: "error",
        title: "Please select company.",
        showCloseButton: true
      };
      this.toasterService.pop(toast);
    }
  }

  sendLogo() {
    this.ISPModel.Info = this.selectedArr;
    this.companyModel.Conference_Id = this.confID;
    this.ISPModel.CompanyDetails = this.companyModel;
    //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
    if (this.selectedArr.length > 0) {
      //console.log(this.ISPModel);
      this.LogoBtnMessage = this.processMessage;
      return this.summaryService
        .sendLogoReminder(this.ISPModel)
        .subscribe(result => {
          // This code will be executed when the HTTP call returns successfully
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
          this.LogoBtnMessage = ConferenceConfig.SUMMARY_EMAIL_LOGO_BTN_MESSAGE;

          this.selectedFilterPrevVal = this.dataTable.search();
          this.selectedSortPrevVal = this.dataTable.order();
          this.selectedPagePrevVal = this.dataTable.page();
          
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          this.loadEmails(this.confID);
        });
    } else {
      var toast: Toast = {
        type: "error",
        title: "Please select company.",
        showCloseButton: true
      };
      this.toasterService.pop(toast);
    }
  }
  send(id:number){
   console.log(id);
   this.dynamicEventModel.EventId = id;
   this.dynamicEventModel.Info = this.selectedArr;
    this.companyModel.Conference_Id = this.confID;
    this.dynamicEventModel.CompanyDetails = this.companyModel;
   
    if (this.selectedArr.length > 0) {
    
      this.LogoBtnMessage = this.processMessage;
      return this.summaryService
        .sendMailForDynamicMailEvent(this.dynamicEventModel)
        .subscribe(result => {
      
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
          this.LogoBtnMessage = ConferenceConfig.SUMMARY_EMAIL_LOGO_BTN_MESSAGE;

          this.selectedFilterPrevVal = this.dataTable.search();
          this.selectedSortPrevVal = this.dataTable.order();
          this.selectedPagePrevVal = this.dataTable.page();
          
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          this.loadEmails(this.confID);
        });
    } else {
      var toast: Toast = {
        type: "error",
        title: "Please select company.",
        showCloseButton: true
      };
      this.toasterService.pop(toast);
    }
  }
  onChange(e: any, id: number, email: string) {
    // onChange(e: any) {
    //console.log(e);
    // console.log(id);
    // console.log(email);
    if (e.target.checked) {
      this._ISPModel = new SendISP();
      this._ISPModel.CompId = id;
      this._ISPModel.Email = email;
      //console.dir(this.ISPModel);
      this.selectedArr.push(this._ISPModel);
    }else{
      let unselectedIndex = this.selectedArr.map((sa: any) => sa.CompId).indexOf(id);
      if (unselectedIndex !== -1)
            {
      this.selectedArr = this.selectedArr.filter((x, i) => i !== unselectedIndex);
            }
    }
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#summary_email");
    this.dataTable = table.DataTable({
      "aoColumns": [
        null,
        null,
        null,
        {"sType": "date"},
        {"sType": "date"},
        {"sType": "date"},
        {"sType": "date"},
      ],
      pagingType: "full_numbers",
      paging: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      search: {"search": self.selectedFilterPrevVal},
      "order": self.selectedSortPrevVal,
      'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#summary_email').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }
}
