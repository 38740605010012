﻿import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
//import { RestApiService } from "../../../../../services/rest-api.service";
import { SummaryService } from "../../../../../services/investor/summary.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { Toast, ToasterService } from "angular2-toaster";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import * as _ from "underscore";
import moment from "moment";
import { PressAccreditationService } from "../press-accreditation/press-accreditation.service";
import {
  PressAccreditationEmailItem,
  PressAccreditationListUpdateModel,
} from "ClientApp/app/models/Application";
import { environment } from "ClientApp/environments/environment";

@Component({
  selector: "app-press-accreditation-list",
  templateUrl: "./press-accreditation-list.component.html",
  styleUrls: [],
})
export class PressAccreditationListComponent implements OnInit {
  enableParticipationMethod: boolean;
  Summaries: any = [];
  selectedValue: any;
  response: any;
  typeSelected: string = "true";
  selectionModelArr: any = [];
  overlay = false;
  selectedPageSize: any;
  approved: number;
  declined: number;
  pending: number;
  stockExchanges: any = [];
  inPerson: number;
  virtual: number;
  selectedInpersonCount: any;
  selectedVirtualCount: any;
  constructor(
    public pressAccreditationService: PressAccreditationService,
    public cService: ConferenceService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}
  listAll: string = "true";
  confs: any = [];
  appItem = new PressAccreditationEmailItem();
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  dataTable: any;
  reportData = [];

  ConferenceFileTitle: any;
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: true,
    title: "",
    useBom: true,
    noDownload: false,
    headers: [
      "CompanyName",
      "FirstName",
      "LastName",
      "Title",
      "ContactEmail",
      "Website",
      "MessageFromCompany",
      "LinkToPublication",
      "ApplicationDate",
      "DocumentsLink",
      "Date Created",
      "Last Updated",
    ],
  };
  appList = new PressAccreditationListUpdateModel();
  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_PRESSACCREDIATION);
    this.typeSelected = "true";
  }
  //Get InPerson / Virtual count
  participationStatusCount(id: any) {
    //debugger
    this.pressAccreditationService
      .getParticipationStatusCount(id)
      .subscribe((data: any) => {
        if (data.Status) {
          console.log("get--ParticipationStatusCount", data);
          this.inPerson = data.Info.InPersonCount;
          this.virtual = data.Info.VirtualCount;
          this.selectedInpersonCount = data.Info.SelectedInPersonCount;
          this.selectedVirtualCount = data.Info.SelectedVirtualCount;
        }
      });
  }
  // Get Conference list
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      //this._conferenceId = this.conferenceItem.ConferenceId;
      console.log(this.conferenceItem.ConferenceID);
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
      this.participationStatusCount(this.conferenceItem.ConferenceID);
    });
  }

  onChangeConference(id: any) {
    //this.getConferenceDetails(id);
    console.log("this.selectedValue", this.selectedValue);
    // if (this.dataTable) {
    //   this.dataTable.destroy();
    // }
    this.getConferenceDetails(this.selectedValue.ConferenceID);
    this.participationStatusCount(this.selectedValue.ConferenceID);
  }

  getConferenceDetails(conferenceId: any) {
    return this.cService
      .getConferenceById(conferenceId)
      .subscribe((data: {}) => {
        this.conferenceDetail = data;
        this.cInfo = this.conferenceDetail.ConferenceCategory;
        this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
        this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadAccreditations(this.listAll, this.selectedValue.ConferenceId);
      });
  }

  accreditationStatusCount() {
    var approved = _.filter(this.Summaries.Info, function (obj: any) {
      return obj.EmailType == "Selected";
    });
    if (approved) this.approved = approved.length;
    var declined = _.filter(this.Summaries.Info, function (obj: any) {
      return obj.EmailType == "Declined";
    });
    if (declined) this.declined = declined.length;
    var pending = _.filter(this.Summaries.Info, function (obj: any) {
      return obj.EmailType != "Declined" && obj.EmailType != "Selected";
    });
    if (pending) this.pending = pending.length;
  }

  onChangeFlag(value: string) {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.typeSelected = value;
    this.loadAccreditations(value, this.selectedValue.ConferenceId);
  }
  // Get summaries list
  loadAccreditations(key: string, conferenceId: any) {
    return this.pressAccreditationService
      .getAccreditation(key, conferenceId)
      .subscribe((data: {}) => {
        this.Summaries = data;
        this.enablePagination();
        this.accreditationStatusCount();
        this.participationStatusCount(this.selectedValue.ConferenceID);
      });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#inv_summary_list");
    this.dataTable = table.DataTable({
      aoColumns: [null, { sType: "date" }, { sType: "date" }, null, null, null],
      pagingType: "full_numbers",
      paging: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      order: [[2, "desc"]],
      columnDefs: [{ type: "date", targets: [2] }],
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>',
        },
      },
      stripeClasses: ["odd-row", "even-row"],
    });
    $("#inv_summary_list").on("length.dt", function (e, settings, len) {
      self.selectedPageSize = len;
    });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.Name + "?")
      .then((confirmed) => {
        this.pressAccreditationService
          .deleteAccreditation(obj.Id)
          .subscribe((data: {}) => {
            this.response = data;
            if (this.response.Code == 200) {
              var toast: Toast = {
                type: "success",
                title: this.response.Message,
                showCloseButton: true,
              };

              this.toasterService.pop(toast);
              if (this.dataTable) {
                this.dataTable.destroy();
              }
              this.loadAccreditations(
                this.typeSelected,
                this.selectedValue.ConferenceId
              );
            } else {
              var toast: Toast = {
                type: "error",
                title: this.response.Message,
                showCloseButton: true,
              };
              this.toasterService.pop(toast);
            }
          });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  downloadCSV() {
    var reportdata: any = [];
    _.each(this.Summaries.Info, function (obj: any) {
      var publications: any = [];
      if (obj.Documents) {
        var docs = obj.Documents.split(",");
        _.each(docs, function (doc) {
          publications.push(environment.documentsPath + doc);
        });
      }
      reportdata.push({
        CompanyName: obj.Name != null ? obj.Name : "",
        FirstName: obj.FirstName || "",
        LastName: obj.LastName || "",
        JobTitle: obj.Position || "",
        ContactEmail: obj.email || "",
        Website: obj.Website || "",
        MessageFromCompany: obj.CompanyMessage || "",
        LinkToPublication: obj.LinkPublication || "",
        ApplicationDate: obj.ApplicationDate || "",
        DocumentsLink: publications.join(", ") || "",
        CreatedOn:
          obj.CreatedOn != null
            ? moment(obj.CreatedOn).format("MM/DD/YYYY hh:mm:a")
            : "",
        LastUpdated:
          obj.newupdatedate != "Null"
            ? moment(obj.newupdatedate).format("MM/DD/YYYY hh:mm:a")
            : "",
      });
    });
    this.csvOptions.title = "Press Accreditation - " + this.ConferenceName;
    new AngularCsv(reportdata, "Press Accreditation", this.csvOptions);
  }

  onChange(e: any, id: number) {
    this.appItem.Id = id;
    this.appItem.IsSelected = e.target.checked ? 1 : 0;
    this.selectionModelArr = [];
    this.selectionModelArr.push(this.appItem);
    this.appItem = new PressAccreditationEmailItem();
    this.appList.PressAccreditationSelections = this.selectionModelArr;
    this.overlay = true;
    this.updateSelection(this.appList);
  }

  updateSelection(model: PressAccreditationListUpdateModel) {
    this.pressAccreditationService
      .updateSelection(model)
      .subscribe((result) => {
        this.response = result;
        this.overlay = false;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true,
          };

          this.toasterService.pop(toast);
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          this.loadAccreditations(this.typeSelected, this._conferenceId);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true,
          };
          this.toasterService.pop(toast);
        }
      });
  }
}
