import { EmailEvent } from "ClientApp/app/models/EmailEvent";
import { Injectable } from '@angular/core';
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmailEventEditComponent } from "ClientApp/app/modules/template/pages/email-event-edit/email-event-edit.component";

@Injectable({
  providedIn: 'root'
})
export class EmailEventService {
  apiURL = environment.baseUrl;
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
      //"Access-Control-Allow-Origin": "*"
    })
  };

  getAllEmailEvents(){
    return this.http.get(this.apiURL + "/Event/ListAllEmailEvents");
  }
  getActiveEmailEventFromAll(){
    return this.http.get(this.apiURL + "/Event/ActiveEmailEventFromAll");
  }
  getEmailEventsByConference(confId: number){
    return this.http.get(this.apiURL + "/Event/ListAllEmailEvents?confId=" + confId);
  }

  getEmailEventById(id: number){
    return this.http.get(this.apiURL + "/Event/" + id);
  }

  createEmailEvent(emailEvent: EmailEvent) {
    //console.log('new event post service',emailEvent,"URL--",this.apiURL + "/Event");
    
    return this.http.post(
      this.apiURL + "/Event/AddEmailEvent",
      emailEvent,
      this.httpOptions
    );
  }

  updateEmailEventId(emailEvent: EmailEvent) {
    // console.log('url',this.apiURL + "/Event", emailEvent, this.httpOptions);
    
    return this.http.put(this.apiURL + "/Event", emailEvent, this.httpOptions);
  }

  deleteEmailEvent(id: any) {
    return this.http.delete(this.apiURL + "/Event/" + id);
  }
}
