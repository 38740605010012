import { Component, OnInit } from '@angular/core';
import { UtilService } from 'ClientApp/app/services/common/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  CopyRightDetails: any = '';

  constructor(private service : UtilService) { }

  ngOnInit() {
    this.showCopyRight();
  }
showCopyRight(){
  this.service.showCopyRight().subscribe((data : any) => {
    console.log(data);
    this.CopyRightDetails = data.Year
  })
}
}
