export class ConferenceDetailModel {
  Conference: ConferenceModel = new ConferenceModel();
  ConferenceCategoryDetails: Array<ConferenceCategoryModel> | undefined;
}

export class ConferenceModel {
  ConferenceId: number | undefined;
  ConferenceID: string | undefined;
  ConferenceName: string | undefined;
  TypeId: number | undefined;
  StartDate: string | undefined;
  EndDate: string | undefined;
  ConferenceYear: number | undefined;
  AllowScheduling: number | undefined;
  AllowInvestorSummary: number | undefined;
  AllowParticipantProfile: number | undefined;
  isActive: number | undefined;
  BannerUrl: string | undefined;
  ConforanceImage: string | undefined;
  ShowOnSite: number | undefined;
  Image: any;
}

export class ConferenceCategoryModel {
  CTypeId: number | undefined;
  BannerContents: string | undefined;
}

export class ConferenceTypeModel {
  Id: number | undefined;
  Type: string | undefined;
  isActive: number | undefined;
  isEBD: boolean | undefined;
  IsAssetsShow: boolean | undefined;
  EnableParticipantMethod: boolean | undefined;
  // RoleId : number | undefined;
}

export class KeyValueModel {
  Id: number | undefined;
  Description: string | undefined;
}

// export class Conference {
//   conferenceId: number | undefined;
// }

// export class ConferenceModel {
//   ConferenceId: number | undefined;
//   ConferenceName: string | undefined;
//   TypeId: number | undefined;
//   StartDate: string | undefined;
//   EndDate: string | undefined;
//   AllowScheduling: number | undefined;
//   AllowInvestorSummary: number | undefined;
//   AllowParticipantProfile: number | undefined;
//   isActive: number | undefined;
//   BannerContents: string | undefined;
//   ShowOnSite: number | undefined;
//   ConferenceCategory: number | undefined;
// }
