import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { EmailTemplateService } from "ClientApp/app/services/template/email-template.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";
import * as _ from 'underscore';
@Component({
  selector: "app-email-template",
  templateUrl: "./email-template.component.html",
  styleUrls: ["./email-template.component.css"]
})
export class EmailTemplateComponent implements OnInit {
  Templates: any = [];
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  selectedValue: any;

  // instance of DataTable
  dataTable: any;
  response: any;
  selectedPageSize: any;
  constructor(
    public eTemplateService: EmailTemplateService,
    public cService: ConferenceService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadConference();
  }

  loadConference() {
    this.cService.getConference().subscribe((data: {}) => {
      this.confs = data;
      //this.Conferences = this.confs.Info;
      this.Conferences= _.filter(this.confs.Info, function(obj:any){ return obj.isActive== 1; });
      //console.log(this.Conferences);
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      this._conferenceId = this.conferenceItem.ConferenceId;
      // console.log(this.conferenceItem.ConferenceId);
      // console.log(this._conferenceId);
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
    });
  }

  // Get summaries list
  loadTemplates(conferenceId: number) {
    return this.eTemplateService
      .getTemplatesByConferenceId(conferenceId)
      .subscribe((data: {}) => {
        this.Templates = data;
        console.log("template--",this.Templates)
        // You'll have to wait that changeDetection occurs and projects data into
        // the HTML template, you can ask Angular to that for you ;-)
        //this.chRef.detectChanges();
        let self = this;
        const table: any = $("#email_template_list");
        this.dataTable = table.DataTable({
          pagingType: "full_numbers",
          paging: true,
          pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
          lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
          language: {
            search:
              '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
            searchPlaceholder: "Search for...",
            paginate: {
              previous: '<i class="fa fa-angle-left"></i>',
              next: '<i class="fa fa-angle-right"></i>'
            }
          },
          stripeClasses: ["odd-row", "even-row"]
        });
        $('#email_template_list').on( 'length.dt', function ( e, settings, len ) {
          self.selectedPageSize = len;
        });
    
        // Apply the search
        // table.columns().eq( 0 ).each( function ( colIdx ) {
        //   $( 'input', table.column( colIdx ).footer() )
        //   .on( 'keyup change', function () {
        //     table .column( colIdx ) .search( this.value ) .draw();
        //   })
        // table.columns().every(function () {
        //   table.column(0).search(searchText).draw();
        // });
      });
  }

  onChangeConference(id: any) {
    //console.log(this.selectedValue);
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  getConferenceDetails(id: any) {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    //console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      //console.log(this.cInfo);
      this.loadTemplates(this.selectedValue.ConferenceId);
    });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.TemplateName)
      .then(confirmed => {
        this.eTemplateService.deleteTemplate(obj.Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: "Successfully Deleted",//this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadTemplates(this.selectedValue.ConferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

}
