import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConferenceTypeCreateModalComponent } from "../../components/conference-type-create-modal/conference-type-create-modal.component";
import { ConferenceTypeEditModalComponent } from "../../components/conference-type-edit-modal/conference-type-edit-modal.component";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ToasterService, Toast } from "angular2-toaster";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";

@Component({
  selector: "app-conference-type-list",
  templateUrl: "./conference-type-list.component.html",
  styleUrls: ["./conference-type-list.component.css"]
})
export class ConferenceTypeListComponent implements OnInit {
  response: any;
  conferenceTypes: any;
  dataTable: any;
  selectedPageSize: any;
  constructor(
    private modalService: NgbModal,
    private conferenceService: ConferenceService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadConferenceType();
  }

  loadConferenceType() {
    return this.conferenceService.getConferenceType().subscribe((data: {}) => {
      this.response = data;
      this.conferenceTypes = this.response.Info;
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#type_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
        pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
        lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });

    $('#type_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
  } );
  }

  /* Modal popup for creating new conference type */
  openFormModal() {
    const modalRef = this.modalService.open(
      ConferenceTypeCreateModalComponent,
      {
          windowClass: "usermodalWidth",
          backdrop: "static"
      }
    );

    modalRef.result
      .then(result => {
        //console.log(result);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadConferenceType();
      })
      .catch(error => {
        //console.log(error);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadConferenceType();
      });
  }

  openEditFormModal(typeId: number) {
    const modalRef = this.modalService.open(ConferenceTypeEditModalComponent, {
        windowClass: "usermodalWidth",
        backdrop: "static"
    });

    modalRef.componentInstance.typeId = typeId;
    modalRef.result
      .then(result => {
        //console.log(result);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadConferenceType();
      })
      .catch(error => {
        //console.log(error);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadConferenceType();
      });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.Type)
      .then(confirmed => {
        this.conferenceService.deleteConferenceType(obj.Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadConferenceType();
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

}
