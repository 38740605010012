import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EBDConfigurationService } from 'ClientApp/app/services/EBD-configuration/ebd-configuration.service';
import { Toast, ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ebd-configuration-create',
  templateUrl: './ebd-configuration-create.component.html',
  styleUrls: ['./ebd-configuration-create.component.css']
})
export class EBDConfigurationCreateComponent implements OnInit {
  ebdConfigurationForm: FormGroup;
  submitted: boolean = false;
  confs: any = [];
  Conferences: any = [];
  dropdownSettings = {};
  editorList: any = [];
  config:any={};

  constructor(private formBuilder: FormBuilder, public EBDservice: EBDConfigurationService,private toasterService: ToasterService, private router: Router) { }

  ngOnInit() {
    this.ebdConfigurationForm = this.formBuilder.group({
      tConference: ["", Validators.required],
      applicationfrom: ["", Validators.required],
      eventcode: ["", Validators.required],
      registrationType: ["", Validators.required],
      eventName: ["", Validators.required],
      eventLogo: ["", Validators.required],
      eventUrl: ["", Validators.required],
      paymentSource: ["", Validators.required]
    });
    this.loadConference();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "ConferenceID",
      textField: "ConferenceName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  get fc() {
    return this.ebdConfigurationForm.controls;
  }

  // Get conference list
  loadConference() {
    return this.EBDservice.getEbdConference().subscribe((data: {}) => {
      this.confs = data;
      console.log(this.confs);
      this.Conferences = this.confs.Info;
      console.log(this.Conferences);
    });
  }

  onSubmit() {
    console.log('test Submit');

    this.submitted = true;
    if (this.ebdConfigurationForm.invalid) {
      console.log("Invalid");
      return;
    } else {
      this.config.ConferenceId=this.ebdConfigurationForm.value.tConference;
      this.config.ApplicationFrom=this.ebdConfigurationForm.value.applicationfrom;
      this.config.EventCode=this.ebdConfigurationForm.value.eventcode;
      this.config.RegistrationType=this.ebdConfigurationForm.value.registrationType;
      this.config.EventName=this.ebdConfigurationForm.value.eventName;
      this.config.EventLogo=this.ebdConfigurationForm.value.eventLogo;
      this.config.EventUrl=this.ebdConfigurationForm.value.eventUrl;
      this.config.PaymentSource=this.ebdConfigurationForm.value.paymentSource;
      console.log('tConfig',this.config);
      this.createEBDconfiguration(this.config);
    }
  }

  createEBDconfiguration(config:any){
    this.EBDservice.createEBDconfig(config).subscribe((result:any)=>{
      console.log('result Add ebd config',result);
      if (result.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: result.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        //this.conferenceForm.reset();
        // Redirect to list.
        this.router.navigate(["administration/EBDconfiguration-list"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: result.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
      
    })
  }

  // ----multiselect

  onItemSelect(item: any) {
    console.log(item);
    this.editorList.push(item);
    console.log(this.editorList);
    this.enableOrDisableValidations();
  }
  onSelectAll(items: any) {
    console.log(items);
    this.editorList = items;
    console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  onDeSelect(item: any) {
    console.log(item);
    var pos = this.editorList
      .map(function (e: { ConferenceID: number }) {
        return e.ConferenceID;
      })
      .indexOf(item.ConferenceID);
    this.editorList.splice(pos, 1);
    console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  enableOrDisableValidations() { }

}
