import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TrackService } from "ClientApp/app/services/track/track.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-track-timeslot-edit",
  templateUrl: "./track-timeslot-edit.component.html",
  styleUrls: ["./track-timeslot-edit.component.css"]
})
export class TrackTimeslotEditComponent implements OnInit {
  @Input() public slotId: number = 0;
  @Input() public trackId: number = 0;
  sID: number = 0;
  isEdit: boolean = true;

  addEditSlotForm!: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    public trackService: TrackService
  ) {}

  ngOnInit() {
    //console.log("New Slot ID " + this.slotId);
    this.sID = this.slotId;
    this.isEdit = true;
    //this.getTrackSlotById(this.slotId);
  }
}
