import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "ClientApp/environments/environment";
import { CompanySelection } from "ClientApp/app/models/Selection";

@Injectable({
  providedIn: "root"
})
export class SelectionService {
  // Define API
  apiURL = environment.baseUrl;
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json" //,
      // "Access-Control-Allow-Origin": "*"
    })
  };

  // Get Emails
  getCompanies(id: number) {
    return this.http.get(
      this.apiURL + "/ChooseCompany/GetChooseCompanyByConferenceid/" + id
    );
  }

  getNotSelectedCompanies(id: number) {
    return this.http.get(
      this.apiURL + "/ChooseCompany/GetChooseCompanyNotSelectedList/" + id
    );
  }

  // Get Selection Round count
  getSelectionRound(id: number) {
    return this.http.get(this.apiURL + "/Count/ListRound/" +id);
  }

  //Update selection round.
  updateSelectionRound(companySelection: CompanySelection) {
    return this.http.put(
      this.apiURL + "/ChooseCompany",
      companySelection,
      this.httpOptions
    );
  }

  // Get Emails
  getEmailList(id: number) {
    return this.http.get(this.apiURL + "/Email/" + id);
  }

  getSelectionCommentDetails(companyId: any) {
    return this.http.get(
      this.apiURL + "/Email/SelectionViewDetails/" + companyId
    );
  }

  // Send Email
  sendEmail(arr: any) {
    return this.http.post(
      this.apiURL + "/Email/ChooseCompanySendEmail",
      arr,
      this.httpOptions
    );
  }

  sendNotSelectedEmail(arr: any) {
    return this.http.post(
      this.apiURL + "/Email/NotSelectedEmail",
      arr,
      this.httpOptions
    );
  }

  updateSelectionConfirmation(arr: any) {
    return this.http.put(
      this.apiURL + "/Email/UpdateChooseCompanyConfirmation",
      arr,
      this.httpOptions
    );
  }

  getDashboard(conferenceId?: any) {
    let cId = conferenceId > 0 ? conferenceId : "";
    return this.http.get(this.apiURL + "/Dashboard/ListAsyncCount/" + cId);
  }

  getConferenceMailDetails(companyId: any, conferenceId: any) {
    console.log('getConfirm', this.apiURL + "/InvestorSummary/Mail/" + companyId + "/" + conferenceId);
    
    return this.http.get(
      this.apiURL + "/InvestorSummary/Mail/" + companyId + "/" + conferenceId
    );
  }

  // Get Note Type List

  // Get Notes by Company
  getNoteTypeList() {
    return this.http.get(this.apiURL + "/NoteType");
  }

  // Get Notes by Company
  getNoteByCompanies(id: number) {
    return this.http.get(this.apiURL + "/Note/" + id);
  }

  // Get Notes by Company
  getNoteById(id: number) {
    return this.http.get(this.apiURL + "/NoteSelect/" + id);
  }

  // save Notes by Company
  saveNoteByCompany(data: any) {
    return this.http.post(this.apiURL + "/Note", data, this.httpOptions);
  }

  // update Notes by Company
  UpdateNoteByCompany(data: any) {
    return this.http.put(this.apiURL + "/Note", data, this.httpOptions);
  }

  // Get Notes by Company
  RemoveNoteById(id: number) {
    return this.http.delete(this.apiURL + "/Note/" + id);
  }
}
