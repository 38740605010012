import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TrackCreateModalComponent } from "../../components/track/track-create-modal/track-create-modal.component";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { TrackService } from "ClientApp/app/services/track/track.service";
import { TrackEditModalComponent } from "../../components/track/track-edit-modal/track-edit-modal.component";
import { TrackTimeslotEditComponent } from "../../components/track/track-timeslot-edit/track-timeslot-edit.component";
import { TrackTimeslotListComponent } from "../../components/track/track-timeslot-list/track-timeslot-list.component";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";
import * as _ from 'underscore';

@Component({
  selector: "app-track-list",
  templateUrl: "./track-list.component.html",
  styleUrls: ["./track-list.component.css"]
})
export class TrackListComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;

  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  tracks: any;
  tracksInfo: any;
  confID: any;
  dataTable: any;
  selectedValue: any;
  response: any;
  constructor(
    private modalService: NgbModal,
    public cService: ConferenceService,
    public trackService: TrackService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }

  openAddNewTrackFormModal() {
    const modalRef = this.modalService.open(TrackCreateModalComponent);
    modalRef.componentInstance.cId = this.confID; //this._conferenceId;
    modalRef.result
      .then(result => {
        console.log(result);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadTracks(this.confID);
      })
      .catch(error => {
        console.log(error);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadTracks(this.confID);
      });
  }

  openEditTrackFormModal(id: number) {
    console.log(id);
    const modalRef = this.modalService.open(TrackEditModalComponent);
    modalRef.componentInstance.cId = this.confID;
    modalRef.componentInstance.tId = id;
    modalRef.result
      .then(result => {
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadTracks(this.confID);
        console.log(result);
      })
      .catch(error => {
        console.log(error);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadTracks(this.confID);
      });
  }

  openTimeSlotModal(id: number) {
    console.log(id);
    const modalRef = this.modalService.open(TrackTimeslotListComponent, {
        windowClass: "modalWidth",
        backdrop: "static"
    });
    modalRef.componentInstance.ConferenceName = this.ConferenceName;
    modalRef.componentInstance.tId = id;
    modalRef.result
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
  }

  loadConference(typeId: number) {
    this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      //this.Conferences = this.confs.Info;
      this.Conferences= this.confs.Info;
      console.log(this.Conferences);
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      this._conferenceId = this.conferenceItem.ConferenceId;
      this.ConferenceName = this.conferenceItem.ConferenceName;
      console.log(this.conferenceItem.ConferenceId);
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
    });
}

  loadConference1() {
    return this.cService.getConference().subscribe((data: {}) => {
      this.confs = data;
      //this.Conferences = this.confs.Info;
      this.Conferences= _.filter(this.confs.Info, function(obj:any){ return obj.isActive== 1; });
      console.log(this.Conferences);
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      this._conferenceId = this.conferenceItem.ConferenceId;
      this.ConferenceName = this.conferenceItem.ConferenceName;
      console.log(this.conferenceItem.ConferenceId);
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
    });
  }

  getConferenceDetails(id: number) {
    console.log(id);
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      this.confID = this.conferenceDetail.Conference.ConferenceId;
      console.log(this.cInfo);
      //this.bannerContent = this.cInfo.BannerContents; // Conference Description
      this.loadTracks(this.confID);
    });
  }

  onChangeConference(val: any) {
    console.log(val);
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  loadTracks(id: number) {
    this.trackService.getTracksByConferenceId(id).subscribe((data: {}) => {
      this.tracks = data;
      this.tracksInfo = this.tracks.Info;
      console.log(this.tracks);
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#track_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
      pageLength: ConferenceConfig.DEFAULT_PAGE_LENGTH,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.Code)
      .then(confirmed => {
        this.trackService.deleteTrack(obj.Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: "Successfully Deleted", //this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadTracks(this.confID);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
}
