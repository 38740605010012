import { ConferenceService } from "ClientApp/app/services/conference.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConferenceModel,
  ConferenceCategoryModel,
  ConferenceDetailModel
} from "ClientApp/app/models/Conference";
//import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { IMyDpOptions, IMyDateModel } from "mydatepicker";
import { environment } from "ClientApp/environments/environment";
@Component({
  selector: "app-conference-edit",
  templateUrl: "./conference-edit.component.html",
  styleUrls: ["./conference-edit.component.css"]
})
export class ConferenceEditComponent implements OnInit {
  public id: any;
  conferenceEditForm!: FormGroup;
  conferenceModel = new ConferenceModel();
  submitted = false;
  response: any;
  editorConfig: any;
  conf: any = {};
  conferenceTypes: any = [];
  typeId: number = 0;
  year: any;
  conferenceCategories = [];
  dropdownSettings = {};
  //selectedItems = [];{ "CTypeId": number; "CTypeName": string; }[]
  public selectedItems: Array<{
    CTypeId: number | undefined;
    CTypeName: string | undefined;
  }> = [];
  editorList: any = [];
  category = new ConferenceCategoryModel();
  categoryList: Array<ConferenceCategoryModel> = [];
  cModel = new ConferenceDetailModel();
  cContent1: string | undefined;
  cContent2: string | undefined;
    cContent3: string | undefined;
    cContent4: string | undefined;
    cContent5: string | undefined;
  ConfId: any;
  banner: any;
  imageChangedEvent: any;
  selectedConforanceFile: any;
  validImage: boolean | undefined;
  ConforancepreviewPhoto: any;
  years:any =[];
  @ViewChild('conferenceImageFileEdit') conferenceImageEdit: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    public cService: ConferenceService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    let startYear = new Date().getFullYear() - 10;
    let endYear = new Date().getFullYear() + 100;
    for(let i=startYear; i<=endYear; i++ ){
      this.years.push(i);
    }
  }

  //myOptions: IAngularMyDpOptions = {
  public myDatePickerOptions: IMyDpOptions = {
    //dateRange: false,
    dateFormat: "mm-dd-yyyy",
    width: "250px"
    // other options...
  };

  public yearOptions: IMyDpOptions = {
    //dateRange: false,
    dateFormat: "yyyy",
    width: "250px",
    yearSelector: true
    //inputFieldValidation : true
    // other options...
  };

  ngOnInit() {
    const conferenceId = this.route.snapshot.paramMap.get("id");
    
    this.id = conferenceId !== null ? conferenceId : 0;
    this.getConferenceById(this.id);
    this.loadConferenceType();
    this.conferenceEditForm = this.formBuilder.group({
      cName: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      //description: ["", Validators.required],
      cContent1: [""],
      cContent2: [""],
        cContent3: [""],
        cContent4: [""],
        cContent5: [""],
      cCategory: ["", Validators.required],
      cType: ["", Validators.required],
      activeStatus: [""],
      year: [""] //["", Validators.required],
    });

    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: "auto",
      minHeight: "250px",
      maxHeight: "auto",
      width: "auto",
      minWidth: "0",
      translate: "yes",
      enableToolbar: true,
      showToolbar: true,
      placeholder: "Enter text here...",
      defaultParagraphSeparator: "",
      defaultFontName: "Times New Roman",
      defaultFontSize: "",
      fonts: [
        { class: "arial", name: "Arial" },
        { class: "times-new-roman", name: "Times New Roman" },
        { class: "calibri", name: "Calibri" },
        { class: "comic-sans-ms", name: "Comic Sans MS" }
      ],
      // customClasses: [
      //   {
      //     name: "quote",
      //     class: "quote"
      //   },
      //   {
      //     name: "redText",
      //     class: "redText"
      //   },
      //   {
      //     name: "titleText",
      //     class: "titleText",
      //     tag: "h1"
      //   }
      // ],
      //uploadUrl: "v1/image",
      sanitize: false,
      toolbarPosition: "top"
    };

    // this.editorConfig = {
    //   editable: true,
    //   spellcheck: true,
    //   height: "250px",
    //   minHeight: "0",
    //   width: "auto",
    //   minWidth: "0",
    //   translate: "yes",
    //   enableToolbar: true,
    //   showToolbar: true,
    //   placeholder: "Add email template here...",
    //   imageEndPoint: "",
    //   toolbar: [
    //     [
    //       "bold",
    //       "italic",
    //       "underline",
    //       "strikeThrough",
    //       "superscript",
    //       "subscript"
    //     ],
    //     ["fontName", "fontSize", "color"],
    //     [
    //       "justifyLeft",
    //       "justifyCenter",
    //       "justifyRight",
    //       "justifyFull",
    //       "indent",
    //       "outdent"
    //     ],
    //     ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
    //     [
    //       "paragraph",
    //       "blockquote",
    //       "removeBlockquote",
    //       "horizontalLine",
    //       "orderedList",
    //       "unorderedList"
    //     ],
    //     ["link", "unlink"]
    //   ]
    // };

    this.dropdownSettings = {
      singleSelection: false,
      idField: "CTypeId",
      textField: "CTypeName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  loadConferenceCategory() {
    this.cService.getConferenceCategory().subscribe((result: {}) => {
      this.response = result;

      if (this.response.Info.length > 0) {
        this.conferenceCategories = this.response.Info;
        //console.log(this.conferenceCategories);
      }
    });
  }

  async getConferenceById(id: any) {
    this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conf = data;
      if(this.conf.Conference && this.conf.Conference.ConforanceImage){
        this.ConforancepreviewPhoto=environment.imagePath +this.conf.Conference.ConforanceImage;
        this.conferenceModel.ConforanceImage = this.conf.Conference.ConforanceImage;
      }
      this.selectedItems = this.conf.ConferenceCategory;
      this.editorList = this.selectedItems;
      this.ConfId = this.conf.Conference.ConferenceId;
      this.conf.ConferenceCategory.forEach((item: ConferenceCategoryModel) => {
        this.category.CTypeId = item.CTypeId;
        if (item.CTypeId == 1) {
          this.cContent1 = item.BannerContents;
        }
        if (item.CTypeId == 2) {
          this.cContent2 = item.BannerContents;
        }
        if (item.CTypeId == 3) {
          this.cContent3 = item.BannerContents;
        }
        if (item.CTypeId == 4) {
          this.cContent4 = item.BannerContents;
        }
        if (item.CTypeId == 5) {
          this.cContent5 = item.BannerContents;
        }
          
      });

      let smodel = new Date(this.conf.Conference.StartDate);
      let emodel = new Date(this.conf.Conference.EndDate);
      let sDay =
        smodel.getDate() <= 9 ? "0" + smodel.getDate() : smodel.getDate();
      let m = smodel.getMonth() + 1;
      let sMonth = m <= 9 ? "0" + m : m;
      let sDate = sMonth + "-" + sDay + "-" + smodel.getFullYear();
      this.year = smodel.getFullYear();
      let eDay =
        emodel.getDate() <= 9 ? "0" + emodel.getDate() : emodel.getDate();
      let em = emodel.getMonth() + 1;
      let eMonth = em <= 9 ? "0" + em : em;
      let eDate = eMonth + "-" + eDay + "-" + emodel.getFullYear();
      this.typeId = this.conf.Conference.TypeId;

      this.conferenceEditForm.patchValue({
        cType: this.conf.Conference.TypeId,
        cName: this.conf.Conference.ConferenceName,
        startDate: {
          date: {
            year: smodel.getFullYear(),
            month: smodel.getMonth() + 1,
            day: smodel.getDate()
          },
          formatted: sDate
        },
        endDate: {
          date: {
            year: emodel.getFullYear(),
            month: emodel.getMonth() + 1,
            day: emodel.getDate()
          },
          formatted: eDate
        },
        cContent1: this.cContent1,
        cContent2: this.cContent2,
          cContent3: this.cContent3,
          cContent4: this.cContent4,
          cContent5: this.cContent5,
        cCategory: this.selectedItems,
        // year: {
        //   date: {
        //     year: this.conf.Conference.ConferenceYear,
        //     month: 0,
        //     day: 0
        //   }
        // },
        year:this.conf.Conference.ConferenceYear,
        activeStatus: this.conf.Conference.isActive == 1 ? true : false
      });
    });
  }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.conferenceEditForm.controls;
  }

  onDateChanged(event: IMyDateModel) {
    //console.log("onDateChanged(): ", event.date);
    this.year = event.date.year;
  }

  loadConferenceType() {
    this.cService.getConferenceType().subscribe((result: {}) => {
      this.response = result;
      if (this.response.Info.length > 0) {
        this.conferenceTypes = this.response.Info;
        this.loadConferenceCategory();
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.conferenceEditForm.invalid) {
      //console.log("Invalid");
      return;
    } else {
      //console.log(this.conferenceEditForm);
      this.conferenceModel.ConferenceID = this.id;
      this.conferenceModel.ConferenceName = this.conferenceEditForm.value.cName;
      this.conferenceModel.StartDate = this.conferenceEditForm.value.startDate.formatted; //sMonth + "-" + sDay + "-" + sYear;
      this.conferenceModel.EndDate = this.conferenceEditForm.value.endDate.formatted; //eMonth + "-" + eDay + "-" + eYear;
      this.conferenceModel.TypeId = +this.conferenceEditForm.value.cType;
      this.conferenceModel.isActive = this.conferenceEditForm.value.activeStatus
        ? 1
        : 0;
      this.conferenceModel.ShowOnSite = 1;
      this.conferenceModel.AllowInvestorSummary = 0;
      this.conferenceModel.AllowParticipantProfile = 0;
      this.conferenceModel.AllowScheduling = 0;
      //this.conferenceModel.ConferenceYear = this.conferenceEditForm.value.year.date.year;
      this.conferenceModel.BannerUrl = "";
      this.conferenceModel.ConferenceYear = this.conferenceEditForm.value.year;
      this.cModel.Conference = this.conferenceModel;

      if (this.editorList.length > 0) {
        this.editorList.forEach((item: { CTypeId: number | undefined }) => {
          this.category.CTypeId = item.CTypeId;
          let id = item.CTypeId;
          if (item.CTypeId == 1) {
            this.category.BannerContents = this.conferenceEditForm.value.cContent1;
          } else if (item.CTypeId == 2) {
            this.category.BannerContents = this.conferenceEditForm.value.cContent2;
          } else if (item.CTypeId == 3) {
            this.category.BannerContents = this.conferenceEditForm.value.cContent3;
          }
          else if (item.CTypeId == 4) {
              this.category.BannerContents = this.conferenceEditForm.value.cContent4;
            }
          else if (item.CTypeId == 5) {
              this.category.BannerContents = this.conferenceEditForm.value.cContent5;
          }
          this.categoryList.push(this.category);
          this.category = new ConferenceCategoryModel();
        });
        this.cModel.ConferenceCategoryDetails = this.categoryList;
      }

      //console.log(this.cModel);
      this.cModel.Conference.ConforanceImage = this.conferenceModel.ConforanceImage;
      this.updateConference(this.cModel);
    }
  }

  updateConference(conf: ConferenceDetailModel) {
    return this.cService.updateConference(conf).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.router.navigate(["administration/conferences"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }

  onItemSelect(item: any) {
    //console.log(item);
    // this.category = new ConferenceCategoryModel();
    // this.category.CTypeId = item.CTypeId;
    // this.category.BannerContents = item.BannerContents;
    this.editorList.push(item);
    //console.log(this.editorList);
    this.enableOrDisableValidations();
  }
  onSelectAll(items: any) {
    //console.log(items);
    this.editorList = items;
    //console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  onDeSelect(item: any) {
    //console.log(item);
    var pos = this.editorList
      .map(function(e: { CTypeId: number }) {
        return e.CTypeId;
      })
      .indexOf(item.CTypeId);
    this.editorList.splice(pos, 1);
    //console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  enableOrDisableValidations() {}

  // image upload
  openConforenceFileDialogue(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById(
      "upload-conforance-photo"
    ) as HTMLElement;
    element.click();
  }

  public onSelectedFarmFileName(event: any) {
    this.imageChangedEvent = event;
    this.selectedConforanceFile = event.target.files[0];
    this.validImage = true;

    if (this.selectedConforanceFile) {
      let extension = this.selectedConforanceFile.name.split(".").pop();

      if (
        extension.toLowerCase() != "png" &&
        extension.toLowerCase() != "jpg" &&
        extension.toLowerCase() != "jpeg"
      ) {
        alert("Please upload valid image.");
        this.validImage = false;
        return;
      }
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(this.selectedConforanceFile);
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    this.ConforancepreviewPhoto = readerEvt.target.result;
    this.conferenceModel.ConforanceImage = this.ConforancepreviewPhoto.split(
      ","
    )[1];
  }

  removeConferenceImage(): void{
    this.ConforancepreviewPhoto = "";
    this.conferenceModel.ConforanceImage = "";
    this.conferenceImageEdit.nativeElement.value = '';
  }
  //end image upload
}
