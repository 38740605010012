import { Component, OnInit } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
@Component({
  selector: "app-investor-application-conference",
  templateUrl: "./investor-application-conference.component.html",
  styleUrls: ["./investor-application-conference.component.css"]
})
export class InvestorApplicationConferenceComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  constructor(public cService: ConferenceService) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_APPLICATION);
  }

  // Get Conference list
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      this.conferenceItem = this.Conferences[0];
      console.log(this.conferenceItem.ConferenceId);
      //this.getConferenceDetails(this.conferenceItem.ConferenceId);
    });
  }
}
