﻿import { ConferenceService } from "./../../../../../services/conference.service";
import { SelectionService } from "ClientApp/app/services/selection/selection.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SelectionConfirmationModel } from "ClientApp/app/models/Selection";
import { Toast, ToasterService } from "angular2-toaster";
import { environment } from "ClientApp/environments/environment";
import { SummaryService } from "ClientApp/app/services/investor/summary.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";

@Component({
    selector: "app-selection-confirmation",
    templateUrl: "./summary-email-confirmation.html",
    styleUrls: []
})
export class SummaryEmailConfirmationComponent implements OnInit {
    confirmationForm!: FormGroup;
    submitted = false;
    disableBtn = false;
    SummaryConfirmationModel: any = {};
    compId: any;
    response: any;
    conferenceDetail: any;
    banner: any;
    cInfo: any;
    conferenceName: any;
    companyName: any;
    todayDate = new Date();
    constructor(
        private route: ActivatedRoute,
        private selectionService: SelectionService,
        private cService: ConferenceService,
        private toasterService: ToasterService,
        private router: Router,
        private formBuilder: FormBuilder,
        private summaryService: SummaryService
    ) { }

    ngOnInit() {
        const companyId = this.route.snapshot.paramMap.get("compId");
        const conferenceId = this.route.snapshot.paramMap.get("conferenceId");
        this.compId = companyId;
        this.confirmationForm = this.formBuilder.group({
            confirm: ["", Validators.required],
            pitchEdited: ["",wordCountValidator(75)]
        });
        this.getConferenceDetails(companyId, conferenceId);
    }

    getConferenceDetails(companyId: any, conferenceId: any) {
        //this._conferenceId = id;
        return this.selectionService
            .getConferenceMailDetails(companyId, conferenceId)
            .subscribe((data: {}) => {
                console.log('data response--',data);
                
                this.response = data;
                this.conferenceDetail = this.response.CompanyDetails;
                console.log(this.conferenceDetail);
                this.conferenceName = this.conferenceDetail.ConferenceName;
                this.companyName = this.conferenceDetail.CompanyName;
                this.confirmationForm.patchValue({
                    pitchEdited: this.conferenceDetail.InvestorPropositionEdited
                });
                if (this.conferenceDetail.ConforanceImage) {
                    this.banner =
                        environment.imagePath + this.conferenceDetail.ConforanceImage;
                }
                this.todayDate = new Date();
            });
    }

    onSubmit() {
        debugger;
        this.submitted = true;
        if (this.confirmationForm.invalid) {
            return;
        } else {
            this.SummaryConfirmationModel.CompanyId = this.compId;
            this.SummaryConfirmationModel.IsPitchApproved = +this.confirmationForm.value.confirm;
            this.SummaryConfirmationModel.InvestorPropositionEdited = this.confirmationForm.value.pitchEdited;
            this.updateConfirmation(this.SummaryConfirmationModel);
        }
    }
    updateConfirmation(model: any) {
        this.disableBtn = true;
        return this.summaryService
            .updateEmail50Confirmation(model)
            .subscribe(result => {
                this.response = result;
                if (this.response.Code == 200) {
                    this.router.navigate(["summaryemail/confirmationSuccess"]);
                } else {
                    var toast: Toast = {
                        type: "error",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                }
                this.disableBtn = false;
            });
    }
    get fc() {
        // get field control(fc)
        return this.confirmationForm.controls;
    }

 
}
