import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailEventService } from 'ClientApp/app/services/template/email-event.service';
import { ToasterService, Toast } from 'angular2-toaster';
import { Router } from '@angular/router';
import { ConferenceService } from 'ClientApp/app/services/conference.service';
import { EmailEvent } from 'ClientApp/app/models/EmailEvent';
import * as _ from 'underscore';
import { ConferenceModel } from "ClientApp/app/models/Conference";

@Component({
  selector: 'app-email-event-create',
  templateUrl: './email-event-create.component.html',
  styleUrls: ['./email-event-create.component.css']
})
export class EmailEventCreateComponent implements OnInit {
  
  constructor(private formBuilder: FormBuilder,
    public cService: ConferenceService,
    private emailEventService: EmailEventService,
    private toasterService: ToasterService,
    private router: Router) { 
    
  }

  emailEventForm: FormGroup;
  Conferences: any = [];
  submitted: boolean = false;
  emailEventModel = new EmailEvent();
  dropdownSettings = {};
  selectedConferenceList:any = [];
  
  ngOnInit() {
    this.emailEventForm = this.formBuilder.group({
      tName: ["", Validators.required],
      tCaption: ["", Validators.required],
      // tConference: ["", Validators.required],
      tBccMail: ["", Validators.required]
    });
    this.loadConferences();

    this.dropdownSettings = {
      singleSelection: false,
      idField: "ConferenceId",
      textField: "ConferenceName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  loadConferences() {
    this.cService.getConference().subscribe((result: any) => {
      if (result.Info.length > 0) {
        this.Conferences = _.filter(result.Info, function(obj:any){ return obj.isActive== 1; });
      }
    });
  }

  get fc() {
    // get field control(fc)
    return this.emailEventForm.controls;
  }

  onSubmit() {
    //console.log('test Submit');
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.emailEventForm.invalid) {
      //console.log('form invalid');
      
      return;
    } else {
      //console.log('else---');
      
      let conferenceList: any = [];
      this.emailEventModel.EventName = this.emailEventForm.value.tName;
      this.emailEventModel.Caption = this.emailEventForm.value.tCaption;
      // this.emailEventModel.ConferenceId = this.emailEventForm.value.tConference;
      this.emailEventModel.BccMailId = this.emailEventForm.value.tBccMail;
      this.selectedConferenceList.forEach((element: any) => {
        let record = new ConferenceModel();
        // record.ConferenceId = element.ConferenceId;
        // record.ConferenceName = element.ConferenceName;
         record.ConferenceId = 0;
        record.ConferenceName = "BioFuture 20";
        conferenceList.push(record);
      });
     
      
      this.emailEventModel.ConferenceDetail = conferenceList;

//console.log('conferencelist---',conferenceList);

      this.createEmailEvent(this.emailEventModel);
    }
  }

  createEmailEvent(emailEvent: EmailEvent){
    //console.log('create email.ts start',emailEvent);
    
     return this.emailEventService.createEmailEvent(emailEvent).subscribe((response: any) => {
       console.log('res--',response);
       
      if (response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.router.navigate(["email/emailevent"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: response.Message,
          showCloseButton: true
        };
        //console.log('toast--',toast);
        
        this.toasterService.pop(toast);
      }
     });
  }

  onItemSelect(item: any) {
    this.selectedConferenceList.push(item);
  }
  onSelectAll(items: any) {
    this.selectedConferenceList = items;
  }

  onDeSelect(item: any) {
    var pos = this.selectedConferenceList
      .map(function(e: { ConferenceId: number }) {
        return e.ConferenceId;
      })
      .indexOf(item.ConferenceId);
    this.selectedConferenceList.splice(pos, 1);
  }

}
