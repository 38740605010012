import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEditFormComponent } from "../add-edit-form/add-edit-form.component";

@Component({
  selector: "app-add-asset",
  templateUrl: "./add-asset.component.html",
  styleUrls: ["./add-asset.component.css"]
})
export class AddAssetComponent implements OnInit {
  @Input() rowObj: any;
  @Input() update: boolean;
  public addAssetForm!: AddEditFormComponent;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    console.log("rrrr "+this.rowObj);
    console.log("update "+this.update);
    //console.log("Inside parent AddAssetComponent" + this.test);
  }

  // closeModal() {
  //   this.activeModal.close("Modal Closed");
  //   console.dir(this.test);
  // }
}
