import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { TrackService } from "ClientApp/app/services/track/track.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TrackTimeslotCreateComponent } from "../track-timeslot-create/track-timeslot-create.component";
import { TrackTimeslotEditComponent } from "../track-timeslot-edit/track-timeslot-edit.component";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
//import { TrackTimeslotEditComponent } from "ClientApp/app/modules/administration";
//import { TrackTimeslotCreateComponent } from "ClientApp/app/modules/administration";

@Component({
  selector: "app-track-timeslot-list",
  templateUrl: "./track-timeslot-list.component.html",
  styleUrls: ["./track-timeslot-list.component.css"]
})
export class TrackTimeslotListComponent implements OnInit {
  @Input() tId: number = 0;
  @Input() slotId: number = 0;
  @Input() ConferenceName: string | undefined;

  response: any;
  trackresponse: any;
  slots: any = [];
  TrackName: any;
  dataTable: any;
  selectedPageSize: any;
  constructor(
    public trackService: TrackService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    // console.log("TrackId " + this.tId);
    // console.log("ConferenceName " + this.ConferenceName);
    this.loadTimeSlots(this.tId);
  }

  // Get timeslots list by trackid
  loadTimeSlots(trackId: number, isRefresh?: boolean) {
    this.trackService.getTrackById(trackId).subscribe((data: {}) => {
      this.trackresponse = data;
      this.TrackName = this.trackresponse.Info.Code;
    });
    return this.trackService.getTrackSlots(trackId).subscribe((data: {}) => {
      this.response = data;
      //console.log(this.response.Info);
      if (this.response.Info != null && this.response.Info.length > 0) {
        if (isRefresh && this.dataTable) {
            this.dataTable.destroy();
        }
        this.slots = this.response.Info;
        this.enablePagination();
      }
    });
  }

  openAddNewTimeSlotModal() {
    //console.log(id);
    const modalRef = this.modalService.open(TrackTimeslotCreateComponent);
    // modalRef.componentInstance.cId = this._conferenceId;
    modalRef.componentInstance.trackId = this.tId;
    modalRef.result
      .then(result => {
        //console.log(result);
        this.loadTimeSlots(this.tId, true);
      })
      .catch(error => {
        //console.log(error);
        this.loadTimeSlots(this.tId, true);
      });
  }

  openEditTrackFormModal(slotId: number) {
    const modalRef = this.modalService.open(TrackTimeslotEditComponent);
    modalRef.componentInstance.trackId = this.tId;
    modalRef.componentInstance.slotId = slotId;
    modalRef.result
      .then(result => {
        this.loadTimeSlots(this.tId, true);
      })
      .catch(error => {
        this.loadTimeSlots(this.tId, true);
      });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#slot_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
      "order": [],
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });

    $('#slot_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
  } );
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.Date + " " + obj.Time)
      .then(confirmed => {
        this.trackService.deleteSlot(obj.Timeslot_Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: "Successfully Deleted", //this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadTimeSlots(this.tId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
}
