import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { ToasterService, Toast } from "angular2-toaster";
import { Profile } from "ClientApp/app/models/Profile";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { environment } from "ClientApp/environments/environment";
import { UtilService } from "ClientApp/app/services/common/util.service";
import * as  _ from "underscore";
@Component({
    selector: "app-profile-edit",
    templateUrl: "./profile-edit.component.html",
    styleUrls: ["./profile-edit.component.css"]
})
export class ProfileEditComponent implements OnInit {
    _conferenceId: number = 0;
    bannerContent: any;
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    profileEditForm!: FormGroup;
    submitted = false;
    disableBtn = false;
    profile = new Profile();
    response: any;
    validationError: boolean = false;
    public id!: any;
    conferenceID: any;
    banner: string = "";

    imageChangedEvent: any;
    selectedConferenceFile: any;
    validImage: boolean | undefined;
    previewPhoto: any;
    conferenceIsActive: any;
    vData: any;
    loginuser: any;
    stockExchanges: any = [];
    private ownershipRadio: string = "Public";
    @ViewChild('profilePhoto') profilePhoto: ElementRef;

    constructor(
        public cService: ConferenceService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private toasterService: ToasterService,
        private utilService: UtilService,
        private element: ElementRef,
        private router: Router
    ) { }

    ngOnInit() {
        const profileId = this.route.snapshot.paramMap.get("id");
        this.id = profileId !== null ? profileId : 0;
        this.loginuser = localStorage.getItem("currentUser");
        this.getStockExchanges();
        this.getProfileDetails(this.id);
        this.profileEditForm = this.formBuilder.group({
            // Presenter Details
            pdsalutation: ["", Validators.required],
            pdfirstName: ["", Validators.required],
            pdnickName: [""],
            pdmiddleName: [""],
            pdlastName: ["", Validators.required],
            pdsuffix: [""],
            pdbadgeName: [""],
            pdjobTitle: [""],
            pdcontactEmail: ["", [Validators.required, Validators.email]],

            // Company Details
            pdcompanyName: ["", Validators.required],
            ownershipOptions: [""],
            ipoYear: [""],
            psExchange: ["", Validators.required],
            stcother: [""],
            tickerSymbol: ["", Validators.required],
            pdcompanyWebsite: [""],
            pdAddress1: [""],
            pdAddress2: [""],
            city: [""],
            state: [""],
            country: [""],
            zip: [""],
            pddirectPhone: [""],
            pdcompanyDescription: ["", wordCountValidator(200)],

            // Personal Details
            personalBiography: ["", wordCountValidator(200)],
            filename: [""]
        });
    }
    getStockExchanges() {
        this.profileService.getStockExchanges().subscribe((data: {}) => {
            this.response = data;
            this.stockExchanges = this.response.Info;
        });
    }
    getProfileDetails(profileId: number) {
        this.profileService
            .getProfileDetailsById(profileId)
            .subscribe((data: {}) => {
                this.response = data;
                //this.id = this.response.Info.Id;
                this._conferenceId = this.response.Info.Conference_Id;
                this.conferenceID = this.response.Info.ConferenceID;
                if (this.response.Info && this.response.Info.Photo) {
                    this.previewPhoto =
                        environment.profileImagePath + this.response.Info.Photo;
                    this.profile.Photo = this.response.Info.Photo;
                }
                this.getConferenceDetails(this.conferenceID);
                this.profileEditForm.patchValue({
                    pdsalutation: this.response.Info.PrefixName,
                    pdfirstName: this.response.Info.FirstName,
                    pdmiddleName: this.response.Info.MiddleName,
                    pdnickName: this.response.Info.NickName,
                    pdlastName: this.response.Info.LastName,
                    pdsuffix: this.response.Info.SuffixName,
                    //pdsalutation: this.response.Info.Conference_Id,
                    pdcontactEmail: this.response.Info.Email,
                    pdbadgeName: this.response.Info.BadgeName,
                    pdjobTitle: this.response.Info.JobTitle,
                    pdcompanyName: this.response.Info.CompanyName,
                    ownershipOptions: this.response.Info.Ownership,
                    psExchange: _.find(this.stockExchanges, { id: parseInt(this.response.Info.Exchange) }),
                    ipoYear: this.response.Info.IPOYear,
                    tickerSymbol: this.response.Info.Ticker,
                    pdcompanyWebsite: this.response.Info.Website,
                    pdAddress1: this.response.Info.Address1,
                    pdAddress2: this.response.Info.Address2,
                    city: this.response.Info.City,
                    state: this.response.Info.State,
                    country: this.response.Info.Country,
                    zip: this.response.Info.PostalCode,
                    pddirectPhone: this.response.Info.DirectPhone,
                    pdcompanyDescription: this.response.Info.CompanyDescription,
                    personalBiography: this.response.Info.Bio,
                    stcother: this.response.Info.ExchangeOther
                });
                this.setradio(this.response.Info.Ownership);
            });
    }

    validateConference(confId: any) {
        this.cService.validateConference(confId).subscribe((data: {}) => {
            this.vData = data;
            console.log(this.vData);
            if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
                this.router.navigate(["/conference/status/" + this._conferenceId]);
            }
        });
    }

    getConferenceDetails(id: number) {
        console.log(id);
        //this._conferenceId = id;
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            if (
                this.conferenceDetail.Conference &&
                this.conferenceDetail.Conference.ConforanceImage
            ) {
                this.banner =
                    environment.imagePath +
                    this.conferenceDetail.Conference.ConforanceImage;
            }
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            if (this.cInfo.length > 0) {
                this.cInfo.forEach((item: any) => {
                    if (item.CTypeId == 3) {
                        this.bannerContent = item.BannerContents; // Conference Description
                    }
                });
            }
            let confId = this.conferenceDetail.Conference.ConferenceId;
            this.validateConference(confId);
        });
    }

    get fc() {
        // get field control(fc)
        return this.profileEditForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.validationError = this.profileEditForm.invalid;
        // stop here if form is invalid
        if (this.profileEditForm.invalid) {
            console.log("Invalid");
            this.utilService.showValidationDiv();
            // let el = this.element.nativeElement.querySelector(".validation-errors");
            // el.scrollIntoView();
            return;
        } else {
            this.validationError = false;
            this.profile.ProfileId = this.id;
            this.profile.PrefixName = this.profileEditForm.value.pdsalutation;
            this.profile.FirstName = this.profileEditForm.value.pdfirstName;
            this.profile.MiddleName = this.profileEditForm.value.pdmiddleName;
            this.profile.NickName = this.profileEditForm.value.pdnickName;
            this.profile.LastName = this.profileEditForm.value.pdlastName;
            this.profile.SuffixName = this.profileEditForm.value.pdsuffix;

            this.profile.Conference_Id = this._conferenceId;
            this.profile.Email = this.profileEditForm.value.pdcontactEmail;
            this.profile.BadgeName = this.profileEditForm.value.pdbadgeName;
            this.profile.JobTitle = this.profileEditForm.value.pdjobTitle;
            this.profile.CompanyName = this.profileEditForm.value.pdcompanyName;
            this.profile.Ownership = this.profileEditForm.value.ownershipOptions;
            this.profile.IPOYear = this.profileEditForm.value.ipoYear;
            this.profile.Exchange =
                this.profileEditForm.value.psExchange != null
                ? this.profileEditForm.value.psExchange.id
                    : "";
            this.profile.Ticker = this.profileEditForm.value.tickerSymbol;
            this.profile.ExchangeOther = this.profileEditForm.value.stcother;
            this.profile.Website = this.profileEditForm.value.pdcompanyWebsite;
            this.profile.Address1 = this.profileEditForm.value.pdAddress1;
            this.profile.Address2 = this.profileEditForm.value.pdAddress2;
            this.profile.City = this.profileEditForm.value.city;
            this.profile.State = this.profileEditForm.value.state;
            this.profile.Country = this.profileEditForm.value.country;
            this.profile.PostalCode = this.profileEditForm.value.zip;
            this.profile.DirectPhone = this.profileEditForm.value.pddirectPhone;
            this.profile.CompanyDescription = this.profileEditForm.value.pdcompanyDescription;
            this.profile.Bio = this.profileEditForm.value.personalBiography;
            this.updateProfile(this.profile);
        }
    }

    updateProfile(profile: Profile) {
        console.log("In updateProfile....");
        this.disableBtn = true;
        return this.profileService.updateProfile(profile).subscribe(result => {
            // This code will be executed when the HTTP call returns successfully
            this.response = result;
            if (this.response.Code == 200) {
                var toast: Toast = {
                    type: "success",
                    title: this.response.Message,
                    showCloseButton: true
                };

                this.toasterService.pop(toast);
            } else {
                var toast: Toast = {
                    type: "error",
                    title: this.response.Message,
                    showCloseButton: true
                };
                this.toasterService.pop(toast);
            }
            this.disableBtn = false;
        });
    }

    // image upload
    openFileDialogue(event: any) {
        event.preventDefault();
        let element: HTMLElement = document.getElementById(
            "upload-photo"
        ) as HTMLElement;
        element.click();
    }

    public onSelectedFileName(event: any) {
        this.imageChangedEvent = event;
        this.selectedConferenceFile = event.target.files[0];
        this.validImage = true;

        if (this.selectedConferenceFile) {
            let extension = this.selectedConferenceFile.name.split(".").pop();

            if (
                extension.toLowerCase() != "png" &&
                extension.toLowerCase() != "jpg" &&
                extension.toLowerCase() != "jpeg"
            ) {
                alert("Please upload valid image.");
                this.validImage = false;
                return;
            }
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(this.selectedConferenceFile);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        this.previewPhoto = readerEvt.target.result;
        this.profile.Photo = this.previewPhoto.split(",")[1];
    }

    removeConferenceImage(): void {
        this.previewPhoto = "";
        this.profile.Photo = "";
        this.profilePhoto.nativeElement.value = '';
    }

    isSelected(name: string): boolean {
        if (!this.ownershipRadio) {
            // if no radio button is selected, always return false so nothing is shown
            return false;
        }
        return this.ownershipRadio === name; // if current radio button is selected, return true, else return false
    }

    setradio(e: string): void {
        this.ownershipRadio = e;
        if (e !== "Private") {
            this.profileEditForm.controls["psExchange"].setValidators([
                Validators.required
            ]);
            this.profileEditForm.controls["tickerSymbol"].setValidators([
                Validators.required
            ]);
        } else {
            this.profileEditForm.controls["psExchange"].setValidators([]);
            this.profileEditForm.controls["tickerSymbol"].setValidators([]);
        }
        this.profileEditForm.controls["psExchange"].updateValueAndValidity();
        this.profileEditForm.controls["tickerSymbol"].updateValueAndValidity();
    }

    isOtherStock(): boolean {
        if (this.profileEditForm.value.psExchange.id == "21") {
            return true;
        }
        return false;
    }

}
