import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class UtilService {
  apiURL = environment.baseUrl;
  validationErrorsElement: any | undefined;
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  loadCountries() {
    return this.http.get(this.apiURL + "/InvestorSummary/GetCountry/");
  }

  loadStates(countryCode: string) {
    return this.http.get(
      this.apiURL + "/InvestorSummary/GetStates/" + countryCode
    );
  }

  getDashboardTracks(conferenceId: any) {
    return this.http.get(
      this.apiURL + "/Dashboard/ListAllTracks/" + conferenceId
    );
  }

  showValidationDiv() {
    this.validationErrorsElement = document.getElementById("validationErrors");
    this.validationErrorsElement.scrollIntoView();
  }
  showCopyRight(){
    return this.http.get(
      this.apiURL + "/copyright/copyrightdtls");
  }
}
