export class CompanySelection {
  ChooseCompany: Array<SelectionItem> | undefined;
}

export class SelectionItem {
  CompId: number | undefined;
  IsSelected: number | undefined;
  ToBeScheduled: number | undefined;
  SelectionRound: number | undefined;
}

export class SelectionConfirmationModel {
  CompanyId: any | undefined;
  Confirmed: number | undefined;
  ConfirmationResponse: any | undefined;
}
