export class Application {
  companyDetails: CompanyDetails = new CompanyDetails();
  contactDetails: ContactDetails = new ContactDetails();
  assistantDetails: AssistantDetails = new AssistantDetails();
  investorDetails: InvestorDetails = new InvestorDetails();
  FoundRoundList: FoundRoundList = new FoundRoundList();
  TimeFrameList: TimeFrameList = new TimeFrameList();
  Documents: any = [];
}
export class CompanyDetails {
  Id: number | undefined;
  ApplicationId: string | undefined;
  CompanyId: string | undefined;
  Name: string | undefined;
  Website: string | undefined;
  Conference_Id: number | undefined;
  ConferenceID: string | undefined;
  IsSelected: boolean | 0 = false;
  CompanyMessage: string | undefined;
  LinkPublication: string | undefined;
  ApplicationDate: any;
  ParticipationMethod: number | undefined;
}

export class ContactDetails {
  Title: string | undefined;
  FirstName: string | undefined;
  LastName: string | undefined;
  Position: string | undefined;
  Department: string | undefined;
  Email: string | undefined;
  Country: string | undefined;
  CountryCode: number | undefined;
  StreetAddress: string | undefined;
  Suite: string | undefined;
  City: string | undefined;
  State: string | undefined;
  StateCode: number | undefined;
  Zip: string | undefined;
  ContactPhone: string | undefined;
  TwitterProfile: string | undefined;
  LinkedInProfile: string | undefined;
}

export class AssistantDetails {
  Title: string | undefined;
  FirstName: string | undefined;
  LastName: string | undefined;
  ContactEmail: string | undefined;
  ContactPhone: string | undefined;
}

export class InvestorDetails {
  IsAccreditedInvestor: boolean | undefined;
  InvestorType: string | undefined;
  InvestorTypeOther: string | undefined;
  FundInvested: string | undefined;
  PensionInvested: string | undefined;
  FamilyOfficeInvested: string | undefined;
  VentureInvested: string | undefined;
  AngelInvested: string | undefined;
  CharitableInvested: string | undefined;
  FINRAInvested: string | undefined;
  AnalystFundInvested: string | undefined;
  CrowdFundingInvested: string | undefined;
  AssetsUnderManagement: {
    id: number;
    description: string;
  };
  AdditionalComments: string | undefined;
  Therapeutics: {
    id: number;
    description: string;
  };
  TherapeuticsOther: string | undefined;
}

export class FoundRoundList {
  FundingRound: string | undefined;
}

export class TimeFrameList {
  TimeFrame: string | undefined;
}

export class ApplicationListUpdateModel {
  InvestorSelections: Array<ApplicationEmailItem> | undefined;
}

export class ApplicationEmailItem {
  Id: number | undefined;
  IsSelected: number | undefined;
}

export class AppValidationModel {
  contactDetails: PresentationDetailModel = new PresentationDetailModel();
  companyDetails: CompanyDetailModel = new CompanyDetailModel();
}

export class PresentationDetailModel {
  Email: string | undefined;
  ContactPhone: string | undefined;
}
export class CompanyDetailModel {
  ConferenceID: any;
  Name: string | undefined;
  CompanyMessage: string | undefined;
  LinkPublication: string | undefined;
  ApplicationDate: any;
}
export class PressAccreditationEmailItem {
  Id: number | undefined;
  IsSelected: number | undefined;
}
export class PressAccreditationListUpdateModel {
  PressAccreditationSelections: Array<PressAccreditationEmailItem> | undefined;
}
