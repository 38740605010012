import { AppComponent } from "./app.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./auth/auth.guard";
import { LoginComponent } from "./modules/login/login.component";
import { UrlRewriteComponent } from "./modules/url-rewrite/url-rewrite.component";
import { UrlRewriteIaComponent } from "./modules/url-rewrite-ia/url-rewrite-ia.component";
import { DashboardComponent } from "./modules/dashboard/dashboard.component";

import { LoginLayoutComponent } from "./shared/layouts/login-layout/login-layout.component";
import { HomeLayoutComponent } from "./shared/layouts/home-layout/home-layout.component";
//import { InvestorSummaryComponent } from "./modules/conference/investor/pages/investor-summary/investor-summary.component";
//import {  } from "./modules/investor-application/investor-application.component";
import { SummaryEmailsComponent } from "./modules/summary-emails/summary-emails.component";
//import { InvestorSummaryListComponent } from "./modules/conference/investor/components/investor-summary-list/investor-summary-list.component";
import { EmailSummaryComponent } from "./modules/conference/investor/components/email-summary/email-summary.component";
//import { SummaryEditsComponent } from "./modules/conference/investor/pages/summary-edits/summary-edits.component";
import { ApplicationListComponent } from "./modules/conference/investor/components/application-list/application-list.component";
import { ApplicationEmailsComponent } from "./modules/conference/investor/components/application-emails/application-emails.component";

// Investor Module
import {
  InvestorSummaryComponent,
  InvestorSummaryListComponent,
  InvestorSummaryEditComponent,
  InvestorApplicationComponent,
  InvestorSummaryConferenceComponent,
  InvestorApplicationConferenceComponent,
  SummaryEditsComponent
} from "./modules/conference/investor";
// Schedule Module
import {
  SelectionCompanyListComponent,
  SelectionEmailComponent,
  SelectionEmailNotselectedComponent
} from "./modules/conference/selection";
import { ReportComponent } from "./modules/report/report.component";
//import { EmailTemplateComponent } from "./modules/template/pages/email-template/email-template.component";
import {
  EmailTemplateCreateComponent,
  EmailTemplateComponent
} from "./modules/template";
import { InvestorApplicationEditComponent } from "./modules/conference/investor/pages/investor-application-edit/investor-application-edit.component";
import { EmailTemplateEditComponent } from "./modules/template/pages/email-template-edit/email-template-edit.component";
import {
  OpenTracksComponent,
  AllTracksComponent,
  ScheduleEmailComponent
} from "./modules/conference/schedule";
import {
  ConferenceCreateComponent,
  ConferenceListComponent,
  ConferenceEditComponent,
  TrackListComponent,
  ReportTemplateListComponent,
  UserListComponent,
  EBDConfigurationListComponent,
  EBDConfigurationCreateComponent,
  EBDConfigurationEditComponent
} from "./modules/administration";
import {
  ProfileConferenceComponent,
  ProfileComponent,
  ProfileListComponent
} from "./modules/conference/profile";
import { ProfileEditComponent } from "./modules/conference/profile/pages/profile-edit/profile-edit.component";
import { InvitationConferenceComponent } from "./modules/conference/profile/pages/invitation-conference/invitation-conference.component";
import { InvitationComponent } from "./modules/conference/profile/pages/invitation/invitation.component";
import { InvitationListComponent } from "./modules/conference/profile/pages/invitation-list/invitation-list.component";
import { InvitationEditComponent } from "./modules/conference/profile/pages/invitation-edit/invitation-edit.component";
import { ConferenceTypeListComponent } from "./modules/administration/conference/pages/conference-type-list/conference-type-list.component";
import { SuccessComponent } from "./shared/pages/success/success.component";
import { SelectionConfirmationComponent } from "./modules/conference/selection/pages/selection-confirmation/selection-confirmation.component";
import { SelectionConfirmationSuccessComponent } from "./modules/conference/selection/pages/selection-confirmation-success/selection-confirmation-success.component";
import { ConferenceUploadComponent } from "./modules/administration/conference/pages/conference-upload/conference-upload.component";
import { ScheduleConfirmationSuccessComponent } from "./modules/conference/schedule/pages/schedule-confirmation-success/schedule-confirmation-success.component";
import { ScheduleConfirmationComponent } from "./modules/conference/schedule/pages/schedule-confirmation/schedule-confirmation.component";
import { ScheduleConfirmationDoneComponent } from "./modules/conference/schedule/pages/schedule-confirmation-done/schedule-confirmation-done.component";
import { ChangePasswordComponent } from "./modules/administration/user/pages/change-password/change-password.component";
import { ConferenceStatusComponent } from "./shared/pages/conference-status/conference-status.component";
import {UserRoleComponent} from './modules/administration/user-role/user-role.component'
import { SummaryEmailConfirmationComponent } from "./modules/conference/investor/pages/summary-email-confirmation/summary-email-confirmation.component";
import { EmailConfirmationSuccessComponent } from "./modules/conference/investor/pages/email-confirmation-success/email-confirmation-success.component";
import { PressAccreditationConferenceComponent } from "./modules/conference/investor/pages/press-accreditation-conference/press-accreditation-conference.component";
import { PressAccreditationComponent } from "./modules/conference/investor/pages/press-accreditation/press-accreditation.component";
import { PressAccreditationListComponent } from "./modules/conference/investor/pages/press-accreditation-list/press-accreditation-list.component";
import { PressAccreditationEditComponent } from "./modules/conference/investor/pages/press-accreditation-edit/press-accreditation-edit.component";
import { PressAccreditationEmailsComponent } from "./modules/conference/investor/components/accreditation-emails/accreditation-email.component";
import { AccreditationEmailConfirmationComponent } from "./modules/conference/investor/components/accreditation-email-confirmation/accreditation-email-confirmation.component";
import { EmailEventComponent } from "./modules/template/pages/email-event/email-event.component";
import { EmailEventCreateComponent } from "./modules/template/pages/email-event-create/email-event-create.component";
import { EmailEventEditComponent } from "./modules/template/pages/email-event-edit/email-event-edit.component";

// const routes: Routes = [
//   //{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
//   { path: "login", component: LoginComponent }
// ];

// const routes: Routes = [
//   { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
//   { path: 'login', component: LoginComponent },
//   { path: '**', redirectTo: ''}
// ];

const routes: Routes = [
  {
    path: "",
    component: LoginComponent
  },
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      {
        path: "urlRewrite",
        component: UrlRewriteComponent
      },
      {
        path: "urlRewriteIa",
        component: UrlRewriteIaComponent
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "investor/investorSummary",
        component: InvestorSummaryConferenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "investor/investorSummary/success/:confId/:type/:Id",
        component: SuccessComponent
      },
      {
        path: "investor/investorSummary/:confId",
        component: InvestorSummaryComponent
      },
      {
        path: "investor/investorSummary/:confId/:compId",
        component: InvestorSummaryComponent
      },
      {
        path: "investorsummaryedit/:id",
        component: InvestorSummaryEditComponent
      },
      {
        path: "investorsummarydelete/:id",
        component: InvestorSummaryEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "investor/investorApplication",
        component: InvestorApplicationConferenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "investor/investorApplication/success/:confId/:type/:Id",
        component: SuccessComponent
      },
      {
        path: "investor/investorApplication/:id",
        component: InvestorApplicationComponent
      },
      {
        path: "investorapplicationedit/:id",
        component: InvestorApplicationEditComponent
      },
      {
        path: "summaryemails",
        component: SummaryEmailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "summarylist",
        component: InvestorSummaryListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "emailsummary",
        component: EmailSummaryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "summaryedits",
        component: SummaryEditsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "applications",
        component: ApplicationListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "applicationemails",
        component: ApplicationEmailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "report",
        component: ReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "email/template",
        component: EmailTemplateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "email/template/create",
        component: EmailTemplateCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "email/template/:id",
        component: EmailTemplateEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "email/emailevent",
        component: EmailEventComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "email/emailevent/create",
        component: EmailEventCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "email/emailevent/:id",
        component: EmailEventEditComponent,
        canActivate: [AuthGuard]
      },
      // Schedule Module
      {
        path: "selections",
        component: SelectionCompanyListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "opentracks",
        component: OpenTracksComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "alltracks",
        component: AllTracksComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "scheduleEmails",
        component: ScheduleEmailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "selection/email",
        component: SelectionEmailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "selection/notSelectedEmail",
        component: SelectionEmailNotselectedComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/conference/create",
        component: ConferenceCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/conferences",
        component: ConferenceListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/conference/edit/:id",
        component: ConferenceEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/tracks",
        component: TrackListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/report/templates",
        component: ReportTemplateListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "profile",
        component: ProfileConferenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "profile/participantprofile/:id",
        component: ProfileComponent
      },
      {
        path: "profile/participantprofile/success/:confId/:type/:Id",
        component: SuccessComponent
      },
      {
        path: "profile/participantprofileedit/:id",
        component: ProfileEditComponent
      },
      {
        path: "profile/participantprofilelist",
        component: ProfileListComponent,
        canActivate: [AuthGuard]
      },
      {
          path: "invitation",
        component: InvitationConferenceComponent,
        canActivate: [AuthGuard]
      },
      {
          path: "invitation/addinvitation/success/:confId/:type/:Id",
        component: SuccessComponent
      },
      {
        path: "invitation/addinvitation/:id",
        component: InvitationComponent
      },
      {
          path: "invitation/invitationlist",
        component: InvitationListComponent,
        canActivate: [AuthGuard]
      },
      {
          path: "invitation/invitationedit/:id",
        component: InvitationEditComponent
      },
      {
        path: "administration/users",
        component: UserListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/conferencetype",
        component: ConferenceTypeListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/EBDconfiguration-list",
        component: EBDConfigurationListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/EBDconfiguration-create",
        component: EBDConfigurationCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "administration/EBDconfiguration-edit/:id",
        component: EBDConfigurationEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "selection/confirmation/Gr6bpihQ_RrqQRJdcp2hrg/tS6ZMFrWmAPAVa-XBj1wXg",
        redirectTo: "selection/confirmation/rxf81jFgNBShw9Br_2Dh5A/tS6ZMFrWmAPAVa-XBj1wXg"
      },
      {
        path: "selection/confirmation/tn3lbjllL_h2InxX50EzXg/tS6ZMFrWmAPAVa-XBj1wXg",
        redirectTo: "selection/confirmation/LluOwzuEazw4EYsDMK1sTw/tS6ZMFrWmAPAVa-XBj1wXg"
      },
      {
        path: "selection/confirmation/tGlWxfhspXT89JZwTL_53w/tS6ZMFrWmAPAVa-XBj1wXg",
        redirectTo: "selection/confirmation/MXnoTGp1vP2RyL15oHhekQ/tS6ZMFrWmAPAVa-XBj1wXg"
      },
      {
        path: "selection/confirmation/:compId/:conferenceId",
        component: SelectionConfirmationComponent
      },
      {
        path: "selection/confirmationSuccess",
        component: SelectionConfirmationSuccessComponent
      },
      {
        path: "selection/confirmationSuccess/:conferenceId",
        component: SelectionConfirmationSuccessComponent
      },
      {
        path: "conference/upload",
        component: ConferenceUploadComponent,
        canActivate: [AuthGuard]
      },
      {
        //path: "schedule/confirmation/:compId",
        path: "schedule/confirmation/:conferenceId/:scheduledId",
        component: ScheduleConfirmationComponent
      },
      {
        path: "schedule/confirmationSuccess",
        component: ScheduleConfirmationSuccessComponent
      },
      {
        path: "schedule/confirmationDone",
        component: ScheduleConfirmationDoneComponent
      },
      {
        path: "user/changePassword",
        component: ChangePasswordComponent
      },
      {
        path: "conference/status/:id",
        component: ConferenceStatusComponent
      },
      {
        path: "administration/userRole",
        component: UserRoleComponent,
        canActivate: [AuthGuard]
        },
        {
            path: "summaryemail/confirmation/:compId/:conferenceId",
            component: SummaryEmailConfirmationComponent
        },
        {
            path: "summaryemail/confirmationSuccess",
            component: EmailConfirmationSuccessComponent
        },
        {
            path: "accreditation",
            component: PressAccreditationConferenceComponent,
            canActivate: [AuthGuard]
        },
        {
            path: "accreditation/:id",
            component: PressAccreditationComponent
        },
        {
            path: "accreditation/success/:confId/:type/:Id",
            component: SuccessComponent
        },
        {
            path: "pressaccreditations",
            component: PressAccreditationListComponent,
            canActivate: [AuthGuard]
        },
        {
            path: "pressacccreditationedit/:id",
            component: PressAccreditationEditComponent
        },
        {
            path: "accreditationemails",
            component: PressAccreditationEmailsComponent,
            canActivate: [AuthGuard]
        },
        {
            path: "accreditationemail/confirmation/:compId/:conferenceId",
            component: AccreditationEmailConfirmationComponent
        }

    ]
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "dashboard" }
  // {
  //   path: "",
  //   component: LoginLayoutComponent,
  //   children: [
  //     {
  //       path: "login",
  //       component: LoginComponent
  //     }
  //   ]
  // },
  // {
  //   path: "investorsummary",
  //   component: HomeLayoutComponent,
  //   //canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: "",
  //       component: InvestorSummaryComponent
  //     }
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
