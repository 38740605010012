import { Component, OnInit, Input } from "@angular/core";
import {
  PresenterDetails,
  InvestorTherapeuticFocus,
  InvestorAssets,
  AssistantDetails,
  CorporateDetails,
  Summary,
  CompanyDetails,
} from "ClientApp/app/models/Summary";
import { ConferenceDetailModel } from "ClientApp/app/models/Conference";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService, Toast } from "angular2-toaster";
import { SummaryService } from "ClientApp/app/services/investor/summary.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { Router } from "@angular/router";
import { ConferenceService } from "ClientApp/app/services/conference.service";

@Component({
  selector: "app-summary-preview-modal",
  templateUrl: "./summary-preview-modal.component.html",
  styleUrls: ["./summary-preview-modal.component.css"],
})
export class SummaryPreviewModalComponent implements OnInit {
  @Input() summaryItem: any;
  @Input() summaryForm: any;
  @Input() conferenceId: any;
  @Input() tFocus: any;
  @Input() conferenceID: any;
  @Input() assetList: any;
  conferenceDetail: any;
  isEBDConf: boolean;
  showInvester: boolean = true;
  summaryModel = new Summary();
  presenterDetails = new PresenterDetails();
  corporateDetails = new CorporateDetails();
  companyDetails = new CompanyDetails();
  investorAssets = new InvestorAssets();
  invetsorTherapeuticFocus = new InvestorTherapeuticFocus();
  assistantDetails = new AssistantDetails();
  conferenceModel = new ConferenceDetailModel();
  public assets: Array<InvestorAssets> = [];
  public droppedItems: Array<InvestorTherapeuticFocus> = [];
  public rows: Array<{
    name: string | undefined;
    indication: string | undefined;
    status: string | undefined;
    milestone: string | undefined;
  }> = [];
  public dragndropItems: Array<any> = [];

  submitted = false;
  disableBtn = false;
  response: any;
  btnMessage: string | undefined;
  submitMessage: string | undefined;
  processMessage: string | undefined;
  fundPlans: { Id: string; Description: string }[];
  fundPlanItem: any;
  fundPlan: any;
  typeId: number;
  constructor(
    public summaryService: SummaryService,
    private toasterService: ToasterService,
    public activeModal: NgbActiveModal,
    private router: Router,
    public cService: ConferenceService
  ) {
    this.fundPlans = [
      {
        Id: "0",
        Description: "Plan to raise money in the next 12 months",
      },
      {
        Id: "1",
        Description: "Plan to raise money in the next 18 months",
      },
      {
        Id: "2",
        Description: "Plan to raise money in the next 24 months",
      },
      {
        Id: "3",
        Description: "Plan to update investors on status or milestones",
      },
      {
        Id: "4",
        Description: "Not disclosed",
      },
    ];
  }
  ngOnInit() {
    console.log("item", localStorage.getItem("participation"));
    console.log("summary preview", this.summaryForm);
    // this.checkInvesterProposition(this.summaryItem.companyDetails.ConferenceID);
    console.log(this.assetList);
    this.submitMessage = ConferenceConfig.SUMMARY_BTN_SUBMIT_MESSAGE;
    this.processMessage = ConferenceConfig.BTN_PROCESS_MESSAGE;
    this.btnMessage = this.submitMessage;

    console.log("test sruthy", this.summaryForm.fundPlans);
    debugger;
    if (
      this.summaryForm.fundPlans != "" &&
      this.summaryForm.fundPlans != null
    ) {
      this.fundPlanItem = this.fundPlans.find(
        (i) => i.Id === this.summaryForm.fundPlans
      );
      this.fundPlan = this.fundPlanItem.Description;
    }

    console.log("conference id", this.conferenceID);
    debugger;
    if (this.conferenceID) {
      return this.cService
        .getConferenceById(this.conferenceID)
        .subscribe((data: {}) => {
          this.conferenceDetail = data;
          this.typeId = this.conferenceDetail.Conference.TypeId;
          console.log("conference", this.conferenceDetail);
          this.isEBDConf = this.conferenceDetail.Conference.isEBD;
        });
    }
  }

  //   checkInvesterProposition(con_id:any){
  //     // console.log('investorProposition condition', con_id);
  //     if(con_id === "tS6ZMFrWmAPAVa-XBj1wXg") {
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "Y-7qsiG-5UuRAfGlHZYKVA") {

  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "FNliUNqTlnQgMdCuomeTJQ") {

  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "Q1ipEG3Wro6PMlPM2tZ_XA") {

  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "0ng413bnEsmHlePWwVkqpA") {

  //         this.showInvester = false
  //         // return false
  //     } else {
  //         this.showInvester = true
  //         // return false
  //     }
  // }

  onSubmit() {
    this.createSummaries(this.summaryItem);
    console.log(JSON.stringify(this.summaryItem));
  }

  createSummaries(summary: Summary) {
    console.log("In createSummaries....");
    this.disableBtn = true;
    this.btnMessage = this.processMessage;
    return this.summaryService.createSummary(summary).subscribe((result) => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true,
        };

        this.toasterService.pop(toast);
        this.activeModal.close();
        // Redirect to success page.
        let confId = this.response.EncryptedConferenceId;
        let id = this.response.EncryptedId;
        let type = 1; // For Summary
        this.router.navigate([
          "investor/investorSummary/success/" + confId + "/" + type + "/" + id,
        ]);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true,
        };
        this.toasterService.pop(toast);
      }
      this.disableBtn = false;
      this.btnMessage = this.submitMessage;
    });
  }
}
