import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-confirmation-success',
  templateUrl: './email-confirmation-success.component.html',
  styleUrls: ['./email-confirmation-success.component.css']
})
export class EmailConfirmationSuccessComponent implements OnInit {
    todayDate: any;
  constructor() { }

    ngOnInit() {
        this.todayDate = new Date();
  }

}
