import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-track-create-modal",
  templateUrl: "./track-create-modal.component.html",
  styleUrls: ["./track-create-modal.component.css"]
})
export class TrackCreateModalComponent implements OnInit {
  @Input() public cId: number = 0;
  @Input() public tId: number = 0;
  conferenceId: number = 0;
  trackId: number = 0;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.conferenceId = this.cId;
    this.trackId = this.tId;
    // console.log(this.cId);
    // console.log(this.tId);
  }
}
