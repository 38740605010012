import { Component, OnInit, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { Toast, ToasterService } from "angular2-toaster";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { UserRoleService } from 'ClientApp/app/services/user-role.service';
import { ConfirmationDialogService } from 'ClientApp/app/services/common/confirmation-dialog.service';
import { ConferenceConfig } from 'ClientApp/app/config/appSettings';
@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {
  response: any;
  userRoleModel: any = {}
  @ViewChild("UserRole") myContent: TemplateRef<any>;
  modalReference: any;
  isSubmitted = false;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
  };
  usersRoles: any;
  dataTable: any;
  dropdownSettings = {};
  conferenceTypeList: any = [];
  Conferences: any = [];
  confs: any = [];
  userRoleId: any;
  selectedPageSize: any;

  constructor(private toasterService: ToasterService,
    private chRef: ChangeDetectorRef,
    private userRoleService: UserRoleService,

    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    public cService: ConferenceService) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "Id",
      textField: "Type",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
    this.loadConference();
    this.loadUserRoles();
  }

  loadConference() {
      return this.cService.getConferenceType().subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = _.filter(this.confs.Info, function (obj: any) { return obj.isActive == 1; });
    });
  }

  // add user role
  openUserRoleFormModal(id: any) {
    this.userRoleId = id;
    this.conferenceTypeList=[];
    this.userRoleModel = {};
    this.modalReference = this.modalService.open(
      this.myContent,
      this.ngbModalOptions
    );

    if (this.userRoleId > 0)
      this.GetUserRoleById();
  }

  onItemSelect(item: any) {

  //  this.conferenceTypeList.push(item);
  }

  onSelectAll(items: any) {
    this.conferenceTypeList = items;
  }

  onDeSelect(item: any) {
    // var pos = this.conferenceTypeList
    //   .map(function (e: { CTypeId: number }) {
    //     return e.CTypeId;
    //   })
    //   .indexOf(item.CTypeId);
    // this.conferenceTypeList.splice(pos, 1);
  }

  // Get User role list
  loadUserRoles() {
    return this.userRoleService.getUserRoles().subscribe((data: {}) => {
      this.response = data;
      this.usersRoles = this.response.Info;
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#user_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
        pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
        lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#user_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
});
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.RoleName)
      .then(confirmed => {
        this.userRoleService.deleteUserRole(obj.RoleId).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadUserRoles();
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  SaveUserRole(form: any) {
    this.isSubmitted = false;
    // stop here if form is invalid
    if (form.invalid) {
      this.isSubmitted = true;
      return;
    }

    if(this.conferenceTypeList.length == 0){
      this.isSubmitted = true;
      return;
    }

    var superThis = this;
    superThis.userRoleModel.UserRoleConferenceTypeList = [];
    superThis.userRoleModel.userRoleConferenceTypeList = [];
    //this.conferenceTypeList =   _.uniq(this.conferenceTypeList , 'ConferenceId'); 
    //this.conferenceTypeList.forEach((confType: any) => {
    //  superThis.userRoleModel.UserRoleConferenceTypeList.push({
    //    Id: 0,
    //    RoleId: 0,
    //    ConferenceTypeId: confType.ConferenceId
    //  })
    //});
      this.conferenceTypeList = _.uniq(this.conferenceTypeList, 'Id');
      this.conferenceTypeList.forEach((confType: any) => {
          superThis.userRoleModel.UserRoleConferenceTypeList.push({
              Id: 0,
              RoleId: 0,
              ConferenceTypeId: confType.Id
          })
      });

    var subc = this.userRoleService.createUserRole(this.userRoleModel);
    if (this.userRoleId> 0)
      subc = this.userRoleService.updateUserRole(this.userRoleModel);

    subc.subscribe((result: {}) => {
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.modalReference.close();
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadUserRoles();
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }

  GetUserRoleById() {
    this.userRoleService.getUserRoleById(this.userRoleId).subscribe((result: {}) => {
      this.response = result;
      if (this.response && this.response.Message.Code == 200) {
        this.userRoleModel = this.response.Info;

          var superThis = this;

        
       let selectedItems :any = [];
          this.userRoleModel.userRoleConferenceTypeList.forEach((confType: any) => {
            var conf = superThis.Conferences.find(function (item: any) { return item.Id == confType.ConferenceTypeId; })
  
            if (conf)
            selectedItems.push(conf);
          });

       superThis.conferenceTypeList =selectedItems;


        this.dropdownSettings = {
          singleSelection: false,
          idField: "Id",
          textField: "Type",
          selectAllText: "Select All",
          unSelectAllText: "UnSelect All",
          itemsShowLimit: 3,
          allowSearchFilter: false,
          enableCheckAll: false
        };
      }
    });
  }
}
