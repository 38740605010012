﻿import { Component, OnInit, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssetAddRowService } from "ClientApp/app/services/asset-add-row.service";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
// Model
import {
  Application,
  CompanyDetails,
  ContactDetails,
} from "./../../../../../models/Application";
import { Toast, ToasterService, ToasterConfig } from "angular2-toaster";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { UtilService } from "ClientApp/app/services/common/util.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { environment } from "ClientApp/environments/environment";
import { PressAccreditationService } from "../press-accreditation/press-accreditation.service";
import { IMyDpOptions } from "mydatepicker";
import { forEach } from "@angular/router/src/utils/collection";
@Component({
  selector: "app-press-accreditation-edit",
  templateUrl: "./press-accreditation-edit.component.html",
  styleUrls: [],
})
export class PressAccreditationEditComponent implements OnInit {
  Summary: any = {};
  accreditationForm!: FormGroup;
  previewDoc: any;
  submitted = false;
  disableBtn = false;
  public id: any;
  public showBtn: boolean = false;
  public fixedDiv: boolean = false;
  application = new Application();
  companyDetails = new CompanyDetails();
  contactDetails = new ContactDetails();
  selectedFiles: any;

  public response: any;
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    animation: "fade",
  });

  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  _conferenceId: any;
  validationError: boolean = false;
  conferenceID: any;
  banner: any;
  contactId: any;
  conferenceIsActive: any;
  vData: any;
  loginuser: any;
  documents: any = [];
  public myDatePickerOptions: IMyDpOptions = {
    editableDateField: false,
    dateFormat: "mm-dd-yyyy",
    width: "250px",
  };
  //country: any;// = new Country();
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private addRowService: AssetAddRowService,
    public pressAccreditationService: PressAccreditationService,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    public cService: ConferenceService,
    private utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit() {
    // Getting current component's id or information using ActivatedRoute service
    const summaryId = this.route.snapshot.paramMap.get("id");
    this.loginuser = localStorage.getItem("currentUser");

    this.id = summaryId !== null ? summaryId : 0;

    this.getSummaryById(this.id);

    this.accreditationForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      website: ["", Validators.required],
      cfirstName: ["", Validators.required],
      clastName: ["", Validators.required],
      cposition: ["", Validators.required],
      ccontactEmail: ["", [Validators.required, Validators.email]],
      date: ["", Validators.required],
      msgcomp: [""],
      link: [""],
      docs: [""],
      participationMethod: ["", Validators.required],
      //removed as per the instruction from anish sir
    });
  }

  containsObject(obj: any, list: any) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  getConferenceDetails(id: any) {
    console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;

      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;

      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 4) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      //            this.validateConference(confId);
    });
  }

  getSummaryById(id: number) {
    this.pressAccreditationService
      .getAccreditationById(id)
      .subscribe((data: {}) => {
        this.application.Documents = [];
        this.Summary = data;
        this._conferenceId = this.Summary.CompanyDetails.Conference_Id;
        this.conferenceID = this.Summary.CompanyDetails.ConferenceID;
        // let smodel = new Date(this.Summary.CompanyDetails.ApplicationDate);
        let smodel = new Date(this.Summary.CompanyDetails.CreatedOn);
        smodel.getDate() <= 9 ? "0" + smodel.getDate() : smodel.getDate();
        let m = smodel.getMonth() + 1;
        let sMonth = m <= 9 ? "0" + m : m;
        let sDay =
          smodel.getDate() <= 9 ? "0" + smodel.getDate() : smodel.getDate();
        let sDate = sMonth + "-" + sDay + "-" + smodel.getFullYear();
        this.accreditationForm.patchValue({
          companyName: this.Summary.CompanyDetails.Name,
          cfirstName: this.Summary.CompanyDetails.FirstName,
          clastName: this.Summary.CompanyDetails.LastName,
          cposition: this.Summary.CompanyDetails.Position,
          ccontactEmail: !this.Summary.CompanyDetails.email
            ? this.Summary.CompanyDetails.Email
            : this.Summary.CompanyDetails.email,
          msgcomp: this.Summary.CompanyDetails.CompanyMessage,
          website: this.Summary.CompanyDetails.Website,
          link: this.Summary.CompanyDetails.LinkPublication,
          participationMethod:
            this.Summary.CompanyDetails.ParticipationMethod.toString(),
          date: {
            date: {
              year: smodel.getFullYear(),
              month: smodel.getMonth() + 1,
              day: smodel.getDate(),
            },
            formatted: sDate,
          },
        });
        if (this.Summary.CompanyDetails.Documents) {
          let pressDocs = this.Summary.CompanyDetails.Documents.split(",");
          for (var i = 0; i < pressDocs.length; i++) {
            var orgName = pressDocs[i].substring(pressDocs[i].indexOf("_") + 1);
            var obj = {
              originalName: pressDocs[i],
              name: orgName,
              content: null,
            };
            this.application.Documents.push(obj);
          }
        }
        this.getConferenceDetails(this.conferenceID);
      });
  }

  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.accreditationForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.validationError = this.accreditationForm.invalid;
    if (this.accreditationForm.invalid) {
      this.utilService.showValidationDiv();
      return;
    } else {
      this.validationError = false;
      this.companyDetails.CompanyId = this.id;
      this.companyDetails.Name = this.accreditationForm.value.companyName;
      this.companyDetails.Website = this.accreditationForm.value.website;
      this.companyDetails.ConferenceID = this._conferenceId;
      this.companyDetails.IsSelected = 0;
      this.contactDetails.FirstName = this.accreditationForm.value.cfirstName;
      this.contactDetails.LastName = this.accreditationForm.value.clastName;
      this.contactDetails.Position = this.accreditationForm.value.cposition;
      this.contactDetails.Email = this.accreditationForm.value.ccontactEmail;
      this.companyDetails.CompanyMessage = this.accreditationForm.value.msgcomp;
      this.companyDetails.LinkPublication = this.accreditationForm.value.link;
      this.companyDetails.ApplicationDate =
        this.accreditationForm.value.date.formatted;
      this.application.companyDetails = this.companyDetails;
      this.application.contactDetails = this.contactDetails;
      this.companyDetails.ParticipationMethod =
        this.accreditationForm.value.participationMethod;
      this.updateAccreditation(this.application);
    }

    //alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.summaryEditForm.value));
  }

  _handleReaderLoaded(readerEvt: any) {
    this.previewDoc = readerEvt.target.result;
    this.application.Documents.push(this.previewDoc.split(",")[1]);
  }

  public onSelectedFileName(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.previewDoc = e.target.result;
          let obj = {
            name: this.selectedFiles[i].name,
            content: this.previewDoc.split(",")[1],
          };
          this.application.Documents.push(obj);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  removeFile(file: any) {
    const index = this.application.Documents.indexOf(file, 0);
    if (index > -1) {
      this.application.Documents.splice(index, 1);
    }
  }

  // Updtae summaries
  updateAccreditation(application: any) {
    this.disableBtn = true;
    return this.pressAccreditationService
      .updateAccreditation(application)
      .subscribe((result) => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true,
          };

          this.toasterService.pop(toast);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true,
          };
          this.toasterService.pop(toast);
        }
        this.disableBtn = false;
      });
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(event: any) {
    let position = 500;
    let scrollPositionFromTop = +window.pageYOffset.toString();
    let windowHeight = +window.innerHeight;
    //let submitBtn = event.nativeElement.querySelectorAll(".submit");
    if (scrollPositionFromTop > position) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    if (
      scrollPositionFromTop + windowHeight >
      document.body.clientHeight - 100
    ) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }
}
