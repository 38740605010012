import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[appFixToBottomOnScroll]"
})
export class FixToBottomOnScrollDirective {
  constructor(el: ElementRef) {
    let scrollDiatance = el.nativeElement.scrollTop();
    if (scrollDiatance > 100) {
    }
  }
}
