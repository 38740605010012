import { EmailModel, SendEmailCompany } from "./../../../../../models/Schedule";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ToasterService, Toast } from "angular2-toaster";
import { ScheduleService } from "ClientApp/app/services/schedule/schedule.service";
import { EmailItemModel } from "ClientApp/app/models/Schedule";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import * as _ from "underscore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewDetailModalComponent } from "../../components/view-detail-modal/view-detail-modal.component";
@Component({
  selector: "app-schedule-email",
  templateUrl: "./schedule-email.component.html",
  styleUrls: ["./schedule-email.component.css"]
})
export class ScheduleEmailComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  emails: any = [];
  response: any;
  emailItemModel = new EmailItemModel();
  emailModel = new EmailModel();
  emailCompanyModel = new SendEmailCompany();
  selectedArr: Array<EmailItemModel> = [];
  conferenceDetail: any;
  cInfo: any;
  ConferenceName: any;
  dataTable: any;
  selectedValue: any;
  selectedPageSize: any;
  selectedFilterPrevVal: string = "";
  selectedSortPrevVal: any = [[0, "asc"]];
  selectedPagePrevVal: any = 0;
  constructor(
    private modalService: NgbModal,
    public cService: ConferenceService,
    public scheduleService: ScheduleService,
    private toasterService: ToasterService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadDefault();
  }

  loadDefault() {
    // Select List companies, Get Company Id and List emails
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }

  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      // this.Conferences = _.filter(this.confs.Info, function(obj: any) {
      //   return obj.isActive == 1;
      // });
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      console.dir(this.conferenceItem);
      // this._conferenceId = this.conferenceItem.ConferenceID;
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
      // setTimeout(() => {
      //   this._conferenceId = 1; //this.conferenceItem.conferenceId;
      //   this.loadEmails(this._conferenceId);
      // }, 1000);
    });
  }

  getConferenceDetails(id: number) {
    console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
      console.log(this.cInfo);
      this.loadEmails(this._conferenceId);
    });
  }

  // Get Emails list
  loadEmails(id: number) {
    return this.scheduleService.getEmailList(id).subscribe((data: {}) => {
        this.emails = data;
        this.selectedArr = [];
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#schedule_email");
    this.dataTable = table.DataTable({
      "aoColumns": [
        null,
        null,
        null,
        {"sType": "date"},
        {"sType": "date"},
        {"sType": "date"},
        null, 
        null
      ],
      pagingType: "full_numbers",
      paging: true,
      search: {"search": self.selectedFilterPrevVal},
      "order": self.selectedSortPrevVal,
      'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#schedule_email').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

  sendEmail() {
    this.emailModel.Info = this.selectedArr;
    this.emailCompanyModel.Conference_Id = this._conferenceId;
    this.emailModel.companydetails = this.emailCompanyModel;
    //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
    if (this.selectedArr.length > 0) {
      console.log(this.emailModel);
      return this.scheduleService
        .sendEmail(this.emailModel)
        .subscribe(result => {
          // This code will be executed when the HTTP call returns successfully
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.selectedFilterPrevVal = this.dataTable.search();
            this.selectedSortPrevVal = this.dataTable.order();
            this.selectedPagePrevVal = this.dataTable.page();

              this.toasterService.pop(toast);
              if (this.dataTable) {
                  this.dataTable.destroy();
              }
              this.loadEmails(this._conferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
    }
  }

    onChangeConference(id: number) {
        this.selectedArr = [];
        console.log("Inside " + id);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.selectedFilterPrevVal = "";
        this.selectedSortPrevVal = [[0, "asc"]];
        this.selectedPagePrevVal = 0;
        this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  onChange(e: any, id: number, email: string) {
    // onChange(e: any) {
    //console.log(e);
    console.log(id);
    console.log(email);
    if (e.target.checked) {
      this.emailItemModel.ScheduledId = id;
      this.emailItemModel.Email = email;
      //console.dir(this.ISPModel);
      this.selectedArr.push(this.emailItemModel);
      this.emailItemModel = new EmailItemModel();
    }else{
      let unselectedIndex = this.selectedArr.map((sa: any) => sa.ScheduledId).indexOf(id);
      if (unselectedIndex !== -1)
            {
      this.selectedArr = this.selectedArr.filter((x, i) => i !== unselectedIndex);
            }
    }
  }

  openViewDetailsModal(id: number) {
    console.log(id);
    const modalRef = this.modalService.open(ViewDetailModalComponent);
    modalRef.componentInstance.companyId = id;
    modalRef.result
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
