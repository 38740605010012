import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "./user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "ClientApp/environments/environment";
import { map } from "rxjs/operators";
import { text } from "@angular/core/src/render3";

@Injectable()
export class AuthService {
  apiURL = environment.baseUrl;

  // private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
  //   false
  // );

  // get isLoggedIn() {
  //   return this.loggedIn.asObservable();
  // }
  // private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User>;
  constructor(private router: Router, private http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem("currentUser") || "{}")
    // );
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    //return this.currentUserSubject.value;
    return localStorage.getItem("currentUser");
  }

  public get userToken() {
    //return this.currentUserSubject.value;
    return localStorage.getItem("token");
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  // login(user: User) {
  //   if (user.userName !== '' && user.password !== '' ) {
  //     this.loggedIn.next(true);
  //     this.router.navigate(['/']);
  //   }
  // }

  login(username: string, password: string) {
    return this.http
      .post<any>(this.apiURL + "/Users/Login", {
        EmailId: username,
        Password: password
      })
      .pipe(
        map(user => {
          let response = user;
          //console.log(response);

          if (response.Message.Code == 200) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            localStorage.setItem("role", JSON.stringify(user.Info.GroupName));
            localStorage.setItem("name", user.Info.LoginName);
            // login successful if there's a jwt token in the response
            // if (user && user.token) {
            //     this.currentUserSubject.next(user);
            // }
            //this.currentUserSubject.next(user);
            return user;
          } else {
            return response;
          }
        })
      );
  }

  getToken(email: string) {
    return this.http
      .post(
        this.apiURL + "/Users/Token",
        {
          EmailId: email
        },
        { responseType: "text" }
      )
      .pipe(
        map(result => {
          let response = result;
          //console.log(response);
          if (response != null) {
            localStorage.setItem("token", response);
            return response;
          } else {
            //console.log("Token Failed");
          }
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    //this.currentUserSubject.next(JSON.parse("{}"));
    //this.router.navigate(["/login"]);
  }
}
