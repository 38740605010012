export const InvestorTypes = [
  {
    Id: 1,
    Description: "Angel Investing",
  },
  {
    Id: 2,
    Description: "Endowment / Foundation",
  },
  {
    Id: 3,
    Description: "Family Office",
  },
  {
    Id: 4,
    Description: "Financial Advising",
  },
  {
    Id: 5,
    Description: "Hedge / Mutual Fund (PM, Buy-side Research)",
  },
  {
    Id: 6,
    Description: "Independent Research Firm",
  },
  {
    Id: 7,
    Description: "Individual Investor",
  },
  {
    Id: 8,
    Description: "Investment Bank",
  },
  {
    Id: 9,
    Description: "Royalty / Debt Financing",
  },
  {
    Id: 10,
    Description: "Sell-Side Research",
  },
  {
    Id: 100,
    Description: "Other",
  },
];
export const AssetsUnderManagement = [
  {
    Id: 1,
    Description: "1M-10M",
  },
  {
    Id: 2,
    Description: "10M-50M",
  },
  {
    Id: 3,
    Description: "50M-100M",
  },
  {
    Id: 4,
    Description: ">100M",
  },
];

export const Therapeutics = [
  {
    Id: 37,
    Description: "Artificial Intelligence (AI)",
  },
  {
    Id: 1,
    Description: "Autoimmune",
  },
  {
    Id: 2,
    Description: "Big Data",
  },
  {
    Id: 3,
    Description: "Bioinformatics",
  },
  {
    Id: 4,
    Description: "Biomanufacturing",
  },
  {
    Id: 5,
    Description: "Biosimilars",
  },
  {
    Id: 6,
    Description: "CNS/Neurological",
  },
  {
    Id: 7,
    Description: "Cardiovascular",
  },
  {
    Id: 8,
    Description: "Contract Drug Development",
  },
  {
    Id: 9,
    Description: "Contract Manufacturing",
  },
  {
    Id: 10,
    Description: "Dermatology",
  },
  {
    Id: 11,
    Description: "Diagnostics",
  },
  {
    Id: 12,
    Description: "Drug Delivery",
  },
  {
    Id: 13,
    Description: "Drug Discovery",
  },
  {
    Id: 14,
    Description: "Endocrine/Metabolic",
  },
  {
    Id: 15,
    Description: "Epigenetics",
  },
  {
    Id: 16,
    Description: "Fibrosis",
  },
  {
    Id: 17,
    Description: "Functional Genomics",
  },
  {
    Id: 18,
    Description: "Gastrointestinal",
  },
  {
    Id: 19,
    Description: "Gene/Cell Therapy",
  },
  {
    Id: 20,
    Description: "Generics",
  },
  {
    Id: 21,
    Description: "Genitourinary",
  },
  {
    Id: 22,
    Description: "Genomics",
  },
  {
    Id: 23,
    Description: "Hematology",
  },
  {
    Id: 24,
    Description: "Hepatic",
  },
  {
    Id: 25,
    Description: "Immunology",
  },
  {
    Id: 26,
    Description: "Infectious Disease",
  },
  {
    Id: 27,
    Description: "Inflammation",
  },
  {
    Id: 28,
    Description: "Machine Learning",
  },
  {
    Id: 29,
    Description: "Microarrays",
  },
  {
    Id: 30,
    Description: "Microbiome",
  },
  {
    Id: 31,
    Description: "Mitochondrial Therapeutics",
  },
  {
    Id: 32,
    Description: "Musculoskeletal",
  },
  {
    Id: 33,
    Description: "Neurology",
  },
  {
    Id: 34,
    Description: "Neuropathy",
  },
  {
    Id: 35,
    Description: "Ophthalmic",
  },
  {
    Id: 36,
    Description: "Orphan/Rare Disease",
  },
  //commented because another "custom other option added at the last"
  // {
  //   Id: 37,
  //   Description: "Other",
  // },
  {
    Id: 38,
    Description: "Personalized Medicine",
  },
  {
    Id: 39,
    Description: "Pharmacogenetics",
  },
  {
    Id: 40,
    Description: "Platform Technology",
  },
  {
    Id: 41,
    Description: "Proteomics",
  },
  {
    Id: 42,
    Description: "Pulmonary",
  },
  {
    Id: 43,
    Description: "Regenerative Medicine",
  },
  {
    Id: 44,
    Description: "Renal",
  },
  {
    Id: 45,
    Description: "Reproductive Medicine",
  },
  {
    Id: 46,
    Description: "Research Tools",
  },
  {
    Id: 47,
    Description: "Transplant",
  },
  {
    Id: 48,
    Description: "Vaccines",
  },
  {
    Id: 49,
    Description: "Veterinary",
  },
  {
    Id: 50,
    Description: "Women’s Health",
  },
  {
    Id: 100,
    Description: "Other",
  },
];
