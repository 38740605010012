export class SendEmailModel {
  Info: Array<SendEmailItemModel> | undefined;
  CompanyDetails: SendEmailCompany | undefined;
}

export class SendEmailItemModel {
  CompId: number | undefined;
  Email: string | undefined;
}

export class SendEmailCompany {
  Conference_Id: number | undefined;
}
