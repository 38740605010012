import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SummaryService } from "ClientApp/app/services/investor/summary.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-url-rewrite-ia",
  templateUrl: "./url-rewrite-ia.component.html",
  styleUrls: ["./url-rewrite-ia.component.css"]
})
export class UrlRewriteIaComponent implements OnInit {
  
  hashCodeDetails: any;

  constructor(private summaryService: SummaryService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      const hashCode = decodeURIComponent(queryParams.get('c_id') || '');
      if (hashCode === undefined || hashCode === null || hashCode === '') {
        this.router.navigate(['investor/investorApplication/tS6ZMFrWmAPAVa-XBj1wXg']);
      } else {
        this.getHashCode(hashCode);
      }
    });
  }

  getHashCode(hashCode: string) {
    return this.summaryService.getHashCode(hashCode).subscribe((data: {}) => {
      this.hashCodeDetails = data;
      this.router.navigate(['investorapplicationedit/' + this.hashCodeDetails.EncryptedId]);
    });
  }
}
