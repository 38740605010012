import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-track-create',
  templateUrl: './track-create.component.html',
  styleUrls: ['./track-create.component.css']
})
export class TrackCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
