import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "app-word-count",
  templateUrl: "./word-count.component.html",
  styleUrls: ["./word-count.component.css"]
})
export class WordCountComponent implements OnInit, OnChanges {
  @Output() onChangeEvent = new EventEmitter();
  @Input() totalCount: number = 0;
  @Input() inputText: string | undefined;
  wCount: number = 0;
  tCount: number = 0;

  constructor() {}

  ngOnInit() {
    // console.log(this.totalCount);
    // console.log(this.inputText);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputText) {
      //console.dir("input changed" + changes.inputText.currentValue);
      var value = changes.inputText.currentValue;
      var str = value.trim().replace("\n", "");
      let wordCount = str ? str.split(" ").length : 0;
      let totalTextCount = this.totalCount;
      this.wCount = wordCount;
      this.tCount = totalTextCount;
      //this.onChangeEvent.emit({count: wordCount, totalCount: this.totalCount});
    }
  }
}
