import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EmailTemplateService } from "ClientApp/app/services/template/email-template.service";
import { ToasterService, Toast } from "angular2-toaster";
import { ActivatedRoute } from "@angular/router";
import { Template } from "ClientApp/app/models/Template";
import { EmailEventService } from "ClientApp/app/services/template/email-event.service";

@Component({
  selector: "app-email-template-edit",
  templateUrl: "./email-template-edit.component.html",
  styleUrls: ["./email-template-edit.component.css"]
})
export class EmailTemplateEditComponent implements OnInit {
  App: any = {};
  templateEditForm!: FormGroup;
  submitted = false;
  public id!: number;
  response: any;
  editorConfig: any;
  templateModel = new Template();
  conferenceId: number | undefined;
  confs: any = [];
  Conferences: any = [];
  EventsList: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private emailEventService: EmailEventService,
    public eTemplateService: EmailTemplateService,
    private toasterService: ToasterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const appId = this.route.snapshot.paramMap.get("id");
    this.id = appId !== null ? +appId : 0;
    this.loadConference();
    this.loadEvents();
    this.templateEditForm = this.formBuilder.group({
      tName: ["", Validators.required],
      tConference: ["", Validators.required],
      tEvent: ["", Validators.required],
      tSubject: ["", Validators.required],
      tContent: ["", Validators.required]
    });

    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: "auto",
      minHeight: "250px",
      maxHeight: "auto",
      width: "auto",
      minWidth: "0",
      translate: "yes",
      enableToolbar: true,
      showToolbar: true,
      placeholder: "Enter text here...",
      defaultParagraphSeparator: "",
      defaultFontName: "Calibri",
      defaultFontSize: "",
      fonts: [
        { class: "arial", name: "Arial" },
        { class: "times-new-roman", name: "Times New Roman" },
        { class: "calibri", name: "Calibri" },
        { class: "comic-sans-ms", name: "Comic Sans MS" },
        { class: "helvetica", name: "Helvetica" },
        { class: "helvetica neue", name: "Helvetica Neue" },
        { class: "verdana", name: "Verdana" },
        { class: "sans-serif", name: "Sans-serif" }
      ],
      // customClasses: [
      //   {
      //     name: "quote",
      //     class: "quote"
      //   },
      //   {
      //     name: "redText",
      //     class: "redText"
      //   },
      //   {
      //     name: "titleText",
      //     class: "titleText",
      //     tag: "h1"
      //   }
      // ],
      //uploadUrl: "v1/image",
      sanitize: false,
      toolbarPosition: "top"
    };

    // this.editorConfig = {
    //   editable: true,
    //   spellcheck: true,
    //   height: "250px",
    //   minHeight: "0",
    //   width: "auto",
    //   minWidth: "0",
    //   translate: "yes",
    //   enableToolbar: true,
    //   showToolbar: true,
    //   placeholder: "Add email template here...",
    //   imageEndPoint: "",
    //   toolbar: [
    //     [
    //       "bold",
    //       "italic",
    //       "underline",
    //       "strikeThrough",
    //       "superscript",
    //       "subscript"
    //     ],
    //     ["fontName", "fontSize", "color"],
    //     [
    //       "justifyLeft",
    //       "justifyCenter",
    //       "justifyRight",
    //       "justifyFull",
    //       "indent",
    //       "outdent"
    //     ],
    //     ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
    //     [
    //       "paragraph",
    //       "blockquote",
    //       "removeBlockquote",
    //       "horizontalLine",
    //       "orderedList",
    //       "unorderedList"
    //     ],
    //     ["link", "unlink"] // , "image", "video"
    //   ]
    // };
  }

  loadConference() {
    return this.eTemplateService.getActiveConference().subscribe((data: {}) => {
      this.confs = data;
      //console.log(this.confs.Info);
      this.Conferences = this.confs.Info;
      //console.log(this.Conferences);
      this.getTemplateById(this.id);
    });
  }

  // onChangeConference(){
  //   let confId = this.templateEditForm.value.tConference;
  //   if (confId){
  //     this.loadEvents(confId);
  //   }else{
  //     this.EventsList = [];
  //   }
  // }

  loadEvents(){
    return this.emailEventService.getAllEmailEvents().subscribe((data: any) => {
      if (data)
      {
        this.EventsList = data.Info;
      }
    });
  }

  getTemplateById(id: number) {
    return this.eTemplateService.getTemplateById(id).subscribe(result => {
      this.App = result;
      this.templateEditForm.patchValue({
        tName: this.App.Info.TemplateName,
        tSubject: this.App.Info.Subject,
        tConference: this.App.Info.ConferenceId,
        tEvent: this.App.Info.EventId,
        tContent: this.App.Info.Contents
      });
      this.conferenceId = this.App.Info.ConferenceId;
      this.loadEvents();
    });
  }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.templateEditForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.templateEditForm.invalid) {
      //console.log("Invalid");
      return;
    } else {
      //console.log(JSON.stringify(this.templateForm.value));
      this.templateModel.Id = this.id;
      this.templateModel.TemplateName = this.templateEditForm.value.tName;
      this.templateModel.Subject = this.templateEditForm.value.tSubject;
      this.templateModel.ConferenceId = this.templateEditForm.value.tConference;
      this.templateModel.EventId = this.templateEditForm.value.tEvent;
      this.templateModel.Contents = this.templateEditForm.value.tContent;
      this.updateTemplate(this.templateModel);
    }
  }

  updateTemplate(template: Template) {
    return this.eTemplateService
      .updateTemplateById(template)
      .subscribe(result => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };

          this.toasterService.pop(toast);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
      });
  }
}
