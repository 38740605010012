import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-note-legend",
  templateUrl: "./note-legend.component.html",
  styleUrls: ["./note-legend.component.css"]
})
export class NoteLegendComponent implements OnInit {
  @Input() public legend: any;
  legendItems: any = [];
  constructor() {}

  ngOnInit() {
    //console.log(this.legend);
    if (this.legend) {
      this.legendItems = this.legend.split(",");
      //console.log(this.legendItems);
      this.legendItems = this.legendItems.filter(function(
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem);
      });
      this.legendItems = this.legendItems.sort();
      //console.log(this.legendItems);
    }
  }
}
