import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { SelectionService } from "ClientApp/app/services/selection/selection.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ToasterService, Toast } from "angular2-toaster";
import {
  SendEmailModel,
  SendEmailCompany,
  SendEmailItemModel
} from "ClientApp/app/models/Email";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import * as _ from "underscore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectionDetailModalComponent } from "../../components/selection-detail-modal/selection-detail-modal.component";

@Component({
  selector: "app-selection-email",
  templateUrl: "./selection-email.component.html",
  styleUrls: ["./selection-email.component.css"]
})
export class SelectionEmailComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  conferenceId: number = 0;
  emails: any = [];
  emailModel = new SendEmailModel();
  emailItemModel = new SendEmailItemModel();
  companyModel = new SendEmailCompany();
  selectedArr: any = [];
  selectionModelArr: any = [];
  response: any;
  _conferenceId: number = 0;
  conferenceDetail: any;
  cInfo: any;
  ConferenceName: any;
  dataTable: any;
  selectedValue: any;
  selectedPageSize: any;
  selectedFilterPrevVal: string = "";
  selectedSortPrevVal: any = [[0, "asc"]];
  selectedPagePrevVal: any = 0;
  constructor(
    private modalService: NgbModal,
    public selectionService: SelectionService,
    public cService: ConferenceService,
    private toasterService: ToasterService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadDefault();
  }

  loadDefault() {
    // Select List companies, Get Company Id and List emails
    //this.loadConference();
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }

  loadConference(typeId: number) {
    //console.log("loadConference");

    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;

      //console.log(this.confs.Info);

      if (this.confs.Info != null && this.confs.Info.length > 0) {
        this.Conferences = this.confs.Info;
        // this.Conferences = _.filter(this.confs.Info, function(obj: any) {
        //   return obj.isActive == 1;
        // });
        //console.log(this.Conferences);

        this.conferenceItem = this.Conferences[0];
        //console.dir(this.conferenceItem.ConferenceId);
        this.selectedValue = this.conferenceItem;
        // this.conferenceId = this.conferenceItem.conferenceId;
        // console.dir(this.conferenceId);
        this.getConferenceDetails(this.selectedValue.ConferenceID);
        // setTimeout(() => {
        //   this.loadEmails(this.conferenceItem.ConferenceId);
        // }, 1000);
      }
    });
  }

  // Get Emails list
  loadEmails(id: number) {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    return this.selectionService.getEmailList(id).subscribe((data: {}) => {
        this.emails = data;
        this.selectionModelArr = [];
        this.selectedArr = [];
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#selection_email");
    this.dataTable = table.DataTable({
      "aoColumns": [
        null,
        null,
        {"sType": "date"},
        {"sType": "date"},
        null,
        null
      ],
      pagingType: "full_numbers",
      paging: true,
      bDestroy: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      search: {"search": self.selectedFilterPrevVal},
      "order": self.selectedSortPrevVal,
      'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#selection_email').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

  onChange(e: any, id: number, email: string) {
    //console.log(id);
    this.emailItemModel.CompId = id;
    this.emailItemModel.Email = email;
    if (e.target.checked) {
      var item = this.emailItemModel;
      this.emailItemModel = new SendEmailItemModel();
      this.selectionModelArr.push(item);
    } else {
      var pos = this.selectionModelArr
        .map(function(e: { CompId: number }) {
          return e.CompId;
        })
        .indexOf(id);
      //console.log("pos " + pos);
      this.selectionModelArr.splice(pos, 1);
    }
  }

  onChangeConference(id: number) {
    //console.log("Inside " + this.selectedValue);
    this.selectionModelArr = [];
    this.selectedFilterPrevVal = "";
    this.selectedSortPrevVal = [[0, "asc"]];
    this.selectedPagePrevVal = 0;
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  getConferenceDetails(id: number) {
    //console.log(id);
    //this._conferenceId = id;
      
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
      //console.log(this.cInfo);
      this.loadEmails(this._conferenceId);
    });
  }

  sendEmail() {
    this.emailModel.Info = this.selectionModelArr;
    this.companyModel.Conference_Id = this._conferenceId;
    this.emailModel.CompanyDetails = this.companyModel;
    //console.log(this.emailModel);

    if (this.selectionModelArr.length > 0) {
      //console.log(this.emailModel);
      return this.selectionService
        .sendEmail(this.emailModel)
        .subscribe(result => {
          // This code will be executed when the HTTP call returns successfully
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

              this.toasterService.pop(toast);
              this.selectedFilterPrevVal = this.dataTable.search();
              this.selectedSortPrevVal = this.dataTable.order();
              this.selectedPagePrevVal = this.dataTable.page();
              this.loadEmails(this._conferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
    }
  }

  sendPresentationAwardEmail() {
    this.emailModel.Info = this.selectedArr;
    this.companyModel.Conference_Id = this._conferenceId;
    this.emailModel.CompanyDetails = this.companyModel;
    if (this.selectedArr.length > 0) {
      return this.cService
        .sendPresentationAwardEmail(this.emailModel)
        .subscribe((result: any) => {
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

              this.toasterService.pop(toast);
              this.loadEmails(this._conferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
    }
  }

  openViewDetailsModal(id: number) {
    console.log(id);
    const modalRef = this.modalService.open(SelectionDetailModalComponent);
    modalRef.componentInstance.companyId = id;
    modalRef.result
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
