import { Component, OnInit } from '@angular/core';
import { ConferenceService } from 'ClientApp/app/services/conference.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailEventService } from 'ClientApp/app/services/template/email-event.service';
import { ToasterService, Toast } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailEvent } from 'ClientApp/app/models/EmailEvent';
import * as _ from 'underscore';
import { ConferenceModel } from "ClientApp/app/models/Conference";

@Component({
  selector: 'app-email-event-edit',
  templateUrl: './email-event-edit.component.html',
  styleUrls: ['./email-event-edit.component.css']
})
export class EmailEventEditComponent implements OnInit {
  public id!: number;
  constructor(private formBuilder: FormBuilder,
    public cService: ConferenceService,
    private emailEventService: EmailEventService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) { }

    emailEventEditForm: FormGroup;
    Conferences: any = [];
    submitted: boolean = false;
    emailEventModel = new EmailEvent();
    dropdownSettings: any = {};
    selectedConferenceList: any = [];
    disableName:boolean=true;
    isDefaultEvent=true;
  ngOnInit() {
    const appId = this.route.snapshot.paramMap.get("id");
    this.id = appId !== null ? +appId : 0;

    this.emailEventEditForm = this.formBuilder.group({
      tName: ["", Validators.required],
      tCaption: ["", Validators.required],
      // tConference: ["", Validators.required],
      tBccMail: ["", Validators.required],
      tEnable: [""],
    });
    this.loadConferences();

    this.dropdownSettings = {
      singleSelection: false,
      idField: "ConferenceId",
      textField: "ConferenceName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  loadConferences() {
    this.cService.getConference().subscribe((result: any) => {
      if (result.Info.length > 0) {
        this.Conferences = _.filter(result.Info, function(obj:any){ return obj.isActive== 1; });
        this.getEmailEventById(this.id);
      }
    });
  }

  getEmailEventById(id: number) {
    return this.emailEventService.getEmailEventById(id).subscribe((result: any) => {
      
      this.selectedConferenceList = result.Info.ConferenceDetail || [];
      var selectedConfIds = this.selectedConferenceList.map((rec: any) => rec.ConferenceId);
      var selectedConfList = this.Conferences.filter((x: any) => selectedConfIds.indexOf(x.ConferenceId) !== -1);
      this.isDefaultEvent=result.Info.IsDefault;
      var isenabled;

      if(result.Info.Status==0){
             isenabled=false;
      }
      else{
        isenabled=true;
      }
      this.emailEventEditForm.patchValue({
        tName: result.Info.EventName,
        tCaption: result.Info.Caption,
        tConference: selectedConfList || "",
        tBccMail: result.Info.BccMailId,
        tEnable:isenabled
      });
    });
  }

  get fc() {
    // get field control(fc)
    return this.emailEventEditForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.emailEventEditForm.invalid) {
      console.log();
      
      return;
    } else {
      let conferenceList: any = [];
      var isenable;
      this.emailEventModel.Id = this.id;
      this.emailEventModel.EventName = this.emailEventEditForm.value.tName;
      this.emailEventModel.Caption = this.emailEventEditForm.value.tCaption;
      //this.emailEventModel.ConferenceId = this.emailEventEditForm.value.tConference;
      this.emailEventModel.BccMailId = this.emailEventEditForm.value.tBccMail;
      console.log("",this.emailEventEditForm.value.tEnable);
        if(this.isDefaultEvent){
          isenable=1;
        }
        else{
          if(this.emailEventEditForm.value.tEnable==true){
            isenable=1;
          }
          else{
            isenable=0;
          }
         
        }
        this.emailEventModel.Status =  isenable;
      this.selectedConferenceList.forEach((element: any) => {
        let record = new ConferenceModel();
        // record.ConferenceId = element.ConferenceId;
        // record.ConferenceName = element.ConferenceName;
         record.ConferenceId = 0;
        record.ConferenceName = "BioFuture 20";
        conferenceList.push(record);
      });
      
      this.emailEventModel.ConferenceDetail = conferenceList;

     this.updateEmailEvent(this.emailEventModel);
    }
  }

  updateEmailEvent(emailEvent: EmailEvent){
    // console.log('update--',emailEvent);
    
     return this.emailEventService.updateEmailEventId(emailEvent).subscribe((response: any) => {
      //  console.log('response---',response);
       
      if (response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.router.navigate(["email/emailevent"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
     });
    }

    // onItemSelect(item: any) {
    //   this.selectedConferenceList.push(item);
    // }
    // onSelectAll(items: any) {
    //   this.selectedConferenceList = items;
    // }
  
    // onDeSelect(item: any) {
    //   var pos = this.selectedConferenceList
    //     .map(function(e: { ConferenceId: number }) {
    //       return e.ConferenceId;
    //     })
    //     .indexOf(item.ConferenceId);
    //   this.selectedConferenceList.splice(pos, 1);
    // }
}
