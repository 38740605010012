import { AbstractControl, ValidatorFn } from "@angular/forms";

export function wordCountValidator(max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    //console.log(control.value);
    if (control.value !== undefined) {
      let content = control.value;
      let str = content && content.replace("\n", "");
      let wordCount = str ? str.split(" ").length : 0;
      //console.log("wordCount " + wordCount);
      if (wordCount > max) {
        return { wordCount: true };
      }
    }
    return null;
  };
}
