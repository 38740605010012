import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-track-edit',
  templateUrl: './track-edit.component.html',
  styleUrls: ['./track-edit.component.css']
})
export class TrackEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
