import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DataService {
  toggleNavMenu: boolean = false;
  counter = 1;
  count: BehaviorSubject<number>;
  messageSource: BehaviorSubject<boolean>;
  //private messageSource = new BehaviorSubject(false);
  //currentMessage = this.messageSource.asObservable();

  constructor() {
    this.count = new BehaviorSubject(this.counter);
    this.messageSource = new BehaviorSubject(this.toggleNavMenu);
  }

  toggleMenu() {
    this.toggleNavMenu = !this.toggleNavMenu;
    this.messageSource.next(this.toggleNavMenu);
  }

  nextCount() {
    this.count.next(++this.counter);
  }
}
