import { ConferenceService } from "./../../../../../services/conference.service";
import { SelectionService } from "ClientApp/app/services/selection/selection.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SelectionConfirmationModel } from "ClientApp/app/models/Selection";
import { Toast, ToasterService } from "angular2-toaster";
import { environment } from "ClientApp/environments/environment";

@Component({
    selector: "app-selection-confirmation",
    templateUrl: "./selection-confirmation.component.html",
    styleUrls: ["./selection-confirmation.component.css"]
})
export class SelectionConfirmationComponent implements OnInit {
    confirmationForm!: FormGroup;
    submitted = false;
    disableBtn = false;
    selectionConfirmationModel = new SelectionConfirmationModel();
    compId: any;
    conferenceId: any;
    response: any;
    conferenceDetail: any;
    banner: any;
    cInfo: any;
    conferenceName: any;
    conferenceType: any;
    bioFutureConfTypeName: any;
    companyName: any;
    constructor(
        private route: ActivatedRoute,
        private selectionService: SelectionService,
        private cService: ConferenceService,
        private toasterService: ToasterService,
        private router: Router,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        let companyId = this.route.snapshot.paramMap.get("compId");
        let conferenceId = this.route.snapshot.paramMap.get("conferenceId");
        // if (companyId == "Gr6bpihQ_RrqQRJdcp2hrg"){
        //     let newCompanyId = "rxf81jFgNBShw9Br_2Dh5A";
        //     this.router.navigate(["/selection/confirmation/", { compId: newCompanyId, conferenceId: conferenceId }])
        // }

        this.compId = companyId;
        this.conferenceId = conferenceId;
        this.confirmationForm = this.formBuilder.group({
            confirm: ["", Validators.required],
            comment: [""]
        });
        //Gr6bpihQ_RrqQRJdcp2hrg/tS6ZMFrWmAPAVa-XBj1wXg
        //rxf81jFgNBShw9Br_2Dh5A/tS6ZMFrWmAPAVa-XBj1wXg
        this.getConferenceTypes();
        this.getConferenceDetails(companyId, conferenceId);
    }

    getConferenceTypes(){
        return this.cService.getConferenceType().subscribe((data: any) => {
            let cTypes = data.Info;
            let matchedTypes = cTypes.filter((x: any) => {return x.Type.indexOf("BioFuture") != 1});
            if (matchedTypes.length > 0){
                this.bioFutureConfTypeName = matchedTypes[0].Type;
            }else{
                this.bioFutureConfTypeName = 'BioFuture';
            }
        })
    }

    getConferenceDetails(companyId: any, conferenceId: any) {
        return this.selectionService
            .getConferenceMailDetails(companyId, conferenceId)
            .subscribe((data: {}) => {
                this.response = data;
                this.conferenceDetail = this.response.CompanyDetails;
                this.conferenceName = this.conferenceDetail.ConferenceName;
                this.conferenceType = this.conferenceDetail.ConferenceType;
                this.companyName = this.conferenceDetail.CompanyName;
                if (this.conferenceDetail.ConforanceImage) {
                    this.banner =
                        environment.imagePath + this.conferenceDetail.ConforanceImage;
                }

            });
    }

    onSubmit() {
        debugger;
        this.submitted = true;
        if (this.confirmationForm.invalid) {
            //console.log("Invalid");
            return;
        } else {
            this.selectionConfirmationModel.CompanyId = this.compId;
            this.selectionConfirmationModel.Confirmed = +this.confirmationForm.value.confirm;
            this.selectionConfirmationModel.ConfirmationResponse = this.confirmationForm.value.comment;
            //console.log(this.selectionConfirmationModel);
            this.updateConfirmation(this.selectionConfirmationModel);
        }
    }

    get fc() {
        // get field control(fc)
        return this.confirmationForm.controls;
    }

    updateConfirmation(model: SelectionConfirmationModel) {
        this.disableBtn = true;
        return this.selectionService
            .updateSelectionConfirmation(model)
            .subscribe(result => {
                // This code will be executed when the HTTP call returns successfully
                this.response = result;
                if (this.response.Code == 200) {
                    // var toast: Toast = {
                    //   type: "success",
                    //   title: this.response.Message,
                    //   showCloseButton: true
                    // };

                    // this.toasterService.pop(toast);
                    // Redirect to success page.
                    this.router.navigate(["selection/confirmationSuccess", {conferenceId: this.conferenceId }]);
                } else {
                    var toast: Toast = {
                        type: "error",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                }
                this.disableBtn = false;
            });
    }
}
