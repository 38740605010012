import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { SummaryService } from "ClientApp/app/services/investor/summary.service";
import { ToasterService } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SummaryEditModalComponent } from "../summary-edits-popup/summary-edit";
import { ConferenceService } from "ClientApp/app/services/conference.service";

@Component({
  selector: "app-summary-edits",
  templateUrl: "./summary-edits.component.html",
  styleUrls: ["./summary-edits.component.css"]
})
export class SummaryEditsComponent implements OnInit {
    confs: any = [];
    Conferences: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    emails: any = [];
    response: any;
    confID: any;
    dataTable: any;
    selectedValue: any;
  summaryEdits: any = [];
  selectedPageSize: any;
  selectedFilterPrevVal: string = "";
  selectedSortPrevVal: any = [[0, "asc"]];
  selectedPagePrevVal: any = 0;
    constructor(
    private modalService: NgbModal,
    public summaryService: SummaryService,
    private toasterService: ToasterService,
        private chRef: ChangeDetectorRef,
        public cService: ConferenceService
  ) {}

  ngOnInit() {
 this.loadDefault();
  }

  loadDefault() {
      this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }
    // Get Conference list
    loadConference(typeId: number) {
        return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            this.confs = data;
            this.Conferences = this.confs.Info;
            this.conferenceItem = this.Conferences[0];
            this.selectedValue = this.conferenceItem;
            console.log(this.conferenceItem.ConferenceId);
            this.confID = this.conferenceItem.ConferenceID;
           
            this.getConferenceDetails(this.confID);
        });
    }
    getConferenceDetails(conferenceId: number) {
        console.log(conferenceId);
        this._conferenceId = conferenceId;
        return this.cService
            .getConferenceById(conferenceId)
            .subscribe((data: {}) => {
                this.conferenceDetail = data;
                //this.cInfo = this.conferenceDetail.ConferenceCategory;
                //this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
                this.confID = this.conferenceDetail.Conference.ConferenceId;
                this.loadSummaryEdits(this.confID);
            });
    }
    onChangeConference(conferenceId: number) {
  
        if (this.dataTable) {
            this.dataTable.destroy();
        }
        this.selectedFilterPrevVal = "";
        this.selectedSortPrevVal = [[0, "asc"]];
        this.selectedPagePrevVal = 0;
        this.getConferenceDetails(this.selectedValue.ConferenceID);
    }
   loadSummaryEdits(confId: number) {
     return this.summaryService.getSummaryEdits(confId).subscribe((data: {}) => {
       this.summaryEdits = data;
       this.enablePagination();
     });
   }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#summary_edit");
    
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
      bDestroy: true,
        pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
        lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
        search: {"search": self.selectedFilterPrevVal},
      "order": self.selectedSortPrevVal,
      'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"],
    });
    $('#summary_edit').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

    openSummaryEditFormModal(compId: number) {
        const modalRef = this.modalService.open(SummaryEditModalComponent, {
            windowClass: "modalWidth",
            backdrop: "static",
            keyboard: false
        });
        this.selectedFilterPrevVal = this.dataTable.search();
        this.selectedSortPrevVal = this.dataTable.order();
        this.selectedPagePrevVal = this.dataTable.page();
        if (this.dataTable) {
            this.dataTable.destroy();
        }
        modalRef.componentInstance.compId = compId;
        modalRef.result
            .then(result => {
                this.loadSummaryEdits(this.confID);
            })
            .catch(error => {
                this.loadSummaryEdits(this.confID);
            });
    }
}
