import { Component, OnInit } from '@angular/core';
import { Toast, ToasterService } from 'angular2-toaster';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EBDConfigurationService } from 'ClientApp/app/services/EBD-configuration/ebd-configuration.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ebd-configuration-edit',
  templateUrl: './ebd-configuration-edit.component.html',
  styleUrls: ['./ebd-configuration-edit.component.css']
})
export class EBDConfigurationEditComponent implements OnInit {
  editConfigurationForm!: FormGroup;
  submitted: boolean = false;
  confs: any = [];
  Conferences:any = [];
  dropdownSettings = {};
  editorList: any = [];
  config:any={};
  public id: any;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, public EBDservice: EBDConfigurationService,private toasterService: ToasterService, private router: Router) { }

  ngOnInit() {
    this.loadConference();
    const conferenceId = this.route.snapshot.paramMap.get("id");
    console.log('id--',conferenceId);
    
    this.id = conferenceId !== null ? conferenceId : 0;
    this.getEBDConfigById(this.id);


    this.editConfigurationForm = this.formBuilder.group({
      tConference: ["", Validators.required],
      applicationfrom: ["", Validators.required],
      eventcode: ["", Validators.required],
      registrationType: ["", Validators.required],
      eventName: ["", Validators.required],
      eventLogo: ["", Validators.required],
      eventUrl: ["", Validators.required],
      paymentSource: ["", Validators.required]
    });
  
    this.dropdownSettings = {
      singleSelection: false,
      idField: "ConferenceID",
      textField: "ConferenceName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  

  get fc() {
    return this.editConfigurationForm.controls;
  }

  // Get conference list
   loadConference() {
     this.EBDservice.getEbdConference().subscribe((data: {}) => {
      this.confs = data;
      console.log(this.confs);
      this.Conferences = this.confs.Info;
      console.log(this.Conferences);
    });
  }

   getEBDConfigById(id:any){
    console.log('getByid',id);
    this.EBDservice.getEBDConfigurationById(id).subscribe((response:any)=>{
      console.log('response getByid---',response);
      this.config =response.Info;
    
      // let selectedConference = this.Conferences.filter((item:any)=>{
      //   return Number(item.ConferenceID) === this.config.ConferenceId; 
      // })
      // let selectedConference = this.Conferences.find((item:any)=>{
      //   console.log('item--',item.ConferenceID);
        
      //   return item.ConferenceID === this.config.ConferenceId; 
      // })
      console.log('selectedConference',this.config.ConferenceId);
      
      console.log('this.config',this.config);
      this.editConfigurationForm.patchValue({
        tConference: this.config.ConferenceId,
        applicationfrom: this.config.ApplicationFrom,
        eventcode: this.config.EventCode,
        registrationType: this.config.RegistrationType,
        eventName: this.config.EventName,
        eventLogo: this.config.EventLogo,
        eventUrl: this.config.EventUrl,
        paymentSource: this.config.PaymentSource,
      })
      
    })
    
  }

  onSubmit() {
    console.log('test Submit');

    this.submitted = true;
    if (this.editConfigurationForm.invalid) {
      console.log("Invalid");
      return;
    } else {
      this.config.ConferenceId=Number(this.editConfigurationForm.value.tConference);
      this.config.ApplicationFrom=this.editConfigurationForm.value.applicationfrom;
      this.config.EventCode=this.editConfigurationForm.value.eventcode;
      this.config.RegistrationType=this.editConfigurationForm.value.registrationType;
      this.config.EventName=this.editConfigurationForm.value.eventName;
      this.config.EventLogo=this.editConfigurationForm.value.eventLogo;
      this.config.EventUrl=this.editConfigurationForm.value.eventUrl;
      this.config.PaymentSource=this.editConfigurationForm.value.paymentSource;
      console.log('tConfig',this.config);
      this.updateEBDconfiguration(this.config);
    }
  }

  updateEBDconfiguration(config:any){
    console.log('conf--',config);
    
    this.EBDservice.updateEBDconfiguration(config).subscribe((result:any)=>{
      console.log('result Add ebd config',result);
      if (result.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: result.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        //this.conferenceForm.reset();
        // Redirect to list.
        this.router.navigate(["administration/EBDconfiguration-list"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: result.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
      
    })
  }

  // ----multiselect

  onItemSelect(item: any) {
    console.log(item);
    this.editorList.push(item);
    console.log(this.editorList);
    this.enableOrDisableValidations();
  }
  onSelectAll(items: any) {
    console.log(items);
    this.editorList = items;
    console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  onDeSelect(item: any) {
    console.log(item);
    var pos = this.editorList
      .map(function (e: { ConferenceID: number }) {
        return e.ConferenceID;
      })
      .indexOf(item.ConferenceID);
    this.editorList.splice(pos, 1);
    console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  enableOrDisableValidations() { }
}
