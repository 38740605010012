export class TrackItem {
  Id: number | undefined;
  Code: string | undefined;
  RoomName: string | undefined;
  RoomDescription: string | undefined;
  SortOrder: number | undefined;
  Conference_Id: number | undefined;
}

export class TrackSlotItem {
  Id: number | undefined;
  Track_Id: number | undefined;
  Count: number | undefined;
  DateTime: string | undefined;
  Private: number | undefined;
  StartTime: string | undefined;
  EndTime: string | undefined;
  IsReserved: number | undefined;
}
