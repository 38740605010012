import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { EBDConfigurationService } from 'ClientApp/app/services/EBD-configuration/ebd-configuration.service';
import { ConfirmationDialogService } from 'ClientApp/app/services/common/confirmation-dialog.service';
import { Toast, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-ebd-configuration-list',
  templateUrl: './ebd-configuration-list.component.html',
  styleUrls: ['./ebd-configuration-list.component.css']
})
export class EBDConfigurationListComponent implements OnInit {

  configurations:any=[];
  dataTable: any;
  selectedPageSize: any;
  response:any;
  constructor( private chRef: ChangeDetectorRef,private EBDservice:EBDConfigurationService, private toasterService: ToasterService,
  private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.loadConfigurations();
  }

  // Get EBD configuration list
  loadConfigurations() {
    return this.EBDservice.getAll_EBDconfigurations().subscribe((data: {}) => {
      console.log('data--',data);
      this.configurations = data;
      console.log('config',this.configurations);
      
      this.enablePagination();
    });
    
  }

  deleteConfiguration(obj: any) {
    console.log('test delete',obj);
    
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.ConferenceName)
      .then(confirmed => {
        this.EBDservice.deleteEBDconfiguration(obj.ConferenceId).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadConfigurations();
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#ebd_configuration_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
        pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
        lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });

    $('#ebd_configuration_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
  } );
  }
}
