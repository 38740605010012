﻿import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { PressAccreditationListUpdateModel } from "ClientApp/app/models/Application";
@Injectable({
    providedIn: "root"
})
export class PressAccreditationService {
    apiURL = environment.baseUrl;

    constructor(private http: HttpClient) { }

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        })
    };

    getAccreditation(isAll: string, conferenceId: any) {
        let key = "";
        let method = "";
        if (isAll == "true") {
            key = "All";
        } else {
            key = "SelectedOnly";
        }
        if (key == "All") {
            method = "GetAllCompanyByConferenceid";
        } else {
            method = "GetSelectedCompanyByConferenceid";
        }
        return this.http.get(
            this.apiURL + "/PressAccreditation/" + method + "/" + conferenceId
        );
    }

    checkIsValid(model: any) {
        return this.http.post(
            this.apiURL + "/PressAccreditation/PressAccreditationPreview",
            model,
            this.httpOptions
        );
    }

    createAccreditation(application: any) {
        return this.http.post(
            this.apiURL + "/PressAccreditation",
            application,
            this.httpOptions
        );
    }

    deleteAccreditation(id: any) {
        return this.http.delete(this.apiURL + "/PressAccreditation/" + id);
    }
   
    getAccreditationById(id: number): Observable<any> {
        const url = this.apiURL + "/PressAccreditation/" + id;
        return this.http.get<any>(url);
    }

    updateAccreditation(application: any) {
        return this.http.put(
            this.apiURL + "/PressAccreditation",
            application,
            this.httpOptions
        );
    }

    updateSelection(model: PressAccreditationListUpdateModel) {
        return this.http.put(
            this.apiURL + "/PressAccreditation/UpdateSelection",
            model,
            this.httpOptions
        );
    }

    getEmailManagementList(conferenceId: number) {
        return this.http.get(
            this.apiURL +
            "/PressEmailManagement/ListSelectedbyConferenceId/" +
            conferenceId
        );
    }

    getDeclineEmailManagementList(conferenceId: number) {
        return this.http.get(
            this.apiURL +
            "/PressEmailManagement/ListNotSelectedByConferenceId/" +
            conferenceId
        );
    }

    sendSelectionEmail(conferenceModel: any) {
        return this.http.post(
            this.apiURL + "/PressEmailManagement/SendSelectionEmail",
            conferenceModel,
            this.httpOptions
        );
    }

    sendDeclinedEmail(arr: any) {
        return this.http.post(
            this.apiURL + "/PressEmailManagement/SendDeclineEmail",
            arr,
            this.httpOptions
        );
    }

    getConferenceMailDetails(companyId: any, conferenceId: any) {
        return this.http.get(
            this.apiURL + "/PressAccreditation/Mail/" + companyId + "/" + conferenceId
        );
    }

    updateEmailConfirmation(arr: any) {
        return this.http.put(
            this.apiURL + "/PressEmailManagement/UpdateEmailConfirmation",
            arr,
            this.httpOptions
        );
    }
    getParticipationStatusCount(conferenceId:any) {
        console.log('get count-->', this.apiURL +
        "/PressAccreditation/GetPaticipationMethodCount/" + conferenceId
        );
        
        return this.http.get(
          this.apiURL +
            "/PressAccreditation/GetPaticipationMethodCount/" + conferenceId
            
        );
      }
}
