import { Component, OnInit } from "@angular/core";
import { ToasterService } from "angular2-toaster";
@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"]
})
export class NotificationComponent implements OnInit {
  constructor(private toastr: ToasterService) {}

  ngOnInit() {}

  successmsg() {
    this.toastr.pop("Toastr Success message", "Success");
  }
  errorsmsg() {
    this.toastr.pop("Toastr Error Notification", "Error");
  }
  infotoastr() {
    this.toastr.pop("Important News", "Information");
  }
  toastrwaring() {
    this.toastr.pop("Battery about to Die", "Warning");
  }
}
