import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormsModule,
  FormGroup,
  Validators
} from "@angular/forms";
import { Template } from "ClientApp/app/models/Template";
import { EmailTemplateService } from "ClientApp/app/services/template/email-template.service";
import { Toast, ToasterService } from "angular2-toaster";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Router } from "@angular/router";
import { EmailEventService } from "ClientApp/app/services/template/email-event.service";

@Component({
  selector: "app-email-template-create",
  templateUrl: "./email-template-create.component.html",
  styleUrls: ["./email-template-create.component.css"]
})
export class EmailTemplateCreateComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private emailEventService: EmailEventService,
    public eTemplateService: EmailTemplateService,
    private toasterService: ToasterService,
    private router: Router
  ) {}
  submitted = false;
  editorConfig: any;
  
  templateForm!: FormGroup;
  templateModel = new Template();
  response: any;
  confs: any = [];
  Conferences: any = [];
  EventsList: any = [];
  ngOnInit() {
    this.templateForm = this.formBuilder.group({
      tName: ["", Validators.required],
      tConference: ["", Validators.required],
      tEvent: ["", Validators.required],
      tSubject: ["", Validators.required],
      tContent: ["", Validators.required]
    });

    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: "auto",
      minHeight: "250px",
      maxHeight: "auto",
      width: "auto",
      minWidth: "0",
      translate: "yes",
      enableToolbar: true,
      showToolbar: true,
      placeholder: "Enter text here...",
      defaultParagraphSeparator: "",
      defaultFontName: "Calibri",
      defaultFontSize: "",
      fonts: [
        { class: "arial", name: "Arial" },
        { class: "times-new-roman", name: "Times New Roman" },
        { class: "calibri", name: "Calibri" },
        { class: "comic-sans-ms", name: "Comic Sans MS" },
        { class: "helvetica", name: "Helvetica" },
        { class: "helvetica neue", name: "Helvetica Neue" },
        { class: "verdana", name: "Verdana" },
        { class: "sans-serif", name: "Sans-serif" }
      ],
      sanitize: false,
      toolbarPosition: "top"
    };
    
    this.loadConference();
    this.loadEvents();
  }

  // onChangeConference(){
   
  //   let confId = this.templateForm.value.tConference;
  //   let confId = 0
  //   //console.log('confId--',confId);
    
  //   if (confId){
  //     this.loadEvents(confId);
  //   }else{
  //     this.EventsList = [];
  //   }
  // }

  loadEvents(){
    return this.emailEventService.getActiveEmailEventFromAll().subscribe((data: any) => {
      ////console.log('responseEvent--',data);
      
      if (data)
      {
        this.EventsList = data.Info;
        //console.log('EventsList',this.EventsList);
        
      }
    });
  }

  // Get summaries list
  loadConference() {
    return this.eTemplateService.getActiveConference().subscribe((data: {}) => {
      this.confs = data;
      ////console.log(this.confs.Info);
      this.Conferences = this.confs.Info;
      ////console.log(this.Conferences);
    });
  }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.templateForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    //console.log('tempForm--',this.templateForm.value);
    
    // stop here if form is invalid
    if (this.templateForm.invalid) {
      //console.log("Invalid");
      return;
    } else {
      ////console.log(JSON.stringify(this.templateForm.value));
      //let request = JSON.stringify(this.templateForm.value.tName);
      this.templateModel.TemplateName = this.templateForm.value.tName;
      this.templateModel.Subject = this.templateForm.value.tSubject;
      this.templateModel.ConferenceId = this.templateForm.value.tConference;
      this.templateModel.EventId = +this.templateForm.value.tEvent;
      this.templateModel.Contents = this.templateForm.value.tContent;
      console.log(this.templateModel);
      this.createTemplate(this.templateModel);
    }
  }

  createTemplate(template: Template) {
    //console.log('template-------',template);
    
    return this.eTemplateService.createTemplate(template).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      //console.log(this.response);
      
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.router.navigate(["email/template"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
    // this.response = this.eTemplateService.createTemplate(template);
    // if (this.response) {
    //   //console.log("createTemplate in component.ts");
    //   //console.log(this.response);
    // }
  }
}
