export class Report {
  CriteriaId: number | undefined;
  ReportId: number | undefined;
  TemplateId: number | undefined;
  ConferenceId: number | undefined;
  // ConferenceId: any;
  ConferenceTypeID:number | undefined;
  FromDate: any;
  ToDate: any;
  ArchiveYear: any;
  ArchiveFromYear: any;
  ArchiveToYear: any;
  Year: any;
  all: any;
}

export class ReportTableModel {
  header: string | undefined;
}

export class ReportFieldModel {
  field: string | undefined;
}
