import { ScheduleService } from "./../../../../../services/schedule/schedule.service";
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { CompanyService } from "ClientApp/app/services/company.service";
import { TrackItem, Schedule } from "ClientApp/app/models/Schedule";
import { Toast, ToasterService } from "angular2-toaster";
import * as _ from "underscore";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { forEach } from "@angular/router/src/utils/collection";
@Component({
    selector: "app-open-tracks",
    templateUrl: "./open-tracks.component.html",
    styleUrls: ["./open-tracks.component.css"]
})
export class OpenTracksComponent implements OnInit {
    confs: any = [];
    Conferences: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    allTracks: any = [];
    tracksList: any = [];
    public conferenceList: any = [];
    conferenceId: number = 0;
    openTracks: any = [];
    companies: any = [];
    trackName: string | undefined;
    selectionTrackArr: any = [];
    selectedValue: any;
    _trackItemModel = new TrackItem();
    _scheduleModel = new Schedule();
    response: any;
    confID: any;
    trackCount: any;
    trackCountOpen: any;
    trackCountUnScheduled: any;
    copyCompanies: any = [];
    public ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        size: "lg"
    };
    @ViewChild("SheduleConfirmationDialog")
    SheduleConfirmationDialogContent: TemplateRef<any>;
    modalReference: any;
    compnyId: any;
    timeSlotId: any;
    Track_Id: any;
    constructor(
        public cService: ConferenceService,
        public scheduleService: ScheduleService, //public compnayService: CompanyService
        private toasterService: ToasterService,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.loadConference(ConferenceConfig.CONF_SUMMARY);
    }

    loadConference(typeId: number) {
        this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            // this.conferenceList = data;
            // console.log(this.conferenceList);
            // console.log("this.response=" + this.conferenceList.Info[0].ConferenceId);
            // this.conferenceId = this.conferenceList.Info[0].ConferenceId;
            this.confs = data;
            this.Conferences = this.confs.Info;
            //this.Conferences = _.filter(this.confs.Info, function (obj: any) { return obj.isActive == 1; });
            console.log(this.Conferences);
            this.conferenceItem = this.Conferences[0];
            this.selectedValue = this.conferenceItem;
            this._conferenceId = this.conferenceItem.ConferenceID;
            console.log(this.conferenceItem.ConferenceId);
            this.getConferenceDetails(this._conferenceId);
            //this.loadOpenTracks(this.conferenceId);
        });
    }

    getConferenceDetails(id: any) {
        console.log(id);
        this._conferenceId = id;
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            console.log(this.cInfo);
            this.confID = this.conferenceDetail.Conference.ConferenceId;
            this.allTracks = [];
            this.tracksList = [];
            this.loadCompanies(this.confID);          
        });
    }

    //TODO: any make it common service
    onChangeConference(id: number) {
        console.log("Inside " + id);
        this.getConferenceDetails(this.selectedValue.ConferenceID);
        //this.getConferenceDetails(id);
    }
    // Get open track list
    loadOpenTracks(key: any) {
        this.loadTrackCount(this.confID);
        return this.scheduleService.getOpenTracks(key).subscribe((data: {}) => {
            // this.openTracks = data;
            // if (this.openTracks.Info.length > 0) {
            //   this.trackName = this.openTracks.Info[0].RoomCode;
            // }
            this.allTracks = data;
            if (
                this.allTracks.Trackinfo != null &&
                this.allTracks.Trackinfo.length > 0
            ) {
                //this.trackName = this.allTracks.Info[0].RoomCode;
                this.tracksList = this.allTracks.Trackinfo;
                for (var i = 0; i < this.tracksList.length; i++) {
                    this.tracksList[i].isShow = true;
                    for (var j = 0; j < this.tracksList[i].Slotsinfo.length; j++) {
                        this.tracksList[i].Slotsinfo[j].companies = _.clone(this.companies);
                        //this.tracksList[i].Slotsinfo[j].companies.push(this.companies);
                    }
                }
                //this.slotsList = this.allTracks.Trackinfo.Slotsinfo;
            } else {
                this.tracksList = [];
            }
           // this.loadCompanies(key);
        });
    }

    loadTrackCount(conferenceId: any) {
        return this.scheduleService
            .getOpenTrackCount(conferenceId)
            .subscribe((data: {}) => {
                this.trackCount = data;
                if (this.trackCount.Info) {
                    this.trackCountOpen =
                        this.trackCount.Info.OpenSlots > 0
                            ? this.trackCount.Info.OpenSlots
                            : 0;
                    this.trackCountUnScheduled =
                        this.trackCount.Info.UnscheduledCompany > 0
                            ? this.trackCount.Info.UnscheduledCompany
                            : 0;
                }
            });
    }
    // Get companies list :  TODO: make it common service
    loadCompanies(key: number) {
        return this.scheduleService.getCompanies(key).subscribe((data: {}) => {
            this.companies = data;
            this.loadOpenTracks(key);
        });
    }

    onChangeCompany(event: any, timeSlotId: number, Track_Id: any, selectedItem: any) {
        const companyId = event.target.value;
        selectedItem.Company_Id = companyId;
        var isExist = _.find(this.selectionTrackArr, function (obj: any) {
            return obj.Company_Id == companyId;
        });
        if (isExist) {
            this.openConfirmDialog(companyId, timeSlotId, Track_Id);
        } else {
            console.log(companyId);
            this._trackItemModel.Id = 0;
            this._trackItemModel.Company_Id = +companyId;
            this._trackItemModel.Conference_Id = this.confID; //this._conferenceId;
            this._trackItemModel.Scheduled = 1;
            this._trackItemModel.TimeSlot_Id = timeSlotId;
            this._trackItemModel.UpdateResponse = 0;
            if (event.target.value != "") {
                let existingTSInd = this.selectionTrackArr.map((elem: any) => elem.TimeSlot_Id).indexOf(timeSlotId);
      
                if (existingTSInd !== -1){
                    this.selectionTrackArr[existingTSInd].Company_Id = +companyId;
                }else{
                    var item = this._trackItemModel;
                    this.selectionTrackArr.push(item);
                    this._trackItemModel = new TrackItem();
                }
                // var item = this._trackItemModel;
                // this._trackItemModel = new TrackItem();
                // this.selectionTrackArr.push(item);
            } else {
                var pos = this.selectionTrackArr
                    .map(function (e: { TimeSlot_Id: number }) {
                        return e.TimeSlot_Id;
                    })
                    .indexOf(timeSlotId);
                //console.log("pos " + pos);
                this.selectionTrackArr.splice(pos, 1);
            }
        }

        if (this.selectionTrackArr.length > 0) {
            let selectedCompanies = this.selectionTrackArr.map((sc: any) => sc.Company_Id);
            //tracksList[i].Slotsinfo[j].companies
            this.tracksList.forEach((trk: any) => {
                trk.Slotsinfo.forEach((slt: any) => {
                    let recordSelected = this.selectionTrackArr.filter((rs: any) => rs.TimeSlot_Id == slt.TimeSlot_Id);
                    recordSelected = recordSelected.length > 0 ? recordSelected[0] : {};
                    // if (trk.Track_Id == Track_Id && slt.TimeSlot_Id == timeSlotId) {

                    // } else {
                    slt.companies.Info = this.companies.Info.filter((cp: any) => (recordSelected.Company_Id == cp.CompId) || selectedCompanies.indexOf(cp.CompId) == -1);
                    // }
                });
            });
        }

        //console.dir(this.selectionTrackArr);
    }

    //selectOptions(slot: any, slotId: any) {
    //    console.log(slot.companies.Info);
    //    if (this.selectionTrackArr.length > 0) {
    //        //let slotCompanyIds = slot.companies.Info.map()
    //        //this.selectionTrackArr.filter(x => )
    //        _.each(this.selectionTrackArr, function (obj: any) {
    //            const index: number = slot.companies.Info.indexOf(_.find(slot.companies.Info, function (res: any) {
    //                return res.CompId == obj.Company_Id;
    //            }));
    //            if (index !== -1 && obj.TimeSlot_Id != slotId) {
    //                slot.companies.Info = _.filter(slot.companies.Info, function (res: any) {
    //                    return res.CompId != obj.Company_Id;
    //                });
    //            }

    //        });
    //        // this.companies.Info = array;
    //        let updatedList = slot.companies.Info;
    //        slot.companies.Info = [];
    //        slot.companies.Info = updatedList;
    //    }
    //    console.log(slot.companies.Info);

    //}

    onScheduleOpenTrack() {
        if (this.selectionTrackArr.length > 0) {
            this._scheduleModel.Sitems = this.selectionTrackArr;
            this.addUpdateSelection(this._scheduleModel);
        }
    }

    addUpdateSelection(schedule: Schedule) {
        console.log(schedule);
        this.scheduleService.addUpdateSelection(schedule).subscribe(result => {
            this.response = result;
            if (this.response.Code == 200) {
                var toast: Toast = {
                    type: "success",
                    title: this.response.Message,
                    showCloseButton: true
                };

                this.toasterService.pop(toast);
                this.selectionTrackArr = [];
                this.loadCompanies(this.confID);
            } else {
                var toast: Toast = {
                    type: "error",
                    title: this.response.Message,
                    showCloseButton: true
                };
                this.toasterService.pop(toast);
            }
        });
    }

    openConfirmDialog(item: any, timeSlotId: number, Track_Id: any) {
        this.compnyId = null;
        this.timeSlotId = null;
        this.Track_Id = null;
        this.modalReference = this.modalService.open(
            this.SheduleConfirmationDialogContent,
            this.ngbModalOptions
        );
        this.compnyId = item;
        this.timeSlotId = timeSlotId;
        this.Track_Id = Track_Id;
    }

    removeFromSelected() {
        var pos = this.selectionTrackArr
            .map(function (e: { TimeSlot_Id: number }) {
                return e.TimeSlot_Id;
            })
            .indexOf(this.compnyId);
        //console.log("pos " + pos);
        this.selectionTrackArr.splice(pos, 1);

        this._trackItemModel.Id = 0;
        this._trackItemModel.Company_Id = +this.compnyId;
        this._trackItemModel.Conference_Id = this.confID; //this._conferenceId;
        this._trackItemModel.Scheduled = 1;
        this._trackItemModel.TimeSlot_Id = this.timeSlotId;
        this._trackItemModel.UpdateResponse = 0;
        var item = this._trackItemModel;
        this._trackItemModel = new TrackItem();
        this.selectionTrackArr.push(item);
        this.modalReference.close();
        var trackId = this.Track_Id;
        var compId = this.compnyId;
        var track = _.find(this.tracksList, function (obj: any) {
            return obj.Track_Id == trackId;
        });

        _.find(track.Slotsinfo, function (obj: any) {
            if (obj.Company_Id == compId) {
                obj.Company_Id = "";
                return obj;
            }
        });
    }

    Cancel() {
        var compId = this.compnyId;
        var trackId = this.Track_Id;
        var timeSlotId = this.timeSlotId;
        var track = _.find(this.tracksList, function (obj: any) {
            return obj.Track_Id == trackId;
        });
        _.find(track.Slotsinfo, function (obj: any) {
            if (obj.Company_Id == compId && obj.TimeSlot_Id == timeSlotId) {
                obj.Company_Id = "";
                return obj;
            }
        });
        this.modalReference.close();
    }
}
