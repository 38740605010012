import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-edit-asset",
  templateUrl: "./edit-asset.component.html",
  styleUrls: ["./edit-asset.component.css"]
})
export class EditAssetComponent implements OnInit {
  @Input() itemObj: any;
  @Input() update: boolean;
  @Input() pos: any;
  @Input() rowObj: any;
  submitted = false;
  response: any;
  editForm!: FormGroup;
  assetObj: any;
  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    console.log(this.itemObj);
    this.assetObj = this.itemObj;
  }
}
