import { Component, OnInit, Input } from "@angular/core";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { Application } from "ClientApp/app/models/Application";
import { ApplicationService } from "ClientApp/app/services/investor/application.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";


// Load the full build.
var _ = require('lodash');


@Component({
  selector: "app-application-preview-modal",
  templateUrl: "./application-preview-modal.component.html",
  styleUrls: ["./application-preview-modal.component.css"]
})
export class ApplicationPreviewModalComponent implements OnInit {
  @Input() applicationForm: any;
  @Input() appItem: any;
  conferenceDetail: any;
  isEBDConf: boolean;
  submitMessage: string | undefined;
  processMessage: string | undefined;
  btnMessage: string | undefined;
  disableBtn: boolean | undefined;
  response: any;
  newThing:any;
  timeFrame_array:any;
  // fundRound:any=["Angel","Seed","Series A"];
  constructor(
    private toasterService: ToasterService,
    public activeModal: NgbActiveModal,
    private router: Router,
    public applicationService: ApplicationService,
    public cService: ConferenceService,
  ) {}

  ngOnInit() {
    console.log('application form on preview',this.applicationForm);
    // var mapped = this.applicationForm.rounds.map(item => ({ [item.key]: item.value }) );
    this.submitMessage = ConferenceConfig.APPLICATION_BTN_SUBMIT_MESSAGE;
    this.processMessage = ConferenceConfig.BTN_PROCESS_MESSAGE;
    this.btnMessage = this.submitMessage;
    if (this.appItem.companyDetails && this.appItem.companyDetails.ConferenceID){
      return this.cService.getConferenceById(this.appItem.companyDetails.ConferenceID).subscribe((data: {}) => {
        this.conferenceDetail = data;
        this.isEBDConf = this.conferenceDetail.Conference.isEBD;
      });
    }
  }
  onSubmit() {
    console.log('appItem',this.appItem);
    this.newThing = this.appItem.FoundRoundList.FundingRound;
    const newArray =[];
    for (let index = 0; index < this.newThing.length; index++) {
      let a = {"FundingRound":this.newThing[index]}
      newArray.push(a);
    }
    console.log('newArray',newArray);
    this.appItem.FoundRoundList =newArray;
    
    this.timeFrame_array = this.appItem.TimeFrameList.TimeFrame;
    const newArray_time=[];
    for (let index = 0; index < this.timeFrame_array.length; index++) {
      let a = {"TimeFrame":this.timeFrame_array[index]}
      
      newArray_time.push(a);
    }
    console.log('newArray',newArray_time);
    this.appItem.TimeFrameList =newArray_time;
    

    console.log('appItem2',this.appItem);
    this.createApplication(this.appItem);
  }

  createApplication(application: Application) {
    console.log("In createApplication....",application);
    
    this.disableBtn = true;
    this.btnMessage = this.processMessage;
    return this.applicationService
      .createApplication(application)
      .subscribe(result => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        console.log('response post---',this.response);
        
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
          this.activeModal.close();
          // Redirect to success page.
          let confId = this.response.EncryptedConferenceId;
          let id = this.response.EncryptedId;
          let type = 2; // For Application
          this.router.navigate([
            "investor/investorApplication/success/" + confId + "/" + type + "/" + id
          ]);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
        this.disableBtn = false;
        this.btnMessage = this.submitMessage;
      });
  }
}
