import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScheduleService } from "ClientApp/app/services/schedule/schedule.service";
import { ScheduleConfirmation } from "ClientApp/app/models/Schedule";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { environment } from "ClientApp/environments/environment";

@Component({
    selector: "app-schedule-confirmation",
    templateUrl: "./schedule-confirmation.component.html",
    styleUrls: ["./schedule-confirmation.component.css"]
})
export class ScheduleConfirmationComponent implements OnInit {
    confirmationForm!: FormGroup;
    scheduleConfirmationModel = new ScheduleConfirmation();
    response: any;
    compId: any;
    submitted: boolean | undefined;
    sId: any;
    cId: any;
    detail: any;
    conferenceDetail: any;
    banner: any;
    isDigitalConf: boolean = false;
    companyName: string = "";
    isBioFuture: any;
    constructor(
        public scheduleService: ScheduleService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        //const companyId = this.route.snapshot.paramMap.get("compId");
        const scheduleId = this.route.snapshot.paramMap.get("scheduledId");
        const conferenceId = this.route.snapshot.paramMap.get("conferenceId");
        //this.updateConfirmation(companyId);
        //console.log("companyId " + companyId);
        //this.compId = companyId;
        this.sId = scheduleId;
        this.cId = conferenceId;
        //console.log("conferenceId "+conferenceId );
        this.confirmationForm = this.formBuilder.group({
            confirm: ["", Validators.required],
            comment: [""]
        });

        this.getTrackDetails(scheduleId, conferenceId);
    }

    getTrackDetails(scheduleId: any, conferenceId: any) {
        return this.scheduleService
            .getTrackDetails(scheduleId, conferenceId)
            .subscribe((result: {}) => {
                debugger
                this.response = result;
                this.conferenceDetail = this.response.Info;
                if (this.conferenceDetail && this.conferenceDetail.ConferenceTypeName && 
                    (this.conferenceDetail.ConferenceTypeName == "Digital Medicine and Medtech Showcase" 
                    || this.conferenceDetail.ConferenceTypeName == "Digital Health Showcase"
                     || this.conferenceDetail.ConferenceTypeName == "Digital Medicine Showcase")) {
                    this.isDigitalConf = true;
                } else {
                    this.isDigitalConf = false;
                }
                if (this.conferenceDetail.ConforanceImage) {
                    this.banner =
                        environment.imagePath + this.conferenceDetail.ConforanceImage;
                }
                this.compId = this.conferenceDetail.CompanyId;
                this.companyName = this.conferenceDetail.CompanyName;
                
                var str=this.conferenceDetail.ConferenceTypeName;
                this.isBioFuture=str.startsWith('BioFuture');
            });
    }

    get fc() {
        // get field control(fc)
        return this.confirmationForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (this.confirmationForm.invalid) {
            console.log("Invalid");
            return;
        } else {
            this.scheduleConfirmationModel.CompanyId = this.compId;
            this.scheduleConfirmationModel.ConfirmationResponseType = +this
                .confirmationForm.value.confirm;
            this.scheduleConfirmationModel.ConfirmationResponse = this.confirmationForm.value.comment;
            this.updateConfirmation(this.scheduleConfirmationModel);
        }
    }

    updateConfirmation(companyId: any) {
        //this.scheduleConfirmationModel.CompanyId = companyId;
        console.log(this.scheduleConfirmationModel);
        return this.scheduleService
            .updateConfirmation(this.scheduleConfirmationModel)
            .subscribe((result: {}) => {
                this.response = result;
                if (this.response.Code == 200) {
                    this.router.navigate(["schedule/confirmationSuccess"]);
                } else {
                    this.router.navigate(["schedule/confirmationDone"]);
                }
            });
    }
}
