import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConferenceTypeModel } from "ClientApp/app/models/Conference";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { User } from "ClientApp/app/models/User";

@Component({
  selector: "app-conference-type-edit-modal",
  templateUrl: "./conference-type-edit-modal.component.html",
  styleUrls: ["./conference-type-edit-modal.component.css"]
})
export class ConferenceTypeEditModalComponent implements OnInit {
  @Input() typeId: number = 0;
  submitted = false;
  response: any;
  editForm!: FormGroup;
  currentUser : any ;
  typeModel = new ConferenceTypeModel();
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    public activeModal: NgbActiveModal,
    private conferenceService: ConferenceService
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    console.log(this.currentUser)
    this.loadType(this.typeId);

    this.editForm = this.formBuilder.group({
      type: ["", Validators.required],
      activeStatus: [""],
      isEBD: [null],
      showAssets: [null],
      enableParticipationMethod: [null],
    });
  }

  loadType(typeId: number) {
    this.conferenceService
      .getConferenceTypeById(typeId)
      .subscribe((result: {}) => {
        this.response = result;
        if (this.response && this.response.Message.Code == 200) {
          this.editForm.patchValue({
            type: this.response.Info.Type,
            activeStatus: this.response.Info.isActive,
            isEBD: this.response.Info.isEBD,
            showAssets: this.response.Info.IsAssetsShow,
            enableParticipationMethod: this.response.Info.EnableParticipantMethod
          });
        }
      });
  }

  get fc() {
    // get field control(fc)
    return this.editForm.controls;
  }

  onSubmit() {
    
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }
    this.typeModel.Id = this.typeId;
   // this.typeModel.RoleId = this.currentUser.Info.RoleId;
    this.typeModel.Type = this.editForm.value.type;
    this.typeModel.isActive = this.editForm.value.activeStatus ? 1 : 0;
    this.typeModel.isEBD = this.editForm.value.isEBD ? true : false;
    this.typeModel.IsAssetsShow = this.editForm.value.showAssets ? true : false;
    this.typeModel.EnableParticipantMethod = this.editForm.value.enableParticipationMethod ? true : false;
    this.updateType(this.typeModel);
  }

  updateType(conferenceTypeModel: ConferenceTypeModel) {
    this.conferenceService
      .updateConferenceType(conferenceTypeModel)
      .subscribe((result: {}) => {
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };

          this.toasterService.pop(toast);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
      });
  }
}
