import { Component, OnInit, HostListener, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UiSwitchModule } from "ngx-toggle-switch";
import {
  Application,
  CompanyDetails,
  ContactDetails,
  AssistantDetails,
  InvestorDetails,
  PresentationDetailModel,
  CompanyDetailModel,
  AppValidationModel,
  TimeFrameList,
  FoundRoundList,
} from "./../../../../../models/Application";
import { ApplicationService } from "ClientApp/app/services/investor/application.service";
import { Toast, ToasterService } from "angular2-toaster";
import { ActivatedRoute, Router } from "@angular/router";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { State, Country } from "ClientApp/app/models/Summary";
import { UtilService } from "ClientApp/app/services/common/util.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { ApplicationPreviewModalComponent } from "../../components/investor-application/application-preview-modal/application-preview-modal.component";
import { SummaryPreviewModalComponent } from "../../components/investor-summary/summary-preview-modal/summary-preview-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "ClientApp/environments/environment";
import moment from "moment";
import {
  AssetsUnderManagement,
  Therapeutics,
  InvestorTypes,
} from "../../../../../shared/data/Collection";
declare let gtag: Function;

@Component({
  selector: "app-investor-application",
  templateUrl: "./investor-application.component.html",
  styleUrls: ["./investor-application.component.css"],
})
export class InvestorApplicationComponent implements OnInit {
  applicationForm!: FormGroup;
  isOtherOptionVisible: boolean = false;
  participationError: boolean = true;
  submitted = false;
  disableBtn = false;
  application = new Application();
  companyDetails = new CompanyDetails();
  contactDetails = new ContactDetails();
  assistantDetails = new AssistantDetails();
  investorDetails = new InvestorDetails();
  funding = new FoundRoundList();
  timeFrameList = new TimeFrameList();
  response: any;
  _conferenceId: any;
  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  isEBDConf: boolean;
  cInfo: any;
  public showBtn: boolean = false;
  public fixedDiv: boolean = false;
  countryResponse: any;
  stateResponse: any;
  public countries: any = [];
  public states: any = [];
  state = new State();
  country = new Country();
  validationError: boolean = false;
  investorTypes = InvestorTypes;
  assets = AssetsUnderManagement;
  therapeuticsOptions = Therapeutics;
  banner: string = "";
  vModel = new AppValidationModel();
  presenterModel = new PresentationDetailModel();
  companyModel = new CompanyDetailModel();
  result: any;
  valid: any;
  otherStatus: boolean = false;
  loginuser: any;
  conforanceStartDate: any;
  conforanceEndDate: any;
  todaydate: any;
  IsExpire: boolean = false;
  conferenceIsActive: any;
  vData: any;
  fundingRounds: any = [
    "Angel",
    "Seed",
    "Series A",
    "Series B",
    "Series C+",
    "Post-IPO",
    "Other",
  ];
  // fundingRounds: any = [
  //    {
  //     "id": "1",
  //     "FundingRound": "Angel"
  //   },
  //   {
  //     "id": "2",
  //     "FundingRound": "Seed"
  //   },
  //   {
  //     "id": "3",
  //     "FundingRound": "Series A"
  //   },
  //   {
  //     "id": "4",
  //     "FundingRound": "Series B"
  //   },
  //   {
  //     "id": "5",
  //     "FundingRound": "Series C+"
  //   },
  //   {
  //     "id": "6",
  //     "FundingRound": "Post-IPO"
  //   },
  //   {
  //     "id": "7",
  //     "FundingRound": "Other"
  //   }
  // ]

  timeFrames: any = ["Now", "Next year", "Not actively investing"];
  // timeFrames:any=[
  //   {
  //     "ids": "1",
  //     "TimeFrame": "Now"
  //   },
  //   {
  //     "ids": "2",
  //     "TimeFrame": "Next year"
  //   },
  //   {
  //     "ids": "3",
  //     "TimeFrame": "Not actively investing"
  //   }
  // ]
  dropdownSettingsTime = {};
  dropdownSettingsRound = {};
  constructor(
    private formBuilder: FormBuilder,
    public applicationService: ApplicationService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    public cService: ConferenceService,
    private utilService: UtilService,
    private element: ElementRef,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    const cId = this.route.snapshot.paramMap.get("id");
    this._conferenceId = cId !== null ? cId : 0;
    this.getConferenceDetails(this._conferenceId);
    //this.getCountries();
    this.loginuser = localStorage.getItem("currentUser");
    this.todaydate = moment(new Date()).format("MM/DD/YYYY");
    this.applicationForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      website: [""],
      ccsalutation: ["", Validators.required],
      cfirstName: ["", Validators.required],
      clastName: ["", Validators.required],
      cposition: ["", Validators.required],
      cdepartment: [""],
      ccontactEmail: ["", [Validators.required, Validators.email]],
      country: ["", Validators.required],
      streetAddress: ["", Validators.required],
      suitePoBox: [""],
      city: ["", Validators.required],
      state: [""],
      zip: ["", Validators.required],
      ccontactPhone: ["", Validators.required],
      asalutation: [""],
      afirstName: [""],
      alastName: [""],
      acontactEmail: [""],
      acontactPhone: [""],
      investorType: ["", Validators.required],
      typeOther: [""],
      assets: ["", Validators.required],
      fundInvested: [""],
      pensionInvested: [""],
      familyOfficeInvested: [""],
      ventureInvested: [""],
      angelInvested: [""],
      charityInvested: [""],
      FINRAInvested: [""],
      analystInvested: [""],
      crowdInvested: [""],
      additionalComments: [""],
      rounds: ["", Validators.required],
      timeFrame: ["", Validators.required],
      participationmethod: ["", Validators.required],
      // participation removed as per the instruction from Anish sir and later uncommented and displayed on condition basis
      // if 'enable participation method' is true in conferance type then enable participation method
      cTwitterProfile: [""],
      cLinkedInProfile: [""],
      isAccredited: ["", Validators.required],
      therapeutics: ["", Validators.required],
      customTherapeutics: [""],
    });

    // ------------multiselection fields-------------------//
    this.dropdownSettingsRound = {
      singleSelection: false,
      idField: "id",
      textField: "FundingRound",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    this.dropdownSettingsTime = {
      singleSelection: false,
      idField: "ids",
      textField: "TimeFrame",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
  }

  //--------------- Multi Selection-----------------//
  onItemSelectRound(item: any) {
    //console.log('item---',item);
    // this.fundingRounds.push(item);
    // console.log('item- stat-',item);
    // this.enableOrDisableValidationsRound();
  }
  onSelectAllRound(items: any) {
    // this.fundingRounds = items;
    // this.enableOrDisableValidationsRound();
  }

  onDeSelectRound(item: any) {
    // var pos = this.fundingRounds
    //   .map(function(e: { id: number }) {
    //     return e.id;
    //   })
    //   .indexOf(item.id);
    // this.fundingRounds.splice(pos, 1);
    // this.enableOrDisableValidationsRound();
  }

  enableOrDisableValidationsRound() {
    // if (this.fundingRounds.length > 0) { return this.applicationForm.controls; }
    // else { return this.applicationForm.invalid; }
    // return this.applicationForm.controls;
  }

  // ------------Time Frame ------------------//
  onItemSelect(item1: any) {
    this.timeFrames.push(item1);
    this.enableOrDisableValidations();
  }
  onSelectAll(items1: any) {
    this.timeFrames = items1;
    this.enableOrDisableValidations();
  }

  onDeSelect(item1: any) {
    var pos = this.timeFrames
      .map(function (e: { ids: number }) {
        return e.ids;
      })
      .indexOf(item1.ids);
    this.timeFrames.splice(pos, 1);
    this.enableOrDisableValidations();
  }

  enableOrDisableValidations() {}

  getCountries() {
    return this.utilService.loadCountries().subscribe((data: {}) => {
      this.countryResponse = data;
      this.countries = this.countryResponse.Info;
      console.dir(this.countries);
    });
  }

  getStates(countryCode: string) {
    return this.utilService.loadStates(countryCode).subscribe((data: {}) => {
      this.stateResponse = data;
      console.dir(this.stateResponse);
      this.states = this.stateResponse.Info;
    });
  }

  onCountryChange(countryObject: any) {
    console.log(countryObject);
    this.getStates(countryObject.Code);
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
      this.getCountries();
    });
  }

  getConferenceDetails(id: number) {
    console.log(id);
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.isEBDConf = this.conferenceDetail.Conference.isEBD;

      //this.conferenceIsActive = this.conferenceDetail.Conference.isActive;
      //console.log(this.conferenceIsActive);
      // if(this.conferenceIsActive == 0){
      //   this.router.navigate(["/conference/status/"+id]);
      // }
      // if (this.conferenceDetail && this.conferenceDetail.Conference.StartDate)
      //   this.conforanceStartDate = moment(
      //     new Date(this.conferenceDetail.Conference.StartDate)
      //   ).format("MM/DD/YYYY");
      // if (this.conferenceDetail && this.conferenceDetail.Conference.EndDate)
      //   this.conforanceEndDate = moment(
      //     new Date(this.conferenceDetail.Conference.EndDate)
      //   ).format("MM/DD/YYYY");

      // if (
      //   this.conforanceStartDate >= this.todaydate &&
      //   this.conforanceEndDate <= this.todaydate
      // )
      //   this.IsExpire = true;

      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;

      if (
        [8, 11, 12].indexOf(Number(this.conferenceDetail.Conference.TypeId)) !==
        -1
      ) {
        gtag("config", "UA-73328446-12", {
          linker: {
            domains: ["knect365.com", "demy-colton.com"],
          },
          page_title: "Investor Application - " + this.ConferenceName,
          page_path: this.router.url,
        });

        gtag("event", "IA Form Open", {
          event_category: this.ConferenceName + " IA Form",
          event_label: "Investor Application",
          event_action: "open",
        });
      }

      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 2) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
    });
  }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.applicationForm.controls;
  }

  onSubmit() {
    console.log("value", this.applicationForm.value.participationmethod);
    this.submitted = true;
    this.validationError = this.applicationForm.invalid;
    console.log(
      "method",
      this.conferenceDetail.Conference.EnableParticipantMethod
    );
    console.log("method", this.applicationForm.value.participationMethod);
    // stop here if form is invalid
    if (this.applicationForm.invalid) {
      // <!-- Participation commented as per the instruction from Anish sir that we temporarily removing the field EnableParticipantMethod based
      //                         condition checking -->
      // if (
      //   this.applicationForm.invalid ||
      //   (this.conferenceDetail.Conference.EnableParticipantMethod == true &&
      //     !this.applicationForm.value.participationmethod)
      // ) {
      //   if (
      //     this.conferenceDetail.Conference.EnableParticipantMethod == true &&
      //     !this.applicationForm.value.participationmethod
      //   ) {
      //     this.participationError = false;
      //   } else {
      //     this.participationError = true;
      //   }
      this.onTherapeuticschange();
      this.validationError = true;
      this.utilService.showValidationDiv();
      // let el = this.element.nativeElement.querySelector(".validation-errors");
      // el.scrollIntoView();
      return;
    } else {
      this.participationError = true;
      this.validationError = false;
      this.companyDetails.Name = this.applicationForm.value.companyName;
      this.companyDetails.Website = this.applicationForm.value.website;
      //this.companyDetails.Conference_Id = this._conferenceId;
      this.companyDetails.ConferenceID = this._conferenceId;
      this.companyDetails.IsSelected = 0;
      //new field
      this.companyDetails.ParticipationMethod =
        this.applicationForm.value.participationmethod;

      this.contactDetails.Title = this.applicationForm.value.ccsalutation;
      this.contactDetails.FirstName = this.applicationForm.value.cfirstName;
      this.contactDetails.LastName = this.applicationForm.value.clastName;
      this.contactDetails.Position = this.applicationForm.value.cposition;
      this.contactDetails.Department = this.applicationForm.value.cdepartment;
      this.contactDetails.Email = this.applicationForm.value.ccontactEmail;
      this.contactDetails.Country = this.applicationForm.value.country.Name;
      this.contactDetails.CountryCode = this.applicationForm.value.country.Code;
      this.contactDetails.StreetAddress =
        this.applicationForm.value.streetAddress;
      this.contactDetails.Suite = this.applicationForm.value.suitePoBox;
      this.contactDetails.City = this.applicationForm.value.city;
      this.contactDetails.State = this.applicationForm.value.state.Name
        ? this.applicationForm.value.state.Name
        : "";
      this.contactDetails.StateCode = this.applicationForm.value.state.Code
        ? this.applicationForm.value.state.Code
        : "";
      this.contactDetails.Zip = this.applicationForm.value.zip;
      this.contactDetails.ContactPhone =
        this.applicationForm.value.ccontactPhone;

      this.contactDetails.TwitterProfile =
        this.applicationForm.value.cTwitterProfile;
      this.contactDetails.LinkedInProfile =
        this.applicationForm.value.cLinkedInProfile;

      this.assistantDetails.Title = this.applicationForm.value.asalutation;
      this.assistantDetails.FirstName = this.applicationForm.value.afirstName;
      this.assistantDetails.LastName = this.applicationForm.value.alastName;
      this.assistantDetails.ContactEmail =
        this.applicationForm.value.acontactEmail;
      this.assistantDetails.ContactPhone =
        this.applicationForm.value.acontactPhone;

      this.investorDetails.IsAccreditedInvestor =
        this.applicationForm.value.isAccredited == 1;
      this.investorDetails.InvestorType =
        this.applicationForm.value.investorType.Id;
      this.investorDetails.InvestorTypeOther =
        this.applicationForm.value.typeOther;
      this.investorDetails.Therapeutics =
        this.applicationForm.value.therapeutics;
      this.investorDetails.TherapeuticsOther =
        this.applicationForm.value.customTherapeutics;
      this.investorDetails.FundInvested = this.applicationForm.value
        .fundInvested
        ? "Yes"
        : "No";
      this.investorDetails.PensionInvested = this.applicationForm.value
        .pensionInvested
        ? "Yes"
        : "No";
      this.investorDetails.FamilyOfficeInvested = this.applicationForm.value
        .familyOfficeInvested
        ? "Yes"
        : "No";
      this.investorDetails.VentureInvested = this.applicationForm.value
        .ventureInvested
        ? "Yes"
        : "No";
      this.investorDetails.AngelInvested = this.applicationForm.value
        .angelInvested
        ? "Yes"
        : "No";
      this.investorDetails.CharitableInvested = this.applicationForm.value
        .charityInvested
        ? "Yes"
        : "No";
      this.investorDetails.FINRAInvested = this.applicationForm.value
        .FINRAInvested
        ? "Yes"
        : "No";
      this.investorDetails.AnalystFundInvested = this.applicationForm.value
        .analystInvested
        ? "Yes"
        : "No";
      this.investorDetails.CrowdFundingInvested = this.applicationForm.value
        .crowdInvested
        ? "Yes"
        : "No";
      this.investorDetails.AssetsUnderManagement =
        this.applicationForm.value.assets;
      this.investorDetails.AdditionalComments =
        this.applicationForm.value.additionalComments;
      this.funding.FundingRound = this.applicationForm.value.rounds;
      this.timeFrameList.TimeFrame = this.applicationForm.value.timeFrame;

      this.application.companyDetails = this.companyDetails;
      this.application.contactDetails = this.contactDetails;
      this.application.assistantDetails = this.assistantDetails;
      this.application.investorDetails = this.investorDetails;
      this.application.FoundRoundList = this.funding;
      this.application.TimeFrameList = this.timeFrameList;
      console.log(JSON.stringify(this.application));

      // Check Email, CompanyName, Phone already exists.
      this.checkIsValid();

      //this.openPreviewModal(this.application, this.applicationForm.value);
      //this.createApplication();
    }
  }

  // Check Email, CompanyName, Phone already exists.
  checkIsValid() {
    this.companyModel.ConferenceID = this._conferenceId;
    this.companyModel.Name = this.applicationForm.value.companyName;
    this.presenterModel.Email = this.applicationForm.value.ccontactEmail;
    this.presenterModel.ContactPhone = this.applicationForm.value.ccontactPhone;
    this.vModel.contactDetails = this.presenterModel;
    this.vModel.companyDetails = this.companyModel;

    if (
      this.companyModel.ConferenceID != "" &&
      this.presenterModel.Email != "" &&
      this.companyModel.Name != "" &&
      this.presenterModel.ContactPhone != ""
    ) {
      return this.applicationService
        .checkIsValid(this.vModel)
        .subscribe((data: {}) => {
          this.result = data;
          this.valid = this.result.Code;
          if (this.valid == "400") {
            var toast: Toast = {
              type: "error",
              title: this.result.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
          } else {
            this.openPreviewModal(this.application, this.applicationForm.value);
          }
        });
      this.vModel = new AppValidationModel();
    }
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(event: any) {
    let position = 500;
    let scrollPositionFromTop = +window.pageYOffset.toString();
    //let submitBtn = event.nativeElement.querySelectorAll(".submit");
    if (scrollPositionFromTop > position) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    let windowHeight = +window.innerHeight;
    if (
      scrollPositionFromTop + windowHeight >
      document.body.clientHeight - 100
    ) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

  isSelected() {
    //console.log("test");
    if (this.applicationForm.value.investorType) {
      console.log(this.applicationForm.value.investorType.Id);
      var check = 10;
      if (
        Number(this.applicationForm.value.investorType.Id) === Number(check)
      ) {
        this.otherStatus = true;
      } else {
        this.otherStatus = false;
        this.applicationForm.value.typeOther = "";
      }
    } else {
      this.otherStatus = false;
      this.applicationForm.value.typeOther = "";
    }
  }

  /* Modal popup for preview */
  openPreviewModal(appModel: any, appForm: any) {
    const modalRef = this.modalService.open(ApplicationPreviewModalComponent, {
      windowClass: "modalWidth",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.appItem = appModel;
    modalRef.componentInstance.applicationForm = appForm;
    modalRef.result
      .then((result: any) => {
        console.log(result);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  onCheckBoxChange(e: any) {
    if (e.target.value) {
      this.participationError = true;
    } else {
      this.participationError = false;
    }
  }

  onTherapeuticschange() {
    debugger;
    const therapeuticsControl = this.applicationForm.controls["therapeutics"];
    const customTherapeuticsControl =
      this.applicationForm.controls["customTherapeutics"];
    if (this.applicationForm.value.therapeutics) {
      var check = 100;
      if (
        Number(this.applicationForm.value.therapeutics.Id) === Number(check)
      ) {
        this.isOtherOptionVisible = true;
        if (!this.applicationForm.value.customTherapeutics) {
          customTherapeuticsControl.setValidators([Validators.required]);
          therapeuticsControl.setValidators(null);
        } else {
          customTherapeuticsControl.setValidators(null);
          therapeuticsControl.setValidators([Validators.required]);
        }
      } else {
        customTherapeuticsControl.setValidators(null);
        therapeuticsControl.setValidators(null);
        this.isOtherOptionVisible = false;
      }
    } else {
      this.isOtherOptionVisible = false;
    }
    therapeuticsControl.updateValueAndValidity();
    customTherapeuticsControl.updateValueAndValidity();
  }
}
