import { Country } from "ClientApp/app/models/Summary";
import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService, Toast } from "angular2-toaster";
import { ReportService } from "ClientApp/app/services/report/report.service";
import {
    ReportTemplate,
    ReportCriteria,
    ReportTemplateDetails,
    ReportTemplateHeader
} from "ClientApp/app/models/ReportTemplate";
import {
    moveItemInArray,
    transferArrayItem,
    CdkDragDrop
} from "@angular/cdk/drag-drop";
import * as _ from "underscore";
@Component({
    selector: "app-report-template-edit-modal",
    templateUrl: "./report-template-edit-modal.component.html",
    styleUrls: ["./report-template-edit-modal.component.css"]
})
export class ReportTemplateEditModalComponent implements OnInit {
    @Input() templateId: number = 0;
    @Input() reportId: number = 0;

    templateEditForm!: FormGroup;
    response: any;
    reportTypes: any;
    reportTypeId: number = 0;

    reportTypeFields: any;
    columns: any;
    items: any;
    criterias = [];
    reportTemplateModel = new ReportTemplate();
    reportCriteriaModel = new ReportCriteria();
    reportHeaderModel = new ReportTemplateHeader();
    reportDetailModel = new ReportTemplateDetails();
    public droppedItems: Array<ReportTemplateDetails> = [];
    public dragndropItemList: Array<ReportTemplateDetails> = [];
    dragndropItems: any;
    submitted = false;
    criteriaError = false;
    fieldsError = false;
    selectedColumns = [];
    idsList = [{}];
    rType: any;
    criteria: any;
    selectedColumnsList: any = [];
    constructor(
        public reportService: ReportService,
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        // console.log("TemplateId " + this.templateId);
        // console.log("ReportId " + this.reportId);
        this.loadDefault(this.templateId, this.reportId);

        this.templateEditForm = this.formBuilder.group({
            reportType: ["", Validators.required],
            templateName: ["", Validators.required],
            criteria: [""],
            sortField: [""],
        });
    }

    get fc() {
        // get field control(fc)
        return this.templateEditForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (!this.dragndropItems || this.dragndropItems.length == 0) {
            this.fieldsError = true;
            return;
        }
        // stop here if form is invalid
        if (!this.templateEditForm.value.criteria && this.templateEditForm.value.reportType == 1) {
            this.criteriaError = true;
            return;
        }
        if (this.templateEditForm.invalid) {
            //console.log("Invalid");
            return;
        } else {
            this.criteriaError = false;
            this.dragndropItems.forEach(
                (item: { ReportDetailsId: number | undefined }) => {
                    this.reportDetailModel.ReportDetailsId = item.ReportDetailsId;
                    this.reportDetailModel.IsSortField = this.templateEditForm.value.sortField && (this.templateEditForm.value.sortField == item.ReportDetailsId) ? true : false;
                    //console.log(this.reportDetailModel);
                    this.droppedItems.push(this.reportDetailModel);
                    this.reportDetailModel = new ReportTemplateDetails();
                }
            );
            //console.log(this.droppedItems);
            this.reportTemplateModel.TemplateDetails = this.droppedItems;
            this.reportHeaderModel.TemplateId = this.templateId;
            this.reportHeaderModel.TemplateName = this.templateEditForm.value.templateName;
            this.reportHeaderModel.ReportId = +this.templateEditForm.value.reportType;
            this.reportTemplateModel.TemplateHeader = this.reportHeaderModel;

            //this.reportTemplateModel.TemplateDetails =
            this.reportCriteriaModel.CriteriaId = +this.templateEditForm.value
                .criteria;
            this.reportTemplateModel.Criteria = this.reportCriteriaModel;

            //console.dir(this.reportTemplateModel);
            this.updateReportTemplate(this.reportTemplateModel);
        }
    }

    updateReportTemplate(templateModel: ReportTemplate) {
        return this.reportService
            .updateReportTemplate(templateModel)
            .subscribe(result => {
                // This code will be executed when the HTTP call returns successfully
                this.response = result;
                if (this.response.Code == 200) {
                    var toast: Toast = {
                        type: "success",
                        title: this.response.Message,
                        showCloseButton: true
                    };

                    this.toasterService.pop(toast);
                    this.activeModal.close();
                } else {
                    var toast: Toast = {
                        type: "error",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                }
            });
    }

    onChangeType(typeId: number) {
        //console.log("typeId " + typeId);
        this.getTypeFields(typeId);
        this.dragndropItems = [];
        this.selectedColumns = [];
        if (typeId == 1) {
            this.templateEditForm.controls["criteria"].setValidators([
                Validators.required
            ]);
            this.templateEditForm.controls["criteria"].updateValueAndValidity();
        }
        else {
            this.templateEditForm.controls["criteria"].setValidators([]);
            this.templateEditForm.controls["criteria"].updateValueAndValidity();
            this.criteriaError = false;
        }
    }

    getTypeFields(typeId: number) {
        this.reportService.getReportTypeFields(typeId).subscribe((result: {}) => {
            this.reportTypeFields = result;
            if (this.reportTypeFields.ReportDetailsList.length > 0) {
                this.columns = this.reportTypeFields.ReportDetailsList;
                this.criterias = this.reportTypeFields.CriteriaList;
                //console.log(this.criterias);
            }
        });
    }

    onDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        if (event.container.id == "selectedColumnList") {
            this.dragndropItems = event.container.data;
        }
        else {
            var fields = this.dragndropItems;
            this.dragndropItems = _.each(this.dragndropItems, function (obj: any) {
                var newObj: any = event.container.data[event.previousIndex];
                if (obj.ReportDetailsId == newObj.ReportDetailsId) {
                    var index = _.findIndex(fields, { ReportDetailsId: obj.ReportDetailsId });
                    fields.splice(index,1);
                }
            });
         }
        this.fieldsError = false;
    }

    loadDefault(templateId: number, reportId: number) {
        // Get report Types
        this.reportService
            .getTemplateDetails(templateId, reportId)
            .subscribe((result: {}) => {
                console.log(`tst`,result);
                
                this.response = result;
                this.criterias = this.response.CriteriaList;
                this.columns = this.response.ReportDetailsList;
                this.reportTypes = this.response.Report;
                this.selectedColumns = this.response.TemplateFields;
                
                // this.columns = this.columns.
                // filter((x : any) => this.selectedColumns
                // .map((elem : any) =>elem.ReportDetailsId).includes(x.ReportDetailsId))

                if (this.response.TemplateFields){
                    let sortFieldRecs = this.response.TemplateFields.filter((x:any) => x.IsSortField == true);
                    let sortFieldVal = sortFieldRecs && sortFieldRecs.length > 0 ? sortFieldRecs[0].ReportDetailsId : "";
                    this.templateEditForm.controls.sortField.setValue(sortFieldVal);
                }

                this.templateId = this.response.TemplateName.TemplateId;

                this.rType = this.reportTypes.filter((obj: { flag: string }) => {
                    return obj.flag === "Selected";
                });

                this.criteria = this.criterias.filter((obj: { flag: string }) => {
                    return obj.flag === "Selected";
                });

                this.selectedColumnsList = this.selectedColumns.filter(
                    (obj: { ReportDetailsId: number }) => {
                        //console.dir(obj.ReportDetailsId);
                        this.reportDetailModel.ReportDetailsId = obj.ReportDetailsId;
                        this.dragndropItemList.push(this.reportDetailModel);
                        this.reportDetailModel = new ReportTemplateDetails();
                    }
                );
                this.dragndropItems = this.dragndropItemList;
                this.templateEditForm.patchValue({
                    templateName: this.response.TemplateName.TemplateName,
                    reportType: this.response.TemplateName.ReportId,
                    criteria: this.criteria[0] ? this.criteria[0].CriteriaId : null,
                });

            });
    }

}
