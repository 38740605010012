export class ConferenceConfig {
  public static CONF_SUMMARY = 1;
  public static CONF_APPLICATION = 2;
    public static CONF_PROFILE = 3;
    public static CONF_PRESSACCREDIATION = 4;
    public static CONF_INVITATION = 5;

  /* Button Text */

  public static SUMMARY_BTN_SUBMIT_MESSAGE = "Submit Investor Summary Profile";
  public static APPLICATION_BTN_SUBMIT_MESSAGE = "Submit Investor Application";
  public static PROFILE_BTN_SUBMIT_MESSAGE = "Submit Participant Profile";
  public static INVITATION_BTN_SUBMIT_MESSAGE = "Submit Invitation Request";
  public static ACCREDITATION_BTN_SUBMIT_MESSAGE = "Submit PRESS ACCREDITATION";
  // Summary Emails
  public static SUMMARY_EMAIL_ISP_BTN_MESSAGE = "Send ISP Reminder";
  public static SUMMARY_EMAIL_SLIDE_BTN_MESSAGE = "Send Slide Deck Reminder";
  public static SUMMARY_EMAIL_LOGO_BTN_MESSAGE = "Send Logo Reminder";

  public static BTN_PROCESS_MESSAGE = "Processing...";

  /* Pagination */
  public static DEFAULT_PAGE_LENGTH = 25;
  public static DEFAULT_PAGE_OPTIONS = [[25, 50, 100, 300], [25, 50, 100, 300]];
}
