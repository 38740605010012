import { DomSanitizer } from "@angular/platform-browser";
import { PipeTransform, Pipe } from "@angular/core";
@Pipe({ name: "dFormat" })
export class DateFormatPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string) {
    let newDate = "";
    if (value != "") {
      let d = value.split(".");
      newDate = d[1] + "/" + d[2] + "/" + d[0];
    }
    return newDate;
  }
}
