import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
//import { RestApiService } from "../../../../../services/rest-api.service";
import { SummaryService } from "../../../../../services/investor/summary.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { Toast, ToasterService } from "angular2-toaster";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import * as _ from "underscore";
import moment from "moment";

@Component({
  selector: "app-investor-summary-list",
  templateUrl: "./investor-summary-list.component.html",
  styleUrls: ["./investor-summary-list.component.css"],
})
export class InvestorSummaryListComponent implements OnInit {
  Summaries: any = [];
  selectedValue: any;
  response: any;
  typeSelected: string = "true";
  selectedPageSize: any;
  inPerson: number;
  virtual: number;
  selectedInpersonCount: any;
  selectedVirtualCount: any;
  constructor(
    public summaryService: SummaryService,
    public cService: ConferenceService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}
  listAll: string = "true";
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  dataTable: any;
  reportData = [];

  ConferenceFileTitle: any;
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: true,
    title: "",
    useBom: true,
    noDownload: false,
    headers: ["CompanyName", "Date Created", "Last Updated"],
  };

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
    this.typeSelected = "true";
  }
  //Get InPerson / Virtual count
  participationStatusCount(id: any) {
    this.summaryService
      .getParticipationStatusCount(id)
      .subscribe((data: any) => {
        if (data.Status) {
          console.log("get--ParticipationStatusCount", data);
          this.inPerson = data.Info.InPersonCount;
          this.virtual = data.Info.VirtualCount;
          this.selectedInpersonCount = data.Info.SelectedInPersonCount;
          this.selectedVirtualCount = data.Info.SelectedVirtualCount;
        }
      });
  }
  // Get Conference list
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      //this._conferenceId = this.conferenceItem.ConferenceId;
      console.log(this.conferenceItem.ConferenceID);
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
      this.participationStatusCount(this.conferenceItem.ConferenceID);
    });
  }

  onChangeConference(id: any) {
    //this.getConferenceDetails(id);
    console.log(this.selectedValue);
    // if (this.dataTable) {
    //   this.dataTable.destroy();
    // }
    this.getConferenceDetails(this.selectedValue.ConferenceID);
    this.participationStatusCount(this.selectedValue.ConferenceID);
  }

  getConferenceDetails(conferenceId: any) {
    console.log(conferenceId);
    //this._conferenceId = conferenceId;
    return this.cService
      .getConferenceById(conferenceId)
      .subscribe((data: {}) => {
        this.conferenceDetail = data;
        console.log(this.conferenceDetail);
        this.cInfo = this.conferenceDetail.ConferenceCategory;
        this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
        this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
        //console.log(this.cInfo);
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadSummaries(
          this.listAll,
          //this.conferenceDetail.Conference.ConferenceId
          this.selectedValue.ConferenceId
        );
      });
  }

  onChangeFlag(value: string) {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.typeSelected = value;
    this.loadSummaries(value, this.selectedValue.ConferenceId);
  }
  // Get summaries list
  loadSummaries(key: string, conferenceId: any) {
    return this.summaryService
      .getSummary(key, conferenceId)
      .subscribe((data: {}) => {
        this.Summaries = data;
        console.log("this.Summaries", this.Summaries);
        this.enablePagination();
      });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#inv_summary_list");
    // if (this.dataTable) {
    //   this.dataTable.destroy();
    // }
    this.dataTable = table.DataTable({
      aoColumns: [null, null, { sType: "date" }, { sType: "date" }, null, null],
      pagingType: "full_numbers",
      paging: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      order: [[2, "desc"]],
      columnDefs: [{ type: "date", targets: [2] }],
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>',
        },
      },
      stripeClasses: ["odd-row", "even-row"],
    });
    $("#inv_summary_list").on("length.dt", function (e, settings, len) {
      self.selectedPageSize = len;
    });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.CompanyName)
      .then((confirmed) => {
        this.summaryService.deleteSummary(obj.CompId).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true,
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadSummaries(
              this.typeSelected,
              this.selectedValue.ConferenceId
            );
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  downloadCSV() {
    var reportdata: any = [];
    _.each(this.Summaries.Info, function (obj: any) {
      reportdata.push({
        CompanyName: obj.CompanyName != null ? obj.CompanyName : "",
        CreatedOn:
          obj.CreatedOn != null
            ? moment(obj.CreatedOn).format("MM/DD/YYYY hh:mm:a")
            : "",
        LastUpdated:
          obj.newupdatedate != "Null"
            ? moment(obj.newupdatedate).format("MM/DD/YYYY hh:mm:a")
            : "",
      });
    });
    this.csvOptions.title = "Investor Summaries - " + this.ConferenceName;
    new AngularCsv(reportdata, "Investor Summaries", this.csvOptions);
  }
}
