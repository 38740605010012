import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { environment } from "ClientApp/environments/environment";

@Component({
  selector: "app-conference-upload",
  templateUrl: "./conference-upload.component.html",
  styleUrls: ["./conference-upload.component.css"]
})
export class ConferenceUploadComponent implements OnInit {
  public progress: number | undefined;
  public message: string | undefined;
  @Output() public onUploadFinished = new EventEmitter();
  apiURL = environment.baseUrl;
  constructor(private http: HttpClient, private cService: ConferenceService) {}

  ngOnInit() {}

  public uploadFile = (files: any) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    //this.uploadBanner(formData);
    this.http
      .post(this.apiURL + "/Conference/ImageUpload", formData, {
        reportProgress: true,
        observe: "events"
      })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(
            (100 * event.loaded) / (event.total ? event.total : 0)
          );
        else if (event.type === HttpEventType.Response) {
          this.message = "Upload success.";
          //this.onUploadFinished.emit(event.body);
        }
      });
  };
}
