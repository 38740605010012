import { Component, OnInit, Input } from "@angular/core";
import { ScheduleService } from "ClientApp/app/services/schedule/schedule.service";

@Component({
  selector: "app-schedule-view-detail",
  templateUrl: "./schedule-view-detail.component.html",
  styleUrls: ["./schedule-view-detail.component.css"]
})
export class ScheduleViewDetailComponent implements OnInit {
  @Input() public companyId: number = 0;
  detail: any;
  dInfo: any;
  companyName: string = "";
  respondedOn: string = "";
  comment: string = "";
  responseType: string = "";
  constructor(public sService: ScheduleService) {}

  ngOnInit() {
    console.log(this.companyId);
    this.getDetails(this.companyId);
  }

  getDetails(companyId: any) {
    return this.sService
      .getCompanyCommentDetails(companyId)
      .subscribe((data: {}) => {
        this.detail = data;
        console.log(this.detail);
        if (this.detail && this.detail.Info) {
          this.dInfo = this.detail.Info;
          this.companyName = this.dInfo.CompanyName;
          this.respondedOn = this.dInfo.ConfirmationResponseDate;
          this.comment = this.dInfo.ConfirmationResponse;
          this.responseType = this.dInfo.ResponseType;
          console.log(this.dInfo);
        }
      });
  }
}
