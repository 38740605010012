import { Component, OnInit } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
@Component({
  selector: "app-invitation-conference",
  templateUrl: "./invitation-conference.component.html",
  styleUrls: ["./invitation-conference.component.css"]
})
export class InvitationConferenceComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  constructor(public cService: ConferenceService) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_INVITATION);
  }

  // Get Conference list
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
    });
  }
}
