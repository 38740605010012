import {
  Application,
  ApplicationListUpdateModel,
  AppValidationModel
} from "./../../models/Application";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class ApplicationService {
  apiURL = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    })
  };

  // createApplication(application: Application) {
  //   console.log("In service");
  //   console.dir(application);
  //   return this.http
  //     .post(this.apiURL + "/InvestorApplication", application, this.httpOptions)
  //     .subscribe(result => {
  //       console.log(result);
  //       console.dir(result);
  //     });
  // }

  createApplication(application: Application) {
    console.log("In service", this.apiURL + "/InvestorApplication",
    application,
    this.httpOptions);
    // console.dir(application);
    return this.http.post(
      this.apiURL + "/InvestorApplication",
      application,
      this.httpOptions
    );
  }

  updateApplication(application: Application) {
    // console.log("In service");
    // console.dir(application);
    return this.http.put(
      this.apiURL + "/InvestorApplication/UpdateAsync",
      application,
      this.httpOptions
    );
  }

  // HttpClient API get() method => Fetch summaries list
  // getApplications(conferenceId: number, pageNo: number, offset: number) {
  //   return this.http.get(
  //     this.apiURL +
  //       "/InvestorApplication/ListAllInvestorApplications/" +
  //       conferenceId +
  //       "/" +
  //       pageNo +
  //       "/" +
  //       offset
  //   );
  // }
  getApplications(conferenceId: number) {
    console.log('get application-->', this.apiURL +
    "/InvestorApplication/ListAllInvestorApplications/" +
    conferenceId);
    
    return this.http.get(
      this.apiURL +
        "/InvestorApplication/ListAllInvestorApplications/" +
        conferenceId
    );
  }

  // getEmailManagementList(conferenceId: number, pageNo: number, offset: number) {
  //   return this.http.get(
  //     this.apiURL +
  //       "/EmailManagement/ListSelectedbyConferenceId/" +
  //       conferenceId +
  //       "/" +
  //       pageNo +
  //       "/" +
  //       offset
  //   );
  // }
  getEmailManagementList(conferenceId: number) {
    return this.http.get(
      this.apiURL +
        "/EmailManagement/ListSelectedbyConferenceId/" +
        conferenceId
    );
  }

  // getDeclineEmailManagementList(
  //   conferenceId: number,
  //   pageNo: number,
  //   offset: number
  // ) {
  //   return this.http.get(
  //     this.apiURL +
  //       "/EmailManagement/ListNotSelectedByConferenceId/" +
  //       conferenceId +
  //       "/" +
  //       pageNo +
  //       "/" +
  //       offset
  //   );
  // }
  getDeclineEmailManagementList(conferenceId: number) {
    return this.http.get(
      this.apiURL +
        "/EmailManagement/ListNotSelectedByConferenceId/" +
        conferenceId
    );
  }
  // HttpClient API get() method => Fetch summaries list
  getApplication(id?: any) {
    let key = "";
    if (id) {
      key = id;
    } else {
      key = "";
    }
    console.log('test---', this.apiURL + "/InvestorApplication/ListAsyncByApplicationId/" + key);
    
    return this.http.get(
      this.apiURL + "/InvestorApplication/ListAsyncByApplicationId/" + key
    );
  }

  sendSelectedEmail(arr: any) {
    return this.http.post(
      this.apiURL + "/EmailManagement/SendSelectionEmail",
      arr,
      this.httpOptions
    );
  }

  sendDeclinedEmail(arr: any) {
    return this.http.post(
      this.apiURL + "/EmailManagement/SendDeclineEmail",
      arr,
      this.httpOptions
    );
  }

  updateSelection(model: ApplicationListUpdateModel) {
    return this.http.put(
      this.apiURL + "/InvestorApplication/UpdateSelection",
      model,
      this.httpOptions
    );
  }

  checkIsValid(model: AppValidationModel) {
    console.log('testing POST--',this.apiURL + "/InvestorApplication/ApplicationPreview",
    model,
    this.httpOptions);
    
    return this.http.post(
      this.apiURL + "/InvestorApplication/ApplicationPreview",
      model,
      this.httpOptions
    );
  }

  deleteApplication(id: any) {
    return this.http.delete(this.apiURL + "/InvestorApplication/" + id);
  }
  getParticipationStatusCount(conferenceId:any) {
    console.log('get count-->', this.apiURL +
    "/InvestorApplication/GetPaticipationMethodCount/"+conferenceId 
    );
    
    return this.http.get(
      this.apiURL +
        "/InvestorApplication/GetPaticipationMethodCount/" +conferenceId
        
    );
  }
  updateNotesInApplication(data: any) {
    return this.http.put(
      this.apiURL + "/InvestorApplication/UpdateStatusRemark",
      data,
      this.httpOptions
    );
  }
}
