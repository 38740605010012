import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";
import * as _ from "underscore";
import moment from "moment";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { environment } from "ClientApp/environments/environment";
import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
@Component({
    selector: "app-profile-list",
    templateUrl: "./profile-list.component.html",
    styleUrls: ["./profile-list.component.css"]
})
export class ProfileListComponent implements OnInit {
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    confs: any = [];
    profiles: any = [];
    Conferences: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    public conferenceList: any = [];
    conferenceId: number = 0;
    dataTable: any;
    selectedValue: any;
    response: any;
    photosDownloadCount: number = 0;
    csvOptions = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalseparator: ".",
        showLabels: true,
        showTitle: true,
        title: "",
        useBom: true,
        noDownload: false,
        headers: []
    };
    selectedPageSize: any;
    stockExchanges: any = [];
    constructor(
        public cService: ConferenceService,
        private profileService: ProfileService,
        private chRef: ChangeDetectorRef,
        private toasterService: ToasterService,
        private confirmationDialogService: ConfirmationDialogService
    ) {
       
    }

    ngOnInit() {
        this.loadConference(ConferenceConfig.CONF_PROFILE);
        this.getStockExchanges();
    }
    getStockExchanges() {
        this.profileService.getStockExchanges().subscribe((data: {}) => {
            this.response = data;
            this.stockExchanges = this.response.Info;
        });
    }
    loadConference(typeId: number) {
        this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            this.confs = data;
            this.Conferences = this.confs.Info;
            console.log(this.Conferences);
            this.conferenceItem = this.Conferences[0];
            this.selectedValue = this.conferenceItem;
            //this._conferenceId = this.conferenceItem.conferenceId;
            console.log(this.conferenceItem.ConferenceId);
            this.getConferenceDetails(this.conferenceItem.ConferenceID);
        });
    }

    onChangeConference(id: number) {
        console.log("Inside " + id);
        if (this.dataTable) {
            this.dataTable.destroy();
        }
        this.getConferenceDetails(this.selectedValue.ConferenceID);
    }

    getConferenceDetails(id: number) {
        console.log(id);
        //this._conferenceId = id;
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
            console.log(this.cInfo);
            this.loadProfiles(this._conferenceId);
        });
    }

    loadProfiles(conferenceId: number) {
        return this.profileService
            .loadProfiles(conferenceId)
            .subscribe((data: {}) => {
                this.profiles = data;
                this.enablePagination();
            });
    }

    enablePagination() {
        let self = this;
        this.chRef.detectChanges();
        const table: any = $("#view_profile_list");
        this.dataTable = table.DataTable({
            "aoColumns": [
                null,
                null,
                {"sType": "date"},
                null,
                null,
                null
              ],
            pagingType: "full_numbers",
            paging: true,
            pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
            lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
            order: [[1, "desc"]],
            columnDefs: [{ type: 'date', 'targets': [1] }],
            language: {
                search:
                    '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
                searchPlaceholder: "Search for...",
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                }
            },
            stripeClasses: ["odd-row", "even-row"]
        });
        $('#view_profile_list').on('length.dt', function (e, settings, len) {
            self.selectedPageSize = len;
        });

    }

    deleteConfirmation(obj: any) {
        this.confirmationDialogService
            .confirm("", "Do you really want to delete " + obj.CompanyName)
            .then(confirmed => {
                this.profileService.deleteProfile(obj.Id).subscribe((data: {}) => {
                    this.response = data;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: "Successfully Deleted",//this.response.Message,
                            showCloseButton: true
                        };

                        this.toasterService.pop(toast);
                        if (this.dataTable) {
                            this.dataTable.destroy();
                        }
                        this.loadProfiles(this._conferenceId);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                });
            })
            .catch(() =>
                console.log(
                    "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
                )
            );
    }

    downloadPhotos() {
        var zip : JSZip;
        //environment.imagePath +this.conf.Conference.ConforanceImage
        if (this.profiles.Info) {
            let photosUrlList = this.profiles.Info.map((x: any) => {
                return environment.profileImagePath + x.Photo
            });

            photosUrlList.forEach((element: any) => {
                this.loadAsArrayBuffer(element, function (buffer: any, url: string, isDone?: boolean) {
                    var filename = url.substr(url.lastIndexOf("/") + 1);
                    zip.file(filename, buffer);

                    if (isDone) {
                        zip.generateAsync({ type: "blob" })
                            .then(function callback(blob: any) {

                                // see FileSaver.js
                                saveAs.saveAs(blob, "Photos.zip");
                            });
                    }
                }, zip, photosUrlList.length)
            });


        }
    }

    loadAsArrayBuffer(url: string, callback: any, zip: JSZip, countToMatch: number): XMLHttpRequest {
        let self = this;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "arraybuffer";
        xhr.onerror = function () {/* handle errors*/ };
        xhr.onload = function () {
            self.photosDownloadCount += 1;
            if (xhr.status === 200) { callback(xhr.response, url, self.photosDownloadCount == countToMatch) }
            else {
                if (self.photosDownloadCount == countToMatch) {
                    zip.generateAsync({ type: "blob" })
                        .then(function callback(blob: any) {

                            // see FileSaver.js
                            saveAs.saveAs(blob, "Photos.zip");
                        });
                }
            }
        };
        xhr.send();

        return xhr;
    }


    downloadCSV() {
        var reportdata: any = [];
        var exchangList = this.stockExchanges;
        _.each(this.profiles.Info, function (obj: any) {
            var stockExh: any = {};
            stockExh = _.find(exchangList, { id: parseInt(obj.Exchange) });
            reportdata.push({
                Company: obj.CompanyName != null ? obj.CompanyName : "",
                Salution: obj.PrefixName || "",
                FirstName: obj.FirstName || "",
                LastName: obj.LastName || "",
                MiddleName: obj.MiddleName || "",
                NickName: obj.NickName || "",
                Suffix: obj.SuffixName || "",
                BadgeName: obj.BadgeName || "",
                JobTitle: obj.JobTitle || "",
                ContactEmail: obj.Email || "",
                Ownership: obj.Ownership || "",
                IPOYear: obj.IPOYear || "",
                StockExchange: stockExh ? stockExh.stockExchange : "",
                StockExchangeOther: obj.ExchangeOther || "",
                TickerSymbol: obj.Ticker || "",
                Website: obj.Website || "",
                Address1: obj.Address1 || "",
                Address2: obj.Address2 || "",
                City: obj.City || "",
                State: obj.State || "",
                Country: obj.Country || "",
                Zip: obj.PostalCode || "",
                DirectPhone: obj.DirectPhone || "",
                CompanyDescription: obj.CompanyDescription || "",
                PersonalBiography: obj.Bio || "",
                PhotoLink: obj.Photo ? environment.profileImagePath + obj.Photo : "",
                LastUpdated:
                    obj.newupdatedate != "Null"
                        ? moment(obj.newupdatedate).format("MM/DD/YYYY hh:mm a")
                        : "",
                Conference: obj.ConferenceName != null ? obj.ConferenceName : "",
            });
        });

        let headers: any = ["Company", "Salution", "FirstName", "LastName", "MiddleName", "NickName", "Suffix",
            "BadgeName", "JobTitle", "ContactEmail", "Ownership", "IPOYear", "StockExchange", "StockExchangeOther",
            "TickerSymbol", "Website", "Address1", "Address2", "City", "State", "Country", "Zip", "DirectPhone", "CompanyDescription", "PersonalBiography","PhotoLink", "LastUpdated", "Conference"];
        this.csvOptions.headers = headers;
        this.csvOptions.title = "Participant Profiles - " + this.ConferenceName;
        new AngularCsv(reportdata, "Participant Profiles", this.csvOptions);
    }

}
