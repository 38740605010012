import { Component, OnInit } from "@angular/core";
import { LoaderService } from "ClientApp/app/services/common/loader.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"]
})
export class LoaderComponent implements OnInit {
  loading: boolean | undefined;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  constructor(private loaderService: LoaderService) {}
  ngOnInit() {}
}
