import { Component, OnInit, ElementRef } from "@angular/core";
import { Invitation } from "ClientApp/app/models/Invitation";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { ToasterService, Toast } from "angular2-toaster";
import { environment } from "ClientApp/environments/environment";
import { UtilService } from "ClientApp/app/services/common/util.service";

@Component({
  selector: "app-invitation-edit",
  templateUrl: "./invitation-edit.component.html",
  styleUrls: ["./invitation-edit.component.css"]
})
export class InvitationEditComponent implements OnInit {
  _conferenceId: number = 0;
  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  invitationEditForm!: FormGroup;
  submitted = false;
  disableBtn = false;
  invitationModel = new Invitation();
  response: any;
  public id!: any;
  validationError: boolean = false;
  conferenceID: any;
  banner: any;
  conferenceIsActive: any;
  vData: any;
  loginuser: any;
  constructor(
    public cService: ConferenceService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private toasterService: ToasterService,
    private utilService: UtilService,
    private element: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    const invitationId = this.route.snapshot.paramMap.get("id");
    this.id = invitationId !== null ? invitationId : 0;
    this.loginuser = localStorage.getItem("currentUser");
    this.getInvitationDetails(this.id);

    this.invitationEditForm = this.formBuilder.group({
      rsalutation: [""],
      rfirstName: ["", Validators.required],
      rnickName: [""],
      rmiddleName: [""],
      rlastName: ["", Validators.required],
      rsuffix: [""],
      rtitle: ["", Validators.required],
      rcontactEmail: ["", [Validators.required, Validators.email]],
      rcompanyName: ["", Validators.required],
      rcompanyWebsite: ["", Validators.required],
      rAddress1: ["", Validators.required],
      rAddress2: [""],
      city: ["", Validators.required],
      state: [""],
      country: ["", Validators.required],
      zip: ["", Validators.required],
      rPhone: ["", Validators.required],
      rassistantEmail: [""],
      rassistantPhone: [""],
      rConferenceFeedBack: ["", Validators.required],
    });
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
    });
  }

  getConferenceDetails(id: number) {
    console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;

      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 5) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
    });
  }

  getInvitationDetails(invitationId: number) {
    this.profileService
      .getInvitationDetailsById(invitationId)
      .subscribe((data: {}) => {
        this.response = data;
        //this.id = this.response.Info.Id;
        this._conferenceId = this.response.Info.Conference_Id;
        this.conferenceID = this.response.Info.ConferenceID;
        this.getConferenceDetails(this.conferenceID);
        this.invitationEditForm.patchValue({
          rsalutation: this.response.Info.PrefixName.trim(),
          rfirstName: this.response.Info.FirstName,
          rnickName: this.response.Info.NickName,
          rmiddleName: this.response.Info.MiddleName,
          rtitle: this.response.Info.Title,
          rlastName: this.response.Info.LastName,
          rsuffix: this.response.Info.SuffixName,
          rcontactEmail: this.response.Info.Email,
          rcompanyName: this.response.Info.CompanyName,
          rcompanyWebsite: this.response.Info.Website,
          rAddress1: this.response.Info.Address1,
          rAddress2: this.response.Info.Address2,
          city: this.response.Info.City,
          state: this.response.Info.State,
          country: this.response.Info.Country,
          zip: this.response.Info.PostalCode,
          rPhone: this.response.Info.Phone,
          rassistantEmail: this.response.Info.AssistantEmail,
          rassistantPhone: this.response.Info.AssistantPhone,
          rConferenceFeedBack:this.response.Info.ConferenceFeedBack
        });
      });
  }

  get fc() {
    // get field control(fc)
    return this.invitationEditForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.validationError = this.invitationEditForm.invalid;
    // stop here if form is invalid
    if (this.invitationEditForm.invalid) {
      console.log("Invalid");
      this.utilService.showValidationDiv();
      // let el = this.element.nativeElement.querySelector(".validation-errors");
      // el.scrollIntoView();
      return;
    } else {
      this.validationError = false;
      this.invitationModel.InvitationId = this.id;
      this.invitationModel.PrefixName = this.invitationEditForm.value.rsalutation;
      this.invitationModel.FirstName = this.invitationEditForm.value.rfirstName;
      this.invitationModel.NickName = this.invitationEditForm.value.rnickName;
      this.invitationModel.MiddleName = this.invitationEditForm.value.rmiddleName;
      this.invitationModel.Title = this.invitationEditForm.value.rtitle;
      this.invitationModel.LastName = this.invitationEditForm.value.rlastName;
      this.invitationModel.SuffixName = this.invitationEditForm.value.rsuffix;

      this.invitationModel.Conference_Id = this._conferenceId;

      this.invitationModel.Email = this.invitationEditForm.value.rcontactEmail;

      this.invitationModel.CompanyName = this.invitationEditForm.value.rcompanyName;

      this.invitationModel.Website = this.invitationEditForm.value.rcompanyWebsite;
      this.invitationModel.Address1 = this.invitationEditForm.value.rAddress1;
      this.invitationModel.Address2 = this.invitationEditForm.value.rAddress2;
      this.invitationModel.City = this.invitationEditForm.value.city;
      this.invitationModel.State = this.invitationEditForm.value.state;
      this.invitationModel.Country = this.invitationEditForm.value.country;
      this.invitationModel.PostalCode = this.invitationEditForm.value.zip;
      this.invitationModel.Phone = this.invitationEditForm.value.rPhone;
      this.invitationModel.AssistantEmail = this.invitationEditForm.value.rassistantEmail;
      this.invitationModel.AssistantPhone = this.invitationEditForm.value.rassistantPhone;
      this.invitationModel.ConferenceFeedBack = this.invitationEditForm.value.rConferenceFeedBack;
      this.updateInvitation(this.invitationModel);
    }
  }

  updateInvitation(invitation: Invitation) {
    console.log("In updateProfile....");
    this.disableBtn = true;
    return this.profileService
      .updateInvitation(invitation)
      .subscribe(result => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };

          this.toasterService.pop(toast);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
        this.disableBtn = false;
      });
  }
}
