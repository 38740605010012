import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "ClientApp/app/services/user.service";
import { UserCreateModalComponent } from "../../components/user-create-modal/user-create-modal.component";
import { UserEditModalComponent } from "../../components/user-edit-modal/user-edit-modal.component";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"]
})
export class UserListComponent implements OnInit {
  response: any;
  users: any;
  dataTable: any;
  selectedPageSize: any;
  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadUsers();
  }

  // Get User list
  loadUsers() {
    return this.userService.getUsers().subscribe((data: {}) => {
      this.response = data;
      this.users = this.response.Info;
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#user_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
        pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
        lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });

    $('#user_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

  /* Modal popup for creating new report template */
  openUserFormModal() {
    const modalRef = this.modalService.open(UserCreateModalComponent, {
      windowClass: "usermodalWidth",
      backdrop: "static",
      keyboard: false
    });
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    modalRef.result
      .then(result => {
        this.loadUsers();
      })
      .catch(error => {
        this.loadUsers();
      });
  }

  /* Modal popup for creating new report template */
  openUserEditFormModal(userId: number) {
    const modalRef = this.modalService.open(UserEditModalComponent, {
      windowClass: "usermodalWidth",
      backdrop: "static",
      keyboard: false
    });
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    modalRef.componentInstance.userId = userId;
    modalRef.result
      .then(result => {
        this.loadUsers();
      })
      .catch(error => {
        this.loadUsers();
      });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.LoginName)
      .then(confirmed => {
        this.userService.deleteUser(obj.UserId).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadUsers();
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
}
