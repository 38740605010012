﻿import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import {
    SendISPReminder,
    SendISP,
    SendISPCompany,
    SendISPDecline
} from "ClientApp/app/models/SendISPReminder";
import { ToasterService, Toast } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import * as _ from "underscore";
import { PressAccreditationService } from "../../pages/press-accreditation/press-accreditation.service";
import { debug } from "util";

@Component({
    selector: "app-accreditation-emails",
    templateUrl: "./accreditation-email.component.html",
    styleUrls: []
})
export class PressAccreditationEmailsComponent implements OnInit {
    confs: any = [];
    Conferences: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    applications: any = [];
    declineList: any = [];
    ISPModel = new SendISPReminder();
    _ISPModel = new SendISP();
   // _ISPModel = new SendISP();
    companyModel = new SendISPCompany();
    response: any;
    selectedArr: Array<SendISP> = [];
    // selectedDeclineArr: Array<SendISP> = [];
    selectedDeclineArr: any[] = [];
    dataTable: any;
    selectedValue: any;
    dataTable1: any;
    selectedSendISP = new SendISP();
    selectedPageSize: any;
    declinedPageSize: any;
    selectedFilterPrevVal: string = "";
    selectedSortPrevVal: any = [[0, "asc"]];
    selectedPagePrevVal: number = 0;
    declineFilterPrevVal: string = "";
    declineSortPrevVal: any = [[0, "asc"]];
    declinePagePrevVal: number = 0;
    constructor(
        public pressAccreditationService: PressAccreditationService,
        public cService: ConferenceService,
        private toasterService: ToasterService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.loadConference(ConferenceConfig.CONF_PRESSACCREDIATION);
    }

    // Get Conference list
    loadConference(typeId: number) {
        return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            console.log(data)
            this.confs = data;
            this.Conferences = this.confs.Info;
            this.conferenceItem = this.Conferences[0];
            this.selectedValue = this.conferenceItem;
            this.getConferenceDetails(this.conferenceItem.ConferenceID);
        });
    }

    getConferenceDetails(id: number) {
        console.log(id);
        //this._conferenceId = id;
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            this.selectedArr = [];
            this.selectedDeclineArr = [];
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            if (this.dataTable1) {
                this.dataTable1.destroy();
            }
            this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
            this.loadList(this._conferenceId);
            this.loadDeclineList(this._conferenceId);
        });
    }

    onChangeConference(id: number) {
        this.selectedArr = [];
        this.selectedDeclineArr = [];
        this.setBasicGridProperties();
        this.getConferenceDetails(this.selectedValue.ConferenceID);
    }

    loadList(conferenceId: number) {
        return this.pressAccreditationService
            .getEmailManagementList(conferenceId)
            .subscribe((data: {}) => {
                this.applications = data;
                this.enablePagination();
            });
    }

    enablePagination() {
        let self = this;
        this.chRef.detectChanges();
        const table: any = $("#app_selected_email_mgmt");
        // if (this.dataTable) {
        //     this.dataTable.destroy();
        // }
        this.dataTable = table.DataTable({
            "aoColumns": [
                null,
                null,
                null,
                {"sType": "date"},
                null,
              ],
            pagingType: "full_numbers",
            paging: true,
            pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
            lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
            search: {"search": self.selectedFilterPrevVal},
            "order": self.selectedSortPrevVal,
            'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
            language: {
                search:
                    '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
                searchPlaceholder: "Search for...",
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                }
            },
            stripeClasses: ["odd-row", "even-row"]
        });
        $('#app_selected_email_mgmt').on( 'length.dt', function ( e, settings, len ) {
            self.selectedPageSize = len;
      });
    }

    loadDeclineList(conferenceId: number) {
        return this.pressAccreditationService
            .getDeclineEmailManagementList(conferenceId)
            .subscribe((data: {}) => {
                this.declineList = data;
                this.selectedDeclineArr = [];
                this.enableDeclinePagination();
            });
    }

    enableDeclinePagination() {
        let self = this;
        this.chRef.detectChanges();
        const table: any = $("#app_decline_email_mgmt");
        // if (this.dataTable1) {
        //     this.dataTable1.destroy();
        // }
        this.dataTable1 = table.DataTable({
            "aoColumns": [
                null,
                null,
                null,
                {"sType": "date"},
                null,
              ],
            pagingType: "full_numbers",
            paging: true,
            pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
            lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
            search: {"search": self.declineFilterPrevVal},
            "order": self.declineSortPrevVal,
            'displayStart': ((this.declinePagePrevVal) * (this.declinedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
            language: {
                search:
                    '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
                searchPlaceholder: "Search for...",
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                }
            },
            stripeClasses: ["odd-row", "even-row"]
        });
        $('#app_decline_email_mgmt').on( 'length.dt', function ( e, settings, len ) {
            self.declinedPageSize = len;
        });
    }

    disableRegistrButton: boolean = true;
    onChange(e: any, id: any, email: any, emailType: string) {
 
        if (e.target.checked) {
            // this._ISPModel.Id = id;
            // this._ISPModel.Email = email;
            // this._ISPModel.EmailType = emailType;
            // this.selectedArr.push(this._ISPModel);
            // this._ISPModel = new SendISP();

            this.selectedArr.push(email)
        }
        else {

            var index = this.selectedArr.indexOf(id);
            this.selectedArr.splice(index, 1);
        }
    }

    onDeclineChange(e: any, id: any, email: any) {
        if (e.target.checked) {
            // this._ISPModel = new SendISP();
            // this._ISPModel.Id = id;
            // this._ISPModel.Info = email;
            //this.selectedDeclineArr.push(this._ISPModel);
            //this._ISPModel = new SendISP();

                this.selectedDeclineArr.push(email)
            
        }
        else {

            var index = this.selectedDeclineArr.indexOf(id);
            this.selectedDeclineArr.splice(index, 1);
        }
    }


    sendDeclined() {
        this.ISPModel.Info = this.selectedDeclineArr;
        this.companyModel.Conference_Id = this._conferenceId;
        this.ISPModel.CompanyDetails = this.companyModel;
        //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
        if (this.selectedDeclineArr.length > 0) {
            console.log(this.ISPModel);
            return this.pressAccreditationService
                .sendDeclinedEmail(this.ISPModel)
                .subscribe(result => {
                    // This code will be executed when the HTTP call returns successfully
                    this.response = result;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true
                        };

                        this.declineFilterPrevVal = this.dataTable1.search();
                        this.declineSortPrevVal = this.dataTable1.order();
                        this.declinePagePrevVal = this.dataTable1.page();

                        this.toasterService.pop(toast);
                        if (this.dataTable1) {
                            this.dataTable1.destroy();
                        }
                        this.loadDeclineList(this._conferenceId);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                   
                });
        }
    }

    sendSelectionEmail() {
        this.ISPModel.Info = this.selectedArr;
        this.companyModel.Conference_Id = this._conferenceId;
        this.ISPModel.CompanyDetails = this.companyModel;
        if (this.selectedArr.length > 0) {
            return this.pressAccreditationService
                .sendSelectionEmail(this.ISPModel)
                .subscribe((result: any) => {
                    this.response = result;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true
                        };

                        this.toasterService.pop(toast);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                    this.selectedFilterPrevVal = this.dataTable.search();
                    this.selectedSortPrevVal = this.dataTable.order();
                    this.selectedPagePrevVal = this.dataTable.page();
                    this.getConferenceDetails(this.selectedValue.ConferenceID);
                });
        }
    }

    getIconByEmailType(emailType: string) {
        if (emailType === 'Declined')
            return 'fa fa-ban'
        else if (emailType === 'Selected')
            return 'fa fa-check'
    }

    setBasicGridProperties(){
        this.selectedFilterPrevVal = "";
        this.selectedSortPrevVal = [[0, "asc"]];
        this.selectedPagePrevVal = 0;
        this.declineFilterPrevVal = "";
        this.declineSortPrevVal = [[0, "asc"]];
        this.declinePagePrevVal = 0;
    }

}
