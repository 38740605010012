import { Schedule, ScheduleConfirmation } from "ClientApp/app/models/Schedule";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "ClientApp/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ScheduleService {
  apiURL = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json" //,
      // "Access-Control-Allow-Origin": "*"
    })
  };

  getOpenTracks(id: number) {
    return this.http.get(
      this.apiURL + "/ScheduleTracks/ListAsyncOpenTracks/" + id
    );
  }

  getFilledTracks(id: number) {
    return this.http.get(
      this.apiURL + "/ScheduleTracks/ListAsyncFilledTracks/" + id
    );
  }

  getAllTracks(id: number) {
    return this.http.get(
      this.apiURL + "/ScheduleTracks/ListAsyncAllTracks/" + id
    );
  }

  // Get Companies
  getCompanies(id: number) {
    return this.http.get(
      this.apiURL + "/ScheduleTracks/ListAsyncCompany/" + id
    );
  }

  getCompaniesAllTracks(id: number) {
    return this.http.get(
      this.apiURL + "/ScheduleTracks/ListAllTracksCompany/" + id
    );
  }

  addUpdateSelection(schedule: Schedule) {
    return this.http.post(
      this.apiURL + "/ScheduleTracks/AddAsync",
      schedule,
      this.httpOptions
    );
  }

  // Get Emails
  getEmailList(id: number) {
    return this.http.get(
      this.apiURL + "/EmailManagement/ScheduledListAsync/" + id
    );
  }

  // Send ISP
  sendEmail(arr: any) {
    return this.http.post(
      this.apiURL + "/EmailManagement/SendScheduledEmail",
      arr,
      this.httpOptions
    );
  }

  updateConfirmation(schedule: ScheduleConfirmation) {
    return this.http.put(
      this.apiURL + "/EmailManagement/UpdateConfirmation",
      schedule,
      this.httpOptions
    );
  }

  getTrackDetails(scheduleId: any, conferenceId: any) {
    return this.http.get(
      this.apiURL +
        "/EmailManagement/ScheduledCompany/" +
        conferenceId +
        "/" +
        scheduleId
    );
  }

  getOpenTrackCount(conferenceId: any) {
    return this.http.get(
      this.apiURL + "/ScheduleTracks/ListCount/" + conferenceId
    );
  }

  getCompanyCommentDetails(companyId: any) {
    return this.http.get(
      this.apiURL + "/EmailManagement/ViewDetails/" + companyId
    );
  }
}
