import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, timeout } from "rxjs/operators";
import { Summary, ValidationModel } from "./../../models/Summary";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class SummaryService {
  // Define API
  apiURL = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json" //,
      // "Access-Control-Allow-Origin": "*"
    })
  };

  // HttpClient API get() method => Fetch summaries list
  // getSummary(isAll: string, conferenceId: any, pageNo: number, offset: number) {
  //   let key = "";
  //   let method = "";
  //   if (isAll == "true") {
  //     key = "All";
  //   } else {
  //     key = "SelectedOnly";
  //   }
  //   if (key == "All") {
  //     method = "GetAllCompanyByConferenceid";
  //   } else {
  //     method = "GetSelectedCompanyByConferenceid";
  //   }
  //   return this.http.get(
  //     this.apiURL +
  //       "/InvestorSummary/" +
  //       method +
  //       "/" +
  //       conferenceId +
  //       "/" +
  //       pageNo +
  //       "/" +
  //       offset
  //   );
  //   // .pipe(
  //   //   retry(1),
  //   //   catchError(this.handleError)
  //   // );
  // }

  getSummary(isAll: string, conferenceId: any) {
    let key = "";
    let method = "";
    if (isAll == "true") {
      key = "All";
    } else {
      key = "SelectedOnly";
    }
    if (key == "All") {
      method = "GetAllCompanyByConferenceid";
    } else {
      method = "GetSelectedCompanyByConferenceid";
    }
    return this.http.get(
      this.apiURL + "/InvestorSummary/" + method + "/" + conferenceId
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // );
  }

  createSummary(summary: Summary) {
    // console.log("In service");
    // console.log(summary);
    return this.http.post(
      this.apiURL + "/InvestorSummary",
      summary,
      this.httpOptions
    );
  }

  updateSummary(summary: Summary) {
    // console.log("In update service");
    // console.log(summary);
    return this.http.put(
      this.apiURL + "/InvestorSummary",
      summary,
      this.httpOptions
    );
  }

  getSummaryById(id: number): Observable<Summary> {
    const url = this.apiURL + "/InvestorSummary/" + id;
    return this.http.get<Summary>(url);
  }

  // Get Emails
  // getEmailList(conferenceId: number, pageNo: number, offset: number) {
  //   return this.http.get(
  //     this.apiURL + "/Email/" + conferenceId + "/" + pageNo + "/" + offset
  //   );
  // }

  getEmailList(conferenceId: number) {
    return this.http.get(
      this.apiURL + "/Email/" + conferenceId
    );
  }

  // Send Email
  sendEmail(arr: any) {
    console.log('testing email sending',this.apiURL + "/Email/SendEmail50Word",
    arr);
    
    return this.http.post(
      this.apiURL + "/Email/SendEmail50Word",
      arr,
      this.httpOptions
    );
  }

  // Send ISP
  sendISPReminder(arr: any) {
    return this.http.post(
      this.apiURL + "/Email/SendISP",
      arr,
      this.httpOptions
    );
  }

  // Send ISP
  sendSlideDeskReminder(arr: any) {
    return this.http.post(
      this.apiURL + "/Email/SendSlideDesk",
      arr,
      this.httpOptions
    );
  }

  sendLogoReminder(arr: any) {
    return this.http.post(
      this.apiURL + "/Email/SendLogo",
      arr,
      this.httpOptions
    );
  }
  sendMailForDynamicMailEvent(arr: any) {
    return this.http.post(
      this.apiURL + "/Email/SendMailForDynamicMailEvent",
      arr,
      this.httpOptions
    );
  }
  getNewEnabledEmailTypes() {
    return this.http.get(
      this.apiURL + "/Event/ActiveEmailEvent" 
    );
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    //console.log(errorMessage);
    return throwError(errorMessage);
  }

  // Get TherapeuticFocus
  getTherapeuticFocus() {
    return this.http.get(
      this.apiURL + "/InvestorSummary/ListTherapeuticMaster/"
    );
  }

  getSummaryEdits(confId: number) {
    //return this.http.get(this.apiURL + "/EditSummary/" + pageNo + "/" + offset);
      return this.http.get(this.apiURL + "/EditSummary/" + confId);
  }

  checkIsValid(model: ValidationModel) {
    return this.http.post(
      this.apiURL + "/InvestorSummary/SummaryPreview",
      model,
      this.httpOptions
    );
  }

  deleteSummary(id: any) {
    return this.http.delete(this.apiURL + "/InvestorSummary/" + id);
  }

  // Get searchtool
  getSearchToolDetail(id: any) {
    return this.http.get(this.apiURL + "/InvestorSummary/SearchTool/" + id);
  }

  // Get Emails
  getinvesterSummeraySeach(typeId: any, seachText: any) {
    return this.http.get(
      this.apiURL + "/InvestorSummary/Search/" + typeId + "/" + seachText
    );
  }

  // Get HashCode
  getHashCode(hashCode: any) {
    return this.http.get(
      this.apiURL + "/InvestorSummary/GetHashCode/" + hashCode
    );
    }

    updateSummaryEdits(summary: any) {
        return this.http.put(this.apiURL + "/EditSummary",
            summary,
            this.httpOptions);
    }
    getEditSummaryById(id: number) {
        const url = this.apiURL + "/EditSummary/SelectItem/" + id;
        return this.http.get(url);
    }
    updateEmail50Confirmation(arr: any) {
        return this.http.put(
            this.apiURL + "/Email/UpdateEmail50Confirmation",
            arr,
            this.httpOptions
        );
    }
    getStockExchanges() {
        return this.http.get(
            this.apiURL + "/InvestorSummary/GetStockExchanges/"
        );
    }
    getParticipationStatusCount(conferenceId:any) {
      console.log('get count-->', this.apiURL +
      "/InvestorSummary/GetPaticipationMethodCount/" +conferenceId
      );
      
      return this.http.get(
        this.apiURL +
          "/InvestorSummary/GetPaticipationMethodCount/" +conferenceId
          
      );
    }
}
