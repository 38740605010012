export class Invitation {
  InvitationId: number | undefined;
  FirstName: string | undefined;
  NickName: string | undefined;
  MiddleName: string | undefined;
  LastName: string | undefined;
  PrefixName: string | undefined;
  SuffixName: string | undefined;
  Title: string | undefined;
  Email: string | undefined;
  CompanyName: string | undefined;
  Website: string | undefined;
  Address1: string | undefined;
  Address2: string | undefined;
  City: string | undefined;
  State: string | undefined;
  Country: string | undefined;
  PostalCode: string | undefined;
  Phone: string | undefined;
  AssistantEmail: string | undefined;
  ConferenceID: string | undefined;
  Conference_Id: number | undefined;
  AssistantPhone: string | undefined;
  ConferenceFeedBack:string | undefined;
}

export class InvitationValidationModel {
  ConferenceID: any;
  Email: string | undefined;
  CompanyName: string | undefined;
  Phone: string | undefined;
}
