export class Profile {
  ProfileId: string | undefined;
  PrefixName: string | undefined;
  NickName: string | undefined;
  FirstName: string | undefined;
  MiddleName: string | undefined;
  LastName: string | undefined;
  SuffixName: string | undefined;
  ConferenceID: string | undefined;
  Conference_Id: number | undefined;
  Email: string | undefined;
  BadgeName: string | undefined;
  JobTitle: string | undefined;
  CompanyName: string | undefined;
  Ownership: string | undefined;
  Website: string | undefined;
  Address1: string | undefined;
  Address2: string | undefined;
  City: string | undefined;
  State: string | undefined;
  Country: string | undefined;
  PostalCode: string | undefined;
  DirectPhone: string | undefined;
  CompanyDescription: string | undefined;
  Bio: string | undefined;
  Photo: string | undefined;
  IPOYear: string | undefined;
  Exchange: string | undefined;
  Ticker: string | undefined;
  ExchangeOther: string | undefined;
  previewPhoto: string | undefined;
}

export class ValidationModel {
  ConferenceID: any;
  Email: string | undefined;
  CompanyName: string | undefined;
  DirectPhone: string | undefined;
}
