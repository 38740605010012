import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import {
    ConferenceModel,
    ConferenceTypeModel,
    ConferenceDetailModel
} from "../models/Conference";
import { SendISPReminder } from "../models/SendISPReminder";
import { AuthService } from "./auth/auth.service";

export interface Conference {
    id: number | string;
    description: string;
}
@Injectable({
    providedIn: "root"
})
export class ConferenceService {
    todos: Observable<Conference[]> | undefined;
    //private _todos: BehaviorSubject<Conference[]>;
    apiURL = environment.baseUrl;
    cItem: {} | undefined;
    cItemArr: Array<{}> = [];
    // private dataStore: {
    //   todos: Conference[];
    // };
    constructor(private http: HttpClient, private authenticationService: AuthService) {
        // this.dataStore = { todos: [] };
        // this._todos = <BehaviorSubject<Conference[]>>new BehaviorSubject([]);
        // this.todos = this._todos.asObservable();
    }

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        })
    };

    // getConferenceId() {
    //   let conf = this.getConference();
    //   this.cItemArr = conf;
    // }
    getConferenceId(): Observable<Conference> {
        return this.http.get<Conference>(this.apiURL + "/Conference");

        //.toPromise()
        //.then(response => response.json().data as Hero[])
        //.catch(this.handleError);
    }

    getConference() {
        return this.http.get(this.apiURL + "/Conference");
    }

    getConferenceType() {
        return this.http.get(this.apiURL + "/ConferenceType");
    }

    getConferenceById(id: any) {
        return this.http.get(this.apiURL + "/Conference/" + id);
    }

    getConferenceByType(typeId: number) {
       // return this.http.get(this.apiURL + "/Conference/GetConferences/" + typeId);
        const currentUser = this.authenticationService.currentUserValue;
        var roleId = 0;
        if (currentUser != null) {
            var userRoleInfo = JSON.parse(currentUser);
            roleId = userRoleInfo.Info.RoleId;
        }
        return this.http.get(this.apiURL + "/Conference/GetConferences/" + typeId + "/" + roleId);
    }
    getConferenceByConfType() {
        const currentUser = this.authenticationService.currentUserValue;
        var roleId = 0;
        if (currentUser != null) {
            var userRoleInfo = JSON.parse(currentUser);
            roleId = userRoleInfo.Info.RoleId;
        }
        return this.http.get(this.apiURL + "/Conference/GetConferenceByConfType/" + roleId);
    }
    getConferenceCategory() {
        return this.http.get(this.apiURL + "/Conference/GetCategory");
    }

    createConference(conferenceModel: ConferenceDetailModel) {
        return this.http.post(
            this.apiURL + "/Conference",
            conferenceModel,
            this.httpOptions
        );
    }

    updateConference(conferenceModel: ConferenceDetailModel) {
        // console.log("In service");
        // console.dir(conferenceModel);
        return this.http.put(
            this.apiURL + "/Conference",
            conferenceModel,
            this.httpOptions
        );
    }

    createConferenceType(conferenceTypeModel: ConferenceTypeModel) {
        return this.http.post(
            this.apiURL + "/ConferenceType",
            conferenceTypeModel,
            this.httpOptions
        );
    }

    getConferenceTypeById(typeId: number) {
        return this.http.get(
            this.apiURL + "/ConferenceType/SelectItem/" + typeId,
            this.httpOptions
        );
    }

    updateConferenceType(conferenceTypeModel: ConferenceTypeModel) {
        return this.http.put(
            this.apiURL + "/ConferenceType",
            conferenceTypeModel,
            this.httpOptions
        );
    }

    // Error handling
    handleError(error: any) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //window.alert(errorMessage);
        //console.log(errorMessage);
        return throwError(errorMessage);
    }

    deleteConferenceType(id: any) {
        return this.http.delete(this.apiURL + "/ConferenceType/" + id);
    }

    applicationRegisterEmails(sendISPModel: any) {
        console.log('applicationRegisterEmails', this.apiURL + "/EmailManagement/ApplicationRegisterEmails",
        sendISPModel);
        
        return this.http.post(
            this.apiURL + "/EmailManagement/ApplicationRegisterEmails",
            sendISPModel,
            this.httpOptions
        );
    }

    sendPresentationAwardEmail(conferenceModel: any) {
        return this.http.post(
            this.apiURL + "/Email/SendPresentationAwardEmail",
            conferenceModel,
            this.httpOptions
        );
    }

    sendSelectionEmail(conferenceModel: any) {
        return this.http.post(
            this.apiURL + "/EmailManagement/SendSelectionEmail",
            conferenceModel,
            this.httpOptions
        );
    }

    validateConference(id: any) {
        return this.http.get(
            this.apiURL + "/Conference/ValidateDate/" + id,
            this.httpOptions
        );
    }
}
