import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, NgForm } from "@angular/forms";
import { TrackService } from "ClientApp/app/services/track/track.service";
import { TrackItem } from "ClientApp/app/models/Track";
import { Toast, ToasterConfig, ToasterService } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-track-create-edit",
  templateUrl: "./track-create-edit.component.html",
  styleUrls: ["./track-create-edit.component.css"]
})
export class TrackCreateEditComponent implements OnInit {
  @Input() public conferenceId: number = 0;
  @Input() public trackId: number = 0;
  addEditTrackForm!: FormGroup;
  submitted = false;
  response: any;
  tItem: any;
  trackInfo: any;
  isTrackAvailable: boolean = false;
  public trackItem = new TrackItem();
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    animation: "fade"
  });
  constructor(
    private formBuilder: FormBuilder,
    public trackService: TrackService,
    private toasterService: ToasterService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    // console.log("conferenceId " + this.conferenceId);
    // console.log("trackId " + this.trackId);
    if (this.trackId > 0) {
      this.isTrackAvailable = true;
      this.getTrackById(this.trackId);
    }
    this.addEditTrackForm = this.formBuilder.group({
      tCode: [""],
      tRoomName: [""],
      tRoomDesc: [""],
      tSortOrder: [""]
    });
  }

  reset() {
    if (!this.isTrackAvailable) {
      this.addEditTrackForm.reset();
    }
  }

  getTrackById(id: number) {
    this.trackService.getTrackById(id).subscribe(result => {
      this.tItem = result;
      this.trackInfo = this.tItem.Info;
      this.addEditTrackForm.patchValue({
        tCode: this.tItem.Info.Code,
        tRoomName: this.tItem.Info.RoomName,
        tRoomDesc: this.tItem.Info.RoomDescription,
        tSortOrder: this.tItem.Info.SortOrder
      });
    });
  }

  save(form: FormGroup) {
    this.submitted = true;
    //console.log(form.value);
    var item = form.value;
    this.trackItem.Code = item.tCode;
    this.trackItem.RoomName = item.tRoomName;
    this.trackItem.RoomDescription = item.tRoomDesc;
    this.trackItem.SortOrder = +item.tSortOrder;
    this.trackItem.Conference_Id = +this.conferenceId;

    if (this.isTrackAvailable) {
      this.trackItem.Id = +this.trackId;
      //console.log(this.trackItem);
      this.updateTrack(this.trackItem);
    } else {
      //console.log(this.trackItem);
      this.createTrack(this.trackItem);
    }
  }

  createTrack(item: TrackItem) {
    return this.trackService.createTrack(item).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.activeModal.close();
        //this.addEditTrackForm.reset();
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }

  updateTrack(item: TrackItem) {
    return this.trackService.updateTrack(item).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.activeModal.close();
        //this.addEditTrackForm.reset();
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }
}
