import { ConferenceService } from "./../../../../../services/conference.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
//import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import {
  ConferenceModel,
  ConferenceCategoryModel,
  ConferenceDetailModel
} from "ClientApp/app/models/Conference";
import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from "mydatepicker";
import { Router } from "@angular/router";

@Component({
  selector: "app-conference-create",
  templateUrl: "./conference-create.component.html",
  styleUrls: ["./conference-create.component.css"]
})
export class ConferenceCreateComponent implements OnInit {
  submitted = false;
  editorConfig: any;

  conferenceForm!: FormGroup;
  conferenceModel = new ConferenceModel();
  response: any;
  confs: any = [];
  Conferences: any = [];
  conferenceTypes: any = [];
  year: any;
  conferenceCategories = [];
  selectedItems = [];
  dropdownSettings = {};
  editorList: any = [];
  category = new ConferenceCategoryModel();
  categoryList: Array<ConferenceCategoryModel> = [];
  cModel = new ConferenceDetailModel();
  imageChangedEvent: any;
  selectedConferenceFile: any;
  validImage: boolean | undefined;
  previewPhoto: any;
  years:any =[]
  @ViewChild('conferenceImageFile') conferenceImage: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private cService: ConferenceService,
    private router: Router
  ) {
    let startYear = new Date().getFullYear() - 10;
    let endYear = new Date().getFullYear() + 100;
    for(let i=startYear; i<=endYear; i++ ){
      this.years.push(i);
    }
  }

  //myOptions: IAngularMyDpOptions = {
  public myDatePickerOptions: IMyDpOptions = {
    editableDateField: false,
    dateFormat: "mm-dd-yyyy",
    width: "250px"
    //inputFieldValidation : true
    // other options...
  };

  public yearOptions: IMyDpOptions = {
    editableDateField: false,
    dateFormat: "yyyy",
    width: "250px",
    yearSelector: true
    //inputFieldValidation : true
    // other options...
  };

  onDateChanged(event: IMyDateModel) {
    //console.log("onDateChanged(): ", event.date);
    this.year = event.date.year;
  }

  ngOnInit() {
    this.loadConferenceType();
    this.conferenceForm = this.formBuilder.group({
      cName: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      //description: ["", Validators.required],
      cContent1: [""],
      cContent2: [""],
        cContent3: [""],
        cContent4: [""],
        cContent5: [""],
      cCategory: ["", Validators.required],
      cType: ["", Validators.required],
      activeStatus: [""],
      year: ["",Validators.required] //["", Validators.required],
    });

    this.conferenceForm.patchValue({
      activeStatus: true
    });

    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: "auto",
      minHeight: "250px",
      maxHeight: "auto",
      width: "auto",
      minWidth: "0",
      translate: "yes",
      enableToolbar: true,
      showToolbar: true,
      placeholder: "Enter text here...",
      defaultParagraphSeparator: "",
      defaultFontName: "Times New Roman",
      defaultFontSize: "",
      fonts: [
        { class: "arial", name: "Arial" },
        { class: "times-new-roman", name: "Times New Roman" },
        { class: "calibri", name: "Calibri" },
        { class: "comic-sans-ms", name: "Comic Sans MS" }
      ],
      // customClasses: [
      //   {
      //     name: "quote",
      //     class: "quote"
      //   },
      //   {
      //     name: "redText",
      //     class: "redText"
      //   },
      //   {
      //     name: "titleText",
      //     class: "titleText",
      //     tag: "h1"
      //   }
      // ],
      //uploadUrl: "v1/image",
      sanitize: false,
      toolbarPosition: "top"
    };

    // this.editorConfig = {
    //   editable: true,
    //   spellcheck: true,
    //   height: "250px",
    //   minHeight: "0",
    //   width: "auto",
    //   minWidth: "0",
    //   translate: "yes",
    //   enableToolbar: true,
    //   showToolbar: true,
    //   placeholder: "Add conference content here...",
    //   imageEndPoint: "",
    //   toolbar: [
    //     [
    //       "bold",
    //       "italic",
    //       "underline",
    //       "strikeThrough",
    //       "superscript",
    //       "subscript"
    //     ],
    //     ["fontName", "fontSize", "color"],
    //     [
    //       "justifyLeft",
    //       "justifyCenter",
    //       "justifyRight",
    //       "justifyFull",
    //       "indent",
    //       "outdent"
    //     ],
    //     ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
    //     [
    //       "paragraph",
    //       "blockquote",
    //       "removeBlockquote",
    //       "horizontalLine",
    //       "orderedList",
    //       "unorderedList"
    //     ],
    //     ["link", "unlink"]
    //   ]
    // };

    this.dropdownSettings = {
      singleSelection: false,
      idField: "CTypeId",
      textField: "CTypeName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.conferenceForm.controls;
  }

  loadConferenceType() {
    this.cService.getConferenceType().subscribe((result: {}) => {
      this.response = result;
      if (this.response.Info.length > 0) {
        this.conferenceTypes = this.response.Info;
        this.loadConferenceCategory();
      }
    });
  }

  loadConferenceCategory() {
    this.cService.getConferenceCategory().subscribe((result: {}) => {
      this.response = result;

      if (this.response.Info.length > 0) {
        this.conferenceCategories = this.response.Info;
        //console.log(this.conferenceCategories);
      }
    });
  }

  // image upload
  openConforenceFileDialogue(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById(
      "upload-conforance-photo"
    ) as HTMLElement;
    element.click();
  }

  public onSelectedFarmFileName(event: any) {
    this.imageChangedEvent = event;
    this.selectedConferenceFile = event.target.files[0];
    this.validImage = true;

    if (this.selectedConferenceFile) {
      let extension = this.selectedConferenceFile.name.split(".").pop();

      if (
        extension.toLowerCase() != "png" &&
        extension.toLowerCase() != "jpg" &&
        extension.toLowerCase() != "jpeg"
      ) {
        alert("Please upload valid image.");
        this.validImage = false;
        return;
      }
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(this.selectedConferenceFile);
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    this.previewPhoto = readerEvt.target.result;
    this.conferenceModel.ConforanceImage = this.previewPhoto.split(",")[1];
  }
  //end image upload

  removeConferenceImage(): void{
    this.previewPhoto = "";
    this.conferenceModel.ConforanceImage = "";
    this.conferenceImage.nativeElement.value = '';
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.conferenceForm.invalid) {
      //console.log("Invalid");
      return;
    } else {
      //console.log(this.conferenceForm);
      this.conferenceModel.ConferenceName = this.conferenceForm.value.cName;
      this.conferenceModel.StartDate = this.conferenceForm.value.startDate.formatted;
      this.conferenceModel.EndDate = this.conferenceForm.value.endDate.formatted;
      this.conferenceModel.TypeId = +this.conferenceForm.value.cType;
      this.conferenceModel.isActive = this.conferenceForm.value.activeStatus
        ? 1
        : 0;
      this.conferenceModel.ShowOnSite = 1;
      this.conferenceModel.AllowInvestorSummary = 0;
      this.conferenceModel.AllowParticipantProfile = 0;
      this.conferenceModel.AllowScheduling = 0;
      //.conferenceModel.ConferenceYear = this.conferenceForm.value.year.date.year;
      //this.conferenceModel.BannerUrl = "";
      this.conferenceModel.ConferenceYear = this.conferenceForm.value.year;
      this.cModel.Conference = this.conferenceModel;

      if (this.editorList.length > 0) {
        this.editorList.forEach((item: { CTypeId: number | undefined }) => {
          this.category.CTypeId = item.CTypeId;
          let id = item.CTypeId;
          if (item.CTypeId == 1) {
            this.category.BannerContents = this.conferenceForm.value.cContent1;
          }
          if (item.CTypeId == 2) {
            this.category.BannerContents = this.conferenceForm.value.cContent2;
          }
          if (item.CTypeId == 3) {
            this.category.BannerContents = this.conferenceForm.value.cContent3;
            }
            if (item.CTypeId == 4) {
                this.category.BannerContents = this.conferenceForm.value.cContent4;
            }
            if (item.CTypeId == 5) {
                this.category.BannerContents = this.conferenceForm.value.cContent5;
            }

          this.categoryList.push(this.category);
          this.category = new ConferenceCategoryModel();
        });
        this.cModel.ConferenceCategoryDetails = this.categoryList;
      }

      //this.conferenceModel.ConferenceCategory = +this.conferenceForm.value.cCategory;
      //this.conferenceModel.BannerContents = this.conferenceForm.value.cContent;
      //console.log(this.cModel);
      this.createConference(this.cModel);
    }
  }

  createConference(conferenceModel: ConferenceDetailModel) {
    return this.cService.createConference(conferenceModel).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        //this.conferenceForm.reset();
        // Redirect to list.
        this.router.navigate(["administration/conferences"]);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
    // this.response = this.eTemplateService.createTemplate(template);
    // if (this.response) {
    //   console.log("createTemplate in component.ts");
    //   console.log(this.response);
    // }
  }

  onItemSelect(item: any) {
    //console.log(item);
    // this.category = new ConferenceCategoryModel();
    // this.category.CTypeId = item.CTypeId;
    // this.category.BannerContents = item.BannerContents;
    this.editorList.push(item);
    //console.log(this.editorList);
    this.enableOrDisableValidations();
  }
  onSelectAll(items: any) {
    //console.log(items);
    this.editorList = items;
    //console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  onDeSelect(item: any) {
    //console.log(item);
    var pos = this.editorList
      .map(function(e: { CTypeId: number }) {
        return e.CTypeId;
      })
      .indexOf(item.CTypeId);
    this.editorList.splice(pos, 1);
    //console.log(this.editorList);
    this.enableOrDisableValidations();
  }

  enableOrDisableValidations() {}
}
