import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserRole } from "../models/UserRole";

@Injectable({
    providedIn: "root"
})
export class UserRoleService {
    constructor(private http: HttpClient) { }

    apiURL = environment.baseUrl;

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json"
        })
    };

    getUserRoles() {
        return this.http.get(this.apiURL + "/UserRole");
    }

    createUserRole(user: UserRole) {
        return this.http.post(this.apiURL + "/UserRole", user, this.httpOptions);
    }

    getUserRoleById(userId: number) {
        return this.http.get(this.apiURL + "/UserRole/Select/" + userId);
    }

    updateUserRole(user: UserRole) {
        return this.http.put(this.apiURL + "/UserRole", user, this.httpOptions);
    }

    deleteUserRole(id: any) {
        return this.http.delete(this.apiURL + "/UserRole/" + id);
    }

    getUserRoleNames() {
        return this.http.get(this.apiURL + "/UserRole/UserRolesList");
    }
}
