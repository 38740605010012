import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AssetAddRowService {
  public rows: Array<{
    name: string | undefined;
    indication: string | undefined;
    status: string | undefined;
    milestone: string | undefined;
  }> = [];
  private rowSource = new BehaviorSubject(this.rows);
  addToRow = this.rowSource.asObservable();

  constructor() {}

  updateTableRow(rowItem: any) {
    this.rowSource.next(rowItem);
  }
}
