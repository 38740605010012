import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef
} from "@angular/core";
import { SelectionService } from "ClientApp/app/services/selection/selection.service";
import { ToasterService, Toast } from "angular2-toaster";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import {
  SendEmailModel,
  SendEmailItemModel,
  SendEmailCompany
} from "ClientApp/app/models/Email";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import * as _ from "underscore";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-selection-email-notselected",
  templateUrl: "./selection-email-notselected.component.html",
  styleUrls: ["./selection-email-notselected.component.css"]
})
export class SelectionEmailNotselectedComponent implements OnInit {
  companies: any = [];
  public conferenceList: any = [];
  emailModel = new SendEmailModel();
  emailItemModel = new SendEmailItemModel();
  companyModel = new SendEmailCompany();
  @ViewChild("SelectionNotes") myContent: TemplateRef<any>;
  selectionModelArr: any = [];
  response: any;
  conferenceId: number = 0;
  conferenceDetail: any;
  cInfo: any;
  ConferenceName: any;
  Conferences: any = [];
  _conferenceId: any;
  dataTable: any;
  conferenceItem: any;
  selectedValue: any;
  NotesModel: any = {
    NoteType_Id: ""
  };
  selectedFilterPrevVal: string = "";
  selectedSortPrevVal: any = [[0, "asc"]];
  selectedPagePrevVal: any = 0;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg"
  };
  modalReference: any;
  isSubmitted = false;
  noteTypeList: any = [];
  companyNoteList: any = [];
  compnyId: any;
  selectedPageSize: any;
  constructor(
    public selectionService: SelectionService,
    public cService: ConferenceService,
    private toasterService: ToasterService,
    private chRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }

  // Get companies list
  loadCompanies(key: number) {
    return this.selectionService
      .getNotSelectedCompanies(key)
      .subscribe(data => {
          this.companies = data;
          this.selectionModelArr = [];
        // console.dir(this.companies);
        // console.dir(this.companies.Info);
        this.enablePagination();
      });
  }

  enablePagination() {
    let self = this;
    this.getNoteTypeList();
    this.chRef.detectChanges();
    const table: any = $("#selection_email_notselected");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
      bDestroy: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      search: {"search": self.selectedFilterPrevVal},
      "order": self.selectedSortPrevVal,
      'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#selection_email_notselected').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

  loadConference(typeId: number) {
    this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.conferenceList = data;
      this.Conferences = this.conferenceList.Info;
      //this.Conferences= _.filter(this.conferenceList.Info, function(obj:any){ return obj.isActive== 1; });
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      //setTimeout(() => {
      //console.log(this.conferenceList);
      //console.log("this.response=" + this.conferenceList.Info[0].ConferenceId);
      this.conferenceId = this.conferenceList.Info[0].ConferenceID;
      this.getConferenceDetails(this.conferenceId);
      //this.loadCompanies(this.conferenceId);
      //}, 500);
    });
  }

  getConferenceDetails(id: number) {
    //console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
      //console.log(this.cInfo);
      this.loadCompanies(this._conferenceId);
    });
  }

  onChangeConference(id: number) {
    //console.log("Inside " + id);
    this.selectionModelArr = [];
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.selectedFilterPrevVal = "";
    this.selectedSortPrevVal = [[0, "asc"]];
    this.selectedPagePrevVal = 0;
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  onChange(e: any, id: number, email: string) {
    //console.log(id);
    this.emailItemModel.CompId = id;
    this.emailItemModel.Email = email;
    if (e.target.checked) {
      var item = this.emailItemModel;
      this.emailItemModel = new SendEmailItemModel();
      this.selectionModelArr.push(item);
    } else {
      var pos = this.selectionModelArr
        .map(function(e: { CompId: number }) {
          return e.CompId;
        })
        .indexOf(id);
      //console.log("pos " + pos);
      this.selectionModelArr.splice(pos, 1);
    }
  }

  sendNotSelectedEmail() {
    this.emailModel.Info = this.selectionModelArr;
    this.companyModel.Conference_Id = this._conferenceId;
    this.emailModel.CompanyDetails = this.companyModel;
    //console.log(this.emailModel);

    if (this.selectionModelArr.length > 0) {
      //console.log(this.emailModel);
      return this.selectionService
        .sendNotSelectedEmail(this.emailModel)
        .subscribe(result => {
          // This code will be executed when the HTTP call returns successfully
          this.response = result;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };
            this.selectedFilterPrevVal = this.dataTable.search();
            this.selectedSortPrevVal = this.dataTable.order();
            this.selectedPagePrevVal = this.dataTable.page();

              this.toasterService.pop(toast);
              if (this.dataTable) {
                  this.dataTable.destroy();
              }
              this.loadCompanies(this._conferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
    }
  }

  // Get Note types
  getNoteTypeList() {
    return this.selectionService.getNoteTypeList().subscribe((data: any) => {
      this.noteTypeList = data.Info;
    });
  }

  // Add Notes
  AddNotes(item: any) {
    this.NotesModel = {
      NoteType_Id: "",
      EntityId: 1,
      EntityType: 1
    };
    this.modalReference = this.modalService.open(
      this.myContent,
      this.ngbModalOptions
    );
    this.compnyId = item.CompId;
    this.getNotesByCompany(this.compnyId);
  }

  editNotes(item: any) {
    return this.selectionService.getNoteById(item.Id).subscribe((data: any) => {
      this.NotesModel = data.Info;
    });
  }

  getNotesByCompany(id: any) {
    return this.selectionService
      .getNoteByCompanies(id)
      .subscribe((data: any) => {
        this.companyNoteList = data.Info;
      //  this.loadCompanies(this._conferenceId);
      });
  }

  SaveNotes(form: any) {
    if (form.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.isSubmitted = true;
    this.NotesModel.EntityId = this.compnyId;
    if (this.NotesModel && this.NotesModel.Id > 0) {
      return this.selectionService
        .UpdateNoteByCompany(this.NotesModel)
        .subscribe((data: any) => {
          this.NotesModel = {
            NoteType_Id: "",
            NoteText: ""
          };
          var toast: Toast = {
            type: "success",
            title: data.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
          this.getNotesByCompany(this.compnyId);
          this.resetForm(form.form);
            this.isSubmitted = false;
            if (this.dataTable) {
                this.dataTable.destroy();
            }
         
            this.loadCompanies(this._conferenceId);
        });
    } else {
      return this.selectionService
        .saveNoteByCompany(this.NotesModel)
        .subscribe((data: any) => {
          this.NotesModel = {
            NoteType_Id: "",
            NoteText: ""
          };
          this.getNotesByCompany(this.compnyId);
          var toast: Toast = {
            type: "success",
            title: data.Message,
            showCloseButton: true
          };

          this.toasterService.pop(toast);
          this.getNotesByCompany(this.compnyId);
          this.resetForm(form.form);
            this.isSubmitted = false;
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            this.loadCompanies(this._conferenceId);
        });
    }
  }
  resetForm(form: any) {
    form.markAsPristine();
    form.markAsUntouched();
    form.updateValueAndValidity();
  }
  removeNotes(item: any) {
    return this.selectionService
      .RemoveNoteById(item.Id)
      .subscribe((data: any) => {
        this.getNotesByCompany(this.compnyId);
        var toast: Toast = {
          type: "success",
          title: data.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
          if (this.dataTable) {
              this.dataTable.destroy();
          }
          this.loadCompanies(this._conferenceId);
      });
  }
}
