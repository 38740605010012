import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { ReportTemplate } from "ClientApp/app/models/ReportTemplate";
import { Report } from "ClientApp/app/models/Report";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ReportService {
  apiURL = environment.baseUrl;

    constructor(private http: HttpClient, private authenticationService: AuthService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  // http://103.209.145.200:8089/api/Report/1

  getListItems(year: string) {
    let key = "";
    if (year != null && year != "") {
      key = year;
    } else {
      key = "";
      }
      const currentUser = this.authenticationService.currentUserValue;
      var roleId = 0;
      if (currentUser != null) {
          var userRoleInfo = JSON.parse(currentUser);
          roleId = userRoleInfo.Info.RoleId;
      }
      return this.http.get(this.apiURL + "/Report/ListAllDetails/" + roleId);
  }

  /*
    Get all report types.
  */
  getReportTypeItems() {
    return this.http.get(this.apiURL + "/Report");
  }

  /*
    Get all report type column fields
  */
  getReportTypeFields(typeId: number) {
    //console.log('getReportTypeFields url---',this.apiURL + "/Report/" + typeId);
    
    return this.http.get(this.apiURL + "/Report/" + typeId);
  }

  /*
    Save report template
  */
  createReportTemplate(item: ReportTemplate) {
    //console.log(item);
    return this.http.post(this.apiURL + "/Report", item, this.httpOptions);
  }

  /*
    Updtae report template
  */
  updateReportTemplate(item: ReportTemplate) {
    //console.log(item);
    return this.http.put(this.apiURL + "/Report", item, this.httpOptions);
  }

  /* Load Report
  // http://103.209.145.200:8089/api/Report/LoadReport
  */
  getReport(data: Report) {
    // console.log('dataService--getReport--',data,'url--',this.apiURL + "/Report/LoadReport");
   
    return this.http.post(
      this.apiURL + "/Report/LoadReport",
      data,
      this.httpOptions
    );
  }

  // http://103.209.145.200:8089/api/Report/ListAllDetails

  getReportTemplates(id: number) {
    return this.http.get(this.apiURL + "/Report/SelectReportTemplate/" + id);
  }

  getAllTemplates() {
    return this.http.get(this.apiURL + "/Report/DisplayTemplate");
  }

  getTemplateDetails(templateId: number, reportId: number) {
    return this.http.get(
      this.apiURL +
        "/Report/SelectTemplateDetailsById/" +
        templateId +
        "/" +
        reportId
    );
    }

    deleteReportTemplate(id: any) {
        return this.http.delete(this.apiURL + "/Report/" + id);
    }
    getStockExchanges() {
        return this.http.get(
            this.apiURL + "/Report/GetStockExchanges/"
        );
    }
}
