import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";

// Service
import { AssetAddRowService } from "ClientApp/app/services/asset-add-row.service";
import { DevelopStatus } from "ClientApp/app/models/Summary";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { Toast, ToasterService } from "angular2-toaster";

@Component({
  selector: "app-add-edit-form",
  templateUrl: "./add-edit-form.component.html",
  styleUrls: ["./add-edit-form.component.css"]
})
export class AddEditFormComponent implements OnInit {
  @Input() assetStatus: any;
  @Input() public assetObj: any;
  @Input() public rowObj: any;
  @Input() public update: boolean;
  @Input() public pos: any;
  addEditAssetForm!: FormGroup;
  submitted = false;
  public name!: string;
  public indication!: string;
  public status!: string;
  public milestone!: string;
  public rows: Array<{
    name: string;
    indication: string;
    status: any;
    milestone: string;
  }> = [];
  selectedDevelopStatus: any;
  @Output() assetItemValueAdded = new EventEmitter<any>();
  assetDevelopmentStatus: any = [];
  stValid: boolean;
  duplicateStatus: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private addRowService: AssetAddRowService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    console.log(this.assetObj);
    console.log(this.update);
    this.addEditAssetForm = this.formBuilder.group({
      name: ["", Validators.required],
      indication: ["", [Validators.required, wordCountValidator(20)]],
      status: ["", Validators.required],
      milestone: ["", [Validators.required, wordCountValidator(50)]]
    });
    this.selectedDevelopStatus = "null";
    this.assetDevelopmentStatus = [
      {
        Id: "0",
        Description: "Pre-Clinical"
      },
      {
        Id: "1",
        Description: "Phase I"
      },
      {
        Id: "2",
        Description: "Phase II"
      },
      {
        Id: "3",
        Description: "Phase III"
      }
    ];

    if (this.assetObj) {
      this.selectedDevelopStatus = this.assetObj.status;
      console.dir(this.selectedDevelopStatus);
      console.dir(this.assetObj);
      this.addEditAssetForm.patchValue({
        name: this.assetObj.name,
        indication: this.assetObj.indication,
        status: this.assetObj.status, //this.selectedDevelopStatus,
        milestone: this.assetObj.milestone
      });
    }
  }

  onChangeDevelopStatus(obj: any) {
    console.log(this.selectedDevelopStatus);
  }

  reset() {
    this.addEditAssetForm.reset();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addEditAssetForm.invalid) {
      console.log("Invalid");
      return;
    } else {
      this.submitted = false;
      console.log(this.addEditAssetForm);

      var item = this.addEditAssetForm.value;
      console.log(item);
      // var st = this.assetDevelopmentStatus.find(
      //   (i: { Id: any }) => i.Id === item.status.Id
      // );
      // console.log(st);
      console.dir(this.rowObj);
      if (
        item.name != "" &&
        item.indication != "" &&
        item.status != "null" &&
        item.milestone != ""
      ) {
        if (this.update) {
          console.dir(this.rowObj);
          console.log(this.pos);
          this.isValid();
          if (!this.stValid) {
            this.rowObj[this.pos] = item;
          }
        } else {
          this.isValid();
          console.log("isDuplicate " + this.stValid);
          if (!this.stValid) {
            let stObj = {
              Id: item.status.Id,
              Description: item.status.Description
            };
            this.rowObj.push({
              name: item.name,
              indication: item.indication,
              status: stObj,
              milestone: item.milestone
            });
            this.duplicateStatus = false;
          } else {
            this.duplicateStatus = true;
            var toast: Toast = {
              type: "error",
              title: "Asset already exists",
              showCloseButton: true
            };

            this.toasterService.pop(toast);
          }
          // let st =
          //   typeof item.status == "object"
          //     ? item.status.Description
          //     : item.status;
        }

        // this.rows.push({
        //   name: item.name,
        //   indication: item.indication,
        //   status: stObj,
        //   milestone: item.milestone
        // });

        this.addRowService.updateTableRow(this.rowObj);

        console.log(this.rowObj);

        // Clear input
        if (!this.update && !this.duplicateStatus) {
          this.reset();
          this.duplicateStatus = false;
        }
      }
    }
  }

  isValid() {
    this.rowObj.forEach((element: any) => {
      console.log(element.status.Id);
      console.log(this.addEditAssetForm.value.status.Id);
      if (
        element.name === this.addEditAssetForm.value.name &&
        element.indication === this.addEditAssetForm.value.indication &&
        element.milestone === this.addEditAssetForm.value.milestone &&
        element.status.Id === this.addEditAssetForm.value.status.Id
      ) {
        this.stValid = true;
      } else {
        this.stValid = false;
      }
    });
  }

  get fc() {
    // get field control(fc)
    return this.addEditAssetForm.controls;
  }

  compareFn1(optionOne: DevelopStatus, optionTwo: DevelopStatus): boolean {
    if (optionOne && optionTwo) {
      return optionOne.Id === optionTwo.Id;
    } else {
      return false;
    }
  }
  // save(addAssetForm: NgForm) {
  //   this.submitted = true;
  //   //console.log(addAssetForm.value);
  //   var item = addAssetForm.value;
  //   console.log(item);
  //   if (
  //     item.name != "" &&
  //     item.indication != "" &&
  //     item.status != "null" &&
  //     item.milestone != ""
  //   ) {
  //     this.rows.push({
  //       name: item.name,
  //       indication: item.indication,
  //       status: item.status,
  //       milestone: item.milestone
  //     });

  //     this.addRowService.updateTableRow(this.rows);

  //     console.log(this.rows);

  //     // Clear input
  //     this.reset();
  //   }

  //   // this.name = "";
  //   // this.indication = "";
  //   // this.status = "";
  //   // this.milestone = "";

  //   // stop here if form is invalid
  //   // if (this.addEditAssetForm.invalid) {
  //   //   console.log("Invalid");
  //   //   return;
  //   // }

  //   //alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.addEditAssetForm.value));
  // }

  // updateAssetTable() {
  //   this.assetItemValueAdded.emit(this.rows);
  // }
}
