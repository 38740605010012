import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EmailTemplateService } from 'ClientApp/app/services/template/email-template.service';
import { ConferenceService } from 'ClientApp/app/services/conference.service';
import { ConfirmationDialogService } from 'ClientApp/app/services/common/confirmation-dialog.service';
import { ToasterService, Toast } from 'angular2-toaster';
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import * as _ from 'underscore';
import { EmailEventService } from 'ClientApp/app/services/template/email-event.service';

@Component({
  selector: 'app-email-event',
  templateUrl: './email-event.component.html',
  styleUrls: ['./email-event.component.css']
})
export class EmailEventComponent implements OnInit {
  Templates: any = [];
  confs: any = [];
  conferences: any = [];
  conferenceItem: any;
  conferenceName: string = "";
  cInfo: any;
  selectedValue: any;
  _conferenceId: number;

  eventList: any = [];

  // instance of DataTable
  dataTable: any;
  response: any;
  selectedPageSize: any;
  constructor(
    public emailEventService: EmailEventService,
    public cService: ConferenceService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadConferences();
  }

  loadConferences() {
    this.cService.getConference().subscribe((result: {}) => {
      this.response = result;
      if (this.response.Info.length > 0) {
        this.conferences= _.filter(this.response.Info, function(obj:any){ return obj.isActive== 1; });
      
      this.conferenceItem = this.conferences[0];
      this.selectedValue = this.conferenceItem;
      this._conferenceId = this.conferenceItem.ConferenceId;

      this.conferenceChange();
      }
    });
  }

  conferenceChange(){
    //console.log(this.selectedValue);
    // if (this.selectedValue == 0){
      this.conferenceName = "All";
      this._conferenceId = 0;
      this.getAllEvents();
    // }else{
    //   this.conferenceName = this.selectedValue.ConferenceName;
    //   this._conferenceId = this.selectedValue.ConferenceId;
    //     this.getAllEvents();
    // }
  }

  getAllEvents(){
    this.emailEventService.getEmailEventsByConference(this._conferenceId).subscribe((data: any) => {
      console.log(data);
      
          this.eventList = data.Info;
    });
  }

  deleteConfirmation(obj: any) {
    if(obj.IsDefault){
     // this.confirmationDialogService.confirm("","This is a default event")
      this.confirmationDialogService.confirm("","You can't delete a default event","Ok","","lg",false)
      return
    }

    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.EventName)
      .then(confirmed => {
        this.emailEventService.deleteEmailEvent(obj.Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: "Successfully Deleted",//this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.getAllEvents();
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }
}
