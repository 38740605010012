import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";

@Component({
  selector: "app-conference-list",
  templateUrl: "./conference-list.component.html",
  styleUrls: ["./conference-list.component.css"]
})
export class ConferenceListComponent implements OnInit {
  conferences: any = [];
  dataTable: any;
  selectedPageSize: any;
  constructor(
    private cService: ConferenceService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadConferences();
  }

  // Get summaries list
  loadConferences() {
    return this.cService.getConference().subscribe((data: {}) => {
      this.conferences = data;
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#conference_list");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
        pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
        lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });

    $('#conference_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
  } );
  }
}
