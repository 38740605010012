import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { environment } from "ClientApp/environments/environment";
declare let gtag: Function;

@Component({
  selector: "app-success",
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.css"]
})
export class SuccessComponent implements OnInit {
  _conferenceId: any;
  Id: any;
  ConferenceName: any;
  conferenceDetail: any;
  type: any;
  typeName: string | undefined;
  url: string | undefined;
  banner: any;
  constructor(
    private route: ActivatedRoute,
    public cService: ConferenceService,
    private router: Router
  ) { }

  ngOnInit() {
    const cId = this.route.snapshot.paramMap.get("confId");
    const enId = this.route.snapshot.paramMap.get("Id");
    const type = this.route.snapshot.paramMap.get("type");
    this._conferenceId = cId !== null ? cId : 0;
    this.Id = enId !== null ? enId : 0;
    this.type = type !== null ? type : 0;
    if (this.type == 1) {
      this.typeName = "Investor Summary Profile (ISP)";
      this.url = "/investorsummaryedit";
    } else if (this.type == 2) {
      this.typeName = "Investor Application";
      this.url = "/investorapplicationedit";
    } else if (this.type == 3) {
      this.typeName = "Profile";
      this.url = "/profile/participantprofileedit";
    } else if (this.type == 4) {
      this.typeName = "Invitation";
      this.url = "/invitation/invitationedit";
    } else if (this.type == 5) {
      this.typeName = "PressAccreditation";
      this.url = "/accreditation";
    }
    this.getConferenceDetails(this._conferenceId);
  }

  getConferenceDetails(id: any) {
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;

        if (this.type == 1 || this.type == 2) {
          if ([8, 11, 12].indexOf(Number(this.conferenceDetail.Conference.TypeId)) !== -1) {
            gtag('config', 'UA-73328446-12',
              {
                'linker': {
                  'domains': ['knect365.com', 'demy-colton.com']
                },
                'page_title': (this.type == 1 ? "Investor Summary Profile" : "Investor Application") + " Submit - " + this.conferenceDetail.Conference.ConferenceName,
                'page_path': this.router.url
              });
              gtag('event', 'ISP Form Open', {
                'event_category': this.conferenceDetail.Conference.ConferenceName + (this.type == 1 ? " ISP " : " IA ") + "Form",
                'event_label': this.type == 1 ? "Investor Summary Profile" : "Investor Application",
                'event_action': 'submit',
              });
          }
        }
      }
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
    });
  }
}
