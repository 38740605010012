export class Schedule {
  Sitems: Array<TrackItem> | undefined;
}

export class TrackItem {
  Id: number | undefined;
  Scheduled: number | undefined;
  TimeSlot_Id: number | undefined;
  Conference_Id: number | undefined;
  Company_Id: number | undefined;
  UpdateResponse: number | undefined;
}

export class EmailModel {
  Info: Array<EmailItemModel> | undefined;
  companydetails: SendEmailCompany | undefined;
}

export class EmailItemModel {
  ScheduledId: number | undefined;
  Email: string | undefined;
}

export class SendEmailCompany {
  Conference_Id: number | undefined;
}

export class ScheduleConfirmation {
  CompanyId: string | undefined;
  ConfirmationResponseType: number | undefined;
  ConfirmationResponse: string | undefined;
}

export class ScheduleTrackDetail {
  Id: any;
  Conference_Id: any;
}
