import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { User, PasswordModel } from "ClientApp/app/models/User";
import { ToasterService, Toast } from "angular2-toaster";
import { UserService } from "ClientApp/app/services/user.service";
import { PasswordValidator } from "ClientApp/app/core/validators/password.validator";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  submitted = false;
  response: any;
  users: any;
  u: any;
  userObj: any;
  user_Id: any;
  cpwdForm!: FormGroup;
  pass = new PasswordModel();
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private userService: UserService
  ) {}

  ngOnInit() {
    //console.log(localStorage.getItem("currentUser"));
    this.u = localStorage.getItem("currentUser");
    this.userObj = JSON.parse(this.u);
    this.user_Id = this.userObj.Info.UserID;
    this.cpwdForm = this.formBuilder.group(
      {
        currentPass: ["", Validators.required],
        newPass: ["", [Validators.required, Validators.minLength(7)]],
        newConfirmPass: ["", [Validators.required, Validators.minLength(7)]]
      },
      {
        validator: PasswordValidator("newPass", "newConfirmPass")
      }
    );
  }

  get fc() {
    // get field control(fc)
    return this.cpwdForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.cpwdForm.invalid) {
      return;
    }

    this.pass.Password = this.cpwdForm.value.currentPass;
    this.pass.NewPassword = this.cpwdForm.value.newPass;
    this.updatePassword(this.pass, this.user_Id);
  }

  updatePassword(pass: PasswordModel, userId: any) {
    this.userService.updatePassword(pass, userId).subscribe((result: {}) => {
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.cpwdForm.reset();
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }
}
