import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-track-timeslot-create",
  templateUrl: "./track-timeslot-create.component.html",
  styleUrls: ["./track-timeslot-create.component.css"]
})
export class TrackTimeslotCreateComponent implements OnInit {
  @Input() public trackId: number = 0;
  tID: number = 0;
  isEdit: boolean = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    //console.log("New Track ID " + this.trackId);
    this.tID = this.trackId;
    this.isEdit = false;
  }
}
