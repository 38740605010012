import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "ClientApp/app/services/common/data.service";
import { AuthService } from "../../../../app/services/auth/auth.service";
@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.css"]
})
export class MenuComponent implements OnInit {
    @Input() public toggleNav: boolean = false;
    toggleNavMenu: boolean = false;
    message: any;
    count: number | undefined;
    userRoleInfo: any;
    constructor(private data: DataService, private authenticationService: AuthService) { }

    ngOnInit() {
        this.data.messageSource.subscribe(flag => {
            this.toggleNavMenu = flag;
        });
        //console.log(localStorage.getItem("role"));
        if (localStorage.getItem("role") === null) {
            this.toggleNav = true;
        } else {
            this.toggleNav = false;
        }
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser != null)
            this.userRoleInfo = JSON.parse(currentUser);
        //console.log(this.toggleNav);
    }
}
