// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,

  // New Server http://202.0.103.128

  // baseUrl:"http://202.0.103.128:8064/api",
  // imagePath: "http://202.0.103.128:8064/api/ConferenceImage/",
  // profileImagePath: "http://202.0.103.128:8064/api/ProfileImage/",
  // documentsPath: "http://202.0.103.128:8064/api/PressDocuments/",

//  // Staging
//  ////baseUrl: "https://n4jrqb1m-5000.asse.devtunnels.ms/api",
//   baseUrl: "https://demycoltonstaging-api.azurewebsites.net/api",
//   //baseUrl: "http://localhost:61929/api",
//   imagePath: "https://demycoltonstaging-api.azurewebsites.net/ConferenceImage/",
//   profileImagePath:
//     "https://demycoltonstaging-api.azurewebsites.net/ProfileImage/",
//   documentsPath:
//     "https://demycoltonstaging-api.azurewebsites.net/PressDocuments/",

  // // Live
  baseUrl: "https://demycolton-api.azurewebsites.net/api",
  imagePath: "https://demycolton-api.azurewebsites.net/ConferenceImage/",
  profileImagePath: "https://demycolton-api.azurewebsites.net/ProfileImage/",
  documentsPath: "https://demycolton-api.azurewebsites.net/PressDocuments/",

  // baseUrl: "https://demycoltondemo-api.azurewebsites.net/api",
  // imagePath: "https://demycoltondemo-api.azurewebsites.net/ConforenceImage/",
  // profileImagePath: "https://demycoltondemo-api.azurewebsites.net/ProfileImage/",
  //documentsPath: "https://demycoltondemo-api.azurewebsites.net/PressDocuments/",

  // baseUrl: "https://demy-coltonlive-demy-coltonliveapi.azurewebsites.net/api",
  // imagePath: "https://demy-coltonlive-demy-coltonliveapi.azurewebsites.net/ConforenceImage/",
  // profileImagePath: "https://demy-coltonlive-demy-coltonliveapi.azurewebsites.net/ProfileImage/",
  //documentsPath: "https://demy-coltonlive-demy-coltonliveapi.azurewebsites.net/PressDocuments/",

  // baseUrl: "https://demycolton.azurewebsites.net/api",
  // imagePath: "https://demycolton.azurewebsites.net/conferenceimage/",
  // profileImagePath: "https://demycolton.azurewebsites.net/profileimage/",
  //documentsPath: "https://demycolton.azurewebsites.net/PressDocuments/",

//  baseUrl: "http://localhost:61929/api",
//   // baseUrl: "http://15.206.69.156:4498/api",
//   imagePath: "http://localhost:61929/ConferenceImage/",
//   profileImagePath: "http://localhost:61929/ProfileImage/",
//   documentsPath: "http://localhost:61929/PressDocuments/",


  // baseUrl:"http://202.0.103.128:8063/api",
  // imagePath: "http://202.0.103.128:8063/api/ConferenceImage/",
  // profileImagePath: "http://202.0.103.128:8063/api/ProfileImage/",
  // documentsPath: "http://202.0.103.128:8063/api/PressDocuments/",

  IdleTime: 86400, // Default values: `idle` is 600 (10 minutes)
  TimeOut: 86400, //timeout` is 300 (5 minutes)
  PingTime: 120, //ping` is 120 (2 minutes) for every 2 minutes
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
