import { ProfileService } from "./../../../../../services/investor/profile.service";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Profile, ValidationModel } from "ClientApp/app/models/Profile";
import { ToasterService, Toast } from "angular2-toaster";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProfilePreviewModalComponent } from "../../components/profile-preview-modal/profile-preview-modal.component";
import { environment } from "ClientApp/environments/environment";
import { UtilService } from "ClientApp/app/services/common/util.service";
import * as _ from "underscore";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
    _conferenceId: any;
    bannerContent: any;
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    profileForm!: FormGroup;
    submitted = false;
    disableBtn = false;
    profile = new Profile();
    response: any;
    validationError: boolean = false;
    banner: string = "";
    vModel = new ValidationModel();
    valid: any;
    result: any;

    imageChangedEvent: any;
    selectedConferenceFile: any;
    validImage: boolean | undefined;
    previewPhoto: any;
    conferenceIsActive: any;
    loginuser: any;
    vData: any;
    private ownershipRadio: string = "";
    stockExchanges: any = [];
    selectedpsExchange: any;

    @ViewChild('profilePhoto') profilePhoto: ElementRef;

    constructor(
        public cService: ConferenceService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private toasterService: ToasterService,
        private element: ElementRef,
        private utilService: UtilService,
        private modalService: NgbModal,
        private router: Router
    ) {
     
    }

    ngOnInit() {
        const cId = this.route.snapshot.paramMap.get("id");
        this._conferenceId = cId !== null ? cId : 0;
        this.loginuser = localStorage.getItem("currentUser");
        this.getConferenceDetails(this._conferenceId);
        this.getStockExchanges();
        this.profileForm = this.formBuilder.group({
            // Presenter Details
            pdsalutation: ["", Validators.required],
            pdfirstName: ["", Validators.required],
            pdnickName: [""],
            pdmiddleName: [""],
            pdlastName: ["", Validators.required],
            pdsuffix: [""],
            pdbadgeName: [""],
            pdjobTitle: [""],
            pdcontactEmail: ["", [Validators.required, Validators.email]],

            // Company Details
            pdcompanyName: ["", Validators.required],
            ownershipOptions: ["", Validators.required],
            ipoYear: [""],
            goPublic: [""],
            stcother: [""],
            psExchange: [null, Validators.required],
            tickerSymbol: ["", Validators.required],
            pdcompanyWebsite: [""],
            pdAddress1: [""],
            pdAddress2: [""],
            city: [""],
            state: [""],
            country: [""],
            zip: [""],
            pddirectPhone: [""],
            pdcompanyDescription: ["", wordCountValidator(200)],

            // Personal Details
            personalBiography: ["", wordCountValidator(200)],
            filename: [""]
        });
    }

    validateConference(confId: any) {
        this.cService.validateConference(confId).subscribe((data: {}) => {
            this.vData = data;
            console.log(this.vData);
            if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
                this.router.navigate(["/conference/status/" + this._conferenceId]);
            }
        });
    }
    onChangeStockExchange(obj: any) {
        this.selectedpsExchange = _.find(this.stockExchanges, function (item) {
            return item.id == obj.id;
        });
        if (this.selectedpsExchange.id !== 21 || this.selectedpsExchange.stockExchange !== 'Other'){
            this.profileForm.controls["stcother"].setValue('');
        }
        console.log(this.selectedpsExchange);
        console.log(this.profileForm.value.stcother);
    }
    getStockExchanges() {
        this.profileService.getStockExchanges().subscribe((data: {}) => {
            this.response = data;
            this.stockExchanges = this.response.Info;
        });
    }
    getConferenceDetails(id: number) {
        console.log(id);
        this._conferenceId = id;
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            if (
                this.conferenceDetail.Conference &&
                this.conferenceDetail.Conference.ConforanceImage
            ) {
                this.banner =
                    environment.imagePath +
                    this.conferenceDetail.Conference.ConforanceImage;
            }
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            if (this.cInfo.length > 0) {
                this.cInfo.forEach((item: any) => {
                    if (item.CTypeId == 3) {
                        this.bannerContent = item.BannerContents; // Conference Description
                    }
                });
            }
            let confId = this.conferenceDetail.Conference.ConferenceId;
            this.validateConference(confId);
        });
    }

    get fc() {
        // get field control(fc)
        return this.profileForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.validationError = this.profileForm.invalid;
        // stop here if form is invalid
        if (this.profileForm.invalid) {
            console.log("Invalid");
            this.utilService.showValidationDiv();
            // let el = this.element.nativeElement.querySelector(".validation-errors");
            // el.scrollIntoView();
            return;
        } else {
            this.validationError = false;
            this.profile.PrefixName = this.profileForm.value.pdsalutation;
            this.profile.FirstName = this.profileForm.value.pdfirstName;
            this.profile.MiddleName = this.profileForm.value.pdmiddleName;
            this.profile.NickName = this.profileForm.value.pdnickName;
            this.profile.LastName = this.profileForm.value.pdlastName;
            this.profile.SuffixName = this.profileForm.value.pdsuffix;

            this.profile.ConferenceID = this._conferenceId;

            this.profile.Email = this.profileForm.value.pdcontactEmail;
            this.profile.BadgeName = this.profileForm.value.pdbadgeName;
            this.profile.JobTitle = this.profileForm.value.pdjobTitle;
            this.profile.CompanyName = this.profileForm.value.pdcompanyName;
            this.profile.Ownership = this.profileForm.value.ownershipOptions;
            this.profile.IPOYear = this.profileForm.value.ipoYear;
            this.profile.Exchange =
                this.profileForm.value.psExchange != null
                    ? this.profileForm.value.psExchange.id
                    : "";
            this.profile.Ticker = this.profileForm.value.tickerSymbol;
            this.profile.Website = this.profileForm.value.pdcompanyWebsite;
            this.profile.Address1 = this.profileForm.value.pdAddress1;
            this.profile.Address2 = this.profileForm.value.pdAddress2;
            this.profile.City = this.profileForm.value.city;
            this.profile.State = this.profileForm.value.state;
            this.profile.Country = this.profileForm.value.country;
            this.profile.PostalCode = this.profileForm.value.zip;
            this.profile.DirectPhone = this.profileForm.value.pddirectPhone;
            this.profile.CompanyDescription = this.profileForm.value.pdcompanyDescription;
            this.profile.ExchangeOther = this.profileForm.value.stcother;
            this.profile.Bio = this.profileForm.value.personalBiography;
            this.checkIsValid();
            //this.openPreviewModal(this.profile, this.profileForm.value);
            //this.createProfile(this.profile);
        }
    }

    // Check Email, CompanyName, Phone already exists.
    checkIsValid() {
        //this.invalidStatus = false;
        this.vModel.ConferenceID = this._conferenceId;
        this.vModel.Email = this.profileForm.value.pdcontactEmail;
        this.vModel.CompanyName = this.profileForm.value.pdcompanyName;
        this.vModel.DirectPhone = this.profileForm.value.pddirectPhone;
        if (
            this.vModel.ConferenceID != "" &&
            this.vModel.Email != "" &&
            this.vModel.CompanyName != ""           
        ) {
            return this.profileService
                .checkIsValid(this.vModel)
                .subscribe((data: {}) => {
                    this.result = data;
                    this.valid = this.result.Code;
                    if (this.valid == "400") {
                        //this.invalidStatus = true;
                        var toast: Toast = {
                            type: "error",
                            title: this.result.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    } else {
                        this.openPreviewModal(this.profile, this.profileForm.value);
                    }
                });
            this.vModel = new ValidationModel();
        }
    }

    /* Modal popup for preview */
    openPreviewModal(model: any, form: any) {
        const modalRef = this.modalService.open(ProfilePreviewModalComponent, {
            windowClass: "modalWidth",
            backdrop: "static",
            keyboard: false
        });
        modalRef.componentInstance.profileItem = model;
        modalRef.componentInstance.profileForm = form;
        modalRef.result
            .then((result: any) => {
                console.log(result);
            })
            .catch((error: any) => {
                console.log(error);
            });
    }

    // image upload
    openFileDialogue(event: any) {
        event.preventDefault();
        let element: HTMLElement = document.getElementById(
            "upload-photo"
        ) as HTMLElement;
        element.click();
    }

    public onSelectedFileName(event: any) {
        this.imageChangedEvent = event;
        this.selectedConferenceFile = event.target.files[0];
        this.validImage = true;

        if (this.selectedConferenceFile) {
            let extension = this.selectedConferenceFile.name.split(".").pop();

            if (
                extension.toLowerCase() != "png" &&
                extension.toLowerCase() != "jpg" &&
                extension.toLowerCase() != "jpeg"
            ) {
                alert("Please upload valid image.");
                this.validImage = false;
                return;
            }
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(this.selectedConferenceFile);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        this.previewPhoto = readerEvt.target.result;
        this.profile.previewPhoto = readerEvt.target.result;
        this.profile.Photo = this.previewPhoto.split(",")[1];
    }

    removeConferenceImage(): void {
        this.previewPhoto = "";
        this.profile.Photo = "";
        this.profilePhoto.nativeElement.value = '';
    }

    isSelected(name: string): boolean {
        if (!this.ownershipRadio) {
            // if no radio button is selected, always return false so nothing is shown
            return false;
        }
        return this.ownershipRadio === name; // if current radio button is selected, return true, else return false
    }

    setradio(e: string): void {
        this.ownershipRadio = e;
        if (e !== "Private") {
            this.profileForm.controls["psExchange"].setValidators([
                Validators.required
            ]);
            this.profileForm.controls["tickerSymbol"].setValidators([
                Validators.required
            ]);
        } else {
            this.profileForm.controls["psExchange"].setValidators([]);
            this.profileForm.controls["tickerSymbol"].setValidators([]);
        }
        this.profileForm.controls["psExchange"].updateValueAndValidity();
        this.profileForm.controls["tickerSymbol"].updateValueAndValidity();
    }

    isOtherStock(): boolean {
        if (this.profileForm.value.psExchange && this.profileForm.value.psExchange.id == "21") {
            return true;
        }
        return false;
    }
}
