import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TrackItem, TrackSlotItem } from "ClientApp/app/models/Track";

@Injectable({
  providedIn: "root"
})
export class TrackService {
  // Define API
  apiURL = environment.baseUrl;
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json" //,
      // "Access-Control-Allow-Origin": "*"
    })
  };

  // Get Emails
  getTracksByConferenceId(id: number) {
    return this.http.get(this.apiURL + "/TimeTrack/ListTimeTrack/" + id);
  }

  // Get Emails
  getTrackById(id: number) {
    return this.http.get(this.apiURL + "/TimeTrack/" + id);
  }

  getTrackSlotById(id: number) {
    return this.http.get(this.apiURL + "/TimeSlot/" + id);
  }
  createTrack(item: TrackItem) {
    //console.log(item);
    return this.http.post(this.apiURL + "/TimeTrack", item, this.httpOptions);
  }

  updateTrack(item: TrackItem) {
    //console.log(item);
    return this.http.put(this.apiURL + "/TimeTrack", item, this.httpOptions);
  }

  getTrackSlots(id: number) {
    // TrackId
    return this.http.get(
      this.apiURL + "/TimeTrack/ListAsyncSlotsByTrackId/" + id
    );
  }

  createTrackSlot(item: TrackSlotItem) {
    //console.log(item);
    return this.http.post(this.apiURL + "/TimeSlot", item, this.httpOptions);
  }

  updateTrackSlot(item: TrackSlotItem) {
    //console.log(item);
    return this.http.put(this.apiURL + "/TimeSlot", item, this.httpOptions);
  }

  deleteTrack(id: any) {
    return this.http.delete(this.apiURL + "/TimeTrack/" + id);
  }

  deleteSlot(id: any) {
    return this.http.delete(this.apiURL + "/TimeSlot/" + id);
  }
}
