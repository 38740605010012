import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Profile, ValidationModel } from "ClientApp/app/models/Profile";
import {
  Invitation,
  InvitationValidationModel
} from "ClientApp/app/models/Invitation";

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  apiURL = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  createProfile(profile: Profile) {
    // console.log("In service");
    // console.log(profile);
    return this.http.post(this.apiURL + "/Profile", profile, this.httpOptions);
  }

  loadProfiles(conferenceId: number) {
    return this.http.get(
      this.apiURL + "/Profile/SelectProfileByCid/" + conferenceId
    );
  }

  getProfileDetailsById(profileId: number) {
    return this.http.get(this.apiURL + "/Profile/" + profileId);
  }

  updateProfile(profile: Profile) {
    return this.http.put(this.apiURL + "/Profile", profile, this.httpOptions);
  }

  createInvitation(invitation: Invitation) {
    // console.log("In service");
    // console.log(invitation);
    return this.http.post(
      this.apiURL + "/NewParticipantInvitation/AddAsync",
      invitation,
      this.httpOptions
    );
  }

  loadInvitations(conferenceId: number) {
    return this.http.get(
      this.apiURL +
        "/NewParticipantInvitation/ListAsyncByConferenceId/" +
        conferenceId
    );
  }

  getInvitationDetailsById(invitationId: number) {
    return this.http.get(
      this.apiURL + "/NewParticipantInvitation/" + invitationId
    );
  }

  updateInvitation(invitation: Invitation) {
    return this.http.put(
      this.apiURL + "/NewParticipantInvitation",
      invitation,
      this.httpOptions
    );
  }

  checkIsValid(model: ValidationModel) {
    return this.http.post(
      this.apiURL + "/Profile/ProfilePreview",
      model,
      this.httpOptions
    );
  }

  checkIsValidInvitation(model: InvitationValidationModel) {
    return this.http.post(
      this.apiURL + "/NewParticipantInvitation/InvitationPreview",
      model,
      this.httpOptions
    );
  }

  deleteProfile(id: any) {
    return this.http.delete(this.apiURL + "/Profile/" + id);
  }

  deleteInvitation(id: any) {
    return this.http.delete(this.apiURL + "/NewParticipantInvitation/" + id);
    }

    getStockExchanges() {
        return this.http.get(
            this.apiURL + "/Profile/GetStockExchanges/"
        );
    }
}
