import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-selection-detail-modal",
  templateUrl: "./selection-detail-modal.component.html",
  styleUrls: ["./selection-detail-modal.component.css"]
})
export class SelectionDetailModalComponent implements OnInit {
  @Input() public companyId: number = 0;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    console.log(this.companyId);
  }
}
