// loader.interceptors.ts
import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LoaderService } from "./../../services/common/loader.service";
import { finalize } from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  requestStackCount = 0;
  constructor(public loaderService: LoaderService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    this.requestStackCount += 1;
    return next.handle(req).pipe(finalize(() => 
      {
        this.requestStackCount -= 1;
        if (this.requestStackCount == 0){
          this.loaderService.hide();
        }
      }
    ));
  }
}
