import { Component, OnInit } from "@angular/core";
import { Toast, ToasterService } from "angular2-toaster";
import { Schedule, TrackItem } from "ClientApp/app/models/Schedule";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ScheduleService } from "ClientApp/app/services/schedule/schedule.service";
import * as _ from "underscore";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
@Component({
  selector: "app-all-tracks",
  templateUrl: "./all-tracks.component.html",
  styleUrls: ["./all-tracks.component.css"]
})
export class AllTracksComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  public conferenceList: any = [];
  conferenceId: number = 0;
  allTracks: any = [];
  companies: any = [];
  trackName: string | undefined;
  selectionTrackArr: any = [];
  selectionArr: any = [];
  _trackItemModel = new TrackItem();
  _scheduleModel = new Schedule();
  response: any;
  tracksList: any = [];
  selectedValue: any;
  modalIsOpen: boolean = false;
  modalMessage: string = "";
  confID: any;
  showFilledSlots: boolean;
  constructor(
    public cService: ConferenceService,
    public scheduleService: ScheduleService, //public compnayService: CompanyService
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }

  loadConference(typeId: number) {
    this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      // this.conferenceList = data;
      // console.log(this.conferenceList);
      // console.log("this.response=" + this.conferenceList.Info[0].ConferenceId);
      // this.conferenceId = this.conferenceList.Info[0].ConferenceId;
      this.confs = data;
      this.Conferences = this.confs.Info;
      //this.Conferences= _.filter(this.confs.Info, function(obj:any){ return obj.isActive== 1; });
      console.log(this.Conferences);
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      console.log(this.conferenceItem);
      this._conferenceId = this.conferenceItem.ConferenceID;
      console.log(this._conferenceId);
      this.getConferenceDetails(this._conferenceId);
    });
  }

  openModal(open: boolean): void {
    this.modalIsOpen = open;
  }

  getConferenceDetails(id: any) {
    console.log(id);
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      this.confID = this.conferenceDetail.Conference.ConferenceId;
      console.log(this.cInfo);
      this.loadCompanies(this.confID);
      //this.loadOpenTracks(this.confID);
    });
  }

  //TODO: any make it common service
  onChangeConference(id: number) {
    console.log("Inside " + id);
    //this.getConferenceDetails(id);
    this.showFilledSlots = false;
    this.selectionTrackArr = [];
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

refreshClick(){
  if (this.showFilledSlots){
    return this.scheduleService.getFilledTracks(this.confID).subscribe((data: {}) => {
      this.setData(data);
    });
  }else{
    this.loadOpenTracks(this.confID);
  }
  
}

setData(data: any): void{
  this.allTracks = data;
      if (
        this.allTracks.Trackinfo != null &&
        this.allTracks.Trackinfo.length > 0
      ) {
        //this.trackName = this.allTracks.Info[0].RoomCode;
        this.tracksList = this.allTracks.Trackinfo;
        let selectedCompanies:any = [];
        this.tracksList.forEach((trk: any) => {
          trk.isShow = true;
          trk.Slotsinfo.forEach((slt: any) => {
              if (slt.Company_Id)
              {
                selectedCompanies.push(slt.Company_Id);
                this._trackItemModel.Id = slt.ScheduledId;
                this._trackItemModel.Company_Id = +slt.Company_Id;
                this._trackItemModel.Conference_Id = this.confID; //this._conferenceId;
                this._trackItemModel.Scheduled = 1;
                this._trackItemModel.TimeSlot_Id = slt.TimeSlot_Id;
                this._trackItemModel.UpdateResponse = 0;
                this.selectionTrackArr.push(this._trackItemModel);
                this._trackItemModel = new TrackItem();
              }
          });
      });

      this.tracksList.forEach((trk: any) => {
        trk.Slotsinfo.forEach((slt: any) => {
          slt.companies = {};
          slt.companies.Info = this.companies.Info.filter((cmp: any) => (selectedCompanies.indexOf(cmp.CompId) === -1) || (slt.Company_Id === cmp.CompId));
        });
    });

      //   for (var i = 0; i < this.tracksList.length; i++) {
      //     for (var j = 0; j < this.tracksList[i].Slotsinfo.length; j++) {
      //         this.tracksList[i].Slotsinfo[j].companies = this.companies.filter((cmp: any) => )
      //         //this.tracksList[i].Slotsinfo[j].companies.push(this.companies);
      //     }
      // }
        //this.slotsList = this.allTracks.Trackinfo.Slotsinfo;
      } else {
        this.tracksList = [];
      }
}

  // Get open track list
  loadOpenTracks(key: any) {
    return this.scheduleService.getAllTracks(key).subscribe((data: {}) => {
      this.setData(data);
      //this.loadCompanies(key);
    });
  }

  // Get companies list :  TODO: make it common service
  loadCompanies(key: number) {
    return this.scheduleService
      .getCompaniesAllTracks(key)
      .subscribe((data: {}) => {
        this.companies = data;
        this.loadOpenTracks(key);
        console.dir(this.companies.Info);
      });
  }

  onChangeCompany(event: any, timeSlotId: number, scheduleId: number, slot: any) {
    if (!slot.IsReserved){
    const companyId = event.target.value;
    console.log(companyId);
    this._trackItemModel.Id = scheduleId;
    this._trackItemModel.Company_Id = companyId ? (+companyId) : 0;
    this._trackItemModel.Conference_Id = this.confID; //this._conferenceId;
    this._trackItemModel.Scheduled = 1;
    this._trackItemModel.TimeSlot_Id = timeSlotId;
    this._trackItemModel.UpdateResponse = 0;
    if (scheduleId){
      let selectedIndex = this.selectionTrackArr.map((elem: any) => elem.Id).indexOf(scheduleId);
      this.selectionTrackArr[selectedIndex].Company_Id = companyId ? (+companyId) : 0;
    }
    else if (event.target.value != "") {
      let existingTSInd = this.selectionTrackArr.map((elem: any) => elem.TimeSlot_Id).indexOf(timeSlotId);
      
      if (existingTSInd !== -1){
        this.selectionTrackArr[existingTSInd].Company_Id = +companyId;
      }else{
        var item = this._trackItemModel;
        this.selectionTrackArr.push(item);
        this._trackItemModel = new TrackItem();
      }
    } else {
      var pos = this.selectionTrackArr
        .map(function(e: { TimeSlot_Id: number }) {
          return e.TimeSlot_Id;
        })
        .indexOf(timeSlotId);
      //console.log("pos " + pos);
      this.selectionTrackArr.splice(pos, 1);
    }
    console.dir(this.selectionTrackArr);
    
    if (this.selectionTrackArr.length > 0) {
      let selectedCompanies = this.selectionTrackArr.map((sc: any) => sc.Company_Id);
      this.tracksList.forEach((trk: any) => {
          trk.Slotsinfo.forEach((slt: any) => {
              let recordSelected = this.selectionTrackArr.filter((rs: any) => rs.TimeSlot_Id == slt.TimeSlot_Id);
              recordSelected = recordSelected.length > 0 ? recordSelected[0] : {};
              slt.companies.Info = this.companies.Info.filter((cp: any) => (recordSelected.Company_Id == cp.CompId) || selectedCompanies.indexOf(cp.CompId) == -1);
          });
      });
    }
  }
  }

  onScheduleOpenTrack() {
    if (this.selectionTrackArr.length > 0) {
      this._scheduleModel.Sitems = [];
      var resArray: any = [];
      this.selectionTrackArr.forEach((element: any) => {
        if (!element.Id){
          resArray.push(element);
        }else{
          var allSlots = [].concat.apply([], this.tracksList.map((tl: any) => tl.Slotsinfo));
          let currentSlot = allSlots.filter((slt: any) => slt.ScheduledId == element.Id);
          let mainCurrentSlt: any = currentSlot.length > 0 ? currentSlot[0] : null;

          if (mainCurrentSlt && (mainCurrentSlt.Company_Id !== element.Company_Id)){
            resArray.push(element);
          }
        }
      });
      this._scheduleModel.Sitems = resArray;
//      this._scheduleModel.Sitems = this.selectionTrackArr;
      console.dir(this._scheduleModel.Sitems);
      this.addUpdateSelection(this._scheduleModel);
    }
  }

  onConfirmation() {
    this.openModal(false);
    for (let i = 0; i < this.selectionTrackArr.length; i++) {
      let item = this.selectionTrackArr[i];
      item.UpdateResponse = 1;
      this.selectionArr.push(item);
    }
    this._scheduleModel.Sitems = this.selectionArr;
    console.dir(this._scheduleModel.Sitems);
    this.addUpdateSelection(this._scheduleModel);
  }

  addUpdateSelection(schedule: Schedule) {
    console.log(schedule);
    this.scheduleService.addUpdateSelection(schedule).subscribe(result => {
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.selectionTrackArr = [];
        this.loadOpenTracks(this.confID);
      } else {
        if (this.response.Code == 400) {
          this.openModal(true);
          this.modalMessage = this.response.Message;
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
      }
    });
  }
}
