import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-view-detail-modal",
  templateUrl: "./view-detail-modal.component.html",
  styleUrls: ["./view-detail-modal.component.css"]
})
export class ViewDetailModalComponent implements OnInit {
  @Input() public companyId: number = 0;
  //companyId: number = 0;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    //this.companyId = this.cId;
    console.log(this.companyId);
  }
}
