import { PasswordModel } from "./../models/User";
import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "../models/User";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient) {}

  apiURL = environment.baseUrl;

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  getUsers() {
    return this.http.get(this.apiURL + "/Users");
  }

  createUser(user: User) {
    return this.http.post(this.apiURL + "/Users", user, this.httpOptions);
  }

  getUserById(userId: number) {
    return this.http.get(this.apiURL + "/Users/Select/" + userId);
  }

  updateUser(user: User) {
    return this.http.put(this.apiURL + "/Users", user, this.httpOptions);
  }

  updatePassword(pass: PasswordModel, userId: any) {
    return this.http.post(
      this.apiURL + "/Users/ChangePassword/" + userId,
      pass,
      this.httpOptions
    );
  }

  deleteUser(id: any) {
    return this.http.delete(this.apiURL + "/Users/" + id);
  }
}
