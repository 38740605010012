import { Component, OnInit } from "@angular/core";
import { UserIdleService } from "angular-user-idle";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "ClientApp/app/services/auth/auth.service";
import { distinctUntilChanged } from 'rxjs/operators';
declare let gtag:Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "Welcome to Angular";
  subtitle =
    ".NET Core + Angular CLI v7 + Bootstrap & FontAwesome + Swagger Template";
  constructor(
    private userIdle: UserIdleService,
    private router: Router,
    private authenticationService: AuthService
  ) {
    router.events
    .pipe(
        distinctUntilChanged((previous: any, current: any) => {
            if (current instanceof NavigationEnd) {
                return previous.url === current.url;
            }
            return true;
        })).subscribe((x: any) => {
    gtag('js', new Date());
    gtag('config', 'UA-99229480-2',{'page_path': x.url});
  });
  }

  ngOnInit() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      console.log(count);
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.logout();
    });
  }

  logout() {
    this.authenticationService.logout();
    console.log("logout()");
    this.router.navigate([""]);
    this.userIdle.stopWatching();
    this.userIdle.stopTimer();
  }
}
