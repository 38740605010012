export class ReportTemplate {
  Criteria: ReportCriteria | undefined;
  TemplateHeader: ReportTemplateHeader | undefined;
  TemplateDetails: Array<ReportTemplateDetails> | undefined;
}

export class ReportCriteria {
  CriteriaId: number | undefined;
}

export class ReportTemplateHeader {
  TemplateId: number | undefined;
  TemplateName: string | undefined;
  ReportId: number | undefined;
}

export class ReportTemplateDetails {
  ReportDetailsId: number | undefined;
  IsSortField: boolean | undefined;
}
