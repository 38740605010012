﻿import { Component, OnInit, HostListener, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UiSwitchModule } from "ngx-toggle-switch";
import {
  Application,
  CompanyDetails,
  ContactDetails,
  PresentationDetailModel,
  CompanyDetailModel,
  AppValidationModel,
} from "./../../../../../models/Application";
import { PressAccreditationService } from "./press-accreditation.service";
import { Toast, ToasterService } from "angular2-toaster";
import { ActivatedRoute, Router } from "@angular/router";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { State, Country } from "ClientApp/app/models/Summary";
import { UtilService } from "ClientApp/app/services/common/util.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { ApplicationPreviewModalComponent } from "../../components/investor-application/application-preview-modal/application-preview-modal.component";
import { SummaryPreviewModalComponent } from "../../components/investor-summary/summary-preview-modal/summary-preview-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "ClientApp/environments/environment";
import moment from "moment";
import { IMyDpOptions } from "mydatepicker";
import { PressAccreditationPreviewModalComponent } from "../press-accreditation-preview-modal/press-accreditation-preview-modal.component";
@Component({
  selector: "app-press-accreditation",
  templateUrl: "./press-accreditation.component.html",
  styleUrls: [],
})
export class PressAccreditationComponent implements OnInit {
  accreditationForm!: FormGroup;
  submitted = false;
  previewDoc: any;
  participationError: boolean = true;
  //disableBtn = false;
  application = new Application();
  companyDetails = new CompanyDetails();
  contactDetails = new ContactDetails();
  //response: any;
  _conferenceId: any;
  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  uploadedFile: any = [];
  cInfo: any;
  public showBtn: boolean = false;
  public fixedDiv: boolean = false;
  validationError: boolean = false;
  banner: string = "";
  vModel = new AppValidationModel();
  presenterModel = new PresentationDetailModel();
  companyModel = new CompanyDetailModel();
  result: any;
  valid: any;
  //otherStatus: boolean = false;
  loginuser: any;
  todaydate: any;
  vData: any;
  selectedFiles: any;
  currentFileName: any;
  public documents: any = [];
  public myDatePickerOptions: IMyDpOptions = {
    editableDateField: false,
    dateFormat: "mm-dd-yyyy",
    width: "250px",
  };
  constructor(
    private formBuilder: FormBuilder,
    public pressAccreditationService: PressAccreditationService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    public cService: ConferenceService,
    private utilService: UtilService,
    private element: ElementRef,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    const cId = this.route.snapshot.paramMap.get("id");
    this._conferenceId = cId !== null ? cId : 0;
    this.getConferenceDetails(this._conferenceId);
    //this.getCountries();
    this.loginuser = localStorage.getItem("currentUser");
    this.todaydate = moment(new Date()).format("MM/DD/YYYY");
    this.accreditationForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      website: ["", Validators.required],
      cfirstName: ["", Validators.required],
      clastName: ["", Validators.required],
      cposition: ["", Validators.required],
      ccontactEmail: ["", [Validators.required, Validators.email]],
      date: ["", Validators.required],
      msgcomp: [""],
      link: [""],
      docs: [""],
      participationMethod: ["", Validators.required],
      // removed as per the instruction from Anish sir
    });
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
    });
  }

  getConferenceDetails(id: number) {
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      console.log(data)
      this.conferenceDetail = data;
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 4) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
    });
  }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.accreditationForm.controls;
  }

  // Check Email, CompanyName, Phone already exists.
  checkIsValid() {
    this.companyModel.ConferenceID = this._conferenceId;
    this.companyModel.Name = this.accreditationForm.value.companyName;
    this.presenterModel.Email = this.accreditationForm.value.ccontactEmail;
    this.companyModel.CompanyMessage = this.accreditationForm.value.msgcomp;
    this.companyModel.LinkPublication = this.accreditationForm.value.link;
    this.companyModel.ApplicationDate =
      this.accreditationForm.value.date.formatted;
    this.vModel.contactDetails = this.presenterModel;
    this.vModel.companyDetails = this.companyModel;
    if (
      this.companyModel.ConferenceID != "" &&
      this.presenterModel.Email != "" &&
      this.companyModel.Name != ""
    ) {
      return this.pressAccreditationService
        .checkIsValid(this.vModel)
        .subscribe((data: {}) => {
          this.result = data;
          this.valid = this.result.Code;
          if (this.valid == "400") {
            var toast: Toast = {
              type: "error",
              title: this.result.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
          } else {
            this.openPreviewModal(
              this.application,
              this.accreditationForm.value
            );
          }
        });
      this.vModel = new AppValidationModel();
    }
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(event: any) {
    let position = 500;
    let scrollPositionFromTop = +window.pageYOffset.toString();
    //let submitBtn = event.nativeElement.querySelectorAll(".submit");
    if (scrollPositionFromTop > position) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    let windowHeight = +window.innerHeight;
    if (
      scrollPositionFromTop + windowHeight >
      document.body.clientHeight - 100
    ) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    this.previewDoc = readerEvt.target.result;
    this.application.Documents.push(this.previewDoc);
  }

  public onSelectedFileName(event: any) {
    this.application.Documents = [];
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.currentFileName = this.selectedFiles[i].name;
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.previewDoc = e.target.result;
          let obj = {
            name: this.selectedFiles[i].name,
            content: this.previewDoc.split(",")[1],
          };
          this.application.Documents.push(obj);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  removeFile(file: any) {
    const index = this.application.Documents.indexOf(file, 0);
    if (index > -1) {
      this.application.Documents.splice(index, 1);
    }
  }

  onSubmit() {
    this.submitted = true;
    this.validationError = this.accreditationForm.invalid;
    // stop here if form is invalid
    if (this.accreditationForm.invalid) {
      //  EnableParticipantMethod checking commented as per the instruction from Anish sir that we temporarily removing the field EnableParticipantMethod based
      // condition checking

      // if (
      //   this.accreditationForm.invalid ||
      //   (this.conferenceDetail.Conference.EnableParticipantMethod == true &&
      //     !this.accreditationForm.value.participationMethod)
      // ) {

      //   if (
      //     this.conferenceDetail.Conference.EnableParticipantMethod == true &&
      //     !this.accreditationForm.value.participationMethod
      //   ) {
      //     this.participationError = false;
      //   } else {
      //     this.participationError = true;
      //   }
      this.validationError = true;
      this.utilService.showValidationDiv();
      return;
    } else {
      this.participationError = true;
      this.validationError = false;
      this.companyDetails.Name = this.accreditationForm.value.companyName;
      this.companyDetails.Website = this.accreditationForm.value.website;
      this.companyDetails.ConferenceID = this._conferenceId;
      this.companyDetails.IsSelected = 0;
      this.contactDetails.FirstName = this.accreditationForm.value.cfirstName;
      this.contactDetails.LastName = this.accreditationForm.value.clastName;
      this.contactDetails.Position = this.accreditationForm.value.cposition;
      this.contactDetails.Email = this.accreditationForm.value.ccontactEmail;
      this.companyDetails.CompanyMessage = this.accreditationForm.value.msgcomp;
      this.companyDetails.LinkPublication = this.accreditationForm.value.link;
      this.companyDetails.ApplicationDate =
        this.accreditationForm.value.date.formatted;
      this.application.companyDetails = this.companyDetails;
      this.application.contactDetails = this.contactDetails;
      this.companyDetails.ParticipationMethod =
        this.accreditationForm.value.participationMethod;
      this.checkIsValid();
    }
  }

  /* Modal popup for preview */
  openPreviewModal(appModel: any, appForm: any) {
    const modalRef = this.modalService.open(
      PressAccreditationPreviewModalComponent,
      {
        windowClass: "modalWidth",
        backdrop: "static",
        keyboard: false,
      }
    );
    modalRef.componentInstance.appItem = appModel;
    modalRef.componentInstance.accreditationForm = appForm;
    modalRef.result
      .then((result: any) => {
        console.log(result);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  onCheckBoxChange(e: any) {
    if (e.target.value) {
      this.participationError = true;
    } else {
      this.participationError = false;
    }
  }
}
