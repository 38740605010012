import { UtilService } from "./../../services/common/util.service";
import { Component, OnInit } from "@angular/core";
import { SelectionService } from "ClientApp/app/services/selection/selection.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import * as _ from "underscore";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  response: any;
    boxItems: any = {};
  filledSlots: any;
  openSlots: any;
  pending: any;
  totalSlots: any;
  selectedValue: any;
  conferenceDetail: any;
  cInfo: any;
  ConferenceName: any;
  confs: any;
  vData: any;
  Conferences: any;
  conferenceItem: any;
  _conferenceId: any;
  trackInfo: any = [];
  trackList: any = [];

  constructor(
    public selectionService: SelectionService,
    public cService: ConferenceService,
    public utilService: UtilService
  ) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
    //this.validateConference();
  }

  loadConference(typeId: number) {
      this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      //this.Conferences = this.confs.Info;
      this.Conferences = _.filter(this.confs.Info, function(obj: any) {
        return obj.isActive == 1;
      });
      //console.log(this.Conferences);
      this.selectedValue = "";

      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      this._conferenceId = this.conferenceItem.ConferenceId;
      this.loadDetails(this._conferenceId);

      // console.log(this.conferenceItem.ConferenceId);
      // console.log(this._conferenceId);
      //this.getConferenceDetails(this.conferenceItem.ConferenceID);
    });
  }

  // validateConference() {
  //   this.cService.validateConference().subscribe((data: {}) => {
  //     this.vData = data;
  //     console.log(this.vData);
  //   });
  // }

  loadDetails(conferenceId?: any) {
    return this.selectionService
      .getDashboard(conferenceId)
        .subscribe((data: {}) => {
        this.response = data;
        this.boxItems = this.response.Info;
        //console.dir(this.boxItems);
        this.totalSlots = this.boxItems.TotalSlots;
        this.filledSlots = this.boxItems.FilledSlots;
        this.openSlots = this.boxItems.OpenSlots;
        this.pending =
          this.boxItems.Pending != undefined ? this.boxItems.Pending : 0;
        //console.log(conferenceId);
        if (conferenceId != "" && conferenceId != undefined) {
          // Load Tracks
          this.loadTrackDetails(conferenceId);
        }
      });
  }

  loadTrackDetails(conferenceId: any) {
    this.trackList = [];
    return this.utilService
      .getDashboardTracks(conferenceId)
      .subscribe((data: {}) => {
          this.response = data;
        this.trackInfo = this.response.Trackinfo;
        if (this.trackInfo && this.trackInfo.length > 0) {
          this.trackList = this.trackInfo;
        }
      });
  }

  onChangeConference(id: any) {
    //console.log(this.selectedValue);
    if (this.selectedValue != "") {
      this.getConferenceDetails(this.selectedValue.ConferenceID);
    } else {
      this.loadDetails();
    }
  }

  getConferenceDetails(id: any) {
    //console.log(id);
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      //console.log(this.cInfo);
      this.loadDetails(this.selectedValue.ConferenceId);
    });
  }
}
