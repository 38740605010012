export class Summary {
  presenterDetails: PresenterDetails = new PresenterDetails();
  corporateDetails: CorporateDetails = new CorporateDetails();
  companyDetails: CompanyDetails = new CompanyDetails();
  //investorAssets: Array<InvestorAssets> = [];
  //invetsorTherapeuticFocus: Array<InvestorTherapeuticFocus> = [];
  assistantDetails: AssistantDetails = new AssistantDetails();
  ConferenceFeedBack: string | undefined;
}

export class PresenterDetails {
  Prefix: string | undefined;
  FirstName: string | undefined;
  NickLastName: string | undefined;
  MiddleName: string | undefined;
  LastName: string | undefined;
  Suffix: string | undefined;
  Generation: string | undefined;
  Title: string | undefined;
  Email: string | undefined;
  Phone: string | undefined;
  TwitterProfile: string | undefined;
  LinkedInProfile: string | undefined;
}

export class AssistantDetails {
  AssistantPrefix: string | undefined;
  FName: string | undefined;
  LName: string | undefined;
  Email: string | undefined;
  Phone: string | undefined;
  IsAssistant: boolean | 0 = false;
}

export class InvestorTherapeuticFocus {
  TherapeuticFocusId: number | undefined;
}

export class CompanyDetails {
  CompId: number | undefined;
  CompanyId: string | undefined;
  ToBeScheduled: boolean | 0 = false;
  SelectionRound: boolean | 0 = false;
  Confirmed: boolean | 0 = false;
  Scheduled: boolean | 0 = false;
  IsTechBioCompany : boolean | 0 =false ;
  CompanyName: string | undefined;
  InvestorProposition: string | undefined;
  BioFutureDescription: string | undefined;
  Address1: string | undefined;
  City: string | undefined;
  State: string | undefined;
  StateCode: string | undefined;
  Zip: string | undefined;
  Country: string | undefined;
  CountryCode: string | undefined;
  Ownership: string | undefined;
  IPOYear: string | undefined;
  Exchange: string | undefined;
  Ticker: string | undefined;
  PublicBeforeConference: string | undefined;
  IndustrySector: string | undefined;
  FinancingAmount: number | undefined;
  Conference_Id: number | undefined;
  ConferenceID: string | undefined;
  YearEstablished: string | undefined;
  Website: string | undefined;
  Employees: number | undefined;
  CompanySummary: string | undefined;
  ClassificationPrimary: string | undefined;
  ClassificationSecondary: string | undefined;
  Products: string | undefined;
  DevelopmentProgram: string | undefined;
  FundingPlan: string | undefined;
  Investors: string | undefined;
  Partners: string | undefined;
  FundsRaised: string | undefined;
  IsFundRaisingMode: boolean | undefined;
  Comments: string | undefined;
  investorAssets: Array<InvestorAssets> | undefined;
  investorTherapeuticFocus: Array<InvestorTherapeuticFocus> | undefined;
  StockExchangeOther: string | undefined;
  ParticipationMethod: number | undefined;
}

export class CorporateDetails {
  ContactId: number | undefined;
  IsCorpComm: boolean | 0 = false;
  Prefix: string | undefined;
  FirstName: string | undefined;
  LastName: string | undefined;
  PhoneNumber: string | undefined;
  Email: string | undefined;
  Generation: string | undefined;
  IsPresenter: boolean | 0 = false;
  IsPrimary: boolean | 0 = false;
  RegId: boolean | 0 = false;
}

export class InvestorAssets {
  AssetName: string | undefined;
  Indication: string | undefined;
  AssetStatus: string | undefined;
  Milestone: string | undefined;
}

// For edit
export class RowAssets {
  name: string | undefined;
  indication: string | undefined;
  status: string | undefined;
  milestone: string | undefined;
}

export class Country {
  Code: string | undefined;
  Name: string | undefined;
}

export class State {
  Code: string | undefined;
  Name: string | undefined;
}

export class ValidationModel {
  presenterDetails: PresentationDetailModel = new PresentationDetailModel();
  companyDetails: CompanyDetailModel = new CompanyDetailModel();
}

export class PresentationDetailModel {
  Email: string | undefined;
  Phone: string | undefined;
}
export class CompanyDetailModel {
  ConferenceID: any;
  CompanyName: string | undefined;
}

export class DevelopStatus {
  Id: string | undefined;
  Description: string | undefined;
}

export class TFocus {
  Id: string | undefined;
  Description: string | undefined;
}

export class PClass {
  Id: string | undefined;
  Description: string | undefined;
}
export class SClass {
  Id: string | undefined;
  Description: string | undefined;
}
