﻿import { Component, OnInit } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { AuthService } from "ClientApp/app/services/auth/auth.service";

@Component({
    selector: "app-press-accreditation-conference",
    templateUrl: "./press-accreditation-conference.component.html",
    styleUrls: []
})
export class PressAccreditationConferenceComponent implements OnInit {
    confs: any = [];
    Conferences: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    constructor(public cService: ConferenceService, private authenticationService: AuthService) { }

    ngOnInit() {
        this.loadConference(ConferenceConfig.CONF_PRESSACCREDIATION);
    }

    // Get Conference list
    loadConference(typeId: number) {

        return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            this.confs = data;
            if (this.confs) {
                this.Conferences = this.confs.Info;
                this.conferenceItem = this.Conferences[0];
            }

            //this.getConferenceDetails(this.conferenceItem.ConferenceId);
        });
    }
}
