import { Component, OnInit } from "@angular/core";
//import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "ClientApp/app/services/auth/auth.service";
import { first } from "rxjs/operators";
//import { AuthService } from "./../../services/auth/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  // private formSubmitAttempt: boolean;
  submitted = false;
  showErrorMessage = false;
  returnUrl: string | undefined;
  error = "";
  loading = false;
  msg: any;
  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["dashboard"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  get fc() {
    return this.loginForm.controls;
  }

  isFieldInvalid(field: string) {
    // return (
    //   (!this.form.get(field).valid && this.form.get(field).touched) ||
    //   (this.form.get(field).untouched && this.formSubmitAttempt)
    // );
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    //console.log(this.loginForm);

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authenticationService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe(
        data => {
          //console.log(data);
          let response = data;
          //console.log(response);
          if (response.Message.Code == 200) {
            //console.log(response.Message.Code);
            this.authenticationService
              .getToken(this.loginForm.value.email)
              .subscribe(result => {
                //console.log(result);
                if (result != null) {
                  this.router.navigate(["/dashboard"]);
                }
              });
          } else {
            this.msg = "Login Failed";
            this.router.navigate([""]);
            this.showErrorMessage = true;
          }
          //this.router.navigate([this.returnUrl]);
          //this.router.navigate(["/dashboard"]);
        },
        error => {
          //console.log(error);
          this.error = error;
          this.loading = false;
          this.showErrorMessage = true;
          this.router.navigate([""]);
        }
      );
  }
}
