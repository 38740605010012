import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ApplicationService } from "ClientApp/app/services/investor/application.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import {
    SendISPReminder,
    SendISP,
    SendISPCompany
} from "ClientApp/app/models/SendISPReminder";
import { ToasterService, Toast } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import * as _ from "underscore";
import { EBDConfigurationService } from "ClientApp/app/services/EBD-configuration/ebd-configuration.service";

@Component({
    selector: "app-application-emails",
    templateUrl: "./application-emails.component.html",
    styleUrls: ["./application-emails.component.css"]
})
export class ApplicationEmailsComponent implements OnInit {
    confs: any = [];
    Conferences: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    ConferenceName: any;
    conferenceDetail: any;
    cInfo: any;
    applications: any = [];
    declineList: any = [];
    ISPModel = new SendISPReminder();
    _ISPModel = new SendISP();
    companyModel = new SendISPCompany();
    response: any;
    selectedArr: Array<SendISP> = [];
    selectedDeclineArr: Array<SendISP> = [];
    dataTable: any;
    selectedValue: any;
    dataTable1: any;
    selectedSendISP = new SendISP();
    selectedPageSize: any;
    selectedFilterPrevVal: string = "";
    selectedSortPrevVal: any = [[0, "asc"]];
    selectedPagePrevVal: number = 0;
    declinedPageSize: any;
    declineFilterPrevVal: string = "";
    declineSortPrevVal: any = [[0, "asc"]];
    declinePagePrevVal: number = 0;
    selectedConference: number = 0;

    constructor(
        public appService: ApplicationService,
        public cService: ConferenceService,
        private toasterService: ToasterService,
        private chRef: ChangeDetectorRef,
        private EBDservice:EBDConfigurationService
    ) { }

    ngOnInit() {
        this.loadConference(ConferenceConfig.CONF_APPLICATION);
    }

    // Get Conference list
    loadConference(typeId: number) {
        return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            this.confs = data;
            this.Conferences = this.confs.Info;
            this.conferenceItem = this.Conferences[0];
            this.selectedValue = this.conferenceItem;
            console.log(this.conferenceItem.ConferenceId);
            this.getConferenceDetails(this.conferenceItem.ConferenceID);
            this.getEbdconferenceById(this.conferenceItem.ConferenceId)
        });
    }

   

    getConferenceDetails(id: number) {
        console.log(id);
        //this._conferenceId = id;
        debugger
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            console.log(this.cInfo);
            this.selectedArr = [];
            this.selectedDeclineArr = [];
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            if (this.dataTable1) {
                this.dataTable1.destroy();
            }
            
            this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
            console.log('confID:::::::::',this._conferenceId);
            this.selectedConference =this._conferenceId;
            this.loadList(this._conferenceId);
            this.loadDeclineList(this._conferenceId);
            this.getEbdconferenceById(this._conferenceId);
        });
    }

    onChangeConference(id: number) {
        this.selectedArr = [];
        this.selectedDeclineArr = [];
        console.log("Inside " + id);
        // if (this.dataTable) {
        //     this.dataTable.destroy();
        // }
        // if (this.dataTable1) {
        //     this.dataTable1.destroy();
        // }
        this.setBasicGridProperties();        
        this.selectedConference = this.selectedValue.ConferenceId;
        this.getConferenceDetails(this.selectedValue.ConferenceID);
       
    }


     //EBD configuration confernce
     getEbdconferenceById(confId:number){
         debugger
       return this.EBDservice.checkEBDConfigurationExist(confId).subscribe((res:any)=>{
           debugger
            if(res.Status===true){
                console.log('Button enabled');
                
                this.disableRegistrButton=false;
            }else{
                console.log('Button disabled');
                this.disableRegistrButton=true; 
            }
            
        })
    }

    loadList(conferenceId: number) {
        return this.appService
            .getEmailManagementList(conferenceId)
            .subscribe((data: {}) => {
                this.applications = data;
                this.selectedArr = [];
                this.enablePagination();
            });
    }

    enablePagination() {
        let self = this;
        this.chRef.detectChanges();
        const table: any = $("#app_selected_email_mgmt");
        // if (this.dataTable) {
        //     this.dataTable.destroy();
        // }
        this.dataTable = table.DataTable({
            "aoColumns": [
                null,
                null,
                null,
                {"sType": "date"},
                null,
                {"sType": "date"},
                null
              ],
            pagingType: "full_numbers",
            paging: true,
            pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
            lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
            search: {"search": self.selectedFilterPrevVal},
            "order": self.selectedSortPrevVal,
            'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
            language: {
                search:
                    '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
                searchPlaceholder: "Search for...",
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                }
            },
            stripeClasses: ["odd-row", "even-row"]
        });

        //this.dataTable

        $('#app_selected_email_mgmt').on( 'length.dt', function ( e, settings, len ) {
            self.selectedPageSize = len;
        });
    }

    loadDeclineList(conferenceId: number) {
        return this.appService
            .getDeclineEmailManagementList(conferenceId)
            .subscribe((data: {}) => {
                this.declineList = data;
                this.selectedDeclineArr = [];
                this.enableDeclinePagination();
            });
    }

    enableDeclinePagination() {
        let self = this;
        this.chRef.detectChanges();
        const table1: any = $("#app_decline_email_mgmt");
        // if (this.dataTable1) {
        //     this.dataTable1.destroy();
        // }
        this.dataTable1 = table1.DataTable({
            "aoColumns": [
                null,
                null,
                null,
                {"sType": "date"},
                null,
                {"sType": "date"}
              ],
            pagingType: "full_numbers",
            paging: true,
            pageLength: this.declinedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
            lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
            search: {"search": self.declineFilterPrevVal},
            "order": self.declineSortPrevVal,
            'displayStart': ((this.declinePagePrevVal) * (this.declinedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
            language: {
                search:
                    '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
                searchPlaceholder: "Search for...",
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                }
            },
            stripeClasses: ["odd-row", "even-row"]
        });
        $('#app_decline_email_mgmt').on( 'length.dt', function ( e, settings, len ) {
            self.declinedPageSize = len;
        });
    }

    disableRegistrButton: boolean = true;
    onChange(e: any, id: any, email: string, emailType: string) {
        // onChange(e: any) {
        //console.log(e);
        debugger
        console.log(id);
        console.log(email);
        console.log(emailType);
        if (e.target.checked) {
            this._ISPModel.Id = id;
            this._ISPModel.Email = email;
            this._ISPModel.EmailType = emailType;
            console.log(this._ISPModel);
            this.selectedArr.push(this._ISPModel);
            this._ISPModel = new SendISP();
        }
        else {
            let unselectedIndex = this.selectedArr.map((sa: any) => sa.Id).indexOf(id);
            if (unselectedIndex !== -1)
            {
                this.selectedArr = this.selectedArr.filter((x, i) => i !== unselectedIndex);
            }
            // var index = this.selectedArr.indexOf(id);
            // this.selectedArr.splice(index, 1);
            // // // this.selectedSendISP = new SendISP();

            // // this.selectedArr= _.without(this.selectedArr, id);

        }

        
        var count = this.selectedArr.filter(x => x.EmailType == undefined).length;
        console.log("Count" + count);
        if (count > 0 && this.selectedArr.length > 0) {
            this.disableRegistrButton = true;
            this.selectedArr
        }
        else {
            this.disableRegistrButton = false;
        }
        
        if (this.disableRegistrButton === false) {
            var confId = this.selectedConference;
            this.getEbdconferenceById(confId);            
        }
    }

    onDeclineChange(e: any, id: number, email: string) {
        // onChange(e: any) {
        //console.log(e);
        console.log(id);
        console.log(email);
        if (e.target.checked) {
            this._ISPModel.Id = id;
            this._ISPModel.Email = email;
            console.log(this._ISPModel);
            this.selectedDeclineArr.push(this._ISPModel);
            this._ISPModel = new SendISP();
        }else{
            let unselectedIndex = this.selectedDeclineArr.map((sa: any) => sa.Id).indexOf(id);
            if (unselectedIndex !== -1)
            {
                this.selectedDeclineArr = this.selectedDeclineArr.filter((x, i) => i !== unselectedIndex);
            }
        }
    }

    sendSelected() {

        this.ISPModel.Info = this.selectedArr;
        this.companyModel.Conference_Id = this._conferenceId;
        this.ISPModel.CompanyDetails = this.companyModel;
        //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
        if (this.selectedArr.length > 0) {
            console.log(this.ISPModel);
            return this.appService
                .sendSelectedEmail(this.ISPModel)
                .subscribe(result => {
                    // This code will be executed when the HTTP call returns successfully
                    this.response = result;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true
                        };

                        this.toasterService.pop(toast);
                        if (this.dataTable) {
                            this.dataTable.destroy();
                        }
                        this.loadList(this._conferenceId);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                    // if (this.dataTable) {
                    //     this.dataTable.destroy();
                    // }
                    // if (this.dataTable1) {
                    //     this.dataTable1.destroy();
                    // }
                    this.getConferenceDetails(this.selectedValue.ConferenceID);

                });
        }
    }

    sendDeclined() {
        this.ISPModel.Info = this.selectedDeclineArr;
        this.companyModel.Conference_Id = this._conferenceId;
        this.ISPModel.CompanyDetails = this.companyModel;
        //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
        if (this.selectedDeclineArr.length > 0) {
            console.log(this.ISPModel);
            return this.appService
                .sendDeclinedEmail(this.ISPModel)
                .subscribe(result => {
                    // This code will be executed when the HTTP call returns successfully
                    this.response = result;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true
                        };

                        this.declineFilterPrevVal = this.dataTable1.search();
                        this.declineSortPrevVal = this.dataTable1.order();
                        this.declinePagePrevVal = this.dataTable1.page();

                        this.toasterService.pop(toast);
                        if (this.dataTable1) {
                            this.dataTable1.destroy();
                        }

                        this.loadDeclineList(this._conferenceId);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                });
        }
    }

    applicationRegisterEmails() {

        var count = this.selectedArr.filter(x => x.EmailType == undefined).length;
        console.log("Count" + count);
        if (count > 0 && this.selectedArr.length > 0) {
            this.disableRegistrButton = true;
            return
        }
        else {
            this.disableRegistrButton = false;
        }
        this.ISPModel.Info = this.selectedArr;
        this.companyModel.Conference_Id = this._conferenceId;
        //this.companyModel.EmailType = "EmailType";
        this.ISPModel.CompanyDetails = this.companyModel;
        if (this.selectedArr.length > 0) {
            return this.cService
                .applicationRegisterEmails(this.ISPModel)
                .subscribe((result: any) => {
                    this.response = result;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true,
                            timeout: 0
                        };

                        this.toasterService.pop(toast);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true,
                            timeout: 0
                        };
                        this.toasterService.pop(toast);
                    }
                    this.selectedFilterPrevVal = this.dataTable.search();
                    this.selectedSortPrevVal = this.dataTable.order();
                    this.selectedPagePrevVal = this.dataTable.page();

                    this.getConferenceDetails(this.selectedValue.ConferenceID);
                });
        }
    }

    sendSelectionEmail() {
        this.ISPModel.Info = this.selectedArr;
        this.companyModel.Conference_Id = this._conferenceId;
        this.ISPModel.CompanyDetails = this.companyModel;
        if (this.selectedArr.length > 0) {
            return this.cService
                .sendSelectionEmail(this.ISPModel)
                .subscribe((result: any) => {
                    this.response = result;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true
                        };

                        this.selectedFilterPrevVal = this.dataTable.search();
                        this.selectedSortPrevVal = this.dataTable.order();
                        this.selectedPagePrevVal = this.dataTable.page();

                        this.toasterService.pop(toast);
                        if (this.dataTable) {
                            this.dataTable.destroy();
                        }
                        this.selectedArr = [];
                        this.selectedDeclineArr = [];
                        this.loadList(this._conferenceId);
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                    //this.getConferenceDetails(this.selectedValue.ConferenceID);
                });
        }
    }


    //applicationRegisterEmails() {
    //  this.ISPModel.Info = this.selectedArr;
    //  this.companyModel.Conference_Id = this._conferenceId;
    //  //this.companyModel.EmailType = "EmailType";
    //  this.ISPModel.CompanyDetails = this.companyModel;
    //  if (this.selectedArr.length > 0) {
    //    return this.cService
    //      .applicationRegisterEmails(this.ISPModel)
    //      .subscribe((result:any) => {
    //        this.response = result;
    //        if (this.response.Code == 200) {
    //          var toast: Toast = {
    //            type: "success",
    //            title: this.response.Message,
    //            showCloseButton: true,
    //            timeout:0
    //          };

    //          this.toasterService.pop(toast);
    //        } else {
    //          var toast: Toast = {
    //            type: "error",
    //            title: this.response.Message,
    //            showCloseButton: true,
    //            timeout:0
    //          };
    //          this.toasterService.pop(toast);
    //        }

    //      });
    //  }
    //}

    //sendPresentationAwardEmail() {
    //  this.ISPModel.Info = this.selectedArr;
    //  this.companyModel.Conference_Id = this._conferenceId;
    //  this.ISPModel.CompanyDetails = this.companyModel;
    //  if (this.selectedArr.length > 0) {
    //    return this.cService
    //      .sendPresentationAwardEmail(this.ISPModel)
    //      .subscribe((result:any) => {
    //        this.response = result;
    //        if (this.response.Code == 200) {
    //          var toast: Toast = {
    //            type: "success",
    //            title: this.response.Message,
    //            showCloseButton: true
    //          };

    //          this.toasterService.pop(toast);
    //          this.applicationRegisterEmails()
    //        } else {
    //          var toast: Toast = {
    //            type: "error",
    //            title: this.response.Message,
    //            showCloseButton: true
    //          };
    //          this.toasterService.pop(toast);
    //        }
    //      });
    //  }
    //  }

    getIconByEmailType(emailType: string) {
        if (emailType === 'Declined')
            return 'fa fa-ban'
        else if (emailType === 'Selected')
            return 'fa fa-check'
    }

    setBasicGridProperties(){
        this.selectedFilterPrevVal = "";
        this.selectedSortPrevVal = [[0, "asc"]];
        this.selectedPagePrevVal = 0;
        this.declineFilterPrevVal = "";
        this.declineSortPrevVal = [[0, "asc"]];
        this.declinePagePrevVal = 0;
    }

}
