import { SelectionService } from "./../../../../../services/selection/selection.service";
import {
    Component,
    OnInit,
    ChangeDetectorRef,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import {
    SelectionItem,
    CompanySelection
} from "ClientApp/app/models/Selection";
import { updateBinding } from "@angular/core/src/render3/instructions";
import { Toast, ToasterService } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";

@Component({
    selector: "app-selection-company-list",
    templateUrl: "./selection-company-list.component.html",
    styleUrls: ["./selection-company-list.component.css"]
})
export class SelectionCompanyListComponent implements OnInit {
    companies: any = [];
    public conferenceList: any = [];
    private selectRound: any;
    public selectRoundList: any = [];
    private selectRoundCount: number | undefined;
    // Status count variables
    public sSelected: any;
    public sPublic: any;
    public sPrivate: any;
    public nsSelected: any;
    public nsPublic: any;
    public nsPrivate: any;
    public sDeleted: any;
    selectedValue: any;
    state: any;
    newState: any;
    preState: any;
    checkState = false;

    companySelectionModel = new CompanySelection();
    _selectionModel = new SelectionItem();
    _sModel: any;
    selectionModelArr: any = [];
    selectedArr: Array<SelectionItem> = [];
    response: any;
    conferenceId: number = 0;
    Conferences: any = [];
    ConferenceName: any;

    confs: any = [];
    conferenceItem: any;
    _conferenceId: number = 0;
    conferenceDetail: any;
    cInfo: any;
    confID: any;
    dataTable: any;
    NotesModel: any = {
        NoteType_Id: ""
    };
    selectedFilterPrevVal: string = "";
    selectedSortPrevVal: any = [[0, "asc"]];
    public ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        size: "lg"
    };
    @ViewChild("SelectionNotes") myContent: TemplateRef<any>;
    modalReference: any;
    isSubmitted = false;
    noteTypeList: any = [];
    companyNoteList: any = [];
    compnyId: any;
    selectedPageSize:any;
    selectedPagePrevVal: any = 0;
    sSpecialCase: any;
    constructor(
        public selectionService: SelectionService,
        public cService: ConferenceService,
        private toasterService: ToasterService,
        private chRef: ChangeDetectorRef,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.loadConference(ConferenceConfig.CONF_SUMMARY);
    }

    // Get companies list
    loadCompanies(key: number) {
        console.log("loadCompanies " + key);
        return this.selectionService.getCompanies(key).subscribe((data: {}) => {
            console.log("full data",data);
            this.companies = data;
            this.enablePagination();
            
            console.dir(this.companies.Info);
            if (this.companies.Message.Code == "200") {
                this.sSelected = this.companies.SelectedStatus[0].Selected;
                //console.dir(this.companies.SelectedStatus);
                this.sPublic = this.companies.SelectedPublicCount[0].countpublic;
                this.sPrivate = this.companies.SelectedPrivateCount[0].countprivate;
                this.nsSelected = this.companies.NotSelectedStatus[0].NotSelected;
                this.nsPublic = this.companies.NotSelectedPublicCount[0].countpublic;
                this.nsPrivate = this.companies.NotSelectedPrivateCount[0].countprivate;
                this.sDeleted = this.companies.DeletedDetails[0].Deleted;
                this.sSpecialCase = this.companies.SpecialCaseCount[0].specialCaseCount;
            } else {
                this.sSelected = 0;
                this.sPublic = 0;
                this.sPrivate = 0;
                this.nsSelected = 0;
                this.nsPublic = 0;
                this.nsPrivate = 0;
                this.sDeleted = 0;
                this.sSpecialCase=0;
            }
            this.getNoteTypeList();
        });
    }

    enablePagination() {
        var self = this;
        this.chRef.detectChanges();
        const table: any = $("#choose_companies");
        this.dataTable = table.DataTable({
            pagingType: "full_numbers",
            paging: true,
            bDestroy: true,
            pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
            lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
            search: {"search": self.selectedFilterPrevVal},
            "order": self.selectedSortPrevVal,
            'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
            language: {
                search:
                    '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
                searchPlaceholder: "Search for...",
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                }
            },
            stripeClasses: ["odd-row", "even-row"]
        });

        $('#choose_companies').on( 'length.dt', function ( e, settings, len ) {
            self.selectedPageSize = len;
        } );
    }

    getIconByEmailStatus(status: number) {
        if (status === 0)
            return 'fa fa-ban'
        else if (status === 1)
            return 'fa fa-check'
    }
    loadConference(typeId: number) {
        this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
            this.conferenceList = data;
            this.Conferences = this.conferenceList.Info;
            //setTimeout(() => {
            //console.log(this.conferenceList);
            // this.Conferences = this.conferenceList.Info;
            // this.Conferences = _.filter(this.conferenceList.Info, function(obj: any) {
            //   return obj.isActive == 1;
            // });
            this.conferenceItem = this.Conferences[0];
            this.selectedValue = this.conferenceItem;
            //console.log("this.response=" + this.conferenceList.Info[0].ConferenceId);
            this.conferenceId = this.conferenceList.Info[0].ConferenceID;
            this.getConferenceDetails(this.conferenceId);
            //this.loadCompanies(this.conferenceId);
            //}, 500);
        });
    }

    getConferenceDetails(id: number) {
        //console.log(id);
        this._conferenceId = id;
        return this.cService.getConferenceById(id).subscribe((data: {}) => {
            this.conferenceDetail = data;
            this.cInfo = this.conferenceDetail.ConferenceCategory;
            this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
            this.confID = this.conferenceDetail.Conference.ConferenceId;
            this.loadSelectionRound(this.confID);
            //console.log(this.cInfo);
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            this.loadCompanies(this.confID);
        });
    }

    onChangeConference(id: number) {
        //console.log("Inside " + id);
        // if (this.dataTable) {
        //   this.dataTable.destroy();
        // }
        //this.getConferenceDetails(id);
        this.selectedFilterPrevVal = "";
        this.selectedSortPrevVal = [[0, "asc"]];
        this.selectedPagePrevVal = 0;
        this.getConferenceDetails(this.selectedValue.ConferenceID);
    }

    loadSelectionRound(id: number) {
        this.selectionService.getSelectionRound(id).subscribe((data: {}) => {
            this.selectRound = data;
            //setTimeout(() => {
            //console.log(this.selectRound);
            this.selectRoundCount = +this.selectRound.SelectionRound;
            //console.log(this.selectRoundCount);
            var numbers = Array();
            //this.selectRoundCount = 3;
            var sCount = this.selectRoundCount;
            if (sCount > 0) {
                for (let index = 0; index < this.selectRoundCount + 1; index++) {
                    let c = index + 1;
                    numbers.push(c.toString());
                }
            } else {
                numbers.push("1");
            }
            this.selectRoundList = numbers;
            
            //this.loadConference();
        });
    }

    onSelectionRound(event: any) {
        const id = event.target.value;
        //console.log(id);
        this._selectionModel.SelectionRound = +id;
        this.preState = this.newState;
        this.newState = event;
        this.checkState = true;
        // if (this.dataTable) {
        //   this.dataTable.destroy();
        // }
    }

    selectedOption(option: any) {
        return this.newState === option;
    }

    onSaveSelection() {
        for (let idx = 0; idx < this.selectionModelArr.length; idx++) {
            this.selectionModelArr[
                idx
            ].SelectionRound = this._selectionModel.SelectionRound;
        }
        console.log(this.selectionModelArr);
        this.companySelectionModel.ChooseCompany = this.selectionModelArr;
        this.updateSelection(this.companySelectionModel);
    }

    onChange(e: any, id: number, obj: any) {
        console.log(id);
        this._selectionModel.CompId = id;
        if (e.target.checked) {
            this._selectionModel.IsSelected = 1;
            this._selectionModel.ToBeScheduled = 1;
        }
        else {
            this._selectionModel.IsSelected = 0;
            this._selectionModel.ToBeScheduled = 0;
        }
        //this._selectionModel.SelectionRound = ?;
        var item = this._selectionModel;
        this._selectionModel = new SelectionItem();
        var alreadyExist = _.find(this.selectionModelArr, function (obj: any) { return obj.CompId == id });
        if (alreadyExist) {
            var pos = this.selectionModelArr
                .map(function (e: { CompId: number }) {
                    return e.CompId;
                })
                .indexOf(id);
             this.selectionModelArr.splice(pos, 1);
        }
        this.selectionModelArr.push(item);
        //if (e.target.checked) {
        //    var item = this._selectionModel;
        //    this._selectionModel = new SelectionItem();
        //    this.selectionModelArr.push(item);
        //} else {
        //    var pos = this.selectionModelArr
        //        .map(function (e: { CompId: number }) {
        //            return e.CompId;
        //        })
        //        .indexOf(id);
        //    //console.log("pos " + pos);
        //    this.selectionModelArr.splice(pos, 1);
        //}
    }

    updateSelection(companySelection: CompanySelection) {
        console.log(companySelection);
        this.selectionService
            .updateSelectionRound(companySelection)
            .subscribe(result => {
                this.response = result;
                if (this.response.Code == 200) {
                    var toast: Toast = {
                        type: "success",
                        title: this.response.Message,
                        showCloseButton: true
                    };

                    this.toasterService.pop(toast);
                    // Set default option element select box
                    this.checkState = false;
                    this.newState = this.preState;
                    this.selectedFilterPrevVal = this.dataTable.search();
                    this.selectedSortPrevVal = this.dataTable.order();
                    this.selectedPagePrevVal = this.dataTable.page();
                    if (this.dataTable) {
                        this.dataTable.destroy();
                    }
                    this.loadCompanies(this.confID);
                } else {
                    var toast: Toast = {
                        type: "error",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                }
            });
    }

    // Get Not types
    getNoteTypeList() {
        return this.selectionService.getNoteTypeList().subscribe((data: any) => {
            this.noteTypeList = data.Info;
        });
    }

    // Add Notes
    AddNotes(item: any) {
        this.NotesModel = {
            NoteType_Id: "",
            EntityId: 1,
            EntityType: 1
        };
        this.modalReference = this.modalService.open(
            this.myContent,
            this.ngbModalOptions
        );
        this.compnyId = item.CompId;
        this.getNotesByCompany(this.compnyId);
    }

    editNotes(item: any) {
        return this.selectionService.getNoteById(item.Id).subscribe((data: any) => {
            this.NotesModel = data.Info;
        });
    }

    getNotesByCompany(id: any) {
        return this.selectionService
            .getNoteByCompanies(id)
            .subscribe((data: any) => {
                this.companyNoteList = data.Info;
            });
    }

    SaveNotes(form: any) {
        if (form.invalid) {
            this.isSubmitted = true;
            return;
        }
        this.isSubmitted = true;
        this.NotesModel.EntityId = this.compnyId;
        if (this.NotesModel && this.NotesModel.Id > 0) {
            return this.selectionService
                .UpdateNoteByCompany(this.NotesModel)
                .subscribe((data: any) => {
                    this.NotesModel = {
                        NoteType_Id: "",
                        NoteText: ""
                    };
                    var toast: Toast = {
                        type: "success",
                        title: data.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                    this.getNotesByCompany(this.compnyId);
                    this.resetForm(form.form);
                    this.isSubmitted = false;
                    if (this.dataTable) {
                        this.dataTable.destroy();
                    }
                    this.loadCompanies(this.confID);
                });
        } else {
            return this.selectionService
                .saveNoteByCompany(this.NotesModel)
                .subscribe((data: any) => {
                    this.NotesModel = {
                        NoteType_Id: "",
                        NoteText: ""
                    };
                    this.getNotesByCompany(this.compnyId);
                    var toast: Toast = {
                        type: "success",
                        title: data.Message,
                        showCloseButton: true
                    };

                    this.toasterService.pop(toast);
                    this.getNotesByCompany(this.compnyId);
                    this.resetForm(form.form);
                    this.isSubmitted = false;
                    if (this.dataTable) {
                        this.dataTable.destroy();
                    }
                    this.loadCompanies(this.confID);
                });
        }
    }
    resetForm(form: any) {
        form.markAsPristine();
        form.markAsUntouched();
        form.updateValueAndValidity();
    }
    removeNotes(item: any) {
        return this.selectionService
            .RemoveNoteById(item.Id)
            .subscribe((data: any) => {
                this.getNotesByCompany(this.compnyId);
                var toast: Toast = {
                    type: "success",
                    title: data.Message,
                    showCloseButton: true
                };
                this.toasterService.pop(toast);
                if (this.dataTable) {
                    this.dataTable.destroy();
                }
                this.loadCompanies(this.confID);
            });
    }
}
