import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-schedule-confirmation-success",
  templateUrl: "./schedule-confirmation-success.component.html",
  styleUrls: ["./schedule-confirmation-success.component.css"]
})
export class ScheduleConfirmationSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
