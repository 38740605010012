import { Component, OnInit } from "@angular/core";
import { ReportService } from "ClientApp/app/services/report/report.service";
import {
    moveItemInArray,
    transferArrayItem,
    CdkDragDrop
} from "@angular/cdk/drag-drop";
import {
    ReportTemplate,
    ReportTemplateDetails,
    ReportTemplateHeader,
    ReportCriteria
} from "ClientApp/app/models/ReportTemplate";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
@Component({
    selector: "app-report-template-modal",
    templateUrl: "./report-template-modal.component.html",
    styleUrls: ["./report-template-modal.component.css"]
})
export class ReportTemplateModalComponent implements OnInit {
    response: any;
    reportTypes: any;
    reportTypeId: number = 0;

    reportTypeFields: any;
    columns: any;
    items: any;
    criterias = [];
    reportTemplateModel = new ReportTemplate();
    reportCriteriaModel = new ReportCriteria();
    reportHeaderModel = new ReportTemplateHeader();
    reportDetailModel = new ReportTemplateDetails();
    public droppedItems: Array<ReportTemplateDetails> = [];
    dragndropItems: any;
    constructor(
        public reportService: ReportService,
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        public activeModal: NgbActiveModal
    ) { }
    submitted = false;
    criteriaError = false;
    fieldsError = false;
    selectedColumns = [];
    idsList = [{}];
    templateForm!: FormGroup;
    isSubmit: boolean = false;
    ngOnInit() {
        this.loadDefault();

        this.templateForm = this.formBuilder.group({
            reportType: ["", Validators.required],
            templateName: ["", Validators.required],
            criteria: [""],
            sortField: [""],
        });
    }

    get fc() {
        // get field control(fc)
        return this.templateForm.controls;
    }

    loadDefault() {
        // Get report Types
        this.reportService.getReportTypeItems().subscribe((result: {}) => {
            this.response = result;
            if (this.response.Info.length > 0) {
                this.reportTypes = this.response.Info;
                // Get fields for report type Id
                this.reportTypeId = this.response.Info[0].ReportId;
                this.getTypeFields(this.reportTypeId);
            }
        });
    }

    getTypeFields(typeId: number) {
        this.reportService.getReportTypeFields(typeId).subscribe((result: {}) => {
            this.reportTypeFields = result;
            if (this.reportTypeFields.ReportDetailsList.length > 0) {
                this.columns = this.reportTypeFields.ReportDetailsList;
                this.criterias = this.reportTypeFields.CriteriaList;
                //console.log(this.criterias);
            }
        });
    }

    onDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            //this.dragndropItems = event.container.data;
            //console.log("previousContainer== container " + this.dragndropItems);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        if (event.container.id == "selectedColumnList") {
            this.dragndropItems = event.container.data;
        }

        this.fieldsError = false;
        //console.log(" " + this.dragndropItems);
        // if (event.container.data.length > 0) {
        //   var jsonArr = event.container.data;
        //   var rId = "ReportId";
        //   var rfId = "ReportField";
        //   console.log(jsonArr);
        //   for (var i = 0; i < jsonArr.length; i++) {
        //     var obj = jsonArr[i];
        //     //var obj = JSON.parse(jsonArr[i]);
        //     // console.log(obj);
        //     // delete obj.rId;
        //     // delete obj.rfId;
        //   }
        // }
        //console.dir(event.container.data);
        //this.dragndropItems = event.container.data;
        //this.items = this.dragndropItems;
    }

    onChangeType(typeId: number) {
        //console.log("typeId " + typeId);
        this.getTypeFields(typeId);
        this.dragndropItems = [];
        this.selectedColumns = [];
        if (typeId == 1) {
            this.templateForm.controls["criteria"].setValidators([
                Validators.required
            ]);
            this.templateForm.controls["criteria"].updateValueAndValidity();
        }
        else {
            this.templateForm.controls["criteria"].setValidators([]);
            this.templateForm.controls["criteria"].updateValueAndValidity();
            this.criteriaError = false;
        }
    }

    createTemplate(item: ReportTemplate) {
        this.reportService.createReportTemplate(item).subscribe((result: {}) => {
            this.reportTypeFields = result;
            if (this.reportTypeFields.ReportDetailsList.length > 0) {
                this.columns = this.reportTypeFields.ReportDetailsList;
                this.criterias = this.reportTypeFields.CriteriaList;
                //console.log(this.criterias);
            }
        });
    }

    onSubmit() {
        this.submitted = true;
        if (!this.dragndropItems || this.dragndropItems.length == 0) {
            this.fieldsError = true;
            return;
        }
        if (!this.templateForm.value.criteria && this.templateForm.value.reportType == 1) {
            this.criteriaError = true;
            return;
        }
        // stop here if form is invalid
        if (this.templateForm.invalid) {
            //console.log("Invalid");
            return;
        } else {
            // this.selectedColumns = this.items;
            //console.log(this.dragndropItems);
            this.isSubmit = true;
            this.criteriaError = false;
            this.dragndropItems.forEach(
                (item: { ReportDetailsId: number | undefined }) => {
                    this.reportDetailModel.ReportDetailsId = item.ReportDetailsId;
                    this.reportDetailModel.IsSortField = this.templateForm.value.sortField && (this.templateForm.value.sortField == item.ReportDetailsId) ? true : false;
                    //console.log(this.reportDetailModel);
                    this.droppedItems.push(this.reportDetailModel);
                    this.reportDetailModel = new ReportTemplateDetails();
                }
            );
            //console.log(this.droppedItems);
            this.reportTemplateModel.TemplateDetails = this.droppedItems;

            // for (let obj of this.items) {
            //   console.log("object:", obj);
            //   for (let key in obj) {
            //     console.log("      key:", key, "value:", obj[key]);
            //     if (key == "ReportDetailsId") {
            //       var obj1 = { key: obj[key] };
            //       this.idsList.push(obj1);
            //       break;
            //     }
            //   }
            // }
            //var arr = this.dragndropItems;
            // for (var i = 0; i < this.items.length; i++) {
            //   var obj = this.items[i];
            //   var rId = "ReportId";
            //   var rfId = "ReportField";
            //   delete obj[rId];
            //   delete obj[rfId];
            //   console.log(obj);
            //   this.idsList.push(obj);
            // }
            // console.log(this.idsList);
            // console.log(this.dragndropItems);
            // console.log(this.selectedColumns);
            this.reportHeaderModel.TemplateName = this.templateForm.value.templateName;
            this.reportHeaderModel.ReportId = +this.templateForm.value.reportType;
            this.reportTemplateModel.TemplateHeader = this.reportHeaderModel;

            //this.reportTemplateModel.TemplateDetails =
            this.reportCriteriaModel.CriteriaId = +this.templateForm.value.criteria;
            this.reportTemplateModel.Criteria = this.reportCriteriaModel;

            //console.dir(this.reportTemplateModel);
            this.createReportTemplate(this.reportTemplateModel);
        }
    }

    createReportTemplate(templateModel: ReportTemplate) {
        return this.reportService
            .createReportTemplate(templateModel)
            .subscribe(result => {
                // This code will be executed when the HTTP call returns successfully
                this.response = result;
                if (this.response.Code == 200) {
                    var toast: Toast = {
                        type: "success",
                        title: this.response.Message,
                        showCloseButton: true
                    };

                    this.toasterService.pop(toast);
                    this.templateForm.reset();
                    this.activeModal.close()
                } else {
                    var toast: Toast = {
                        type: "error",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.reportTemplateModel.TemplateDetails = [];
                    this.toasterService.pop(toast);
                }
            });
    }
}
