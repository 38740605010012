import { Injectable } from '@angular/core';
import { environment } from 'ClientApp/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EBDConfigurationService {
  apiURL = environment.baseUrl;

  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  getEbdConference() {
    return this.http.get(this.apiURL + "/Conference/GetConferenceListForEBD");
  }

  getAll_EBDconfigurations() {
    return this.http.get(this.apiURL + "/Conference/GetAllEBDConfiguration");
  }

  createEBDconfig(config: any) {
    return this.http.post(
      this.apiURL + "/Conference/AddEBDConfiguration",
      config,
      this.httpOptions
    );
  }

  getEBDConfigurationById(id: any) {
    return this.http.get(this.apiURL + "/Conference/GetEBDConfigurationById/" + id);
  }

  updateEBDconfiguration(data:any){
    return this.http.post(
      this.apiURL + "/Conference/EditEBDConfiguration",
      data,
      this.httpOptions
    );
  }

  deleteEBDconfiguration(id:any){
    return this.http.get(this.apiURL + "/Conference/DeleteEBDConfiguration/" + id);
  }

  checkEBDConfigurationExist(id: any) {
    return this.http.get(this.apiURL + "/Conference/CheckEBDConfigurationExist/" + id);
  }


}
