import { Component, OnInit, Input } from "@angular/core";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ToasterService, Toast } from "angular2-toaster";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Invitation } from "ClientApp/app/models/Invitation";

@Component({
  selector: "app-invitation-preview-modal",
  templateUrl: "./invitation-preview-modal.component.html",
  styleUrls: ["./invitation-preview-modal.component.css"]
})
export class InvitationPreviewModalComponent implements OnInit {
  @Input() invItem: any;
  @Input() invForm: any;
  @Input() Conference: any;
  submitMessage: string | undefined;
  processMessage: string | undefined;
  btnMessage: string | undefined;
  disableBtn: boolean | undefined;
  response: any;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private profileService: ProfileService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    console.log(this.invItem);
    this.submitMessage = ConferenceConfig.INVITATION_BTN_SUBMIT_MESSAGE;
    this.processMessage = ConferenceConfig.BTN_PROCESS_MESSAGE;
    this.btnMessage = this.submitMessage;
  }

  onSubmit() {
    this.createInvitation(this.invItem);
  }

  createInvitation(invitation: Invitation) {
    console.log("In createProfile....");
    this.disableBtn = true;
    return this.profileService
      .createInvitation(invitation)
      .subscribe(result => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };

          this.toasterService.pop(toast);
          this.activeModal.close();
          // Redirect to success page.
          let confId = this.response.EncryptedConferenceId;
          let id = this.response.EncryptedId;
          let type = 4; // For Invitation
          this.router.navigate([
            "invitation/addinvitation/success/" +
              confId +
              "/" +
              type +
              "/" +
              id
          ]);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
        this.disableBtn = false;
      });
  }
}
