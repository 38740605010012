import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, NgForm, Validators } from "@angular/forms";
import { FormsModule } from '@angular/forms';
import { TrackItem, TrackSlotItem } from "ClientApp/app/models/Track";
import { ToasterConfig, ToasterService, Toast } from "angular2-toaster";
import { TrackService } from "ClientApp/app/services/track/track.service";
import { IMyDpOptions } from "mydatepicker";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-track-timeslot-create-edit",
  templateUrl: "./track-timeslot-create-edit.component.html",
  styleUrls: ["./track-timeslot-create-edit.component.css"]
})
export class TrackTimeslotCreateEditComponent implements OnInit {
  @Input() public tID: number = 0;
  @Input() public isEdit: boolean = false;
  @Input() public slotId: number = 0;
  addEditSlotForm!: FormGroup;
  submitted = false;
  response: any;
  tItem: any;
  trackInfo: any;
  isTrackAvailable: boolean = false;
  timeStartDefault: any;
  timeEndDefault: any;
  slots: any;
  slotInfo: any;
  rslots: any;
  isFlag: boolean = false;
  trackDate: Object = "";
  startTime: any;
  endTime: any;
  isCreationOfSuccesionTimes: boolean = false;
  public trackSlotItem = new TrackSlotItem();
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    animation: "fade"
  });
  constructor(
    private formBuilder: FormBuilder,
    public trackService: TrackService,
    private toasterService: ToasterService,
    public activeModal: NgbActiveModal
  ) {}

  public myDatePickerOptions: IMyDpOptions = {
    //dateRange: false,
    dayLabels: { su: "Sun", mo: "Mon", tu: "Tue", we: "Wed", th: "Thu", fr: "Fri", sa: "Sat" },
    monthLabels: { 1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun", 7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec" },
    dateFormat: "mm-dd-yyyy",
    width: "250px",
    todayBtnTxt: "Today",
    firstDayOfWeek: "mo",
    sunHighlight: true,
    // other options...
  };

  ngOnInit() {
    // console.log("tID in form" + this.tID);
    // console.log("isEdit " + this.isEdit);
    // console.log("Slot ID " + this.slotId);
      this.defaultFormSetting();
  }

    defaultFormSetting(): void {
        if (this.slotId > 0) {
            this.isFlag = true;
            this.addEditSlotForm = this.formBuilder.group({
                sDate: ['', Validators.required],
                sStartTime: [""],
                tType: ["1"],
                tReserved: [""]
                // tType: [{ disabled: true }],
                // tReserved: [{ disabled: true }],
                // times: [{ disabled: true }],
                // eTime: [{ disabled: true }],
                // increment: [{ disabled: true }]
            });
            this.getTrackSlotById(this.slotId);
        } else {
            this.addEditSlotForm = this.formBuilder.group({
                sDate: ['', Validators.required],
                sStartTime: [""],
                tType: ["1"],
                tReserved: [""],
                times: [""],
                eTime: [""],
                increment: ["", Validators.required]
            });
            this.timeStartDefault = { hour: 8, minute: 0 };
            this.timeEndDefault = { hour: 10, minute: 0 };
            this.addEditSlotForm.patchValue({
                sStartTime: this.timeStartDefault,
                eTime: this.timeEndDefault
            });
        }
    }

    get fc() {
        // get field control(fc)
        return this.addEditSlotForm.controls;
    }

  getTrackSlotById(slotId: number) {
    this.trackService.getTrackSlotById(slotId).subscribe(result => {
      this.rslots = result;
      //console.log(this.rslots);
      this.slotInfo = this.rslots.Info;
      this.slots = this.slotInfo;
      var startDate = new Date(this.slotInfo.DateTime);
      var dateValue = {date: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate()
      }};
      
      var d = new Date(this.slotInfo.StartTime);
      this.timeStartDefault = { hour: d.getHours(), minute: d.getMinutes() };
      this.addEditSlotForm.patchValue({
        sDate: dateValue,
        sStartTime: this.timeStartDefault,
        tType:  this.slotInfo.TimeslotType.toString(),
         tReserved: this.slotInfo.IsReserved
      });
    });
  }

  formatAMPM(date: any) {
    var hours = date.hour;
    var minutes = date.minute;
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ampm; //+ " " +
    return strTime;
  }

  save(form: FormGroup) {
      this.submitted = true;

      if (this.addEditSlotForm.invalid) {
        if (!this.isCreationOfSuccesionTimes && this.fc.increment.errors && !this.fc.sDate.errors)
        {
        }else{
          return;
        }
      }
    //console.log(form.value);
    var item = form.value;

    if (!this.isFlag) {
      this.trackSlotItem.Track_Id = this.tID; // getting 0
      this.trackSlotItem.Count = +item.increment;
      this.trackSlotItem.Private = +item.tType;
      this.trackSlotItem.DateTime = item.sDate.formatted;
      this.trackSlotItem.StartTime = this.formatAMPM(item.sStartTime); //item.sStartTime;
      this.trackSlotItem.EndTime = this.isCreationOfSuccesionTimes ? this.formatAMPM(item.eTime) : undefined; //item.eTime;
      this.trackSlotItem.IsReserved = item.tReserved ? 1 : 0;
      this.createTrackSlot(this.trackSlotItem);
    } else {
      this.trackSlotItem.Id = this.slotInfo.Id;
      this.trackSlotItem.Track_Id = this.tID;
      this.trackSlotItem.Private = +item.tType;
      var date = new Date(item.sDate.date.year, item.sDate.date.month-1, item.sDate.date.day);
      var dateSplit = date.toLocaleDateString().split('/');
      this.trackSlotItem.DateTime = dateSplit[0]+"-"+dateSplit[1]+"-"+dateSplit[2];
      this.trackSlotItem.StartTime = this.formatAMPM(item.sStartTime); //item.sStartTime;
      this.trackSlotItem.IsReserved = item.tReserved ? 1 : 0;
      //let sTime = this.slotInfo.StartTime;

      // this.trackSlotItem.StartTime = this.formatAMPM(item.sStartTime);
      // var dTime = new Date(this.slotInfo.DateTime);
      // var month = dTime.getMonth() + 1;
      // this.trackSlotItem.DateTime =
      //   month + "-" + dTime.getDate() + "-" + dTime.getFullYear();
      // this.trackSlotItem.Private = this.slotInfo.slotType === "Private" ? 1 : 0;
      // this.trackSlotItem.IsReserved = item.tReserved;
      this.updateTrackSlot(this.trackSlotItem);
    }
    //console.log(this.trackSlotItem);

    // if (this.isTrackAvailable) {
    //   this.trackItem.Id = +this.trackId;
    //   console.log(this.trackItem);
    //   this.updateTrack(this.trackItem);
    // } else {
    //   console.log(this.trackItem);
    //   this.createTrack(this.trackItem);
    // }
  }

  createTrackSlot(item: TrackSlotItem) {
    return this.trackService.createTrackSlot(item).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.activeModal.close();
        //this.addEditSlotForm.reset();
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }

  updateTrackSlot(item: TrackSlotItem) {
    return this.trackService.updateTrackSlot(item).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.activeModal.close();
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
    });
  }

    reset() {
        this.submitted = false;
        this.defaultFormSetting();
  }
}
