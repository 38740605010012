import { take } from "rxjs/operators";
import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { MatDialog } from "@angular/material";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddAssetComponent } from "../../components/investor-summary/assets/add-asset/add-asset.component";
import { AssetAddRowService } from "ClientApp/app/services/asset-add-row.service";

// Service
import { SummaryService } from "./../../../../../services/investor/summary.service";
import { environment } from "../../../../../../environments/environment";
// Model
import {
  Summary,
  PresenterDetails,
  CorporateDetails,
  CompanyDetails,
  InvestorAssets,
  InvestorTherapeuticFocus,
  AssistantDetails,
  ValidationModel,
  PresentationDetailModel,
  CompanyDetailModel,
  State,
  Country,
  TFocus,
} from "./../../../../../models/Summary";

import { ToasterService, ToasterConfig, Toast } from "angular2-toaster";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import {
  ConferenceDetailModel,
  KeyValueModel,
} from "ClientApp/app/models/Conference";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "ClientApp/app/services/common/util.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { SummaryPreviewModalComponent } from "../../components/investor-summary/summary-preview-modal/summary-preview-modal.component";
import { EditAssetComponent } from "../../components/investor-summary/assets/edit-asset/edit-asset.component";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import * as _ from "underscore";
declare let gtag: Function;

@Component({
  selector: "app-investor-summary",
  templateUrl: "./investor-summary.component.html",
  styleUrls: ["./investor-summary.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class InvestorSummaryComponent implements OnInit {
  // summaryForm!: FormGroup;
  isDraggable: boolean = true;
  isParticipationMethodEnabled: boolean = false;
  participationError: boolean = true;
  summaryForm: FormGroup;
  submitted = false;
  disableBtn = false;
  apiURL = environment.baseUrl;
  summaryModel = new Summary();
  presenterDetails = new PresenterDetails();
  corporateDetails = new CorporateDetails();
  companyDetails = new CompanyDetails();
  investorAssets = new InvestorAssets();
  invetsorTherapeuticFocus = new InvestorTherapeuticFocus();
  assistantDetails = new AssistantDetails();
  conferenceModel = new ConferenceDetailModel();
  public assets: Array<InvestorAssets> = [];
  public droppedItems: Array<InvestorTherapeuticFocus> = [];
  public isEBDConf: boolean;
  public isAssetsShow: boolean;
  public rows: Array<{
    name: string | undefined;
    indication: string | undefined;
    status: any | undefined;
    milestone: string | undefined;
  }> = [];
  public assetrows: Array<{
    name: string | undefined;
    indication: string | undefined;
    status: any | undefined;
    milestone: string | undefined;
  }> = [];
  private addAssetComponent = AddAssetComponent;
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: any;
  public dragndropItems: Array<any> = [];
  public completed: Array<any> = [];
  public response: any;
  private ownershipRadio: string = "";
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    animation: "fade",
  });
  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  countryResponse: any;
  stateResponse: any;
  public countries: any = [];
  public states: any = [];
  public showFlag: boolean = false;
  public showBtn: boolean = false;
  public fixedDiv: boolean = false;
  therapeuticFocusList: any;
  controlErrors: any;
  validationError: boolean = false;
  selectedValue: any;
  stockExchanges: any = [];
  pClassifications: any = [];
  pClassifications1: any = [];
  sClassifications: any = [];
  sClassifications1: any = [];
  selectedpsExchange: any;
  selectedPC: any;
  selectedSC: any;
  selectedPCName: any;
  selectedSCName: any;
  selectedDevlopStatus: any;
  assetDevelopmentStatus: any = [];
  //selectedDevelopStatus: any;
  selectedFund: any;
  fundPlans: any = [];
  public assetList: Array<InvestorAssets> = [];
  banner: string = "";
  defaultItems: any;
  vModel = new ValidationModel();
  presenterModel = new PresentationDetailModel();
  companyModel = new CompanyDetailModel();
  valid: any;
  result: any;

  seachText: any;
  isSearchSubmitted: boolean = false;
  isErrpr: boolean = false;
  state = new State();
  country = new Country();
  countryCode: any;
  public tFocusList: Array<TFocus> = [];
  tFocus = new TFocus();
  Summary: any = {};
  public ps1: any;
  public ps: any;
  searchTextResponse: any;
  isShowAdvanceSearch: boolean = false;
  searchToolDetail: any;
  TypeId: any;
  conferenceIsActive: any;
  vData: any;
  loginuser: any;
  pClassification: any;
  sClassification: any;
  companyId: any;

  //invalidStatus: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private addRowService: AssetAddRowService,
    public summaryService: SummaryService,
    private toasterService: ToasterService,
    public cService: ConferenceService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private element: ElementRef,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router
  ) {
    // TODO: Remove once API is ready.
    this.pClassifications = [
      {
        Id: "0",
        Description: "Biotechnology",
      },
      {
        Id: "1",
        Description: "Digital Medicine",
      },
      {
        Id: "2",
        Description: "Pharmaceutical",
      },
      {
        Id: "3",
        Description: "Medtech",
      },
    ];

    this.pClassifications1 = [
      {
        Id: "1",
        Description: "Digital Medicine",
      },
      {
        Id: "3",
        Description: "Medtech",
      },
    ];

    this.sClassifications = [
      {
        Id: "0",
        Description: "Biotechnology",
      },
      {
        Id: "1",
        Description: "Digital Medicine", // China, Digital Medicine
      },
      {
        Id: "2",
        Description: "Pharmaceutical",
      },
      {
        Id: "3",
        Description: "Medtech", // China, Digital Medicine
      },
    ];

    this.sClassifications1 = [
      {
        Id: "1",
        Description: "Digital Medicine", // China, Digital Medicine
      },
      {
        Id: "3",
        Description: "Medtech", // China, Digital Medicine
      },
    ];

    this.fundPlans = [
      {
        Id: "0",
        Description: "Plan to raise money in the next 12 months",
      },
      {
        Id: "1",
        Description: "Plan to raise money in the next 18 months",
      },
      {
        Id: "2",
        Description: "Plan to raise money in the next 24 months",
      },
      {
        Id: "3",
        Description: "Plan to update investors on status or milestones",
      },
      {
        Id: "4",
        Description: "Not disclosed",
      },
    ];

    //this.stockExchanges = [
    //    {
    //        Id: "0",
    //        Description: "Alternative Investment Market (AIM)"
    //    },
    //    {
    //        Id: "1",
    //        Description: "Australian Securities Exchange (ASX)"
    //    },
    //    {
    //        Id: "2",
    //        Description: "Euronext"
    //    },
    //    {
    //        Id: "3",
    //        Description: "Frankfurt Stock Exchange"
    //    },
    //    {
    //        Id: "4",
    //        Description: "Hong Kong Stock Exchange (HKEX)"
    //    },
    //    {
    //        Id: "5",
    //        Description: "Korea Exchange (KRX)"
    //    },
    //    {
    //        Id: "6",
    //        Description: "London Stock Exchange (LSE)"
    //    },
    //    {
    //        Id: "7",
    //        Description: "Madrid Stock Exchange"
    //    },
    //    {
    //        Id: "8",
    //        Description: "Milan Stock Exchange"
    //    },
    //    {
    //        Id: "9",
    //        Description: "NASDAQ"
    //    },
    //    {
    //        Id: "10",
    //        Description: "New York Stock Exchange (NYSE)"
    //    },
    //    {
    //        Id: "11",
    //        Description: "Copenhagen Stock Exchange (CSE)"
    //    },
    //    {
    //        Id: "12",
    //        Description: "Helsinki Stock Exchange (OMXH)"
    //    },
    //    {
    //        Id: "13",
    //        Description: "Stockholm Stock Exchange (OMX)"
    //    },
    //    {
    //        Id: "14",
    //        Description: "OTC Bulletin Board (OTCBB)"
    //    },
    //    {
    //        Id: "15",
    //        Description: "Shanghai Stock Exchange (SSE)"
    //    },
    //    {
    //        Id: "16",
    //        Description: "Swiss Exchange (SIX)"
    //    },
    //    {
    //        Id: "17",
    //        Description: "Tel Aviv Stock Exchange (TASE)"
    //    },
    //    {
    //        Id: "18",
    //        Description: "Tokyo Stock Exchange (TSE)"
    //    },
    //    {
    //        Id: "19",
    //        Description: "Toronto Stock Exchange (TSX)"
    //    },
    //    {
    //        Id: "20",
    //        Description: "Vienna Stock Exchange (WBAG)"
    //    },
    //    {
    //        Id: "22",
    //        Description: "OTC Bulletin Board (OTCQX)"
    //    },
    //    {
    //        Id: "21",
    //        Description: "Other"
    //    }
    //];

    this.assetDevelopmentStatus = [
      {
        Id: "0",
        Description: "Pre-Clinical",
      },
      {
        Id: "1",
        Description: "Phase I",
      },
      {
        Id: "2",
        Description: "Phase II",
      },
      {
        Id: "3",
        Description: "Phase III",
      },
    ];

    this.selectedPC = "";
    this.selectedPCName = "";
    this.selectedSC = "";
    this.selectedSCName = "";
    this.pClassification = "";
    this.sClassification = "";
    //this.selectedDevelopStatus = "null";
    //this.selectedPC = "null";
    //this.default = "null";
    // this.summaryForm = this.formBuilder.group({
    //   pClassification: [this.pClassifications[0]]
    // });
    //this.summaryForm.controls['pClassification'].setValue(this.default, {onlySelf: true});
    // this.selectedFund = "null";
    // this.selectedpsExchange = "null";
  } // , public dialog: MatDialog

  ngOnInit() {
    this.rows = [];
    const cId = this.route.snapshot.paramMap.get("confId");
    this._conferenceId = cId !== null ? cId : 0;
    this.companyId = this.route.snapshot.paramMap.get("compId") || 0;
    this.loginuser = localStorage.getItem("currentUser");
    this.summaryForm = this.formBuilder.group({
      //Company Name
      conferenceId: [""],
      companyName: ["", Validators.required],

      // Presenter Details
      psalutation: ["", Validators.required],
      pfirstName: ["", Validators.required],
      pnickName: [""],
      pmiddleName: [""],
      plastName: ["", Validators.required],
      psuffix: [""],
      pgeneration: [""],
      pjobTitle: ["", Validators.required],
      pcontactEmail: ["", [Validators.required, Validators.email]],
      pcontactPhone: ["", Validators.required],
      pTwitterProfile: [""],
      pLinkedInProfile: [""],
      // Corporate Communications Details
      ccsalutation: [""],
      ccfirstName: [""],
      cclastName: [""],
      cccontactEmail: [""],
      cccontactPhone: [""],

      // Assistant Communications Details
      asalutation: [""],
      afirstName: [""],
      alastName: [""],
      acontactEmail: [""],
      acontactPhone: [""],

      // Company Details
      ownership: ["", Validators.required],
      ipoYear: [""],
      goPublic: [""],
      stcother: [""],
      // psExchange: [""],
      // tickerSymbol: [""],
      psExchange: [null, Validators.required],
      tickerSymbol: ["", Validators.required],
      country: ["", Validators.required],
      streetAddress: ["", Validators.required],
      city: ["", Validators.required],
      state: [""],
      zip: ["", Validators.required],

      yearEst: ["", Validators.required],
      website: ["", Validators.required],
      nEmployees: ["", Validators.required],
      //companySummary: ["", Validators.maxLength(100)],
      // companySummary: ["", [Validators.required, wordCountValidator(100)]],

      investorProposition: ["", [Validators.required, wordCountValidator(75)]],
      bioFutureDescription : ["", [Validators.required, wordCountValidator(75)] ],
      pClassification: [null, Validators.required],
      sClassification: [null],
      technologyTargetProduct: [
        "",
        [Validators.required, wordCountValidator(50)],
      ],
      developmentProgram: ["", [Validators.required, wordCountValidator(20)]],

      //Assets

      fundPlans: [null, Validators.required],
      majorInvestor: ["", wordCountValidator(30)],
      majorInvestorDropDown: [null, Validators.required],
      corporatePartner: ["", [Validators.required, wordCountValidator(30)]],
      isTechBioCompany : [""],
      fundRaised: ["", [Validators.required, wordCountValidator(20)]],
      isFundRaisingMode: ["", Validators.required],
      additionalCommment: ["", wordCountValidator(100)],
      enablearticipationMethod: [false],
      participationMethod: ["", Validators.required],
      // if 'enable participation method' is true in conferance type then enable participation method
      confFeedBack: [""],
    });
    this.getConferenceDetails(this._conferenceId);
    this.getStockExchanges();
    this.addRowService.updateTableRow(this.rows);
    //this.loadConference();

    this.addRowService.addToRow.subscribe((rowItem) => (this.rows = rowItem));
    console.log("rows : " + this.rows);
    //this.getCountries();

    // console.log('this.companyModel.ConferenceID--',this.conferenceDetail);
  }

  // checkInvesterProposition(){
  //     // console.log('investorProposition condition');
  //     // console.log('this.companyModel.conferenceDetail--',this.conferenceDetail.Conference.TypeId);
  //     if(this.conferenceDetail.Conference.TypeId === 8) {
  //         this.setInvesterPropositionValidation()
  //         return false
  //     }
  //     else if(this.conferenceDetail.Conference.TypeId === 13) {
  //         this.setInvesterPropositionValidation()

  //         return false
  //     }
  //     else if(this.conferenceDetail.Conference.TypeId === 11) {
  //         this.setInvesterPropositionValidation()

  //         return false
  //     }
  //     else if(this.conferenceDetail.Conference.TypeId === 12) {
  //         this.setInvesterPropositionValidation()

  //         return false
  //     }else return true
  // }

  // setInvesterPropositionValidation(){
  //     // console.log('setInvesterPropositionValidation');
  //     this.summaryForm.controls["investorProposition"].setValidators([]);
  //     this.summaryForm.controls["investorProposition"].updateValueAndValidity();

  // }

  compareAssetDevelopFn(
    optionOne: KeyValueModel,
    optionTwo: KeyValueModel
  ): boolean {
    return optionOne.Id === optionTwo.Id;
  }

  getStockExchanges() {
    this.summaryService.getStockExchanges().subscribe((data: {}) => {
      this.response = data;
      this.stockExchanges = this.response.Info;
    });
  }

  onChangeFundStatus(obj: any) {
    // this.selectedFund = obj;
    var isExist = _.find(this.fundPlans, function (item: any) {
      return item.Id == obj;
    });
    if (isExist) this.selectedFund = isExist;
    else this.selectedFund = obj;
  }
  // onChangeDevelopStatus(obj: any) {
  //   this.selectedDevelopStatus = obj;
  //   //console.log(this.selectedDevelopStatus);
  // }

  onChangePC(obj: any) {
    console.dir(obj);
    var isExist = this.pClassifications.filter(
      (x: any) => x.Id == (obj as any)
    );
    console.dir(isExist);
    if (isExist) {
      this.selectedPC = isExist[0].Id;
      this.selectedPCName = isExist[0].Description;
    } else {
      this.selectedPC = obj;
      this.selectedPCName = "";
    }
    console.log(this.selectedPC);
    console.log(this.selectedPCName);
  }

  onChangeSC(obj: any) {
    var isExist = this.sClassifications.filter(
      (x: any) => x.Id == (obj as any)
    );
    if (isExist) {
      this.selectedSC = isExist[0].Id;
      this.selectedSCName = isExist[0].Description;
    } else {
      this.selectedSC = obj;
      this.selectedSCName = "";
    }
  }

  onChangeStockExchange(obj: any) {
    //this.selectedpsExchange = obj;
    this.selectedpsExchange = _.find(this.stockExchanges, function (item) {
      return item.id == obj.id;
    });
    if (
      this.selectedpsExchange.id !== 21 ||
      this.selectedpsExchange.stockExchange !== "Other"
    ) {
      this.summaryForm.controls["stcother"].setValue("");
    }
    console.log(this.selectedpsExchange);
    console.log(this.summaryForm.value.stcother);
  }

  // onChanged(event: any) {
  //   console.log("event " + event);
  //   this.wordCount = event.count;
  //   this.totalCount = event.totalCount;
  // }
  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.summaryForm.controls;
  }

  getTherapeuticFocus() {
    return this.summaryService.getTherapeuticFocus().subscribe((data: {}) => {
      this.response = data;
      console.log("resp :: ", this.response);

      this.therapeuticFocusList = this.response.Info;
      this.defaultItems = this.therapeuticFocusList;
      console.dir(this.therapeuticFocusList);
    });
  }

  getCountries() {
    return this.utilService.loadCountries().subscribe((data: {}) => {
      this.countryResponse = data;
      this.countries = this.countryResponse.Info;
      console.dir(this.countries);
      this.getTherapeuticFocus();
      if (this.companyId) {
        this.gotoConforanceDetailForm(this.companyId);
      }
    });
  }

  getStates(countryCode: string) {
    return this.utilService.loadStates(countryCode).subscribe((data: {}) => {
      this.stateResponse = data;
      console.dir(this.stateResponse);
      this.states = this.stateResponse.Info;
    });
  }

  onCountryChange(countryObject: any) {
    // console.log(countryObject);
    // if (countryObject != null) {
    //   this.getStates(countryObject);

    // }
    console.log(countryObject);
    if (countryObject != null) {
      this.getStates(countryObject.Code);
      this.getStates(countryObject);
    }
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
      this.getCountries();
    });
  }

  getConferenceDetails(id: number) {
    console.log(id);
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.isParticipationMethodEnabled =
        this.conferenceDetail.Conference.EnableParticipantMethod;
      this.TypeId = this.conferenceDetail.Conference.TypeId;
      console.log("this.conferenceDetail", this.conferenceDetail);
      this.isEBDConf = this.conferenceDetail.Conference.isEBD;
      this.isAssetsShow = this.conferenceDetail.Conference.IsAssetsShow;

      // dynamic validation
      // if (this.conferenceDetail.Conference.TypeId === 12) {
      //   const yearEst = this.summaryForm.get('yearEst');
      //   if (yearEst !== null) {
      //     yearEst.setValidators([Validators.required]);
      //     yearEst.updateValueAndValidity();
      //   }
      // }

      // this.conferenceIsActive = this.conferenceDetail.Conference.isActive;
      // console.log(this.conferenceIsActive);
      // if (this.conferenceIsActive == 0) {
      //   this.router.navigate(["/conference/status/" + id]);
      // }
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;

      if (
        [8, 11, 12].indexOf(Number(this.conferenceDetail.Conference.TypeId)) !==
        -1
      ) {
        gtag("config", "UA-73328446-12", {
          linker: {
            domains: ["knect365.com", "demy-colton.com"],
          },
          page_title:
            "Investor Summary Profile - " +
            this.conferenceDetail.Conference.ConferenceName,
          page_path: this.router.url,
        });

        gtag("event", "ISP Form Open", {
          event_category: this.ConferenceName + " ISP Form",
          event_label: "Investor Summary Profile",
          event_action: "open",
        });
      }

      if (
        Number(this.conferenceDetail.Conference.TypeId) === Number(10) ||
        Number(this.conferenceDetail.Conference.TypeId) === Number(11) ||
        Number(this.conferenceDetail.Conference.TypeId) === Number(17)
      ) {
        this.pClassifications = this.pClassifications1.slice(0);
        this.sClassifications = this.sClassifications1.slice(0);
      }
      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 1) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
    });
  }

  // Get summaries list
  // loadConference() {
  //   return this.cService.getConference().subscribe((data: {}) => {
  //     this.confs = data;
  //     this.Conferences = this.confs.Info;
  //     this.conferenceItem = this.Conferences[0];
  //     //this._conferenceId = this.conferenceItem.conferenceId;
  //     //this.conferenceModel.conferenceId = this._conferenceId;
  //     // console.dir(this.conferenceItem);
  //     console.log(this.conferenceItem.ConferenceId);
  //     this.getConferenceDetails(this.conferenceItem.ConferenceId);
  //     // console.dir(this._conferenceId);
  //     setTimeout(() => {
  //       this.summaryForm.patchValue({
  //         conferenceId: this.conferenceItem
  //       });
  //     }, 500);
  //   });
  // }

  //     if (
  //       Number(this.conferenceDetail.Conference.TypeId) === Number(13) ||
  //       Number(this.conferenceDetail.Conference.TypeId) === Number(11)
  //     ) {
  //       this.pClassifications = this.pClassifications1.slice(0);
  //       this.sClassifications = this.sClassifications1.slice(0);
  //     }

  //     if (this.cInfo.length > 0) {
  //       this.cInfo.forEach((item: any) => {
  //         if (item.CTypeId == 1) {
  //           this.bannerContent = item.BannerContents; // Conference Description
  //         }
  //       });
  //       this.getCountries();
  //     }
  //   });
  // }

  onConferenceChange(val: any) {
    console.log(val);
    this.getConferenceDetails(val);
  }

  setradio(e: string): void {
    this.ownershipRadio = e;
    console.log(e);
    if (e !== "Private") {
      console.log(e);
      this.summaryForm.controls["psExchange"].setValidators([
        Validators.required,
      ]);
      this.summaryForm.controls["tickerSymbol"].setValidators([
        Validators.required,
      ]);
    } else {
      console.log(e);
      this.summaryForm.controls["psExchange"].setValidators([]);
      this.summaryForm.controls["tickerSymbol"].setValidators([]);
    }
    this.summaryForm.controls["psExchange"].updateValueAndValidity();
    this.summaryForm.controls["tickerSymbol"].updateValueAndValidity();
  }
  isSelected(name: string): boolean {
    if (!this.ownershipRadio) {
      // if no radio button is selected, always return false so nothing is shown
      return false;
    }
    return this.ownershipRadio === name; // if current radio button is selected, return true, else return false
  }

  popToast() {
    var toast: Toast = {
      type: "info",
      title: "Here is a Toast Title",
      body: "Here is a Toast Body",
      showCloseButton: true,
    };

    this.toasterService.pop(toast);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    console.log("container " + event.container);
    console.dir(event.container);
    console.log("previousContainer " + event.previousContainer);
    console.dir(event.previousContainer);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data, //this.defaultItems, //event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      //this.completed = event.container.data;
    } else {
      transferArrayItem(
        event.previousContainer.data, //this.defaultItems, //event.previousContainer.data,
        event.container.data, //this.completed, //event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      // this.completed.forEach((item, i) => {
      //   item.position = i;
      // });
      //this.resetList();
    }
    console.dir(event.container.data);
    console.dir(event.previousContainer.data);
    this.isDraggable = this.completed.length <= 3;
    this.dragndropItems = this.completed; //event.container.data;
    //console.dir(this.completed);
    //console.dir(this.dragndropItems);
  }

  // Reset list method used
  // resetList() {
  //   this.defaultItems = [];
  //   setTimeout(() => {
  //     this.defaultItems = this.therapeuticFocusList.slice();
  //   }, 0);
  // }

  openAssetFormModal() {
    const modalRef = this.modalService.open(AddAssetComponent, {
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.rowObj = this.rows;
    modalRef.componentInstance.update = false;
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log("rows " + this.rows);
        console.log(error);
      });
  }

  openEditFormModal(typeId: number) {
    const modalRef = this.modalService.open(EditAssetComponent, {
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.rowObj = this.rows;
    modalRef.componentInstance.update = true;
    modalRef.componentInstance.pos = typeId;
    var selectedItem = this.rows[typeId];
    console.dir(selectedItem);
    if (selectedItem) {
      modalRef.componentInstance.itemObj = selectedItem;
    }
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Get summaries list
  // createSummaries(summary: Summary) {
  //   console.log("In createSummaries....");
  //   this.disableBtn = true;
  //   return this.summaryService.createSummary(summary).subscribe(result => {
  //     // This code will be executed when the HTTP call returns successfully
  //     this.response = result;
  //     if (this.response.Code == 200) {
  //       var toast: Toast = {
  //         type: "success",
  //         title: this.response.Message,
  //         showCloseButton: true
  //       };

  //       this.toasterService.pop(toast);
  //       this.summaryForm.reset();
  //     } else {
  //       var toast: Toast = {
  //         type: "error",
  //         title: this.response.Message,
  //         showCloseButton: true
  //       };
  //       this.toasterService.pop(toast);
  //     }
  //     this.disableBtn = false;
  //   });
  // }

  onSubmit() {
    console.log(this.summaryForm);
    console.log(
      "EnableParticipantMethod",
      this.conferenceDetail.Conference.EnableParticipantMethod,
      this.summaryForm.value.participationMethod
    );
    if(!(this.TypeId === 18 
      //||  this.TypeId === 12
    )){
      this.summaryForm.controls['bioFutureDescription'].setErrors(null)
    }

    
    this.submitted = true;
    this.validationError = this.summaryForm.invalid;
    // stop here if form is invalid
    console.log(this.summaryForm);
    console.log("validationError " + this.validationError);
    //this.openPreviewModal();
    console.log("Completed length " + this.completed.length);
    if (this.completed.length == 0) {
      this.validationError = true;

      this.utilService.showValidationDiv();
      // let el = this.element.nativeElement.querySelector("validationErrors");
      // el.scrollIntoView();
      return;
    }

    if (!this.conferenceDetail.Conference.EnableParticipantMethod) {
      this.summaryForm.controls["participationMethod"].setValidators(null);
      this.summaryForm.controls["participationMethod"].updateValueAndValidity();
    }

    if (this.conferenceDetail.Conference.TypeId != 18) {
      this.summaryForm.controls["isFundRaisingMode"].setValidators(null);
      this.summaryForm.controls["majorInvestorDropDown"].setValidators(null);
      this.summaryForm.controls["isFundRaisingMode"].updateValueAndValidity();
      this.summaryForm.controls[
        "majorInvestorDropDown"
      ].updateValueAndValidity();
    } else {
      this.summaryForm.controls["fundPlans"].setValidators(null);
      this.summaryForm.controls["majorInvestor"].setValidators(null);
      this.summaryForm.controls["participationMethod"].setValidators(null);
      this.summaryForm.controls["fundPlans"].updateValueAndValidity();
      this.summaryForm.controls["majorInvestor"].updateValueAndValidity();
      this.summaryForm.controls["participationMethod"].updateValueAndValidity();
    }
    if (this.completed.length > 3) {
      this.isDraggable = false;
      this.validationError = true;
      this.utilService.showValidationDiv();
      return;
    }

    if (this.summaryForm.invalid) {
      this.utilService.showValidationDiv();

      // END

      //if (this.validationError) {
      //let el = this.element.nativeElement.querySelector("validationErrors");
      //el.scrollIntoView({ behavior: "smooth" });
      //}

      // Object.keys(this.summaryForm.controls).forEach(key => {
      //   if (this.summaryForm.get(key) != null) {
      //     this.controlErrors = this.summaryForm.get(key)!.errors;
      //     console.log(this.controlErrors);

      //     if (this.controlErrors != null) {
      //       Object.keys(this.controlErrors).forEach(keyError => {
      //         console.log(
      //           "Key control: " +
      //             key +
      //             ", keyError: " +
      //             keyError +
      //             ", err value: ",
      //           this.controlErrors[keyError]
      //         );
      //       });
      //     }
      //   }
      // });
      return;
    } else {
      this.validationError = false;

      // PresenterDetails Set Model Properties
      console.log(this.summaryForm.value);
      this.presenterDetails.Prefix = this.summaryForm.value.psalutation;
      this.presenterDetails.FirstName = this.summaryForm.value.pfirstName;
      this.presenterDetails.NickLastName = this.summaryForm.value.pnickName;
      this.presenterDetails.MiddleName = this.summaryForm.value.pmiddleName;
      this.presenterDetails.LastName = this.summaryForm.value.plastName;
      this.presenterDetails.Suffix = this.summaryForm.value.psuffix;
      this.presenterDetails.Generation = this.summaryForm.value.pgeneration;
      this.presenterDetails.Title = this.summaryForm.value.pjobTitle;
      this.presenterDetails.Email = this.summaryForm.value.pcontactEmail;
      this.presenterDetails.Phone = this.summaryForm.value.pcontactPhone;
      this.presenterDetails.TwitterProfile =
        this.summaryForm.value.pTwitterProfile;
      this.presenterDetails.LinkedInProfile =
        this.summaryForm.value.pLinkedInProfile;

      this.summaryModel.presenterDetails = this.presenterDetails;
      this.summaryModel.ConferenceFeedBack =
        this.summaryForm.value.confFeedBack;

      // CorporateDetails Set Model Properties
      this.corporateDetails.IsCorpComm = 0; // Logic to be done in backend.
      this.corporateDetails.Prefix = this.summaryForm.value.ccsalutation;
      this.corporateDetails.FirstName = this.summaryForm.value.ccfirstName;
      this.corporateDetails.LastName = this.summaryForm.value.cclastName;
      this.corporateDetails.PhoneNumber = this.summaryForm.value.cccontactPhone;
      this.corporateDetails.Email = this.summaryForm.value.cccontactEmail;
      this.corporateDetails.Generation = "";
      this.corporateDetails.IsPresenter = 0; // Logic to be done in backend.
      this.corporateDetails.IsPrimary = 0; // Logic to be done in backend.
      this.corporateDetails.RegId = 0; // Logic to be done in backend.

      this.summaryModel.corporateDetails = this.corporateDetails;

      // CompanyDetails Set Model Properties
      this.companyDetails.ToBeScheduled = 0;
      this.companyDetails.SelectionRound = 0;
      this.companyDetails.Confirmed = 0;
      this.companyDetails.Scheduled = 0;
      this.companyDetails.CompanyName = this.summaryForm.value.companyName;
      this.companyDetails.InvestorProposition =
        this.summaryForm.value.investorProposition || "";

        this.companyDetails.BioFutureDescription =
        this.summaryForm.value.bioFutureDescription || "";

        this.companyDetails.IsTechBioCompany = 
        this.summaryForm.value.isTechBioCompany || "";

      this.companyDetails.Address1 = this.summaryForm.value.streetAddress;
      this.companyDetails.City = this.summaryForm.value.city;
      // this.companyDetails.State = this.summaryForm.value.state.Name;
      // this.companyDetails.StateCode = this.summaryForm.value.state.Code;
      // this.companyDetails.Country = this.summaryForm.value.country.Name;
      // this.companyDetails.CountryCode = this.summaryForm.value.country.Code;
      this.companyDetails.Ownership = this.summaryForm.value.ownership;
      this.companyDetails.IPOYear = this.summaryForm.value.ipoYear;
      this.companyDetails.Exchange =
        this.summaryForm.value.psExchange != null
          ? this.summaryForm.value.psExchange.id
          : "";
      this.companyDetails.Ticker = this.summaryForm.value.tickerSymbol;
      this.companyDetails.Zip = this.summaryForm.value.zip;
      this.companyDetails.PublicBeforeConference = "No";
      this.companyDetails.IndustrySector = ""; // TO DO field
      this.companyDetails.FinancingAmount = 45; // TO DO field
      this.companyDetails.ConferenceID = this._conferenceId;
      this.companyDetails.YearEstablished = this.summaryForm.value.yearEst;
      this.companyDetails.Website = this.summaryForm.value.website;
      this.companyDetails.Employees = this.summaryForm.value.nEmployees;
      this.companyDetails.CompanySummary =
        this.summaryForm.value.companySummary;
      if (this.pClassification) {
        this.selectedPC = this.pClassification;
      }
      this.companyDetails.ClassificationPrimary =
        this.selectedPC != null ? this.selectedPC : ""; // this.selectedPC != null ? this.selectedPC.Id : "";
      if (this.sClassification) {
        this.selectedSC = this.sClassification;
      }
      this.companyDetails.ClassificationSecondary =
        this.selectedSC != null ? this.selectedSC : ""; // this.selectedSC != null ? this.selectedSC.Id : "";
      this.companyDetails.Products =
        this.summaryForm.value.technologyTargetProduct;
      this.companyDetails.DevelopmentProgram =
        this.summaryForm.value.developmentProgram;
      this.companyDetails.FundingPlan =
        this.selectedFund != null ? this.selectedFund.Id : ""; //this.summaryForm.value.fundPlans.Id;
      this.companyDetails.Investors =
        this.conferenceDetail.Conference.TypeId == 18
          ? this.summaryForm.value.majorInvestorDropDown
          : this.summaryForm.value.majorInvestor;
      this.companyDetails.Partners = this.summaryForm.value.corporatePartner;
      this.companyDetails.IsTechBioCompany = this.summaryForm.value.isTechBioCompany;
      this.companyDetails.FundsRaised = this.summaryForm.value.fundRaised;
      this.companyDetails.IsFundRaisingMode =
        this.summaryForm.value.isFundRaisingMode;
      this.companyDetails.Comments = this.summaryForm.value.additionalCommment;
      this.companyDetails.Comments = this.summaryForm.value.additionalCommment;
      this.companyDetails.ParticipationMethod =
        this.summaryForm.value.participationMethod;
      var countrycode = this.summaryForm.value.country;
      var isExistCountry = _.find(this.countries, function (obj: any) {
        return obj.Code == countrycode;
      });
      if (this.summaryForm.value.participationMethod) {
        this.summaryForm.value.enablearticipationMethod = true;
      }
      if (isExistCountry) {
        this.companyDetails.Country = isExistCountry.Name;
        this.companyDetails.CountryCode = isExistCountry.Code;
      }

      var statecode = this.summaryForm.value.state;
      var isExistState = _.find(this.states, function (obj: any) {
        return obj.Code == statecode;
      });
      if (isExistState) {
        this.companyDetails.State = isExistState.Name;
        this.companyDetails.StateCode = isExistState.Code;
      }
      // InvestorAssets Set Model Properties
      console.dir(this.rows);
      // let arr = this.rows;
      // this.rows = arr.filter(function(item, index) {
      //   return arr.indexOf(item) >= index;
      // });
      this.assets = [];
      console.dir(this.rows);
      this.rows.forEach((item) => {
        this.investorAssets = new InvestorAssets();
        this.investorAssets.AssetName = item.name;
        this.investorAssets.Indication = item.indication;
        this.investorAssets.AssetStatus = item.status.Id;
        this.investorAssets.Milestone = item.milestone;
        console.dir(this.investorAssets);
        this.assets.push(this.investorAssets);
      });

      // For Preview
      this.assetList = [];
      this.rows.forEach((item) => {
        this.investorAssets = new InvestorAssets();
        this.investorAssets.AssetName = item.name;
        this.investorAssets.Indication = item.indication;
        this.investorAssets.AssetStatus = item.status;
        this.investorAssets.Milestone = item.milestone;
        console.dir(this.investorAssets);
        this.assetList.push(this.investorAssets);
      });

      console.dir(this.rows);
      console.dir(this.assets);
      //this.summaryModel.investorAssets = this.assets;
      this.companyDetails.investorAssets = this.assets;
      console.dir(this.companyDetails.investorAssets);
      // InvetsorTherapeuticFocus Set Model Properties
      console.dir(this.dragndropItems);
      this.droppedItems = [];
      this.dragndropItems.forEach((item) => {
        this.invetsorTherapeuticFocus.TherapeuticFocusId = item.Id;
        this.droppedItems.push(this.invetsorTherapeuticFocus);
        this.invetsorTherapeuticFocus = new InvestorTherapeuticFocus();
      });

      console.dir(this.droppedItems);
      //this.summaryModel.invetsorTherapeuticFocus = this.droppedItems;
      this.companyDetails.investorTherapeuticFocus = this.droppedItems;

      this.summaryModel.companyDetails = this.companyDetails;

      // AssistantDetails Set Model Properties
      this.assistantDetails.IsAssistant = 0;
      this.assistantDetails.AssistantPrefix =
        this.summaryForm.value.asalutation;
      this.assistantDetails.FName = this.summaryForm.value.afirstName;
      this.assistantDetails.LName = this.summaryForm.value.alastName;
      this.assistantDetails.Email = this.summaryForm.value.acontactEmail;
      this.assistantDetails.Phone = this.summaryForm.value.acontactPhone;
      this.summaryModel.assistantDetails = this.assistantDetails;

      console.dir(this.dragndropItems);
      console.log(JSON.stringify(this.summaryModel));

      // Check Email, CompanyName, Phone already exists.
      this.checkIsValid();
      // this.openPreviewModal(
      //   this.summaryModel,
      //   this.dragndropItems,
      //   this._conferenceId
      // );
      //this.createSummaries(this.summaryModel);
    }
  }

  // Check Email, CompanyName, Phone already exists.
  checkIsValid() {
    this.presenterModel.Email = this.summaryForm.value.pcontactEmail;
    this.presenterModel.Phone = this.summaryForm.value.pcontactPhone;
    this.companyModel.CompanyName = this.summaryForm.value.companyName;
    this.companyModel.ConferenceID = this._conferenceId;
    this.vModel.presenterDetails = this.presenterModel;
    this.vModel.companyDetails = this.companyModel;
    if (
      this.companyModel.ConferenceID != "" &&
      this.companyModel.CompanyName != "" &&
      this.presenterModel.Email != "" &&
      this.presenterModel.Phone != ""
    ) {
      return this.summaryService
        .checkIsValid(this.vModel)
        .subscribe((data: {}) => {
          this.result = data;
          this.valid = this.result.Code;
          if (this.valid == "400") {
            //this.invalidStatus = true;
            var toast: Toast = {
              type: "error",
              title: this.result.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
          } else {
            //this.invalidStatus = false;
            this.openPreviewModal(
              this.summaryModel,
              this.dragndropItems,
              this._conferenceId
            );
          }
        });
      this.vModel = new ValidationModel();
    }
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(event: any) {
    let position = 550;
    let scrollPositionFromTop = +window.pageYOffset.toString();
    let windowHeight = +window.innerHeight;
    //let submitBtn = event.nativeElement.querySelectorAll(".submit");
    if (scrollPositionFromTop > position) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    if (
      scrollPositionFromTop + windowHeight >
      document.body.clientHeight - 100
    ) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
      //let a = scrollPositionFromTop + windowHeight;
      //let b = document.body.clientHeight;
      //console.log("Addition " + a);
      //console.log("clientHeight " + b);
    }
    //console.log("scrolerl top " + scrollPositionFromTop);
  }

  /* Modal popup for preview */
  openPreviewModal(model: any, focus: any, id: any) {
    console.log("conference id", id);
    const modalRef = this.modalService.open(SummaryPreviewModalComponent, {
      windowClass: "modalWidth",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.summaryItem = model;
    modalRef.componentInstance.summaryForm = this.summaryForm.value;
    modalRef.componentInstance.summaryForm.pClassification =
      this.selectedPCName;
    modalRef.componentInstance.summaryForm.sClassification =
      this.selectedSCName;
    modalRef.componentInstance.tFocus = focus;
    modalRef.componentInstance.conferenceID = id;
    modalRef.componentInstance.assetList = this.assetList;
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteAsset(pos: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + this.rows[pos].name)
      .then((confirmed) => {
        this.rows.splice(pos, 1);
        this.assetList.splice(pos, 1);
        console.log(pos);
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  IsShowSearch() {
    this.isShowAdvanceSearch = !this.isShowAdvanceSearch;
    // this.seachText = null;
    // if (this._conferenceId) this.getSearchToolDetail();
    this.seachText = null;
    if (this._conferenceId) this.getSearchToolDetail();
  }

  getSearchToolDetail() {
    return this.summaryService
      .getSearchToolDetail(this._conferenceId)
      .subscribe((data: {}) => {
        this.searchToolDetail = data;
        this.TypeId = this.searchToolDetail.ConferenceDetails.TypeId;
      });
  }

  getinvesterSummeraySeach(SearchForm: any) {
    if (SearchForm.invalid) {
      this.isSearchSubmitted = true;
      return;
    }
    return this.summaryService
      .getinvesterSummeraySeach(this.TypeId, this.seachText)
      .subscribe((data: any) => {
        if (data && data.Message.Code == "200") {
          this.searchTextResponse = data.CompanyDetails;
          this.isSearchSubmitted = false;
          this.isErrpr = false;
        } else {
          this.isErrpr = true;
          this.searchTextResponse = null;
        }
      });
  }

  gotoConforanceDetailForm(companyId: any) {
    // this.router.navigate(["/investor/investorSummary/" + item.ConferenceID]);
    // this.searchTextResponse = null;
    // this.isShowAdvanceSearch = false;
    // this.isErrpr = false;
    // this.ngOnInit();
    this.searchTextResponse = null;
    this.isShowAdvanceSearch = false;
    this.isErrpr = false;
    this.getSummaryById(companyId);
  }

  gotoNewCompany() {
    this.router.navigate(["/investor/investorSummary"]);
  }

  getSummaryById(id: number) {
    this.summaryService.getSummaryById(id).subscribe((data: {}) => {
      this.Summary = data;

      console.log(this.Summary);
      this.ps =
        this.Summary.PresenterDetails && this.Summary.PresenterDetails.Prefix
          ? this.Summary.PresenterDetails.Prefix
          : "";
      if (this.Summary.CompanyDetails) {
        this.ps1 = this.Summary.CompanyDetails.IPOYear;
      }
      this.completed = this.Summary.InvestorTherapeuticFocus;
      if (this.completed && this.completed.length > 0) {
        this.completed.forEach((item: any) => {
          this.tFocus.Id = item.TherapeuticFocusId;
          this.tFocus.Description = item.TherapeuticFocus;
          this.tFocusList.push(this.tFocus);
          this.tFocus = new TFocus();
        });
        this.completed = this.tFocusList;
      }

      let ar = this.therapeuticFocusList;
      if (this.completed.length > 0) {
        this.completed.filter(function (completed: any) {
          ar = ar.filter(function (list: any) {
            return list.Id !== completed.Id;
          });
        });
        this.therapeuticFocusList = ar;
      }

      this.assets = this.Summary.InvestorAssets;
      console.dir(this.assets);

      // AssetStatus = 0 , 2;
      if (this.assets && this.assets.length > 0) {
        this.assets.forEach((item) => {
          var st = this.assetDevelopmentStatus.find(
            (i: { Id: any }) => i.Id === item.AssetStatus
          );
          console.dir(st);
          let r1 = {
            name: item.AssetName,
            indication: item.Indication,
            status: st, //item.AssetStatus, //
            milestone: item.Milestone,
          };
          this.rows.push(r1);
          console.dir(this.rows);
        });
      }
      if (!this.Summary.CorporateDetails)
        this.Summary.CorporateDetails = new CorporateDetails();
      if (!this.Summary.Assistantdetails)
        this.Summary.Assistantdetails = new AssistantDetails();
      if (!this.Summary.CompanyDetails)
        this.Summary.companyDetails = new CompanyDetails();
      this.countryCode = this.Summary.CompanyDetails.CountryCode
        ? this.Summary.CompanyDetails.CountryCode.trim()
        : "";
      this.country.Code = this.Summary.CompanyDetails.CountryCode
        ? this.Summary.CompanyDetails.CountryCode.trim()
        : "";
      this.country.Name = this.Summary.CompanyDetails.Country
        ? this.Summary.CompanyDetails.Country.trim()
        : "";
      this.state.Code = this.Summary.CompanyDetails.StateCode
        ? this.Summary.CompanyDetails.StateCode.trim()
        : "";
      this.state.Name = this.Summary.CompanyDetails.State
        ? this.Summary.CompanyDetails.State.trim()
        : "";
      var psalutuin = this.Summary.PresenterDetails.Prefix
        ? this.Summary.PresenterDetails.Prefix.trim()
        : "";
      var ccsalutation = this.Summary.CorporateDetails.Prefix
        ? this.Summary.CorporateDetails.Prefix.trim()
        : "";
      this.pClassification = this.Summary.CompanyDetails.ClassificationPrimary
        ? this.Summary.CompanyDetails.ClassificationPrimary.trim()
        : "";
      this.sClassification = this.Summary.CompanyDetails.ClassificationSecondary
        ? this.Summary.CompanyDetails.ClassificationSecondary.trim()
        : "";
      //this.ownershipRadio = this.Summary.CompanyDetails.Ownership;
      //this.isSelected(this.Summary.CompanyDetails.Ownership);
      this.onCountryChange(this.country.Code);

      this.summaryForm.patchValue({
        companyName: this.Summary.CompanyDetails.CompanyName,

        psalutation: psalutuin ? psalutuin.trim() : "", //this.Summary.PresenterDetails.Prefix,
        pfirstName: this.Summary.PresenterDetails.FirstName,
        pnickName: this.Summary.PresenterDetails.NickLastName,
        pmiddleName: this.Summary.PresenterDetails.MiddleName,
        plastName: this.Summary.PresenterDetails.LastName,
        psuffix: this.Summary.PresenterDetails.Suffix,
        psExchange: this.Summary.CompanyDetails.Exchange,
        pgeneration: this.Summary.PresenterDetails.Generation,
        pjobTitle: this.Summary.PresenterDetails.Title,
        pcontactEmail: this.Summary.PresenterDetails.Email,
        pcontactPhone: this.Summary.PresenterDetails.Phone,

        ccsalutation: ccsalutation ? ccsalutation.trim() : "", //this.Summary.CorporateDetails.Prefix,
        ccfirstName: this.Summary.CorporateDetails.Firstname,
        cclastName: this.Summary.CorporateDetails.Lastname,
        cccontactEmail: this.Summary.CorporateDetails.Email,
        cccontactPhone: this.Summary.CorporateDetails.PhoneNumber,

        asalutation: this.Summary.Assistantdetails.Prefix
          ? this.Summary.Assistantdetails.Prefix.trim()
          : "", //asalutation ? asalutation.trim() : "",//"Mr",
        afirstName: this.Summary.Assistantdetails.FName,
        alastName: this.Summary.Assistantdetails.LName,
        acontactEmail: this.Summary.Assistantdetails.Email,
        acontactPhone: this.Summary.Assistantdetails.Phone,

        ownership: this.Summary.CompanyDetails.Ownership,
        ipoYear: this.Summary.CompanyDetails.IPOYear,
        goPublic: this.Summary.CompanyDetails.PublicBeforeConference,
        // psExchange:
        //   this.Summary.CompanyDetails.Exchange != null
        //     ? this.Summary.PresenterDetails.Exchange
        //     : "",
        tickerSymbol: this.Summary.CompanyDetails.Ticker,
        country: this.country.Code,
        streetAddress: this.Summary.CompanyDetails.Address1,
        city: this.Summary.CompanyDetails.City,
        state: this.state.Code,
        zip: this.Summary.CompanyDetails.Zip,
        yearEst: this.Summary.CompanyDetails.YearEstablished,
        website: this.Summary.CompanyDetails.Website,
        nEmployees: this.Summary.CompanyDetails.Employees,
        companySummary: this.Summary.CompanyDetails.CompanySummary,
        investorProposition: this.Summary.CompanyDetails.InvestorProposition,
        bioFutureDescription: this.Summary.CompanyDetails.BioFutureDescription,
      
        pClassification: this.pClassification
          ? this.pClassification.trim()
          : "", //this.Summary.CompanyDetails.ClassificationPrimary,
        sClassification: this.sClassification
          ? this.sClassification.trim()
          : "", //this.Summary.CompanyDetails.ClassificationSecondary,
        technologyTargetProduct: this.Summary.CompanyDetails.Products,
        developmentProgram: this.Summary.CompanyDetails.DevelopmentProgram,
        fundPlans: this.Summary.CompanyDetails.FundingPlan,
        majorInvestor: this.Summary.CompanyDetails.Investors,
        corporatePartner: this.Summary.CompanyDetails.Partners,
        isTechBioCompany: this.Summary.CompanyDetails.IsTechBioCompany,
        fundRaised: this.Summary.CompanyDetails.FundsRaised,
        additionalCommment: this.Summary.CompanyDetails.Comments,
      });
      console.log(`@@@@`,this.summaryForm)
      this.setradio(this.Summary.CompanyDetails.Ownership);
      //this.getConferenceDetails(this.conferenceID);
      //this.ngOnInit()
    });
  }

  isOtherStock(): boolean {
    if (
      this.summaryForm.value.psExchange &&
      this.summaryForm.value.psExchange.id == "21"
    ) {
      return true;
    }
    return false;
  }

  onCheckBoxChange(e: any) {
    if (e.target.value) {
      this.participationError = true;
    } else {
      this.participationError = false;
    }
  }
}
