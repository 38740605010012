import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { environment } from "ClientApp/environments/environment";

@Component({
  selector: "app-conference-status",
  templateUrl: "./conference-status.component.html",
  styleUrls: ["./conference-status.component.css"]
})
export class ConferenceStatusComponent implements OnInit {
  _conferenceId: string | number;
  conferenceDetail: any;
  ConferenceName: any;
  banner: any;

  constructor(
    private route: ActivatedRoute,
    public cService: ConferenceService
  ) {}

  ngOnInit() {
    const cId = this.route.snapshot.paramMap.get("id");
    this._conferenceId = cId !== null ? cId : 0;
    this.getConferenceDetails(this._conferenceId);
  }

  getConferenceDetails(id: any) {
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      console.log(this.conferenceDetail);
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
    });
  }
}
