import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-confirmation-done',
  templateUrl: './schedule-confirmation-done.component.html',
  styleUrls: ['./schedule-confirmation-done.component.css']
})
export class ScheduleConfirmationDoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
