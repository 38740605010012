import { ContactDetails } from "./pressacc";

export class SendISPReminder {
  Info: Array<SendISP> | undefined;
  CompanyDetails: SendISPCompany | undefined;
}

export class SendISP {
  CompId: number | undefined;
  Id: number | undefined;
  Email: string | undefined;
  Info: ContactDetails[]
  EmailType: string | undefined;
}

export class SendISPDecline {
  CompId: number | undefined;
  Id: number | undefined;
  Info: string | undefined;
  EmailType: string | undefined;
}
export class SendISPCompany {
  Conference_Id: number | undefined;
  //EmailType: string | undefined;
}
export class InvestorSummaryList2 {
  EventId: number | undefined;
  Info: Array<SendISP> | undefined;
  CompanyDetails: SendISPCompany | undefined;
}