import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "ClientApp/app/services/auth/auth.service";
import { User } from "ClientApp/app/services/auth/user";
import { DataService } from "ClientApp/app/services/common/data.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  currentUser: any;
  toggleNav: any;
  toggleNavMenu: any;
  uName: any;
  count: number | undefined;
  constructor(
    private router: Router,
    private authenticationService: AuthService,
    private dataService: DataService
  ) {
    // this.authenticationService.currentUser.subscribe(
    //   x => (this.currentUser = x)
    // );
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.dataService.messageSource.subscribe(flag => {
      this.toggleNavMenu = flag;
    });
    //console.log(localStorage.getItem("role"));
    if (localStorage.getItem("role") === null) {
      this.toggleNav = true;
      //console.log("Admin here");
    } else {
      this.toggleNav = false;
    }
    //console.log(this.toggleNav);
    if (localStorage.getItem("role") != null) {
      this.uName = localStorage.getItem("name");
    }
  }

  toggleMenu() {
    this.dataService.toggleMenu();
  }

  get isAdmin() {
    return this.currentUser && localStorage.getItem("role") == "Admin";
  }

  logout() {
    this.authenticationService.logout();
    //console.log("logout()");
    this.router.navigate([""]);
  }
}
