export class User {
    UserId: number | undefined;
    UserName: string | undefined;
    LoginName: string | undefined;
    Password: string | undefined;
    EmailId: string | undefined;
    RoleId: number | undefined;
}

export class PasswordModel {
    Password: string | undefined;
    NewPassword: string | undefined;
}
