import { Component, OnInit } from "@angular/core";
import { ReportService } from "ClientApp/app/services/report/report.service";
import {
  Report,
  ReportTableModel,
  ReportFieldModel,
} from "ClientApp/app/models/Report";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { IMyDpOptions, IMyDate } from "mydatepicker";
import { trim } from "jquery";
import { Schedule, TrackItem } from "ClientApp/app/models/Schedule";
import { ScheduleService } from "ClientApp/app/services/schedule/schedule.service";
import * as _ from "underscore";
import { environment } from "ClientApp/environments/environment";
import {
  AssetsUnderManagement,
  Therapeutics,
} from "../../shared/data/Collection";
declare let gtag: Function;
@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent implements OnInit {
  listItems: any = [];
  isTemplateSelect: boolean = false;

  yearList: any;
  conferenceList: any;
  reportList: any;
  templateList: any;
  conferenceType: any;
  confTypeId: any;

  response: any;
  submitted = false;
  reportForm!: FormGroup;
  reportModel = new Report();
  reportTableModel = new ReportTableModel();
  reportFieldModel = new ReportFieldModel();
  public headerItems: Array<ReportTableModel> = [];
  public headerFields: Array<ReportFieldModel> = [];
  header: any;
  fundRoundRearrange: any;
  AssetsRearrange: any;
  timeFrameRearrange: any;
  records: any;
  years: any = [];
  tableHeadList = [];
  rowValue: any;
  recordObj: any;
  rowObj: any;
  rowValuesObj: Array<string> = [];
  rowValues: Array<Array<string>> = [];
  public keyId: string = "test";

  _scheduleModel = new Schedule();

  reportData: any;
  reportHeader: any = [];
  ConferenceFileTitle: any;
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: true,
    title: "",
    useBom: true,
    noDownload: false,
    headers: this.reportHeader,
  };
  selectedTemplate: any;
  stockExchanges: any = [];
  dateShow = false;
  yearShow = false;
  private defaultYear: IMyDate = { year: 0, month: 0, day: 0 };
  private defaultCurrentDate: IMyDate = { year: 0, month: 0, day: 0 };

  investorTypes = [
    {
      Id: "0",
      Description: "Angel Investing",
    },
    {
      Id: "1",
      Description: "Endowment / Foundation",
    },
    {
      Id: "2",
      Description: "Family Office",
    },
    {
      Id: "3",
      Description: "Financial Advising",
    },
    {
      Id: "4",
      Description: "Hedge / Mutual Fund (PM, Buy-side Research)",
    },
    {
      Id: "5",
      Description: "Independent Research Firm",
    },
    {
      Id: "6",
      Description: "Individual Investor",
    },
    {
      Id: "7",
      Description: "Investment Bank",
    },
    {
      Id: "8",
      Description: "Royalty / Debt Financing",
    },
    {
      Id: "9",
      Description: "Sell-Side Research",
    },
    {
      Id: "10",
      Description: "Other",
    },
  ];

  fundPlans = [
    {
      Id: "0",
      Description: "Plan to raise money in the next 12 months",
    },
    {
      Id: "1",
      Description: "Plan to raise money in the next 18 months",
    },
    {
      Id: "2",
      Description: "Plan to raise money in the next 24 months",
    },
    {
      Id: "3",
      Description: "Plan to update investors on status or milestones",
    },
    {
      Id: "4",
      Description: "Not disclosed",
    },
  ];

  pClassifications = [
    {
      Id: "0",
      Description: "Biotechnology",
    },
    {
      Id: "1",
      Description: "Digital Medicine",
    },
    {
      Id: "2",
      Description: "Pharmaceutical",
    },
    {
      Id: "3",
      Description: "Medtech",
    },
  ];

  sClassifications = [
    {
      Id: "0",
      Description: "Biotechnology",
    },
    {
      Id: "1",
      Description: "Digital Medicine", // China, Digital Medicine
    },
    {
      Id: "2",
      Description: "Pharmaceutical",
    },
    {
      Id: "3",
      Description: "Medtech", // China, Digital Medicine
    },
  ];

  constructor(
    public reportService: ReportService,
    private formBuilder: FormBuilder,
    public scheduleService: ScheduleService
  ) {
    let startYear = new Date().getFullYear() - 10;
    let endYear = new Date().getFullYear() + 100;
    for (let i = startYear; i <= endYear; i++) {
      this.years.push(i);
      // //console.log('years--',this.years);
    }
  }

  ngOnInit() {
    this.getStockExchanges();
    this.loadDefaultListItems("");
    //console.log('condition--this.conferenceType', this.conferenceType);

    this.reportForm = this.formBuilder.group({
      conferenceType: [""],
      conferenceId: ["", Validators.required],
      //reportId: ["", Validators.required],
      templateId: ["", Validators.required],
      startDate: [""],
      endDate: [""],
      year: ["", Validators.required],
      toYear: [""],
    });
  }

  public myDatePickerOptions: IMyDpOptions = {
    editableDateField: false,
    dateFormat: "mm-dd-yyyy",
    width: "250px",
  };

  public yearOptions: IMyDpOptions = {
    editableDateField: false,
    dateFormat: "yyyy",
    width: "250px",
    yearSelector: true,
  };
  getStockExchanges() {
    this.reportService.getStockExchanges().subscribe((data: {}) => {
      this.response = data;
      this.stockExchanges = this.response.Info;
      console.log("data--", data);
    });
  }
  loadDefaultListItems(year: string) {
    this.reportService.getListItems(year).subscribe((result: {}) => {
      this.listItems = result;
      //console.log('list--', this.listItems);

      this.conferenceList = this.listItems.Conference;
      this.templateList = this.listItems.Templates;
      this.conferenceType = this.listItems.ConferenceType;
      // //console.log('conferenceList',this.conferenceList);
      //console.log('conferenceType', this.conferenceType);
      // this.reportList = this.listItems.Report;
      // this.templateList = this.listItems.TemplateMaster;
      // this.yearList = this.listItems.ConferenceYear;
    });
  }

  get fc() {
    // get field control(fc)
    return this.reportForm.controls;
  }

  onSubmit() {
    //console.log(this.reportForm.value);
    // this.confTypeId = this.reportForm.value.conferenceType;
    // console.log(this.reportForm.value, this.confTypeId);
    //console.log("dhgveudsgreu", this.templateList[8].TemplateId);

    this.submitted = true;
    if (this.reportForm.invalid) {
      //console.log("Invalid");
      return;
    } else {
      // if(this.reportForm.value.conferenceId==""){
      //     console.log(this.reportForm.value.conferenceId);
      //     this.reportModel.ConferenceId = 0;
      // }
      this.reportModel.CriteriaId = this.reportForm.value.templateId.CriteriaId;
      this.reportModel.ConferenceId = +this.reportForm.value.conferenceId;
      this.reportModel.ConferenceTypeID = +this.reportForm.value.conferenceType;

      // if (this.templateList[8].TemplateId === 9) {
      //     this.reportModel.ConferenceTypeID = +this.confTypeId;
      // } else {
      //     console.log("not id");

      // }

      this.reportModel.ReportId = +this.reportForm.value.templateId.ReportId;
      this.reportModel.TemplateId =
        +this.reportForm.value.templateId.TemplateId;
      if (this.dateShow && this.reportForm.value.startDate) {
        this.reportModel.FromDate = this.reportForm.value.startDate.formatted;
      } else {
        delete this.reportModel.FromDate;
      }

      if (this.dateShow && this.reportForm.value.endDate) {
        this.reportModel.ToDate = this.reportForm.value.endDate.formatted;
      } else {
        delete this.reportModel.ToDate;
      }

      if (this.yearShow && this.reportForm.value.year) {
        this.reportModel.ArchiveFromYear = this.reportForm.value.year;
        this.reportModel.Year = 1;
        this.reportModel.all = 0;
      } else {
        delete this.reportModel.ArchiveFromYear;
        delete this.reportModel.Year;
        this.reportModel.Year = 0;
        this.reportModel.all = 1;
      }

      if (this.yearShow && this.reportForm.value.toYear) {
        this.reportModel.ArchiveToYear = this.reportForm.value.toYear;
        this.reportModel.Year = 1;
        this.reportModel.all = 0;
      } else {
        delete this.reportModel.ArchiveToYear;
        delete this.reportModel.Year;
        this.reportModel.Year = 0;
        this.reportModel.all = 1;
      }

      // if (this.yearShow && this.reportForm.value.activeStatus) {
      //   this.reportModel.Year = this.reportForm.value.activeStatus ? 1 : 0;
      // } else {
      //   delete this.reportModel.Year;
      // }

      //console.dir(this.reportModel);

      var confId = this.reportModel.ConferenceId;
      // var typeId = this.reportModel.ConferenceTypeID;

      var confSelectedDetail = this.conferenceList.filter(function (obj: any) {
        // console.log('obj---',obj);
        return obj.ConferenceId == confId;
      });

      // if (this.templateList[8].TemplateId == 9) {
      //     var confId = this.reportModel.ConferenceTypeID;
      //     console.log('confId---', confId);
      //     var confSelectedDetail = this.conferenceList.filter(function (obj: any) {
      //         // console.log('obj---',obj);
      //         return obj.ConferenceId == 26;
      //     });

      // } else {

      //     confId = this.reportModel.ConferenceId;
      //     var confSelectedDetail = this.conferenceList.filter(function (obj: any) {
      //         console.log('obj---',obj);
      //         return obj.ConferenceId == confId;
      //     });
      // }

      //console.log('confSelectedDetail--', confSelectedDetail);

      var TemplateId = this.reportModel.TemplateId;
      var confTemplateSelectedDetail = this.templateList.filter(function (
        obj: any
      ) {
        return obj.TemplateId == TemplateId;
      });

      if (confSelectedDetail)
        if (confSelectedDetail.length === 0) {
          //console.log('-------', confSelectedDetail);

          this.ConferenceFileTitle = "select confernce";
        } else {
          this.ConferenceFileTitle = confSelectedDetail[0].ConferenceName;
        }
      // console.log('----this.ConferenceFileTitle---',this.ConferenceFileTitle);
      if (confTemplateSelectedDetail)
        // console.log('---confTemplateSelectedDetail----',confTemplateSelectedDetail);
        this.ConferenceFileTitle =
          this.ConferenceFileTitle +
          " For " +
          confTemplateSelectedDetail[0].TemplateName;

      //console.log("on submit..",this.reportModel);
      this.getReportDetails(this.reportModel);
    }
  }

  // onChangeReport(id: number) {
  //   this.getreportTemplates(id);
  // }

  // onChangeYear(year: string) {
  //   this.loadDefaultListItems(year);
  // }

  getreportTemplates(id: number) {
    this.reportService.getReportTemplates(id).subscribe((result: {}) => {
      this.response = result;
      this.templateList = this.response.Info;
      // console.log('templatelist--',this.templateList);
    });
  }

  // Invester application array change order
  move(arr: any, old_index: any, new_index: any) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
  //End

  getReportDetails(data: Report) {
    this.rowValues = [];
    this.headerItems = [];
    this.headerFields = [];
    this.reportService.getReport(data).subscribe((result: {}) => {
      this.response = result;
      console.log("response ::: ", this.response);

      if (
        this.response.TemplateDetailsList != null &&
        this.response.TemplateDetailsList.length > 0
      ) {
        if (this.selectedTemplate.TemplateId === 8) {
          this.AssetsRearrange = this.move(
            this.response.TemplateDetailsList,
            33,
            22
          );
          this.fundRoundRearrange = this.move(
            this.response.TemplateDetailsList,
            34,
            23
          );
          this.timeFrameRearrange = this.move(
            this.response.TemplateDetailsList,
            35,
            24
          );
        }
        this.header = this.response.TemplateDetailsList;
        // Get 'Caption' key for table header.
        // Get 'ReportField' key for column value.
        this.header.forEach(
          (item: {
            Caption: string | undefined;
            ReportField: string | undefined;
          }) => {
            this.reportTableModel.header = item.Caption;
            this.headerItems.push(this.reportTableModel);
            this.reportFieldModel.field = item.ReportField;
            this.headerFields.push(this.reportFieldModel);
            this.reportTableModel = new ReportTableModel();
            this.reportFieldModel = new ReportFieldModel();
            // console.log('headername',this.headerItems);
            // console.dir('headerFields',this.headerFields);
          }
        );
      }

      this.records = null;
      if (
        this.response.InvestorSummaryDetailsList != null &&
        this.response.InvestorSummaryDetailsList.length > 0
      ) {
        this.records = this.response.InvestorSummaryDetailsList;
      }
      if (
        this.response.ApplicationDetailsList != null &&
        this.response.ApplicationDetailsList.length > 0
      ) {
        this.records = this.response.ApplicationDetailsList;
      }
      if (
        this.response.AccreditationDetailsList != null &&
        this.response.AccreditationDetailsList.length > 0
      ) {
        this.records = this.response.AccreditationDetailsList;
      }
      if (
        this.response.ProfileDetailsList != null &&
        this.response.ProfileDetailsList.length > 0
      ) {
        this.records = this.response.ProfileDetailsList;
      }
      if (
        this.response.InvitationDetailsList != null &&
        this.response.InvitationDetailsList.length > 0
      ) {
        this.records = this.response.InvitationDetailsList;
      }

      if (this.records != null && this.records.length > 0) {
        var recordLoop = 0;
        for (recordLoop = 0; recordLoop < this.records.length; ++recordLoop) {
          if (
            this.records[recordLoop].CreatedOn == "1899-12-31 19:00:00" ||
            this.records[recordLoop].CreatedOn == "1900-01-01T00:00:00" ||
            this.records[recordLoop].CreatedOn == "0001-01-01T00:00:00"
          ) {
            this.records[recordLoop].CreatedOn = "";
          }
          if (
            this.records[recordLoop].LastUpdatedOn == "1899-12-31 19:00:00" ||
            this.records[recordLoop].LastUpdatedOn == "1900-01-01T00:00:00" ||
            this.records[recordLoop].LastUpdatedOn == "0001-01-01T00:00:00"
          ) {
            this.records[recordLoop].LastUpdatedOn = "";
          }
          if (
            this.records[recordLoop].UpdatedOn == "1899-12-31 19:00:00" ||
            this.records[recordLoop].UpdatedOn == "1900-01-01T00:00:00" ||
            this.records[recordLoop].UpdatedOn == "0001-01-01T00:00:00"
          ) {
            this.records[recordLoop].UpdatedOn = "";
          }
          if (
            this.records[recordLoop].EmailSentOn == "1899-12-31 19:00:00" ||
            this.records[recordLoop].EmailSentOn == "1900-01-01T00:00:00" ||
            this.records[recordLoop].EmailSentOn == "0001-01-01T00:00:00"
          ) {
            this.records[recordLoop].EmailSentOn = "";
          }
          let assets = AssetsUnderManagement.filter(
            (x: any) => x.Id === this.records[recordLoop].AssetsUnderManagement
          );
          let therapeutics = Therapeutics.filter(
            (x: any) => x.Id === this.records[recordLoop].Therapeutics
          );
          if (assets.length != 0) {
            this.records[recordLoop].AssetsUnderManagement =
              assets[0].Description;
          } else {
            this.records[recordLoop].AssetsUnderManagement = " ";
          }

          if (therapeutics.length != 0) {
            this.records[recordLoop].Therapeutics = therapeutics[0].Description;
          } else {
            this.records[recordLoop].Therapeutics = " ";
          }
          console.log("new", assets);
          console.log("new", therapeutics);
        }
        let newRecord: any;
        console.log("records", this.records);
        if (this.headerFields != null && this.headerFields.length > 0) {
          for (let i = 0; i < this.records.length; i++) {
            for (let j = 0; j < this.headerFields.length; j++) {
              let keyValue = "" + this.headerFields[j].field; // convert to string
              let key = keyValue; // trim(keyValue);
              if (this.records[i].hasOwnProperty(key)) {
                //console.log("KEY " + key);
                switch (key) {
                  case "IsSelected":
                    if (this.records[i][key] == 0) {
                      this.rowValue = "Pending";
                    }
                    if (this.records[i][key] == 1) {
                      this.rowValue = "Approved";
                    }
                    if (this.records[i][key] == 2) {
                      this.rowValue = "Special cases";
                    }
                    // this.rowValue = (this.records[i][key] === 0) ? 'No' : 'Yes';
                    break;
                  case "IsStet":
                    this.rowValue = this.records[i][key] === 0 ? "No" : "Yes";
                    break;
                  case "InvestorType":
                    const found = this.investorTypes.find((p) => {
                      return p.Id === this.records[i][key];
                    });
                    this.rowValue =
                      found !== undefined ? found.Description : "";
                    break;
                  case "FundingPlan":
                    const fund = this.fundPlans.find((p) => {
                      return p.Id === this.records[i][key];
                    });
                    this.rowValue = fund !== undefined ? fund.Description : "";
                    break;
                  case "CompanyClassificationPrimary":
                    const pc = this.pClassifications.find((p) => {
                      return p.Id === this.records[i][key];
                    });
                    this.rowValue = pc !== undefined ? pc.Description : "";
                    break;
                  case "CompanyClassificationSecondary":
                    const sc = this.sClassifications.find((p) => {
                      return p.Id === this.records[i][key];
                    });
                    this.rowValue = sc !== undefined ? sc.Description : "";
                    break;
                  case "Exchange":
                    var se: any = {};
                    se = _.find(this.stockExchanges, {
                      id: parseInt(this.records[i][key]),
                    });
                    if (se) {
                      var splitValue = se.stockExchange.split("(");
                      if (splitValue[1])
                        this.rowValue = splitValue[1].split(")")[0];
                      else this.rowValue = splitValue[0];
                    } else {
                      this.rowValue = "";
                    }
                    break;
                  // case 'PrimaryStockExchange':
                  //     var se: any = {};
                  //     se = _.find(this.stockExchanges, { id: parseInt(this.records[i][key]) });
                  //     if (se && se.stockExchange != 'abbrev') {
                  //         var splitValue = se.stockExchange.split('(');
                  //         if (splitValue[1])
                  //             this.rowValue = splitValue[1].split(')')[0];
                  //         else
                  //             this.rowValue = splitValue[0];
                  //     }
                  //     else {
                  //         this.rowValue = '';
                  //     }
                  //     break;
                  case "Documents":
                    var publications: any = [];
                    var docs = this.records[i][key].split(",");
                    _.each(docs, function (doc) {
                      publications.push(environment.documentsPath + doc);
                    });
                    this.rowValue = publications.join(", ");
                    break;
                  case "Photo":
                    this.rowValue = this.records[i][key]
                      ? environment.profileImagePath + this.records[i][key]
                      : "";
                    break;
                  default:
                    this.rowValue = this.records[i][key];
                    break;
                }
              } else {
                this.rowValue = "";
              }
              if (this.rowValue == "N/A") this.rowValue = "";
              this.rowValuesObj.push(this.rowValue);
            }
            this.rowValues.push(this.rowValuesObj);

            this.rowValuesObj = [];
          }
        }
      }
      console.log("rowValues====>", this.rowValues);
    });
  }

  cancelScheduled(id: number): void {
    if (confirm("Are you sure, you want cancel the schduled company?")) {
      this._scheduleModel.Sitems = [];
      var trackSlot = new TrackItem();
      trackSlot.Id = id;
      trackSlot.Company_Id = 0;
      this._scheduleModel.Sitems.push(trackSlot);
      this.scheduleService
        .addUpdateSelection(this._scheduleModel)
        .subscribe((result) => {
          this.getReportDetails(this.reportModel);
        });
    }
  }

  downloadCSV() {
    this.reportHeader = [];
    this.reportHeader.push(
      Array.prototype.map
        .call(this.headerItems, function (item: any) {
          return item.header;
        })
        .join(",")
    );
    this.csvOptions.headers = this.reportHeader;
    this.csvOptions.title = this.ConferenceFileTitle;
    this.reportData = this.rowValues;
    new AngularCsv(this.reportData, this.ConferenceFileTitle, this.csvOptions);
  }

  onChangeTemplate(obj: any) {
    this.selectedTemplate = obj;
    if (this.selectedTemplate.TemplateId == 9) {
      this.isTemplateSelect = true;
    } else {
      this.isTemplateSelect = false;
    }
    this.clearDate();
    // let today: Date = new Date();
    //console.log(this.selectedTemplate);
    switch (obj.TemplateId) {
      case 3: // Summary Links
        this.dateShow = true;
        this.setDateValidation();
        this.yearShow = false;
        this.removeYearValidation();
        break;

      case 9: // Archive Export ISP
        this.yearShow = true;
        this.setConferenceValidation();
        this.dateShow = false;
        this.removeDateValidation();

        break;

      case 10: // Archive Export Profile
        this.yearShow = true;
        this.setYearValidation();
        this.dateShow = false;
        this.removeDateValidation();
        //this.removeYearValidation();
        break;

      default:
        this.dateShow = false;
        this.removeDateValidation();
        this.yearShow = false;
        this.removeYearValidation();
        break;
    }
    this.reportForm.controls["startDate"].updateValueAndValidity();
    this.reportForm.controls["endDate"].updateValueAndValidity();
    this.reportForm.controls["year"].updateValueAndValidity();
    this.reportForm.controls["toYear"].updateValueAndValidity();
  }

  clearDate(): void {
    // Clear the date using the patchValue function
    this.reportForm.patchValue({
      startDate: null,
      endDate: null,
      year: "",
      toYear: "",
    });
  }

  setDateValidation() {
    this.reportForm.controls["startDate"].setValidators([Validators.required]);
    this.reportForm.controls["endDate"].setValidators([Validators.required]);
    // this.reportForm.controls["conferenceId"].setValidators([Validators.required]);
  }

  removeDateValidation() {
    this.reportForm.controls["startDate"].setValidators([]);
    this.reportForm.controls["endDate"].setValidators([]);
  }

  setYearValidation() {
    this.reportForm.controls["year"].setValidators([Validators.required]);
    this.reportForm.controls["toYear"].setValidators([Validators.required]);
    // this.reportForm.controls["conferenceId"].setValidators([Validators.required]);
  }

  setConferenceValidation() {
    // this.reportForm.controls["conferenceType"].setValidators([Validators.required]);
    // this.reportForm.controls["year"].setValidators([Validators.required]);
    this.reportForm.controls["conferenceId"].setValidators([]);
    this.reportForm.controls["conferenceId"].updateValueAndValidity();
  }

  removeYearValidation() {
    this.reportForm.controls["year"].setValidators([]);
    this.reportForm.controls["toYear"].setValidators([]);
  }
}
