import { ConferenceService } from "ClientApp/app/services/conference.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConferenceTypeModel } from "ClientApp/app/models/Conference";

@Component({
  selector: "app-conference-type-create-modal",
  templateUrl: "./conference-type-create-modal.component.html",
  styleUrls: ["./conference-type-create-modal.component.css"]
})
export class ConferenceTypeCreateModalComponent implements OnInit {
  submitted = false;
  response: any;
  users: any;
  createForm!: FormGroup;
  typeModel = new ConferenceTypeModel();
  currentUser: any;
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    public activeModal: NgbActiveModal,
    private conferenceService: ConferenceService
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    console.log(this.currentUser)
    this.createForm = this.formBuilder.group({
      type: ["", Validators.required],
      activeStatus: [""],
      isEBD: [null],
      showAssets: [null],
      enableParticipationMethod:[null]
    });

    this.createForm.patchValue({
      activeStatus: true,
      showAssets: true
    });
  }

  get fc() {
    // get field control(fc)
    return this.createForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.createForm.invalid) {
      return;
    }

    this.typeModel.Type = this.createForm.value.type;
    //this.typeModel.RoleId = this.currentUser.Info.RoleId;
    this.typeModel.isActive = this.createForm.value.activeStatus ? 1 : 0;
    this.typeModel.isEBD = this.createForm.value.isEBD ? true : false;
    this.typeModel.IsAssetsShow = this.createForm.value.IsAssetsShow ? true : false;
    this.typeModel.EnableParticipantMethod = this.createForm.value.enableParticipationMethod ? true : false;
    this.createConferenceType(this.typeModel);
  }

  createConferenceType(conferenceTypeModel: ConferenceTypeModel) {
    return this.conferenceService
      .createConferenceType(conferenceTypeModel)
      .subscribe(result => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };

          this.toasterService.pop(toast);
          this.activeModal.close();
          //this.createForm.reset();
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
      });
  }
}
