import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportTemplateModalComponent } from "../../components/report-template-modal/report-template-modal.component";
import { ReportService } from "ClientApp/app/services/report/report.service";
import { ReportTemplateEditModalComponent } from "../../components/report-template-edit-modal/report-template-edit-modal.component";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";

@Component({
  selector: "app-report-template-list",
  templateUrl: "./report-template-list.component.html",
  styleUrls: ["./report-template-list.component.css"]
})
export class ReportTemplateListComponent implements OnInit {
  templates: any;
  response: any;
  dataTable: any;
  selectedPageSize: any;
  constructor(
    private modalService: NgbModal,
    private reportService: ReportService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadTemplates();
  }

  // Get Templates list
  loadTemplates() {
      return this.reportService.getAllTemplates().subscribe((data: {}) => {
      this.response = data;
      this.templates = this.response.Info;
      this.enablePagination();
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#report_template");
    table.destroy();
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#type_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
  } );
  }

  /* Modal popup for creating new report template */
  openTemplateFormModal() {
    const modalRef = this.modalService.open(ReportTemplateModalComponent, {
        windowClass: "modalWidth",
        backdrop: "static"
    });

    modalRef.result
      .then(result => {
        //console.log(result);
        this.loadTemplates();
        modalRef.close();
      })
      .catch(error => {
        //console.log(error);
      });
  }

  /* Modal popup for creating new report template */
  openTemplateEditFormModal(templateId: number, reportId: number) {
    const modalRef = this.modalService.open(ReportTemplateEditModalComponent, {
        windowClass: "modalWidth",
        backdrop: "static"
    });
    modalRef.componentInstance.templateId = templateId;
    modalRef.componentInstance.reportId = reportId;
    modalRef.result
      .then(result => {
        this.loadTemplates();
        //console.log(result);
      })
      .catch(error => {
        if (this.dataTable) {
          this.dataTable.destroy();
        }
        this.loadTemplates();
        //console.log(error);
      });
    }

    deleteConfirmation(obj: any) {
        this.confirmationDialogService
            .confirm("", "Do you really want to delete?")
            .then(confirmed => {
                this.reportService.deleteReportTemplate(obj.TemplateId).subscribe((data: {}) => {
                    this.response = data;
                    if (this.response.Code == 200) {
                        var toast: Toast = {
                            type: "success",
                            title: this.response.Message,
                            showCloseButton: true
                        };

                        this.toasterService.pop(toast);
                        if (this.dataTable) {
                            this.dataTable.destroy();
                        }
                        this.loadTemplates();
                    } else {
                        var toast: Toast = {
                            type: "error",
                            title: this.response.Message,
                            showCloseButton: true
                        };
                        this.toasterService.pop(toast);
                    }
                });
            })
            .catch(() =>
                console.log(
                    "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
                )
            );
    }
}
