import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToasterModule, ToasterService, ToasterConfig } from "angular2-toaster";
import { UiSwitchModule } from "ngx-toggle-switch";
//import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
// import { QuillModule } from "ngx-quill";
import { NgxEditorModule } from "ngx-editor";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { DataTablesModule } from "angular-datatables";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AngularEditorModule } from "@kolkov/angular-editor";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/components/header/header.component";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from "./modules/login/login.component";
import { UrlRewriteComponent } from "./modules/url-rewrite/url-rewrite.component";
import { UrlRewriteIaComponent } from "./modules/url-rewrite-ia/url-rewrite-ia.component";
import { DashboardComponent } from "./modules/dashboard/dashboard.component";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./services/auth/auth.service";
import { HomeLayoutComponent } from "./shared/layouts/home-layout/home-layout.component";
import { LoginLayoutComponent } from "./shared/layouts/login-layout/login-layout.component";
import { MenuComponent } from "./shared/components/menu/menu.component";
//import { InvestorSummaryComponent } from "./modules/conference/investor/pages/investor-summary/investor-summary.component";
//import {  } from "./modules/investor-application/investor-application.component";
import { SummaryEmailsComponent } from "./modules/summary-emails/summary-emails.component";
//import { InvestorSummaryListComponent } from "./modules/conference/investor/components/investor-summary-list/investor-summary-list.component";
import { EmailSummaryComponent } from "./modules/conference/investor/components/email-summary/email-summary.component";
//import { SummaryEditsComponent } from "./modules/conference/investor/pages/summary-edits/summary-edits.component";
import { ApplicationListComponent } from "./modules/conference/investor/components/application-list/application-list.component";
import { ApplicationEmailsComponent } from "./modules/conference/investor/components/application-emails/application-emails.component";
//import { AngularMyDatePickerModule } from "angular-mydatepicker";
import { MyDatePickerModule } from "mydatepicker";
import { SummaryEditModalComponent } from './modules/conference/investor/pages/summary-edits-popup/summary-edit';
// Investor Module
import {
    InvestorSummaryComponent,
    InvestorSummaryListComponent,
    AddAssetComponent,
    AddEditFormComponent,
    InvestorSummaryEditComponent,
    InvestorApplicationComponent,
    InvestorSummaryConferenceComponent,
    InvestorApplicationConferenceComponent,
    SummaryPreviewModalComponent,
//    SummaryEditModalComponent,
    SummaryEditsComponent
} from "./modules/conference/investor";

// Template Module
import {
    EmailTemplateComponent,
    EmailTemplateCreateComponent
} from "./modules/template";

import {
    EmailEventComponent,
    EmailEventCreateComponent,
    EmailEventEditComponent
} from "./modules/template";

// Selection Module
import {
    SelectionCompanyListComponent,
    SelectionEmailComponent,
    SelectionEmailNotselectedComponent
} from "./modules/conference/selection";

// Schedule Module
import {
    OpenTracksComponent,
    AllTracksComponent,
    ScheduleEmailComponent
} from "./modules/conference/schedule";

import { SummaryService } from "./services/investor/summary.service";
import { NotificationComponent } from "./shared/components/notification/notification.component";
import { ReportComponent } from "./modules/report/report.component";
import { EditAssetComponent } from "./modules/conference/investor/components/investor-summary/assets/edit-asset/edit-asset.component";
import { InvestorApplicationEditComponent } from "./modules/conference/investor/pages/investor-application-edit/investor-application-edit.component";
import { EmailTemplateEditComponent } from "./modules/template/pages/email-template-edit/email-template-edit.component";
import {
    ConferenceCreateComponent,
    ConferenceListComponent,
    ConferenceEditComponent,
    TrackListComponent,
    TrackCreateComponent,
    TrackCreateModalComponent,
    TrackCreateEditComponent,
    TrackEditModalComponent,
    TrackTimeslotCreateEditComponent,
    TrackTimeslotListComponent,
    ReportTemplateListComponent,
    ReportTemplateModalComponent,
    UserListComponent,
    UserCreateModalComponent,
    UserEditModalComponent,
    ConferenceTypeCreateModalComponent,
    EBDConfigurationListComponent,
    EBDConfigurationEditComponent,
    EBDConfigurationCreateComponent
    //TrackTimeslotEditComponent
    //TrackTimeslotCreateComponent
} from "./modules/administration";
import { TrackEditComponent } from "./modules/administration/track/pages/track-edit/track-edit.component";
import { TrackTimeslotCreateComponent } from "./modules/administration/track/components/track/track-timeslot-create/track-timeslot-create.component";
import { FixToBottomOnScrollDirective } from "./directives/fix-to-bottom-on-scroll.directive";
import { TrackTimeslotEditComponent } from "./modules/administration/track/components/track/track-timeslot-edit/track-timeslot-edit.component";
import { WordCountComponent } from "./shared/components/util/word-count/word-count.component";
import {
    ProfileComponent,
    ProfileConferenceComponent,
    ProfileListComponent
} from "./modules/conference/profile";
import { ProfileEditComponent } from "./modules/conference/profile/pages/profile-edit/profile-edit.component";
import { ReportTemplateEditModalComponent } from "./modules/administration/report/components/report-template-edit-modal/report-template-edit-modal.component";
import { InvitationConferenceComponent } from "./modules/conference/profile/pages/invitation-conference/invitation-conference.component";
import { InvitationComponent } from "./modules/conference/profile/pages/invitation/invitation.component";
import { InvitationListComponent } from "./modules/conference/profile/pages/invitation-list/invitation-list.component";
import { InvitationEditComponent } from "./modules/conference/profile/pages/invitation-edit/invitation-edit.component";
// import { JwtInterceptor } from "./core/helpers/jwt.Interceptor";
import { ConferenceTypeListComponent } from "./modules/administration/conference/pages/conference-type-list/conference-type-list.component";
import { ConferenceTypeEditModalComponent } from "./modules/administration/conference/components/conference-type-edit-modal/conference-type-edit-modal.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { SuccessComponent } from "./shared/pages/success/success.component";
import { ApplicationPreviewModalComponent } from "./modules/conference/investor/components/investor-application/application-preview-modal/application-preview-modal.component";
import { SelectionConfirmationComponent } from "./modules/conference/selection/pages/selection-confirmation/selection-confirmation.component";
import { SelectionConfirmationSuccessComponent } from "./modules/conference/selection/pages/selection-confirmation-success/selection-confirmation-success.component";
import { ConferenceUploadComponent } from "./modules/administration/conference/pages/conference-upload/conference-upload.component";
import { LoaderComponent } from "./shared/components/util/loader/loader.component";
import { LoaderService } from "./services/common/loader.service";
import { LoaderInterceptor } from "./core/helpers/loader.interceptor";
import { ScheduleConfirmationComponent } from "./modules/conference/schedule/pages/schedule-confirmation/schedule-confirmation.component";
import { ScheduleConfirmationSuccessComponent } from "./modules/conference/schedule/pages/schedule-confirmation-success/schedule-confirmation-success.component";
import { ProfilePreviewModalComponent } from "./modules/conference/profile/components/profile-preview-modal/profile-preview-modal.component";
import { InvitationPreviewModalComponent } from "./modules/conference/profile/components/invitation-preview-modal/invitation-preview-modal.component";
import { ScheduleConfirmationDoneComponent } from "./modules/conference/schedule/pages/schedule-confirmation-done/schedule-confirmation-done.component";
import { SafeHtmlPipe } from "./core/pipes/safehtml.pipe";
import { ChangePasswordComponent } from "./modules/administration/user/pages/change-password/change-password.component";
import { ConfirmationDialogComponent } from "./shared/components/util/confirmation-dialog/confirmation-dialog.component";
import { DateFormatPipe } from "./core/pipes/dateformat.pipe";
import { NoteLegendComponent } from "./modules/conference/selection/components/note-legend/note-legend.component";
import { UserIdleModule } from "angular-user-idle";
import { environment } from "../environments/environment";
import { ViewDetailModalComponent } from "./modules/conference/schedule/components/view-detail-modal/view-detail-modal.component";
import { ScheduleViewDetailComponent } from "./modules/conference/schedule/components/schedule-view-detail/schedule-view-detail.component";
import { SelectionDetailModalComponent } from "./modules/conference/selection/components/selection-detail-modal/selection-detail-modal.component";
import { SelectionViewDetailComponent } from "./modules/conference/selection/components/selection-view-detail/selection-view-detail.component";
import { ConferenceStatusComponent } from './shared/pages/conference-status/conference-status.component';
import { UserRoleComponent } from './modules/administration/user-role/user-role.component';
import { SummaryEmailConfirmationComponent } from "./modules/conference/investor/pages/summary-email-confirmation/summary-email-confirmation.component";
import { EmailConfirmationSuccessComponent } from "./modules/conference/investor/pages/email-confirmation-success/email-confirmation-success.component";
import { PressAccreditationConferenceComponent } from "./modules/conference/investor/pages/press-accreditation-conference/press-accreditation-conference.component";
import { PressAccreditationComponent } from "./modules/conference/investor/pages/press-accreditation/press-accreditation.component";
import { PressAccreditationPreviewModalComponent } from "./modules/conference/investor/pages/press-accreditation-preview-modal/press-accreditation-preview-modal.component";
import { PressAccreditationListComponent } from "./modules/conference/investor/pages/press-accreditation-list/press-accreditation-list.component";
import { PressAccreditationEmailsComponent } from "./modules/conference/investor/components/accreditation-emails/accreditation-email.component";
import { PressAccreditationEditComponent } from "./modules/conference/investor/pages/press-accreditation-edit/press-accreditation-edit.component";
import { AccreditationEmailConfirmationComponent } from "./modules/conference/investor/components/accreditation-email-confirmation/accreditation-email-confirmation.component";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        UrlRewriteComponent,
        UrlRewriteIaComponent,
        DashboardComponent,
        HomeLayoutComponent,
        LoginLayoutComponent,
        MenuComponent,
        InvestorSummaryComponent,
        InvestorApplicationComponent,
        SummaryEmailsComponent,
        InvestorSummaryListComponent,
        EmailSummaryComponent,
        SummaryEditsComponent,
        ApplicationListComponent,
        ApplicationEmailsComponent,
        AddAssetComponent,
        EditAssetComponent,
        AddEditFormComponent,
        InvestorSummaryEditComponent,
        NotificationComponent,
        ReportComponent,
        EmailTemplateComponent,
        EmailTemplateCreateComponent,
        EmailEventComponent,
        EmailEventCreateComponent,
        EmailEventEditComponent,
        InvestorApplicationEditComponent,
        EmailTemplateEditComponent,
        SelectionCompanyListComponent,
        OpenTracksComponent,
        AllTracksComponent,
        ScheduleEmailComponent,
        SelectionEmailComponent,
        SelectionEmailNotselectedComponent,
        ConferenceCreateComponent,
        ConferenceEditComponent,
        ConferenceListComponent,
        EBDConfigurationListComponent,
        EBDConfigurationEditComponent,
        EBDConfigurationCreateComponent,
        InvestorSummaryConferenceComponent,
        InvestorApplicationConferenceComponent,
        TrackListComponent,
        TrackCreateComponent,
        TrackCreateModalComponent,
        TrackCreateEditComponent,
        TrackEditModalComponent,
        TrackTimeslotEditComponent,
        TrackTimeslotCreateEditComponent,
        TrackTimeslotListComponent,
        TrackTimeslotCreateComponent,
        TrackEditComponent,
        FixToBottomOnScrollDirective,
        WordCountComponent,
        ReportTemplateListComponent,
        ReportTemplateModalComponent,
        ProfileConferenceComponent,
        ProfileComponent,
        ProfileListComponent,
        ProfileEditComponent,
        ReportTemplateEditModalComponent,
        InvitationConferenceComponent,
        InvitationComponent,
        InvitationListComponent,
        InvitationEditComponent,
        UserListComponent,
        UserCreateModalComponent,
        UserEditModalComponent,
        SummaryPreviewModalComponent,
        ApplicationPreviewModalComponent,
        ConferenceTypeCreateModalComponent,
        ConferenceTypeListComponent,
        ConferenceTypeEditModalComponent,
        SuccessComponent,
        SelectionConfirmationComponent,
        SelectionConfirmationSuccessComponent,
        ConferenceUploadComponent,
        LoaderComponent,
        ScheduleConfirmationComponent,
        ScheduleConfirmationSuccessComponent,
        ProfilePreviewModalComponent,
        InvitationPreviewModalComponent,
        ScheduleConfirmationDoneComponent,
        SafeHtmlPipe,
        DateFormatPipe,
        ChangePasswordComponent,
        ConfirmationDialogComponent,
        NoteLegendComponent,
        ViewDetailModalComponent,
        ScheduleViewDetailComponent,
        SelectionDetailModalComponent,
        SelectionViewDetailComponent,
        ConferenceStatusComponent,
        UserRoleComponent,
        SummaryEditModalComponent,
        SummaryEmailConfirmationComponent,
        EmailConfirmationSuccessComponent,
        PressAccreditationConferenceComponent,
        PressAccreditationComponent,
        PressAccreditationPreviewModalComponent,
        PressAccreditationListComponent,
        PressAccreditationEditComponent,
        PressAccreditationEmailsComponent,
        AccreditationEmailConfirmationComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DragDropModule,
        NgbModule.forRoot(),
        HttpClientModule,
        ToasterModule.forRoot(),
        UiSwitchModule,
        NgxEditorModule,
        MyDatePickerModule,
        NgMultiSelectDropDownModule.forRoot(),
        DataTablesModule,
        MatProgressSpinnerModule,
        AngularEditorModule,
        UserIdleModule.forRoot({
            idle: environment.IdleTime,
            timeout: environment.TimeOut,
            ping: environment.PingTime
        })
    ],
    providers: [
        AuthService,
        AuthGuard,
        SummaryService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        AddAssetComponent,
        TrackCreateModalComponent,
        TrackEditModalComponent,
        TrackTimeslotEditComponent,
        TrackTimeslotListComponent,
        TrackTimeslotCreateComponent,
        ReportTemplateModalComponent,
        ReportTemplateEditModalComponent,
        UserCreateModalComponent,
        UserEditModalComponent,
        SummaryPreviewModalComponent,
        ApplicationPreviewModalComponent,
        ConferenceTypeCreateModalComponent,
        ConferenceTypeEditModalComponent,
        ProfilePreviewModalComponent,
        InvitationPreviewModalComponent,
        ConfirmationDialogComponent,
        EditAssetComponent,
        NoteLegendComponent,
        ViewDetailModalComponent,
        SelectionDetailModalComponent,
        SummaryEditModalComponent,
        EmailConfirmationSuccessComponent,
        PressAccreditationConferenceComponent,
        PressAccreditationComponent,
        PressAccreditationPreviewModalComponent,
        PressAccreditationListComponent,
        PressAccreditationEditComponent,
        PressAccreditationEmailsComponent,
        AccreditationEmailConfirmationComponent
    ]
})
export class AppModule { }
