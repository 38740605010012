import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "ClientApp/app/models/User";
import { UserService } from "ClientApp/app/services/user.service";
import { PasswordValidator } from "ClientApp/app/core/validators/password.validator";
import { UserRoleService } from "ClientApp/app/services/user-role.service";

@Component({
    selector: "app-user-create-modal",
    templateUrl: "./user-create-modal.component.html",
    styleUrls: ["./user-create-modal.component.css"]
})
export class UserCreateModalComponent implements OnInit {
    submitted = false;
    response: any;
    users: any;
    userCreateForm!: FormGroup;
    user = new User();
    roles: any;
    constructor(
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        public activeModal: NgbActiveModal,
        private userService: UserService,
        private userRoleService: UserRoleService
    ) { }

    ngOnInit() {
        this.userRoleService.getUserRoleNames().subscribe((data: {}) => {
            this.roles = data;
        });
        this.userCreateForm = this.formBuilder.group(
            {
                name: ["", Validators.required],
                email: ["", Validators.required],
                password: ["", Validators.required],
                cpassword: ["", Validators.required],
                role: ["", Validators.required]
            },
            {
                validator: PasswordValidator("password", "cpassword")
            }
        );
    }

    get fc() {
        // get field control(fc)
        return this.userCreateForm.controls;
    }

    createUser(user: User) {
        this.userService.createUser(user).subscribe((result: {}) => {
            this.response = result;
            if (this.response.Code == 200) {
                var toast: Toast = {
                    type: "success",
                    title: this.response.Message,
                    showCloseButton: true
                };

                this.toasterService.pop(toast);
                this.activeModal.close();
            } else {
                var toast: Toast = {
                    type: "error",
                    title: this.response.Message,
                    showCloseButton: true
                };
                this.toasterService.pop(toast);
            }
        });
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.userCreateForm.invalid) {
            return;
        }

        this.user.LoginName = this.userCreateForm.value.name;
        this.user.EmailId = this.userCreateForm.value.email;
        this.user.Password = this.userCreateForm.value.password;
        this.user.UserName = "";
        this.user.RoleId = this.userCreateForm.value.role;
        this.createUser(this.user);
    }
}
