import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { SummaryService } from "ClientApp/app/services/investor/summary.service";
import {
  SendISP,
  SendISPReminder,
  SendISPCompany
} from "ClientApp/app/models/SendISPReminder";
import { ToasterService, Toast } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";

@Component({
  selector: "app-email-summary",
  templateUrl: "./email-summary.component.html",
  styleUrls: ["./email-summary.component.css"]
})
export class EmailSummaryComponent implements OnInit {
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  emails: any = [];
  _ISPModel = new SendISP();
  ISPModel = new SendISPReminder();
  selectedArr: Array<SendISP> = [];
  companyModel = new SendISPCompany();
  response: any;
  confID: any;
  dataTable: any;
  selectedValue: any;
  selectedPageSize: any;
  selectedFilterPrevVal: string = "";
  selectedSortPrevVal: any = [[0, "asc"]];
  selectedPagePrevVal: any = 0;
  constructor(
    public cService: ConferenceService,
    public summaryService: SummaryService,
    private toasterService: ToasterService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadDefault();
  }

  loadDefault() {
    this.loadConference(ConferenceConfig.CONF_SUMMARY);
  }

  // Get Conference list
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      console.log(this.conferenceItem.ConferenceId);
      this.confID = this.conferenceItem.ConferenceID;
      this.getConferenceDetails(this.confID);
    });
  }

  getConferenceDetails(conferenceId: number) {
    console.log(conferenceId);
    this._conferenceId = conferenceId;
    return this.cService
      .getConferenceById(conferenceId)
      .subscribe((data: {}) => {
        this.conferenceDetail = data;
        this.cInfo = this.conferenceDetail.ConferenceCategory;
        this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
        this.confID = this.conferenceDetail.Conference.ConferenceId;
        this.loadEmails(this.confID);
        //this.enablePagination();
      });
  }

    onChangeConference(conferenceId: number) {
    //console.log("Inside " + conferenceId);
    this.selectedArr = [];
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.selectedFilterPrevVal = "";
    this.selectedSortPrevVal = [[0, "asc"]];
    this.selectedPagePrevVal = 0;
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

   loadEmails(id: number) {
     return this.summaryService.getEmailList(id).subscribe((data: {}) => {
         this.emails = data;
         this.selectedArr = [];
       this.enablePagination();
     });
   }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#email_summary");
    this.dataTable = table.DataTable({
      pagingType: "full_numbers",
      paging: true,
      bDestroy: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      search: {"search": self.selectedFilterPrevVal},
      "order": self.selectedSortPrevVal,
      'displayStart': ((this.selectedPagePrevVal) * (this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH)),
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"],
     // serverSide: true,
     // processing: true,

      //ajax: (dataTablesParameters: any, callback: any) => {
      //  const page =
      //    parseInt(dataTablesParameters.start) /
      //      parseInt(dataTablesParameters.length) +
      //    1;
      //  let page_length = ConferenceConfig.DEFAULT_PAGE_LENGTH;
      //  if (dataTablesParameters.length > 25) {
      //    page_length = dataTablesParameters.length;
      //  } else {
      //    page_length = ConferenceConfig.DEFAULT_PAGE_LENGTH;
      //  }
      //  this.summaryService
      //    .getEmailList(this.confID)
      //    .subscribe((data: {}) => {
      //      this.emails = data;
      //      callback({
      //        recordsTotal: this.emails.TotalItems
      //          ? this.emails.TotalItems.TotalCount
      //          : 0,
      //        recordsFiltered: this.emails.TotalItems
      //          ? this.emails.TotalItems.TotalCount
      //          : 0,
      //        data: []
      //      });
      //      console.log(this.emails);
      //    });
      //}
    });
    $('#email_summary').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

  onChange(e: any, id: number, email: string) {
    // onChange(e: any) {
    //console.log(e);
    console.log(id);
    console.log(email);
    if (e.target.checked) {
      this._ISPModel.CompId = id;
      this._ISPModel.Email = email;
      //console.dir(this.ISPModel);
      this.selectedArr.push(this._ISPModel);
      this._ISPModel = new SendISP();
    }else{
      let unselectedIndex = this.selectedArr.map((sa: any) => sa.CompId).indexOf(id);
      if (unselectedIndex !== -1)
            {
      this.selectedArr = this.selectedArr.filter((x, i) => i !== unselectedIndex);
            }
    }
  }
    getIconByEmailStatus(status: number) {
        if (status === 0)
            return 'fa fa-ban'
        else if (status === 1)
            return 'fa fa-check'
    }
    sendEmail() {
    this.ISPModel.Info = this.selectedArr;
    this.companyModel.Conference_Id = this.confID;
    this.ISPModel.CompanyDetails = this.companyModel;
    console.log(this.ISPModel);
    //Info:[{"CompId":4,"Email":"test@gmail.com"}]}
    if (this.selectedArr.length > 0) {
      console.log(this.ISPModel);
      return this.summaryService.sendEmail(this.ISPModel).subscribe(result => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        console.log('response--',this.response);
        
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true
          };
          this.selectedFilterPrevVal = this.dataTable.search();
          this.selectedSortPrevVal = this.dataTable.order();
          this.selectedPagePrevVal = this.dataTable.page();
            this.toasterService.pop(toast);
            if (this.dataTable) {
                this.dataTable.destroy();
            }
            this.loadEmails(this.confID);
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true
          };
          this.toasterService.pop(toast);
        }
      });
    }
  }
}
