import { Template } from "ClientApp/app/models/Template";
import { Injectable } from "@angular/core";
import { environment } from "ClientApp/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Injectable({
  providedIn: "root"
})
export class EmailTemplateService {
  apiURL = environment.baseUrl;
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
      //"Access-Control-Allow-Origin": "*"
    })
  };

  createTemplate(template: Template) {
    // console.log("In service");
    // console.dir(template);
    return this.http.post(
      this.apiURL + "/Template",
      template,
      this.httpOptions
    );
  }

  updateTemplateById(template: Template) {
    // console.log("In service");
    // console.dir(template);
    return this.http.put(this.apiURL + "/Template", template, this.httpOptions);
  }

  getConference() {
    return this.http.get(this.apiURL + "/Conference");
  }

  getActiveConference() {
    return this.http.get(this.apiURL + "/Conference/ActiveConferences");
  }

  getAllEvents(){
    return this.http.get(this.apiURL + "/Template/ListAllEvents");
  }

  getTemplates() {
    //console.log("In getTemplates-  service");
    return this.http.get(this.apiURL + "/Template/ListAllTemplates");
  }

  getTemplatesByConferenceId(conferenceId: number) {
    console.log("In getTemplates-  url---",this.apiURL + "/Template/ListAllTemplates/" + conferenceId);
    return this.http.get(
      this.apiURL + "/Template/ListAllTemplates/" + conferenceId //+ "/1/2"
    );
  }

  getTemplateById(id: number) {
    return this.http.get(this.apiURL + "/Template/" + id);
  }

  deleteTemplate(id: any) {
    return this.http.delete(this.apiURL + "/Template/" + id);
  }

  // createTemplate(template: Template) {
  //   console.log("In service");
  //   console.dir(template);
  //   return this.http
  //     .post(this.apiURL + "/Template", template, this.httpOptions)
  //     .subscribe(result => {
  //       console.log(result);
  //       console.dir(result);
  //       return result;
  //     });
  // }
}
