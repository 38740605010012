import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { ToasterService, Toast } from "angular2-toaster";
import * as _ from "underscore";
import moment from "moment";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";

@Component({
  selector: "app-invitation-list",
  templateUrl: "./invitation-list.component.html",
  styleUrls: ["./invitation-list.component.css"]
})
export class InvitationListComponent implements OnInit {
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  confs: any = [];
  invitations: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  public conferenceList: any = [];
  conferenceId: number = 0;
  response: any;
  dataTable: any;
  selectedValue: any;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: []
  };
  selectedPageSize: any;
  constructor(
    public cService: ConferenceService,
    private profileService: ProfileService,
    private chRef: ChangeDetectorRef,
    private toasterService: ToasterService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_INVITATION);
  }

  loadConference(typeId: number) {
    this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      console.log(this.Conferences);
      if (this.Conferences != null && this.Conferences.length > 0) {
        this.conferenceItem = this.Conferences[0];
        this.selectedValue = this.conferenceItem;
        this._conferenceId = this.conferenceItem.conferenceId;
        console.log(this.conferenceItem.ConferenceId);
        this.getConferenceDetails(this.conferenceItem.ConferenceID);
      }
    });
  }

  onChangeConference(id: number) {
    console.log("Inside " + id);
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.getConferenceDetails(this.selectedValue.ConferenceID);
  }

  getConferenceDetails(id: number) {
    console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
      console.log(this.cInfo);
      this.loadInvitations(this._conferenceId);
    });
  }

  loadInvitations(conferenceId: number) {
    return this.profileService
      .loadInvitations(conferenceId)
      .subscribe((data: {}) => {
        this.response = data;
        this.invitations = this.response.Info;
        this.enablePagination();
      });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#view_invitation_list");
    this.dataTable = table.DataTable({
      "aoColumns": [
        null,
        null,
        {"sType": "date"},
        null,
        null,
        null
      ],
      pagingType: "full_numbers",
      paging: true,
      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      order: [[1, "desc"]],
      columnDefs: [{ type: 'date', 'targets': [1] }],
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>'
        }
      },
      stripeClasses: ["odd-row", "even-row"]
    });
    $('#view_invitation_list').on( 'length.dt', function ( e, settings, len ) {
      self.selectedPageSize = len;
    });
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.CompanyName)
      .then(confirmed => {
        this.profileService.deleteInvitation(obj.Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadInvitations(this._conferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

    downloadCSV() {
    var reportdata: any = [];
    _.each(this.invitations, function(obj: any) {
      reportdata.push({
        Company: obj.CompanyName != null ? obj.CompanyName : "",
        Prefix: obj.PrefixName || "",
        FirstName: obj.FirstName || "",
        LastName: obj.LastName || "",
        MiddleName: obj.MiddleName || "",
        NickName: obj.NickName || "",
        Suffix: obj.SuffixName || "",
        Title: obj.Title || "",
        Email: obj.Email || "",
        Website: obj.Website || "",
        Address1: obj.Address1 || "",
        Address2: obj.Address2 || "",
        City: obj.City || "",
        State: obj.State || "",
        Country: obj.Country || "",
        Zip: obj.PostalCode || "",
        Phone: obj.Phone || "",
        AssistantEmail: obj.AssistantEmail || "",
        AssistantPhone: obj.AssistantPhone || "",
        LastUpdated:
          obj.newupdatedate != "Null"
            ? moment(obj.newupdatedate).format("MM/DD/YYYY hh:mm a")
            : "",
          Conference: obj.ConferenceName != null ? obj.ConferenceName : "",
      });
    });

        let headers: any = ["Company", "Prefix", "FirstName", "LastName", "MiddleName", "NickName",
            "Suffix", "Title", "Email", "Website", "Address1","Address2", "City", "State", "Country", "Zip",
            "Phone", "AssistantEmail","AssistantPhone","LastUpdated", "Conference"];
    this.csvOptions.headers = headers;
    this.csvOptions.title = "Invitation List - " + this.ConferenceName;
    new AngularCsv(reportdata, "Invitation List", this.csvOptions);
  }
}
