﻿import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SummaryService } from "ClientApp/app/services/investor/summary.service";

@Component({
    selector: "app-user-create-modal",
    templateUrl: "./summary-edit.html",
    styleUrls: []
})
export class SummaryEditModalComponent implements OnInit {
    @Input() compId: number = 0;
    submitted = false;
    response: any;
    summaryEditForm!: FormGroup;
    summary: any = {};
    constructor(
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        private summaryService: SummaryService,
        public activeModal: NgbActiveModal,
    ) { }

    ngOnInit() {

        this.summaryEditForm = this.formBuilder.group(
            {
                pitch: [""],
                edited: [""],
                moderator: [""],
                isStet: [""]
            }
        );
        
        if (this.compId) {
            this.summaryService.getEditSummaryById(this.compId).subscribe((result: {})=> {
                this.response = result;
                if (this.response && this.response.Message.Code == 200) {
                    this.summaryEditForm.patchValue({
                        pitch: this.response.Info.InvestorProposition,
                        edited: this.response.Info.InvestorPropositionEdited,
                        moderator: this.response.Info.InvestorPropositionModerator,
                        isStet: this.response.Info.IsStet == 1 ? true : false,
                    });
                }
            });
        }
    }

    get fc() {
        // get field control(fc)
        return this.summaryEditForm.controls;
    }

    updateSummary(summary: any) {
        this.summaryService.updateSummaryEdits(summary).subscribe((result: {}) => {
            this.response = result;
            if (this.response.Code == 200) {
                var toast: Toast = {
                    type: "success",
                    title: this.response.Message,
                    showCloseButton: true
                };

                this.toasterService.pop(toast);
                this.activeModal.close();
            } else {
                var toast: Toast = {
                    type: "error",
                    title: this.response.Message,
                    showCloseButton: true
                };
                this.toasterService.pop(toast);
            }
        });
    }

    onSubmit() {
            this.summary.InvestorProposition = this.summaryEditForm.value.pitch;
            this.summary.InvestorPropositionEdited = this.summaryEditForm.value.edited;
            this.summary.InvestorPropositionModerator = (this.summaryEditForm && this.summaryEditForm.value && this.summaryEditForm.value.moderator) ? this.summaryEditForm.value.moderator : "" ;
            this.summary.CompId = this.compId;
            this.summary.IsStet = this.summaryEditForm.value.isStet ? 1 : 0;
            this.updateSummary(this.summary);
    }
}
