﻿import { Component, OnInit, Input } from "@angular/core";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { Application } from "ClientApp/app/models/Application";
import { ApplicationService } from "ClientApp/app/services/investor/application.service";
import { PressAccreditationService } from "../press-accreditation/press-accreditation.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";

@Component({
    selector: "press-accreditation-preview-modal",
    templateUrl: "./press-accreditation-preview-modal.component.html",
    styleUrls: []
})
export class PressAccreditationPreviewModalComponent implements OnInit {
    @Input() accreditationForm: any;
    @Input() appItem: any;
    submitMessage: string | undefined;
    processMessage: string | undefined;
    btnMessage: string | undefined;
    disableBtn: boolean | undefined;
    response: any;
    conferenceDetail: any;
    isEBDConf: boolean;
    constructor(
        private toasterService: ToasterService,
        public activeModal: NgbActiveModal,
        private router: Router,
        public pressAccreditationService: PressAccreditationService,
        public cService: ConferenceService
    ) { }

    ngOnInit() {
        console.log('accreditation form', this.accreditationForm);
        this.submitMessage = ConferenceConfig.ACCREDITATION_BTN_SUBMIT_MESSAGE;
        this.processMessage = ConferenceConfig.BTN_PROCESS_MESSAGE;
        this.btnMessage = this.submitMessage;
        if (this.appItem.companyDetails && this.appItem.companyDetails.ConferenceID){
            return this.cService.getConferenceById(this.appItem.companyDetails.ConferenceID).subscribe((data: {}) => {
              this.conferenceDetail = data;
              this.isEBDConf = this.conferenceDetail.Conference.isEBD;
            });
          }
    }
    onSubmit() {
        this.createPressAccreditation(this.appItem);
    }

    createPressAccreditation(application: any) {
        this.disableBtn = true;
        this.btnMessage = this.processMessage;
        return this.pressAccreditationService
            .createAccreditation(application)
            .subscribe(result => {
                // This code will be executed when the HTTP call returns successfully
                this.response = result;
                if (this.response.Code == 200) {
                    var toast: Toast = {
                        type: "success",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                    this.activeModal.close();
                    // Redirect to success page.
                    let confId = this.response.EncryptedConferenceId;
                    let id = this.response.EncryptedId;
                    let type = 5; // For Accreditation
                    this.router.navigate([
                        "accreditation/success/" + confId + "/" + type + "/" + id
                    ]);
                } else {
                    var toast: Toast = {
                        type: "error",
                        title: this.response.Message,
                        showCloseButton: true
                    };
                    this.toasterService.pop(toast);
                }
                this.disableBtn = false;
                this.btnMessage = this.submitMessage;
            });
    }
}
