import { Component, OnInit, Input } from "@angular/core";
import { Profile } from "ClientApp/app/models/Profile";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { ToasterService, Toast } from "angular2-toaster";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { debug } from "util";

@Component({
  selector: "app-profile-preview-modal",
  templateUrl: "./profile-preview-modal.component.html",
  styleUrls: ["./profile-preview-modal.component.css"]
})
export class ProfilePreviewModalComponent implements OnInit {
  @Input() profileItem: any;
  @Input() profileForm: any;
  submitMessage: string | undefined;
  processMessage: string | undefined;
  btnMessage: string | undefined;
  disableBtn: boolean | undefined;
  response: any;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private profileService: ProfileService,
    private toasterService: ToasterService
  ) {}

    ngOnInit() {
    console.log(this.profileForm);
    this.submitMessage = ConferenceConfig.PROFILE_BTN_SUBMIT_MESSAGE;
    this.processMessage = ConferenceConfig.BTN_PROCESS_MESSAGE;
    this.btnMessage = this.submitMessage;
  }

  onSubmit() {
    this.createProfile(this.profileItem);
  }

  createProfile(profile: Profile) {
    console.log("In createProfile....");
    this.disableBtn = true;
    return this.profileService.createProfile(profile).subscribe(result => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true
        };

        this.toasterService.pop(toast);
        this.activeModal.close();
        // Redirect to success page.
        let confId = this.response.EncryptedConferenceId;
        let id = this.response.EncryptedId;
        let type = 3; // For Profile
        this.router.navigate([
          "profile/participantprofile/success/" + confId + "/" + type + "/" + id
        ]);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true
        };
        this.toasterService.pop(toast);
      }
      this.disableBtn = false;
    });
  }
}
