import { Component, OnInit } from "@angular/core";
import { ToasterConfig } from "angular2-toaster";

@Component({
  selector: "app-home-layout",
  templateUrl: "./home-layout.component.html",
  styleUrls: ["./home-layout.component.css"]
})
export class HomeLayoutComponent implements OnInit {
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    animation: "fade",
    timeout: 10000
  });
  toggleNav = false;
  constructor() {}

  ngOnInit() {}

  toggleNavClass() {
    this.toggleNav = !this.toggleNav;
  }
}

// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-home-layout',
//   template: `
//     <app-header></app-header>
//     <router-outlet></router-outlet>
//     <app-footer></app-footer>
//   `,
//   styles: []
// })
// export class HomeLayoutComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
