import {
  Component,
  OnInit,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ApplicationService } from "ClientApp/app/services/investor/application.service";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { ConferenceConfig } from "ClientApp/app/config/appSettings";
import {
  ApplicationEmailItem,
  ApplicationListUpdateModel,
} from "ClientApp/app/models/Application";
import { Toast, ToasterService } from "angular2-toaster";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import * as _ from "underscore";
import moment from "moment";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
@Component({
  selector: "app-application-list",
  templateUrl: "./application-list.component.html",
  styleUrls: ["./application-list.component.css"],
})
export class ApplicationListComponent implements OnInit {
  NoteForm!: FormGroup;
  applications: any = [];
  confs: any = [];
  Conferences: any = [];
  conferenceItem: any;
  _conferenceId: number = 0;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  appItem = new ApplicationEmailItem();
  appList = new ApplicationListUpdateModel();
  selectionModelArr: any = [];
  response: any;

  overlay = false;
  dataTable: any;
  selectedValue: any;
  statusTick: string;
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    showTitle: true,
    title: "",
    useBom: true,
    noDownload: false,
    headers: [
      "CompanyName",
      "Contact",
      "Created Date",
      "Updated Date",
      "Email",
      "Funding Rounds",
      "Time Frames",
    ],
  };
  approved: number;
  declined: number;
  pending: number;
  specialcases: number;
  selectedPageSize: any;
  inPerson: number;
  virtual: number;
  //notes
  public ngbModalOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: undefined,
  };
  @ViewChild("SelectionNotes") myContent: TemplateRef<any>;

  modalReference: any;
  submitted: boolean = false;
  fc: any;
  applicationid: any;
  selectedInpersonCount: any;
  selectedVirtualCount: any;
  //end
  constructor(
    private formBuilder: FormBuilder,
    public appService: ApplicationService,
    public cService: ConferenceService,
    private toasterService: ToasterService,
    private chRef: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.loadConference(ConferenceConfig.CONF_APPLICATION);
    this.statusTick = "assets/img/tick.png";
    this.NoteForm = this.formBuilder.group({
      Remark: [""],
      Status: [""],
    });
    this.fc = this.NoteForm.controls;
  }

  // Get Conference list
  loadConference(typeId: number) {
    return this.cService.getConferenceByType(typeId).subscribe((data: {}) => {
      this.confs = data;
      this.Conferences = this.confs.Info;
      this.conferenceItem = this.Conferences[0];
      this.selectedValue = this.conferenceItem;
      // console.log(this.conferenceItem.ConferenceId);
      this.getConferenceDetails(this.conferenceItem.ConferenceID);
      // this.participationStatusCount(this.conferenceItem.ConferenceID);
    });
  }

  getConferenceDetails(id: any) {
    // console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      console.log(this.cInfo);
      //this.loadApplications(this._conferenceId);
      this._conferenceId = this.conferenceDetail.Conference.ConferenceId;
      this.loadApplications(this.conferenceDetail.Conference.ConferenceId);
    });
  }

  onChangeConference(id: any) {
    console.log("Inside " + id);
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.getConferenceDetails(this.selectedValue.ConferenceID);
    this.participationStatusCount(this.selectedValue.ConferenceID);
  }

  // Get applications list
  loadApplications(conferenceId: number, isRefresh?: boolean) {
    return this.appService
      .getApplications(conferenceId)
      .subscribe((data: {}) => {
        if (isRefresh && this.dataTable) {
          this.dataTable.destroy();
        }
        this.applications = data;
        console.log("getApplications--", this.applications);

        this.enablePagination();
        this.investorStatusCount();
      });
  }
  //Get InPerson / Virtual count
  participationStatusCount(id: any) {
    this.appService.getParticipationStatusCount(id).subscribe((data: any) => {
      if (data.Status) {
        console.log("get--ParticipationStatusCount", data);
        this.inPerson = data.Info.InPersonCount;
        this.virtual = data.Info.VirtualCount;
        this.selectedInpersonCount = data.Info.SelectedInPersonCount;
        this.selectedVirtualCount = data.Info.SelectedVirtualCount;
      }
    });
  }

  enablePagination() {
    let self = this;
    this.chRef.detectChanges();
    const table: any = $("#application_list");

    this.dataTable = table.DataTable({
      aoColumns: [
        null,
        null,
        { sType: "date" },
        { sType: "date" },
        null,
        null,
        null,
        null,
      ],
      pagingType: "full_numbers",
      paging: true,
      processing: true,

      pageLength: this.selectedPageSize || ConferenceConfig.DEFAULT_PAGE_LENGTH,
      lengthMenu: ConferenceConfig.DEFAULT_PAGE_OPTIONS,
      order: [[2, "desc"]],
      columnDefs: [{ type: "date", targets: [2] }],
      language: {
        search:
          '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
        searchPlaceholder: "Search for...",
        paginate: {
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>',
        },
      },
      stripeClasses: ["odd-row", "even-row"],
    });
    $("#application_list").on("length.dt", function (e, settings, len) {
      self.selectedPageSize = len;
    });
  }
  investorStatusCount() {
    var approved = _.filter(this.applications.Info, function (obj: any) {
      return obj.EmailType == "Selected";
    });
    if (approved) this.approved = approved.length;
    var declined = _.filter(this.applications.Info, function (obj: any) {
      return obj.EmailType == "Declined";
    });
    if (declined) this.declined = declined.length;
    var pending = _.filter(this.applications.Info, function (obj: any) {
      return obj.EmailType != "Declined" && obj.EmailType != "Selected";
    });
    if (pending) this.pending = pending.length;

    // var pending = _.filter(this.applications.Info, function (obj: any) {
    //     return obj.EmailType != "Declined" && obj.EmailType != "Selected" && obj.IsSelected != 2;
    // });
    // if (pending)
    //     this.pending = pending.length;

    // var specialcase= _.filter(this.applications.Info, function (obj: any) {
    //         return obj.IsSelected == 2;
    // });
    // if (specialcase)
    //     this.specialcases=specialcase.length;
  }
  onChange(e: any, id: number) {
    this.appItem.Id = id;
    this.appItem.IsSelected = e.target.checked ? 1 : 0;
    this.selectionModelArr = [];
    this.selectionModelArr.push(this.appItem);
    this.appItem = new ApplicationEmailItem();
    this.appList.InvestorSelections = this.selectionModelArr;
    // console.log(this.appList);
    this.overlay = true;
    this.updateSelection(this.appList);
  }

  updateSelection(model: ApplicationListUpdateModel) {
    this.appService.updateSelection(model).subscribe((result) => {
      this.response = result;
      this.overlay = false;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true,
        };

        this.toasterService.pop(toast);
        debugger;
        this.participationStatusCount(this.selectedValue.ConferenceID);
        //this.loadApplications(this._conferenceId, true);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true,
        };
        this.toasterService.pop(toast);
        this.loadApplications(this._conferenceId, true);
      }
    });
  }

  disableOverlay() {
    this.overlay = false;
  }

  hasProp(o: any, name: any) {
    return o.hasOwnProperty(name);
  }

  deleteConfirmation(obj: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + obj.CompanyName)
      .then((confirmed) => {
        this.appService.deleteApplication(obj.Id).subscribe((data: {}) => {
          this.response = data;
          if (this.response.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: this.response.Message,
              showCloseButton: true,
            };

            this.toasterService.pop(toast);
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadApplications(this._conferenceId);
          } else {
            var toast: Toast = {
              type: "error",
              title: this.response.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
          }
        });
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  getIconByEmailType(emailType: string) {
    if (emailType === "Declined") return "fa fa-ban";
    else if (emailType === "Selected") return "fa fa-check";
  }
  onChangeFlag(value: string) {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    if (value == "false") {
      this.applications.Info = _.filter(
        this.applications.Info,
        function (obj: any) {
          return obj.IsSelected == true;
        }
      );
      this.enablePagination();
      this.investorStatusCount();
    } else {
      this.loadApplications(this.conferenceDetail.Conference.ConferenceId);
    }
  }

  downloadCSV() {
    var reportdata: any = [];
    _.each(this.applications.Info, function (obj: any) {
      reportdata.push({
        Company: obj.CompanyName != null ? obj.CompanyName : "",
        Contact: obj.ContactFirstName + " " + obj.ContactLastName,
        CreatedOn:
          obj.CreatedOn != null
            ? moment(obj.CreatedOn).format("MM/DD/YYYY hh:mm:a")
            : "",
        LastUpdated:
          obj.newupdatedate != "Null"
            ? moment(obj.newupdatedate).format("MM/DD/YYYY hh:mm:a")
            : "",
        Email: obj.EmailType || "",
        // FoundRounds:obj.FoundRounds||"",
        // TimeFrames:obj.TimeFrames|| ""
      });
    });
    this.csvOptions.title = "Investor Applications - " + this.ConferenceName;
    new AngularCsv(reportdata, "Investor Applications", this.csvOptions);
  }
  // Add Notes
  AddNotes(item: any) {
    debugger;
    console.log("item:", item);
    this.applicationid = item.ApplicationId;
    var data = {
      Status: item.IsSelected.toString(),
      Remark: item.Remark,
    };

    this.NoteForm.patchValue(data);
    this.modalReference = this.modalService.open(
      this.myContent,
      this.ngbModalOptions
    );
  }
  //save notes
  SaveNotes() {
    this.submitted = true;
    if (this.NoteForm.invalid) {
      return;
    } else {
      debugger;
      var status = +this.NoteForm.value.Status;
      var d = {
        ApplicationId: this.applicationid,
        IsSelected: status,
        Remark: this.NoteForm.value.Remark,
      };
      console.log("data", d);
      return this.appService
        .updateNotesInApplication(d)
        .subscribe((res: any) => {
          console.log("response", res);
          if (res.Code == 200) {
            var toast: Toast = {
              type: "success",
              title: res.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
            debugger;
            this.modalReference = this.modalService.dismissAll();
            this.submitted = false;
            this.NoteForm.reset();
            if (this.dataTable) {
              this.dataTable.destroy();
            }
            this.loadApplications(
              this.conferenceDetail.Conference.ConferenceId
            );
          } else {
            var toast: Toast = {
              type: "error",
              title: res.Message,
              showCloseButton: true,
            };
            this.toasterService.pop(toast);
          }
        });
    }
  }
}
