import { Component, OnInit } from '@angular/core';
import { ConferenceService } from 'ClientApp/app/services/conference.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-selection-confirmation-success',
  templateUrl: './selection-confirmation-success.component.html',
  styleUrls: ['./selection-confirmation-success.component.css']
})
export class SelectionConfirmationSuccessComponent implements OnInit {
  isBioFutureType: boolean = false;
  constructor(private cService: ConferenceService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    let conferenceId = this.route.snapshot.paramMap.get("conferenceId");
    this.getConferenceDetails(conferenceId);
  }

  getConferenceDetails(conferenceId: any) {
    return this.cService
        .getConferenceById(conferenceId)
        .subscribe((data: any) => {
            if (data && data.Conference){
              if (data.Conference.Type && (data.Conference.Type.indexOf("BioFuture") != -1)){
                this.isBioFutureType = true;
              }
            }
        });
}

}
