import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  Application,
  CompanyDetails,
  ContactDetails,
  InvestorDetails,
  AssistantDetails,
  FoundRoundList,
  TimeFrameList,
} from "ClientApp/app/models/Application";
import { ApplicationService } from "ClientApp/app/services/investor/application.service";
import { ToasterService, Toast } from "angular2-toaster";
import { ActivatedRoute, Router } from "@angular/router";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { State, Country } from "ClientApp/app/models/Summary";
import { UtilService } from "ClientApp/app/services/common/util.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { environment } from "ClientApp/environments/environment";
import {
  AssetsUnderManagement,
  Therapeutics,
  InvestorTypes,
} from "../../../../../shared/data/Collection";
import { any } from "underscore";
declare let gtag: Function;

@Component({
  selector: "app-investor-application-edit",
  templateUrl: "./investor-application-edit.component.html",
  styleUrls: ["./investor-application-edit.component.css"],
})
export class InvestorApplicationEditComponent implements OnInit {
  @ViewChild("namedElement") namedElement: ElementRef;
  isOtherOptionVisible: boolean = false;
  App: any = {};
  applicationEditForm!: FormGroup;
  submitted = false;
  disableBtn = false;
  public id!: any;
  application = new Application();
  companyDetails = new CompanyDetails();
  contactDetails = new ContactDetails();
  assistantDetails = new AssistantDetails();
  investorDetails = new InvestorDetails();
  funding = new FoundRoundList();
  timeFrameList = new TimeFrameList();
  response: any;
  public showBtn: boolean = false;
  public fixedDiv: boolean = false;
  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  isEBDConf: boolean;
  cInfo: any;
  _conferenceId: any;
  radioSelected: number = 0;
  countryResponse: any;
  stateResponse: any;
  public countries: any = [];
  public states: any = [];
  state = new State();
  country = new Country();
  countryCode: any;
  validationError: boolean = false;
  conferenceID: any;
  banner: any;
  conferenceIsActive: any;
  vData: any;
  IsExpire: boolean;
  loginuser: any;
  fundingRounds: any = [
    "Angel",
    "Seed",
    "Series A",
    "Series B",
    "Series C+",
    "Post-IPO",
    "Other",
  ];
  newArr: any = [];
  newTimeArray: any = [];
  participationmethodRadio: string = "0";
  assets = AssetsUnderManagement;
  therapeutics = Therapeutics;
  investorTypes = InvestorTypes;
  // fundingRounds: any = [
  //   {
  //     id: 1,
  //     FundingRound: "Angel"
  //   },
  //   {
  //     id: 2,
  //     FundingRound: "Seed"
  //   },
  //   {
  //     id: 3,
  //     FundingRound: "Series A"
  //   },
  //   {
  //     id: 4,
  //     FundingRound: "Series B"
  //   },
  //   {
  //     id: 5,
  //     FundingRound: "Series C+"
  //   },
  //   {
  //     id: 6,
  //     FundingRound: "Post-IPO"
  //   },
  //   {
  //     id: 7,
  //     FundingRound: "Other"
  //   }
  // ]
  timeFrames: any = ["Now", "Next year", "Not actively investing"];
  // timeFrames:any=[
  //   {
  //     "ids": 1,
  //     "TimeFrame": "Now"
  //   },
  //   {
  //     "ids": 2,
  //     "TimeFrame": "Next year"
  //   },
  //   {
  //     "ids": 3,
  //     "TimeFrame": "Not actively investing"
  //   }
  // ]
  dropdownSettingsTime = {};
  dropdownSettingsRound = {};
  constructor(
    private formBuilder: FormBuilder,
    public applicationService: ApplicationService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    public cService: ConferenceService,
    private utilService: UtilService,
    private element: ElementRef,
    private router: Router
  ) {}

  // ngAfterViewInit() {
  //   this.namedElement.nativeElement.click();
  // }

  ngOnInit() {
    const appId = this.route.snapshot.paramMap.get("id");
    this.id = appId !== null ? appId : 0;
    this.loginuser = localStorage.getItem("currentUser");
    this.getApplicationById(this.id);

    this.applicationEditForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      website: [""],
      ccsalutation: ["", Validators.required],
      cfirstName: ["", Validators.required],
      clastName: ["", Validators.required],
      cposition: ["", Validators.required],
      cdepartment: [""],
      ccontactEmail: ["", [Validators.required, Validators.email]],
      country: ["", Validators.required],
      streetAddress: ["", Validators.required],
      suitePoBox: [""],
      city: ["", Validators.required],
      state: [""],
      zip: ["", Validators.required],
      ccontactPhone: ["", Validators.required],
      asalutation: [""],
      afirstName: [""],
      alastName: [""],
      acontactEmail: [""],
      acontactPhone: [""],
      investorType: ["", Validators.required],
      typeOther: [""],
      assets: ["", Validators.required],
      fundInvested: [""],
      pensionInvested: [""],
      familyOfficeInvested: [""],
      ventureInvested: [""],
      angelInvested: [""],
      charityInvested: [""],
      FINRAInvested: [""],
      analystInvested: [""],
      crowdInvested: [""],
      additionalComments: [""],
      rounds: ["", Validators.required],
      timeFrame: ["", Validators.required],
      participationmethod: ["", Validators.required],
      // participation removed as per the instruction from Anish sir and later uncommented and displayed on condition basis
      // if 'enable participation method' is true in conferance type then enable participation method
      cTwitterProfile: [""],
      cLinkedInProfile: [""],
      isAccredited: ["", Validators.required],
      therapeutics: ["", Validators.required],
      customTherapeutics: [""],
    });

    // ------------multiselection fields-------------------//
    this.dropdownSettingsRound = {
      singleSelection: false,
      idField: "id",
      textField: "FundingRound",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    this.dropdownSettingsTime = {
      singleSelection: false,
      idField: "ids",
      textField: "TimeFrame",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
  }

  public showAlert(msg: string) {
    //console.log('testing virtual data');
  }

  //--------------- Multi Selection-----------------//
  onItemSelectRound(item: any) {
    this.fundingRounds.push(item);
    this.enableOrDisableValidations();
  }
  onSelectAllRound(items: any) {
    this.fundingRounds = items;
    this.enableOrDisableValidations();
  }

  onDeSelectRound(item: any) {
    var pos = this.fundingRounds
      .map(function (e: { id: number }) {
        return e.id;
      })
      .indexOf(item.id);
    this.fundingRounds.splice(pos, 1);
    this.enableOrDisableValidationsRound();
  }

  enableOrDisableValidationsRound() {}

  // ------------Time Frame ------------------//
  onItemSelect(item1: any) {
    this.timeFrames.push(item1);
    this.enableOrDisableValidations();
  }
  onSelectAll(items1: any) {
    this.timeFrames = items1;
    this.enableOrDisableValidations();
  }

  onDeSelect(item1: any) {
    var pos = this.timeFrames
      .map(function (e: { ids: number }) {
        return e.ids;
      })
      .indexOf(item1.ids);
    this.timeFrames.splice(pos, 1);
    this.enableOrDisableValidations();
  }

  enableOrDisableValidations() {}

  getApplicationById(id: number) {
    this.applicationService.getApplication(id).subscribe((data: {}) => {
      this.App = data;
      console.log("edit data", this.App);
      this.countryCode = this.App.ContactInfo.CountryCode;
      this.country.Code = this.App.ContactInfo.CountryCode;
      this.country.Name = this.App.ContactInfo.Country;
      this.state.Code = this.App.ContactInfo.StateCode;
      this.state.Name = this.App.ContactInfo.State;
      this._conferenceId = this.App.CompanyInfo.Conference_Id;
      this.conferenceID = this.App.CompanyInfo.ConferenceID;
      console.log(this.countryCode);
      //console.log('editForm GETround-----------------',this.App.FoundRoundList);
      let x = this.App.FoundRoundList;
      var roundData: any = [];
      for (let i = 0; i < x.length; i++) {
        const element = x[i];
        // console.log('eleme--',element.FundingRound);
        roundData.push(element.FundingRound);
      }
      //console.log('roundData--',roundData);

      //   const newArray =[];
      // for (let index = 0; index < roundData.length; index++) {
      //   let a = {"FundingRound":roundData[index]}
      //   newArray.push(a);
      // }
      // console.log('newArray',newArray);
      //   let selectedRounds = newArray.find({element}) => ZoneId === roundData)
      // console.log('selectedRounds-',selectedRounds);
      //console.log('editForm timeframe-----------------',this.App.TimeFramesList);
      let y = this.App.TimeFramesList;
      var timeData: any = [];
      for (let j = 0; j < y.length; j++) {
        const elements = y[j];
        // console.log('eleme--',element.FundingRound);

        timeData.push(elements.TimeFrame);
      }
      //console.log('timeData--',timeData);
      this.getConferenceDetails(this.conferenceID);
      if (this.App.CompanyInfo.ParticipationMethod == 0) {
        this.participationmethodRadio = "0";
      }
      if (this.App.CompanyInfo.ParticipationMethod == 1) {
        this.participationmethodRadio = "1";
      }

      this.isOtherOptionVisible = this.App.InvestorInfo.Therapeutics.id == 100;
      this.applicationEditForm.patchValue({
        companyName: this.App.CompanyInfo.Name,
        website: this.App.CompanyInfo.Website,
        // participationmethod:this.App.CompanyInfo.ParticipationMethod,
        participationmethod: this.participationmethodRadio,
        ccsalutation: this.App.ContactInfo.Title,
        cfirstName: this.App.ContactInfo.FirstName,
        clastName: this.App.ContactInfo.LastName,
        cposition: this.App.ContactInfo.Position,
        cdepartment: this.App.ContactInfo.Department,
        ccontactEmail: this.App.ContactInfo.Email,

        country: this.country,
        streetAddress: this.App.ContactInfo.StreetAddress,
        suitePoBox: this.App.ContactInfo.Suite,
        city: this.App.ContactInfo.City,
        state: this.state,
        zip: this.App.ContactInfo.Zip,
        ccontactPhone: this.App.ContactInfo.ContactPhone,
        asalutation: this.App.AssistantInfo.Title,
        afirstName: this.App.AssistantInfo.FirstName,
        alastName: this.App.AssistantInfo.LastName,
        acontactEmail: this.App.AssistantInfo.ContactEmail,
        acontactPhone: this.App.AssistantInfo.ContactPhone,
        isAccredited:
          this.App.InvestorInfo.IsAccreditedInvestor == false ? "0" : "1",
        investorType: this.App.InvestorInfo.InvestorType,
        typeOther: this.App.InvestorInfo.InvestorTypeOther,
        assets: this.App.InvestorInfo.AssetsUnderManagement.id,
        rounds: roundData,
        cTwitterProfile: this.App.ContactInfo.TwitterProfile,
        cLinkedInProfile: this.App.ContactInfo.LinkedInProfile,
        // rounds :this.App.FoundRoundList,
        // timeFrame:this.App.TimeFramesList ,
        timeFrame: timeData,
        fundInvested:
          this.App.InvestorInfo.FundInvested == "Yes" ? true : false,
        pensionInvested:
          this.App.InvestorInfo.PensionInvested == "Yes" ? true : false,
        familyOfficeInvested:
          this.App.InvestorInfo.FamilyOfficeInvested == "Yes" ? true : false,
        ventureInvested:
          this.App.InvestorInfo.VentureInvested == "Yes" ? true : false,
        angelInvested:
          this.App.InvestorInfo.AngelInvested == "Yes" ? true : false,
        charityInvested:
          this.App.InvestorInfo.CharitableInvested == "Yes" ? true : false,
        FINRAInvested:
          this.App.InvestorInfo.FINRAInvested == "Yes" ? true : false,
        analystInvested:
          this.App.InvestorInfo.AnalystFundInvested == "Yes" ? true : false,
        crowdInvested:
          this.App.InvestorInfo.CrowdFundingInvested == "Yes" ? true : false,
        additionalComments: this.App.InvestorInfo.AdditionalComments,
        therapeutics: this.App.InvestorInfo.Therapeutics.id,
        customTherapeutics: this.App.InvestorInfo.therapeuticsOther,
      });
      console.log("Therapeutics", this.App.InvestorInfo.Therapeutics.id);
      // this.applicationEditForm.setValue({
      //   participationmethod:1,
      // });
      //console.log('appForm----------------',this.applicationEditForm.value);
    });
  }

  // async getApplicationById(id: number) {
  //   this.App = await this.applicationService.getApplication(id).toPromise();

  //   this.country.Code = this.App.ContactInfo.CountryCode;
  //   this.country.Name = this.App.ContactInfo.Country;
  //   this.state.Code = this.App.ContactInfo.StateCode;
  //   this.state.Name = this.App.ContactInfo.State;
  //   setTimeout(() => {
  //     this.getConferenceDetails(this.App.CompanyInfo.Conference_Id);
  //     this.applicationEditForm.patchValue({
  //       companyName: this.App.CompanyInfo.Name,
  //       website: this.App.CompanyInfo.Website,
  //       ccsalutation: this.App.ContactInfo.Title,
  //       cfirstName: this.App.ContactInfo.FirstName,
  //       clastName: this.App.ContactInfo.LastName,
  //       cposition: this.App.ContactInfo.Position,
  //       cdepartment: this.App.ContactInfo.Department,
  //       ccontactEmail: this.App.ContactInfo.Email,
  //       country: this.country,
  //       streetAddress: this.App.ContactInfo.StreetAddress,
  //       suitePoBox: this.App.ContactInfo.Suite,
  //       city: this.App.ContactInfo.City,
  //       state: this.state,
  //       zip: this.App.ContactInfo.Zip,
  //       ccontactPhone: this.App.ContactInfo.ContactPhone,
  //       asalutation: this.App.AssistantInfo.Title,
  //       afirstName: this.App.AssistantInfo.FirstName,
  //       alastName: this.App.AssistantInfo.LastName,
  //       acontactEmail: this.App.AssistantInfo.ContactEmail,
  //       acontactPhone: this.App.AssistantInfo.ContactPhone,
  //       investorType: this.App.InvestorInfo.InvestorType,
  //       assets: this.App.InvestorInfo.AssetsUnderManagement,
  //       fundInvested:
  //         this.App.InvestorInfo.FundInvested == "Yes" ? true : false,
  //       pensionInvested:
  //         this.App.InvestorInfo.PensionInvested == "Yes" ? true : false,
  //       familyOfficeInvested:
  //         this.App.InvestorInfo.FamilyOfficeInvested == "Yes" ? true : false,
  //       ventureInvested:
  //         this.App.InvestorInfo.VentureInvested == "Yes" ? true : false,
  //       angelInvested:
  //         this.App.InvestorInfo.AngelInvested == "Yes" ? true : false,
  //       charityInvested:
  //         this.App.InvestorInfo.CharitableInvested == "Yes" ? true : false,
  //       FINRAInvested:
  //         this.App.InvestorInfo.FINRAInvested == "Yes" ? true : false,
  //       analystInvested:
  //         this.App.InvestorInfo.AnalystFundInvested == "Yes" ? true : false,
  //       crowdInvested:
  //         this.App.InvestorInfo.CrowdFundingInvested == "Yes" ? true : false,
  //       additionalComments: this.App.InvestorInfo.AdditionalComments
  //     });
  //   }, 1000);
  // }

  // convenience getter for easy access to form fields
  get fc() {
    // get field control(fc)
    return this.applicationEditForm.controls;
  }

  getCountries(code: string) {
    return this.utilService.loadCountries().subscribe((data: {}) => {
      this.countryResponse = data;
      this.countries = this.countryResponse.Info;
      this.getStates(code);
      console.dir(this.countries);
    });
  }

  getStates(countryCode: string) {
    return this.utilService.loadStates(countryCode).subscribe((data: {}) => {
      this.stateResponse = data;
      console.dir(this.stateResponse);
      this.namedElement.nativeElement.click();
      this.states = this.stateResponse.Info;
    });
  }

  compareFn(optionOne: Country, optionTwo: Country): boolean {
    return optionOne.Code === optionTwo.Code;
  }

  onCountryChange(countryObject: any) {
    console.log(countryObject);
    this.getStates(countryObject.Code);
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
      this.getCountries(this.countryCode);
    });
  }

  getConferenceDetails(id: any) {
    console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;
      this.isEBDConf = this.conferenceDetail.Conference.isEBD;
      // this.cService.validateConference(id).subscribe((data: {}) => {
      //   this.vData = data;
      //   console.log(this.vData);
      //   if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
      //     //this.IsExpire = true;
      //     this.router.navigate(["/conference/status/" + id]);
      //   }
      // });
      // this.conferenceIsActive = this.conferenceDetail.Conference.isActive;
      // console.log(this.conferenceIsActive);
      // if (this.conferenceIsActive == 0) {
      //   this.router.navigate(["/conference/status/" + id]);
      // }
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 2) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }

      if (
        [8, 11, 12].indexOf(Number(this.conferenceDetail.Conference.TypeId)) !==
        -1
      ) {
        gtag("config", "UA-73328446-12", {
          linker: {
            domains: ["knect365.com", "demy-colton.com"],
          },
          page_title: "Investor Application - " + this.ConferenceName,
          page_path: this.router.url,
        });
        gtag("event", "ISP Form Open", {
          event_category: this.ConferenceName + " IA Form",
          event_label: "Investor Application",
          event_action: "open",
        });
      }

      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
      //this.getCountries(this.countryCode);
    });
  }

  onSubmit() {
    //console.log("submit :::: data");

    this.submitted = true;
    this.validationError = this.applicationEditForm.invalid;
    // stop here if form is invalid
    if (this.applicationEditForm.invalid) {
      this.utilService.showValidationDiv();
      console.log("Invalid");
      // let el = this.element.nativeElement.querySelector(".validation-errors");
      // el.scrollIntoView();
      return;
    } else {
      this.validationError = false;
      this.companyDetails.ApplicationId = this.id;
      this.companyDetails.Name = this.applicationEditForm.value.companyName;
      this.companyDetails.Website = this.applicationEditForm.value.website;
      this.companyDetails.Conference_Id = this._conferenceId;
      this.companyDetails.IsSelected = 0;
      //new field
      this.companyDetails.ParticipationMethod =
        this.applicationEditForm.value.participationmethod;

      this.contactDetails.Title = this.applicationEditForm.value.ccsalutation;
      this.contactDetails.FirstName = this.applicationEditForm.value.cfirstName;
      this.contactDetails.LastName = this.applicationEditForm.value.clastName;
      this.contactDetails.Position = this.applicationEditForm.value.cposition;
      this.contactDetails.Department =
        this.applicationEditForm.value.cdepartment;
      this.contactDetails.Email = this.applicationEditForm.value.ccontactEmail;
      this.contactDetails.Country = this.applicationEditForm.value.country.Name;
      this.contactDetails.CountryCode =
        this.applicationEditForm.value.country.Code;
      this.contactDetails.StreetAddress =
        this.applicationEditForm.value.streetAddress;
      this.contactDetails.Suite = this.applicationEditForm.value.suitePoBox;
      this.contactDetails.City = this.applicationEditForm.value.city;
      this.contactDetails.State = this.applicationEditForm.value.state.Name;
      this.contactDetails.StateCode = this.applicationEditForm.value.state.Code;
      this.contactDetails.Zip = this.applicationEditForm.value.zip;
      this.contactDetails.ContactPhone =
        this.applicationEditForm.value.ccontactPhone;
      this.contactDetails.TwitterProfile =
        this.applicationEditForm.value.cTwitterProfile;
      this.contactDetails.LinkedInProfile =
        this.applicationEditForm.value.cLinkedInProfile;

      this.assistantDetails.Title = this.applicationEditForm.value.asalutation;
      this.assistantDetails.FirstName =
        this.applicationEditForm.value.afirstName;
      this.assistantDetails.LastName = this.applicationEditForm.value.alastName;
      this.assistantDetails.ContactEmail =
        this.applicationEditForm.value.acontactEmail;
      this.assistantDetails.ContactPhone =
        this.applicationEditForm.value.acontactPhone;

      this.investorDetails.IsAccreditedInvestor =
        this.applicationEditForm.value.isAccredited == 1;
      this.investorDetails.InvestorType =
        this.applicationEditForm.value.investorType;
      this.investorDetails.InvestorTypeOther =
        this.applicationEditForm.value.typeOther;
      this.investorDetails.Therapeutics = {
        id: +this.applicationEditForm.value.therapeutics,
        description: "",
      };
      this.investorDetails.TherapeuticsOther =
        this.applicationEditForm.value.customTherapeutics;
      this.investorDetails.FundInvested = this.applicationEditForm.value
        .fundInvested
        ? "Yes"
        : "No";
      this.investorDetails.PensionInvested = this.applicationEditForm.value
        .pensionInvested
        ? "Yes"
        : "No";
      this.investorDetails.FamilyOfficeInvested = this.applicationEditForm.value
        .familyOfficeInvested
        ? "Yes"
        : "No";
      this.investorDetails.VentureInvested = this.applicationEditForm.value
        .ventureInvested
        ? "Yes"
        : "No";
      this.investorDetails.AngelInvested = this.applicationEditForm.value
        .angelInvested
        ? "Yes"
        : "No";
      this.investorDetails.CharitableInvested = this.applicationEditForm.value
        .charityInvested
        ? "Yes"
        : "No";
      this.investorDetails.FINRAInvested = this.applicationEditForm.value
        .FINRAInvested
        ? "Yes"
        : "No";
      this.investorDetails.AnalystFundInvested = this.applicationEditForm.value
        .analystInvested
        ? "Yes"
        : "No";
      this.investorDetails.CrowdFundingInvested = this.applicationEditForm.value
        .crowdInvested
        ? "Yes"
        : "No";
      this.investorDetails.AssetsUnderManagement = {
        id: +this.applicationEditForm.value.assets,
        description: "",
      };

      this.investorDetails.AdditionalComments =
        this.applicationEditForm.value.additionalComments;
      // this.funding = this.applicationEditForm.value.rounds;
      // this.timeFrameList = this.applicationEditForm.value.timeFrame;

      //console.log("this.funding ::: ", this.applicationEditForm.value.timeFrame);
      this.newTimeArray = [];
      for (
        let j = 0;
        j < this.applicationEditForm.value.timeFrame.length;
        j++
      ) {
        let obj = {
          TimeFrame: this.applicationEditForm.value.timeFrame[j],
        };
        this.newTimeArray.push(obj);
      }

      this.newArr = [];
      for (let j = 0; j < this.applicationEditForm.value.rounds.length; j++) {
        let obj = {
          FundingRound: this.applicationEditForm.value.rounds[j],
        };
        this.newArr.push(obj);
      }

      this.application.companyDetails = this.companyDetails;
      this.application.contactDetails = this.contactDetails;
      this.application.assistantDetails = this.assistantDetails;
      this.application.investorDetails = this.investorDetails;
      // this.application.FoundRoundList = this.funding;
      this.application.FoundRoundList = this.newArr;
      this.application.TimeFrameList = this.newTimeArray;
      console.log(JSON.stringify(this.application));
      //console.log("this.application ::::::::: ", this.application);

      this.updateApplication(this.application);
    }
  }

  updateApplication(application: Application) {
    //console.log("In updateApplication....",application);
    this.disableBtn = true;
    return this.applicationService
      .updateApplication(application)
      .subscribe((result) => {
        // This code will be executed when the HTTP call returns successfully
        this.response = result;
        if (this.response.Code == 200) {
          var toast: Toast = {
            type: "success",
            title: this.response.Message,
            showCloseButton: true,
          };

          this.toasterService.pop(toast);
          //this.applicationEditForm.reset();
        } else {
          var toast: Toast = {
            type: "error",
            title: this.response.Message,
            showCloseButton: true,
          };
          this.toasterService.pop(toast);
        }
        this.disableBtn = false;
      });
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(event: any) {
    let position = 500;
    let scrollPositionFromTop = +window.pageYOffset.toString();
    //let submitBtn = event.nativeElement.querySelectorAll(".submit");
    if (scrollPositionFromTop > position) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }
    let windowHeight = +window.innerHeight;
    if (
      scrollPositionFromTop + windowHeight >
      document.body.clientHeight - 100
    ) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
    //console.log("scrolerl top " + scrollPositionFromTop);
  }

  onTherapeuticschange(e: any) {
    console.log("clicked", this.applicationEditForm.value.therapeutics);
    const therapeuticsControl =
      this.applicationEditForm.controls["therapeutics"];
    const customTherapeuticsControl =
      this.applicationEditForm.controls["customTherapeutics"];
    if (this.applicationEditForm.value.therapeutics) {
      var check = 100;
      if (
        Number(this.applicationEditForm.value.therapeutics) === Number(check)
      ) {
        this.isOtherOptionVisible = true;
        if (!this.applicationEditForm.value.customTherapeutics) {
          customTherapeuticsControl.setValidators([Validators.required]);
          therapeuticsControl.setValidators(null);
        } else {
          customTherapeuticsControl.setValidators(null);
          therapeuticsControl.setValidators([Validators.required]);
        }
      } else {
        this.isOtherOptionVisible = false;
      }
    } else {
      this.isOtherOptionVisible = false;
    }
    therapeuticsControl.updateValueAndValidity();
    customTherapeuticsControl.updateValueAndValidity();
  }
}
