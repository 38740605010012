import { Component, OnInit, HostListener } from "@angular/core";

// Service
import { SummaryService } from "./../../../../../services/investor/summary.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssetAddRowService } from "ClientApp/app/services/asset-add-row.service";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
// Model
import {
  Summary,
  PresenterDetails,
  CorporateDetails,
  CompanyDetails,
  InvestorAssets,
  InvestorTherapeuticFocus,
  AssistantDetails,
  RowAssets,
  State,
  Country,
  TFocus,
} from "./../../../../../models/Summary";
import { AddAssetComponent } from "../../components/investor-summary/assets/add-asset/add-asset.component";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Toast, ToasterService, ToasterConfig } from "angular2-toaster";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import { UtilService } from "ClientApp/app/services/common/util.service";
import { wordCountValidator } from "ClientApp/app/core/validators/wordcount.validator";
import { environment } from "ClientApp/environments/environment";
import { EditAssetComponent } from "../../components/investor-summary/assets/edit-asset/edit-asset.component";
import { ConfirmationDialogService } from "ClientApp/app/services/common/confirmation-dialog.service";
import * as _ from "underscore";
declare let gtag: Function;

@Component({
  selector: "app-investor-summary-edit",
  templateUrl: "./investor-summary-edit.component.html",
  styleUrls: ["./investor-summary-edit.component.css"],
})
export class InvestorSummaryEditComponent implements OnInit {
  isDraggable: boolean = true;
  isParticipationMethodEnabled: boolean = false;
  showInvester: boolean = true;
  Summary: any = {};
  summaryEditForm!: FormGroup;
  submitted = false;
  disableBtn = false;
  TypeId: any;
  public id: any;
  public showBtn: boolean = false;
  public fixedDiv: boolean = false;
  scrollCount: any = 1;
  summaryModel = new Summary();
  presenterDetails = new PresenterDetails();
  corporateDetails = new CorporateDetails();
  companyDetails = new CompanyDetails();
  investorAssets = new InvestorAssets();
  invetsorTherapeuticFocus = new InvestorTherapeuticFocus();
  assistantDetails = new AssistantDetails();
  rowAssets = new RowAssets();
  tFocus = new TFocus();
  public assets: Array<InvestorAssets> = [];
  public assets1: Array<InvestorAssets> = [];
  public droppedItems: Array<InvestorTherapeuticFocus> = [];
  public isEBDConf: boolean;
  public isAssetsShow: boolean;
  public rows: Array<{
    name: string | undefined;
    indication: string | undefined;
    status: any | undefined;
    milestone: string | undefined;
  }> = [];

  public rowsObj: {
    name: string | undefined;
    indication: string | undefined;
    status: string | undefined;
    milestone: string | undefined;
  };
  public tFocusList: Array<TFocus> = [];
  public completed: Array<TFocus> = [];
  private addAssetComponent = AddAssetComponent;
  public dragndropItems: Array<any> = [];
  private ownershipRadio: string = "Public";
  public ps: any;
  public ps1: any;
  public response: any;
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    animation: "fade",
  });

  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  _conferenceId: any;
  countryResponse: any;
  stateResponse: any;
  public countries: any = [];
  public states: any = [];
  state = new State();
  country = new Country();
  countryCode: any;
  therapeuticFocusList: any;
  validationError: boolean = false;
  conferenceID: any;
  banner: any;
  assetDevelopmentStatus: any = [];
  contactId: any;
  pClassifications: any = [];
  pClassifications1: any = [];
  sClassifications: any = [];
  sClassifications1: any = [];
  selectedPC: any;
  selectedSC: any;
  selectedPCName: any;
  selectedSCName: any;
  conferenceIsActive: any;
  vData: any;
  loginuser: any;
  stockExchanges: any = [];
  //country: any;// = new Country();
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private addRowService: AssetAddRowService,
    public summaryService: SummaryService,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    public cService: ConferenceService,
    private utilService: UtilService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router
  ) {
    // TODO: Remove once API is ready.

    this.pClassifications = [
      {
        Id: "0",
        Description: "Biotechnology",
      },
      {
        Id: "1",
        Description: "Digital Medicine",
      },
      {
        Id: "2",
        Description: "Pharmaceutical",
      },
      {
        Id: "3",
        Description: "Medtech",
      },
    ];

    this.pClassifications1 = [
      {
        Id: "1",
        Description: "Digital Medicine",
      },
      {
        Id: "3",
        Description: "Medtech",
      },
    ];

    this.sClassifications = [
      {
        Id: "0",
        Description: "Biotechnology",
      },
      {
        Id: "1",
        Description: "Digital Medicine", // China, Digital Medicine
      },
      {
        Id: "2",
        Description: "Pharmaceutical",
      },
      {
        Id: "3",
        Description: "Medtech", // China, Digital Medicine
      },
    ];

    this.sClassifications1 = [
      {
        Id: "1",
        Description: "Digital Medicine", // China, Digital Medicine
      },
      {
        Id: "3",
        Description: "Medtech", // China, Digital Medicine
      },
    ];
  }

  ngOnInit() {
    // Getting current component's id or information using ActivatedRoute service
    const summaryId = this.route.snapshot.paramMap.get("id");
    this.loginuser = localStorage.getItem("currentUser");
    this.getStockExchanges();
    // this.route.paramMap.pipe(
    //   switchMap(params => {
    //     const id = +params.get("id")
    //     return this.summaryService.getSummaryById(id).subscribe((data: {}) => {
    //       this.Summary = data;
    //       console.log(this.Summary);
    //     });
    //   })
    // )

    this.assetDevelopmentStatus = [
      {
        Id: "0",
        Description: "Pre-Clinical",
      },
      {
        Id: "1",
        Description: "Phase I",
      },
      {
        Id: "2",
        Description: "Phase II",
      },
      {
        Id: "3",
        Description: "Phase III",
      },
    ];

    this.id = summaryId !== null ? summaryId : 0;

    this.summaryEditForm = this.formBuilder.group({
      //Company Name
      companyName: ["", Validators.required],

      // Presenter Details
      psalutation: ["", Validators.required],
      pfirstName: ["", Validators.required],
      pnickName: [""],
      pmiddleName: [""],
      plastName: ["", Validators.required],
      psuffix: [""],
      pgeneration: [""],
      pjobTitle: ["", Validators.required],
      pcontactEmail: ["", [Validators.required, Validators.email]],
      pcontactPhone: ["", Validators.required],
      pTwitterProfile: [""],
      pLinkedInProfile: [""],
      // Corporate Communications Details
      ccsalutation: [""],
      ccfirstName: [""],
      cclastName: [""],
      cccontactEmail: [""],
      cccontactPhone: [""],

      // Assistant Communications Details
      asalutation: [""],
      afirstName: [""],
      alastName: [""],
      acontactEmail: [""],
      acontactPhone: [""],

      // Company Details
      ownership: ["", Validators.required],
      ipoYear: [""],
      goPublic: [""],
      psExchange: [null, Validators.required],
      stcother: [""],
      tickerSymbol: ["", Validators.required],
      country: ["", Validators.required],
      streetAddress: ["", Validators.required],
      city: ["", Validators.required],
      state: [""],
      zip: ["", Validators.required],
      yearEst: ["", Validators.required],
      website: ["", Validators.required],
      nEmployees: ["", Validators.required],
      // companySummary: ["", [Validators.required, wordCountValidator(100)]],

      investorProposition: ["", [Validators.required, wordCountValidator(75)]],
      bioFutureDescription: ["", [Validators.required, wordCountValidator(75)]],
      isTechBioCompany : [""],
      pClassification: [null, Validators.required],
      sClassification: [null],
      technologyTargetProduct: [
        "",
        [Validators.required, wordCountValidator(50)],
      ],
      developmentProgram: ["", [Validators.required, wordCountValidator(20)]],

      //Assets

      fundPlans: ["", Validators.required],
      majorInvestor: ["", wordCountValidator(30)],
      majorInvestorDropDown: ["", Validators.required],
      corporatePartner: ["", [Validators.required, wordCountValidator(30)]],
      isFundRaisingMode: ["", Validators.required],
      fundRaised: ["", [Validators.required, wordCountValidator(20)]],
      additionalCommment: ["", wordCountValidator(100)],
      participationMethod: ["", Validators.required],

      // if 'enable participation method' is true in conferance type then enable participation method
      confFeedBack: [""],
    });

    this.addRowService.addToRow.subscribe((rowItem) => {
      //this.rows = rowItem;
      console.dir(rowItem);
      console.dir(this.rows);
      // rowItem.forEach(item => {

      // })
      // var st = this.rows.find((i: { Id: any }) => i.status.Id === rowItem.status.Id
      //   );
      // var ss = this.containsObject(rowItem[0], this.rows);
      // console.log(ss);
      // this.rows = this.rows.concat(rowItem);
      // console.dir(this.rows);
    });

    this.getSummaryById(this.id);
  }

  // checkInvesterProposition(conferenceTypeID:any){
  //     console.log('investorProposition condition',conferenceTypeID);
  //     // console.log('this.companyModel.conferenceDetail--',this.conferenceDetail.Conference.TypeId);
  //     if(conferenceTypeID === 8) {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(conferenceTypeID === 13) {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(conferenceTypeID === 11) {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(conferenceTypeID === 12) {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }else {
  //         this.showInvester = true
  //         // return false
  //     }
  // }

  // checkInvesterProposition(con_id:any){
  //     // console.log('investorProposition condition');
  //     if(con_id === "tS6ZMFrWmAPAVa-XBj1wXg") {
  //         this.setInvesterPropositionValidation();
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "Y-7qsiG-5UuRAfGlHZYKVA") {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "FNliUNqTlnQgMdCuomeTJQ") {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "Q1ipEG3Wro6PMlPM2tZ_XA") {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     }
  //     else if(con_id === "0ng413bnEsmHlePWwVkqpA") {
  //         this.setInvesterPropositionValidation()
  //         this.showInvester = false
  //         // return false
  //     } else {
  //         this.showInvester = true
  //         // return false
  //     }
  // }

  // setInvesterPropositionValidation(){
  //     console.log('setInvesterPropositionValidation');
  //     this.summaryEditForm.controls["investorProposition"].setValidators([]);
  //     this.summaryEditForm.controls["investorProposition"].updateValueAndValidity();

  // }

  containsObject(obj: any, list: any) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  getTherapeuticFocus() {
    return this.summaryService.getTherapeuticFocus().subscribe((data: {}) => {
      this.response = data;
      this.therapeuticFocusList = this.response.Info;
      // Filter out selected items.
      this.completed.forEach((item: any) => {
        this.tFocus.Id = item.TherapeuticFocusId;
        this.tFocus.Description = item.TherapeuticFocus;
        this.tFocusList.push(this.tFocus);
        this.tFocus = new TFocus();
      });
      this.completed = this.tFocusList;
      let ar = this.therapeuticFocusList;
      this.completed.filter(function (completed: any) {
        ar = ar.filter(function (list: any) {
          return list.Id !== completed.Id;
        });
      });
      this.therapeuticFocusList = ar;

      console.dir(this.therapeuticFocusList);
      console.dir(this.completed);
      this.getCountries(this.countryCode);
    });
  }
  getStockExchanges() {
    //debugger;
    this.summaryService.getStockExchanges().subscribe((data: {}) => {
      //debugger;
      this.response = data;
      this.stockExchanges = this.response.Info;
      console.log("this.stockExchanges", this.stockExchanges);
    });
  }

  setradio(e: string): void {
    //debugger;
    this.ownershipRadio = e;
    console.log(e);

    if (e !== "Private") {
      console.log(e);
      this.summaryEditForm.controls["psExchange"].setValidators([
        Validators.required,
      ]);
      this.summaryEditForm.controls["tickerSymbol"].setValidators([
        Validators.required,
      ]);
    } else {
      console.log(e);
      //Added by Abhilasha on 22/12/2021
      this.summaryEditForm.controls["psExchange"].patchValue(null);
      this.summaryEditForm.controls["tickerSymbol"].patchValue("");
      this.summaryEditForm.controls["ipoYear"].patchValue("");
      this.summaryEditForm.controls["stcother"].patchValue("");
      //end
      this.summaryEditForm.controls["psExchange"].setValidators([]);
      this.summaryEditForm.controls["tickerSymbol"].setValidators([]);
    }
    this.summaryEditForm.controls["psExchange"].updateValueAndValidity();
    this.summaryEditForm.controls["tickerSymbol"].updateValueAndValidity();
  }
  isSelected(name: string): boolean {
    if (!this.ownershipRadio) {
      // if no radio button is selected, always return false so nothing is shown
      return false;
    }
    return this.ownershipRadio === name; // if current radio button is selected, return true, else return false
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
      this.getTherapeuticFocus();
    });
  }

  getConferenceDetails(id: any) {
    console.log(id);
    //this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      console.log("edit--", data);

      this.conferenceDetail = data;
      this.TypeId = this.conferenceDetail.Conference.TypeId;
      this.isParticipationMethodEnabled =
        this.conferenceDetail.Conference.EnableParticipantMethod;
      console.log("edit--", this.conferenceDetail.Conference.TypeId);
      // this.checkInvesterProposition(this.conferenceDetail.Conference.TypeId);
      this.isEBDConf = this.conferenceDetail.Conference.isEBD;
      this.isAssetsShow = this.conferenceDetail.Conference.IsAssetsShow;
      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;

      // this.conferenceIsActive = this.conferenceDetail.Conference.isActive;
      // console.log(this.conferenceIsActive);
      // if (this.conferenceIsActive == 0) {
      //   this.router.navigate(["/conference/status/" + id]);
      // }
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;

      if (
        [8, 11, 12].indexOf(Number(this.conferenceDetail.Conference.TypeId)) !==
        -1
      ) {
        gtag("config", "UA-73328446-12", {
          linker: {
            domains: ["knect365.com", "demy-colton.com"],
          },
          page_title: "Investor Summary Profile - " + this.ConferenceName,
          page_path: this.router.url,
        });

        gtag("event", "ISP Form Open", {
          event_category: this.ConferenceName + " ISP Form",
          event_label: "Investor Summary Profile",
          event_action: "open",
        });
      }

      if (
        Number(this.conferenceDetail.Conference.TypeId) === Number(10) ||
        Number(this.conferenceDetail.Conference.TypeId) === Number(11) ||
        Number(this.conferenceDetail.Conference.TypeId) === Number(17)
      ) {
        this.pClassifications = this.pClassifications1.slice(0);
        this.sClassifications = this.sClassifications1.slice(0);
      }
      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 1) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
      //this.getTherapeuticFocus();
    });
  }

  getCountries(code: string) {
    return this.utilService.loadCountries().subscribe((data: {}) => {
      this.countryResponse = data;
      this.countries = this.countryResponse.Info;
      this.getStates(code);
      console.dir(this.countries);
    });
  }

  getStates(countryCode: string) {
    return this.utilService.loadStates(countryCode).subscribe((data: {}) => {
      this.stateResponse = data;
      console.dir(this.stateResponse);
      this.states = this.stateResponse.Info;
    });
  }

  onChangePC(obj: any) {
    var isExist = this.pClassifications.filter(
      (x: any) => x.Id == (obj as any)
    );
    if (isExist) {
      this.selectedPC = isExist[0].Id;
      this.selectedPCName = isExist[0].Description;
    } else {
      this.selectedPC = obj;
      this.selectedPCName = "";
    }
  }

  onChangeSC(obj: any) {
    var isExist = this.sClassifications.filter(
      (x: any) => x.Id == (obj as any)
    );
    if (isExist) {
      this.selectedSC = isExist[0].Id;
      this.selectedSCName = isExist[0].Description;
    } else {
      this.selectedSC = obj;
      this.selectedSCName = "";
    }
  }

  compareFn(optionOne: Country, optionTwo: Country): boolean {
    return optionOne.Code === optionTwo.Code;
  }

  onCountryChange(countryObject: any) {
    console.log(countryObject);
    this.getStates(countryObject.Code);
  }
  getSummaryById(id: number) {
    this.summaryService.getSummaryById(id).subscribe((data: {}) => {
      console.log("data-------investorProposition", data);

      this.Summary = data;
      console.log(this.Summary);
      this.ps =
        this.Summary.PresenterDetails.Prefix != null
          ? this.Summary.PresenterDetails.Prefix
          : "";
      this.ps1 = this.Summary.CompanyDetails.IPOYear;
      this.completed = this.Summary.InvestorTherapeuticFocus;
      this.assets = this.Summary.InvestorAssets;
      console.dir(this.assets);

      // AssetStatus = 0 , 2;

      this.assets.forEach((item) => {
        var st = this.assetDevelopmentStatus.find(
          (i: { Id: any }) => i.Id === item.AssetStatus
        );
        console.dir(st);
        let r1 = {
          name: item.AssetName,
          indication: item.Indication,
          status: st, //item.AssetStatus, //
          milestone: item.Milestone,
        };
        this.rows.push(r1);
        console.dir(this.rows);
      });

      this.countryCode = this.Summary.CompanyDetails.CountryCode;
      this.country.Code = this.Summary.CompanyDetails.CountryCode;
      this.country.Name = this.Summary.CompanyDetails.Country;
      this.state.Code = this.Summary.CompanyDetails.StateCode;
      this.state.Name = this.Summary.CompanyDetails.State;
      this._conferenceId = this.Summary.CompanyDetails.Conference_Id;
      this.conferenceID = this.Summary.CompanyDetails.ConferenceID;
      console.dir(this.country);
      console.dir(this.state);
      console.dir(this.completed);
      this.dragndropItems = this.completed;
      //this.getConferenceDetails(this.Summary.CompanyDetails.Conference_Id);
      if (!this.Summary.CorporateDetails)
        this.Summary.CorporateDetails = new CorporateDetails();
      if (!this.Summary.Assistantdetails)
        this.Summary.Assistantdetails = new AssistantDetails();
      this.ownershipRadio = this.Summary.CompanyDetails.Ownership;
      this.contactId = this.Summary.CorporateDetails.ContactId;

      this.isSelected(this.Summary.CompanyDetails.Ownership);
      //debugger;

      this.summaryEditForm.patchValue({
        companyName: this.Summary.CompanyDetails.CompanyName,
        psalutation: this.Summary.PresenterDetails.Prefix,
        pfirstName: this.Summary.PresenterDetails.FirstName,
        pnickName: this.Summary.PresenterDetails.NickLastName,
        pmiddleName: this.Summary.PresenterDetails.MiddleName,
        plastName: this.Summary.PresenterDetails.LastName,
        psuffix: this.Summary.PresenterDetails.Suffix,
        psExchange: _.find(this.stockExchanges, {
          id: parseInt(this.Summary.CompanyDetails.Exchange),
        }),
        pgeneration: this.Summary.PresenterDetails.Generation,
        pjobTitle: this.Summary.PresenterDetails.Title,
        pcontactEmail: this.Summary.PresenterDetails.Email,
        pcontactPhone: this.Summary.PresenterDetails.Phone,
        pTwitterProfile: this.Summary.PresenterDetails.TwitterProfile,
        pLinkedInProfile: this.Summary.PresenterDetails.LinkedInProfile,

        ccsalutation: this.Summary.CorporateDetails.Prefix
          ? this.Summary.CorporateDetails.Prefix
          : "",
        ccfirstName: this.Summary.CorporateDetails.Firstname,
        cclastName: this.Summary.CorporateDetails.Lastname,
        cccontactEmail: this.Summary.CorporateDetails.Email,
        cccontactPhone: this.Summary.CorporateDetails.PhoneNumber,

        asalutation: this.Summary.Assistantdetails.AssistantPrefix
          ? this.Summary.Assistantdetails.AssistantPrefix.trim()
          : "", //asalutation ? asalutation.trim() : "",//"Mr",        afirstName: this.Summary.Assistantdetails.FName,
        afirstName: this.Summary.Assistantdetails.FName,
        alastName: this.Summary.Assistantdetails.LName,
        acontactEmail: this.Summary.Assistantdetails.Email,
        acontactPhone: this.Summary.Assistantdetails.Phone,

        ownership: this.Summary.CompanyDetails.Ownership,
        ipoYear: this.Summary.CompanyDetails.IPOYear,
        goPublic: this.Summary.CompanyDetails.PublicBeforeConference,

        tickerSymbol: this.Summary.CompanyDetails.Ticker,
        country: this.country,
        streetAddress: this.Summary.CompanyDetails.Address1,
        city: this.Summary.CompanyDetails.City,
        state: this.state,
        zip: this.Summary.CompanyDetails.Zip,
        yearEst: this.Summary.CompanyDetails.YearEstablished,
        website: this.Summary.CompanyDetails.Website,
        nEmployees: this.Summary.CompanyDetails.Employees,
        companySummary: this.Summary.CompanyDetails.CompanySummary,
        investorProposition: this.Summary.CompanyDetails.InvestorProposition,
        bioFutureDescription: this.Summary.CompanyDetails.BioFutureDescription,
        isTechBioCompany: this.Summary.CompanyDetails.IsTechBioCompany,
        pClassification: this.Summary.CompanyDetails.ClassificationPrimary,
        sClassification: this.Summary.CompanyDetails.ClassificationSecondary
          ? this.Summary.CompanyDetails.ClassificationSecondary
          : "null",
        technologyTargetProduct: this.Summary.CompanyDetails.Products,
        developmentProgram: this.Summary.CompanyDetails.DevelopmentProgram,
        fundPlans: this.Summary.CompanyDetails.FundingPlan,
        majorInvestor: this.Summary.CompanyDetails.Investors,
        majorInvestorDropDown: this.Summary.CompanyDetails.Investors,
        corporatePartner: this.Summary.CompanyDetails.Partners,
        fundRaised: this.Summary.CompanyDetails.FundsRaised,
        isFundRaisingMode:
          this.Summary.CompanyDetails.IsFundRaisingMode.toString(),
        additionalCommment: this.Summary.CompanyDetails.Comments,
        participationMethod:
          this.Summary.CompanyDetails.ParticipationMethod.toString(),
        confFeedBack: this.Summary.CompanyDetails.ConferenceFeedBack,
        stcother: this.Summary.CompanyDetails.StockExchangeOther,
      });
      //Added by Abhilasha on 22/12/2021
      if (this.summaryEditForm.value.psExchange == undefined) {
        this.summaryEditForm.patchValue({
          psExchange: null,
        });
      }
      //end
      this.setradio(this.Summary.CompanyDetails.Ownership);
      this.getConferenceDetails(this.conferenceID);
    });
  }

  get fc() {
    // get field control(fc)
    return this.summaryEditForm.controls;
  }
  onDrop(event: CdkDragDrop<string[]>) {
    console.dir(this.completed.length);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.dragndropItems = event.container.data;
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.isDraggable = this.completed.length <= 3;

    // console.dir(event.container.data);
    //this.dragndropItems = event.container.data;
    this.dragndropItems = this.completed;
  }

  openAssetFormModal() {
    const modalRef = this.modalService.open(AddAssetComponent, {
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.rowObj = this.rows;
    modalRef.componentInstance.update = false;
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  openEditFormModal(typeId: number) {
    const modalRef = this.modalService.open(EditAssetComponent, {
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.rowObj = this.rows;
    modalRef.componentInstance.update = true;
    modalRef.componentInstance.pos = typeId;
    var selectedItem = this.rows[typeId];
    console.dir(selectedItem);
    if (selectedItem) {
      modalRef.componentInstance.itemObj = selectedItem;
    }
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSubmit() {

    if(!(this.TypeId === 18 
    //  ||this.TypeId === 12
    )){
      this.summaryEditForm.controls['bioFutureDescription'].setErrors(null)
    }

    this.submitted = true;
    this.validationError = this.summaryEditForm.invalid;
    // stop here if form is invalid
    if (this.completed.length == 0) {
      this.validationError = true;
      this.utilService.showValidationDiv();
      return;
    }
    if (!this.conferenceDetail.Conference.EnableParticipantMethod) {
      this.summaryEditForm.controls["participationMethod"].setValidators(null);
      this.summaryEditForm.controls[
        "participationMethod"
      ].updateValueAndValidity();
    }

    if (this.conferenceDetail.Conference.TypeId != 18) {
      this.summaryEditForm.controls["isFundRaisingMode"].setValidators(null);
      this.summaryEditForm.controls["majorInvestorDropDown"].setValidators(
        null
      );
      this.summaryEditForm.controls[
        "isFundRaisingMode"
      ].updateValueAndValidity();
      this.summaryEditForm.controls[
        "majorInvestorDropDown"
      ].updateValueAndValidity();
    } else {
      this.summaryEditForm.controls["fundPlans"].setValidators(null);
      this.summaryEditForm.controls["majorInvestor"].setValidators(null);
      this.summaryEditForm.controls["participationMethod"].setValidators(null);
      this.summaryEditForm.controls[
        "participationMethod"
      ].updateValueAndValidity();
      this.summaryEditForm.controls["fundPlans"].updateValueAndValidity();
      this.summaryEditForm.controls["majorInvestor"].updateValueAndValidity();
    }
    if (this.completed.length > 3) {
      this.isDraggable = false;
      this.validationError = true;
      this.utilService.showValidationDiv();
      return;
    }

    if (this.summaryEditForm.invalid) {
      console.log("Invalid");
      this.utilService.showValidationDiv();
      return;
    } else {
      this.validationError = false;
      // PresenterDetails Set Model Properties
      this.presenterDetails.Prefix = this.summaryEditForm.value.psalutation;
      this.presenterDetails.FirstName = this.summaryEditForm.value.pfirstName;
      this.presenterDetails.NickLastName = this.summaryEditForm.value.pnickName;
      this.presenterDetails.MiddleName = this.summaryEditForm.value.pmiddleName;
      this.presenterDetails.LastName = this.summaryEditForm.value.plastName;
      this.presenterDetails.Suffix = this.summaryEditForm.value.psuffix;
      this.presenterDetails.Generation = this.summaryEditForm.value.pgeneration;
      this.presenterDetails.Title = this.summaryEditForm.value.pjobTitle;
      this.presenterDetails.Email = this.summaryEditForm.value.pcontactEmail;
      this.presenterDetails.Phone = this.summaryEditForm.value.pcontactPhone;
      this.presenterDetails.TwitterProfile =
        this.summaryEditForm.value.pTwitterProfile;
      this.presenterDetails.LinkedInProfile =
        this.summaryEditForm.value.pLinkedInProfile;

      this.summaryModel.presenterDetails = this.presenterDetails;

      // CorporateDetails Set Model Properties
      this.corporateDetails.ContactId = this.contactId;
      this.corporateDetails.IsCorpComm = 0; // Logic to be done in backend.
      this.corporateDetails.Prefix = this.summaryEditForm.value.ccsalutation;
      this.corporateDetails.FirstName = this.summaryEditForm.value.ccfirstName;
      this.corporateDetails.LastName = this.summaryEditForm.value.cclastName;
      this.corporateDetails.PhoneNumber =
        this.summaryEditForm.value.cccontactPhone;
      this.corporateDetails.Email = this.summaryEditForm.value.cccontactEmail;
      this.corporateDetails.Generation = "";
      this.corporateDetails.IsPresenter = 0; // Logic to be done in backend.
      this.corporateDetails.IsPrimary = 0; // Logic to be done in backend.
      this.corporateDetails.RegId = 0; // Logic to be done in backend.

      this.summaryModel.corporateDetails = this.corporateDetails;
      // CompanyDetails Set Model Properties
      //this.companyDetails.CompId = this.id;
      this.companyDetails.CompanyId = this.id;
      this.companyDetails.ToBeScheduled = 0;
      this.companyDetails.SelectionRound = 0;
      this.companyDetails.Confirmed = 0;
      this.companyDetails.Scheduled = 0;
      this.companyDetails.CompanyName = this.summaryEditForm.value.companyName;
      this.companyDetails.InvestorProposition =
        this.summaryEditForm.value.investorProposition || "";

        this.companyDetails.BioFutureDescription =
        this.summaryEditForm.value.bioFutureDescription || "";
        this.companyDetails.IsTechBioCompany =
        this.summaryEditForm.value.isTechBioCompany || "";
      this.companyDetails.Address1 = this.summaryEditForm.value.streetAddress;
      this.companyDetails.City = this.summaryEditForm.value.city;
      this.companyDetails.State = this.summaryEditForm.value.state.Name;
      this.companyDetails.StateCode = this.summaryEditForm.value.state.Code;
      this.companyDetails.Country = this.summaryEditForm.value.country.Name;
      this.companyDetails.CountryCode = this.summaryEditForm.value.country.Code;
      this.companyDetails.Ownership = this.summaryEditForm.value.ownership;
      this.companyDetails.IPOYear = this.summaryEditForm.value.ipoYear;
      this.companyDetails.Exchange = this.summaryEditForm.value.psExchange
        ? this.summaryEditForm.value.psExchange.id
        : null;
      this.companyDetails.Ticker = this.summaryEditForm.value.tickerSymbol;
      this.companyDetails.Zip = this.summaryEditForm.value.zip;
      this.companyDetails.PublicBeforeConference = "No";
      this.companyDetails.IndustrySector = ""; // TO DO field
      this.companyDetails.FinancingAmount = 45; // TO DO field
      this.companyDetails.Conference_Id = this._conferenceId;
      this.companyDetails.YearEstablished = this.summaryEditForm.value.yearEst;
      this.companyDetails.Website = this.summaryEditForm.value.website;
      this.companyDetails.Employees = this.summaryEditForm.value.nEmployees;
      this.companyDetails.CompanySummary =
        this.summaryEditForm.value.companySummary;
      this.companyDetails.ClassificationPrimary =
        this.summaryEditForm.value.pClassification;
      this.companyDetails.ClassificationSecondary =
        this.summaryEditForm.value.sClassification;
      this.companyDetails.Products =
        this.summaryEditForm.value.technologyTargetProduct;
      this.companyDetails.DevelopmentProgram =
        this.summaryEditForm.value.developmentProgram;
      this.companyDetails.FundingPlan = this.summaryEditForm.value.fundPlans;
      this.companyDetails.Investors =
        this.conferenceDetail.Conference.TypeId == 18
          ? this.summaryEditForm.value.majorInvestorDropDown
          : this.summaryEditForm.value.majorInvestor;
      this.companyDetails.Partners =
        this.summaryEditForm.value.corporatePartner;
      this.companyDetails.FundsRaised = this.summaryEditForm.value.fundRaised;
      this.companyDetails.Comments =
        this.summaryEditForm.value.additionalCommment;
      this.companyDetails.ParticipationMethod =
        this.summaryEditForm.value.participationMethod;
      this.companyDetails.StockExchangeOther =
        this.summaryEditForm.value.stcother;

      //this.summaryModel.companyDetails = this.companyDetails;

      // InvestorAssets Set Model Properties
      console.dir(this.rows);
      this.assets1 = [];
      this.rows.forEach((item) => {
        this.investorAssets = new InvestorAssets();
        this.investorAssets.AssetName = item.name;
        this.investorAssets.Indication = item.indication;
        this.investorAssets.AssetStatus =
          typeof item.status == "string" ? item.status : item.status.Id;
        this.investorAssets.Milestone = item.milestone;
        console.dir(this.investorAssets);
        this.assets1.push(this.investorAssets);
      });
      console.dir(this.assets1);
      this.companyDetails.investorAssets = this.assets1;

      // InvetsorTherapeuticFocus Set Model Properties
      console.dir(this.dragndropItems);
      this.droppedItems = [];
      this.dragndropItems.forEach((item) => {
        let itemId = 0;
        if (item.hasOwnProperty("TherapeuticFocusId")) {
          itemId = item.TherapeuticFocusId;
        } else if (item.hasOwnProperty("Id")) {
          itemId = item.Id;
        }
        this.invetsorTherapeuticFocus.TherapeuticFocusId = itemId;
        this.droppedItems.push(this.invetsorTherapeuticFocus);
        this.invetsorTherapeuticFocus = new InvestorTherapeuticFocus();
      });
      console.dir(this.droppedItems);
      this.companyDetails.investorTherapeuticFocus = this.droppedItems;

      this.summaryModel.companyDetails = this.companyDetails;
      // AssistantDetails Set Model Properties
      this.assistantDetails.IsAssistant = 0;
      this.assistantDetails.AssistantPrefix =
        this.summaryEditForm.value.asalutation;
      this.assistantDetails.FName = this.summaryEditForm.value.afirstName;
      this.assistantDetails.LName = this.summaryEditForm.value.alastName;
      this.assistantDetails.Email = this.summaryEditForm.value.acontactEmail;
      this.assistantDetails.Phone = this.summaryEditForm.value.acontactPhone;
      this.summaryModel.assistantDetails = this.assistantDetails;
      this.summaryModel.ConferenceFeedBack =
        this.summaryEditForm.value.confFeedBack;

      console.dir(this.dragndropItems);
      console.log(JSON.stringify(this.summaryModel));
      console.dir(this.assets);
      this.updateSummaries(this.summaryModel);
    }

    //alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.summaryEditForm.value));
  }

  // Updtae summaries
  updateSummaries(summary: Summary) {
    console.log("In updateSummaries....");
    this.disableBtn = true;
    return this.summaryService.updateSummary(summary).subscribe((result) => {
      // This code will be executed when the HTTP call returns successfully
      this.response = result;
      if (this.response.Code == 200) {
        var toast: Toast = {
          type: "success",
          title: this.response.Message,
          showCloseButton: true,
        };

        this.toasterService.pop(toast);
      } else {
        var toast: Toast = {
          type: "error",
          title: this.response.Message,
          showCloseButton: true,
        };
        this.toasterService.pop(toast);
      }
      this.disableBtn = false;
    });
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(event: any) {
    let position = 500;
    let scrollPositionFromTop = +window.pageYOffset.toString();
    let windowHeight = +window.innerHeight;
    //let submitBtn = event.nativeElement.querySelectorAll(".submit");
    if (scrollPositionFromTop > position) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    if (
      scrollPositionFromTop + windowHeight >
      document.body.clientHeight - 100
    ) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

  deleteAsset(pos: any) {
    this.confirmationDialogService
      .confirm("", "Do you really want to delete " + this.rows[pos].name)
      .then((confirmed) => {
        this.rows.splice(pos, 1);
        console.log(pos);
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  isOtherStock(): boolean {
    if (
      this.summaryEditForm.value.psExchange &&
      this.summaryEditForm.value.psExchange.id == "21"
    ) {
      return true;
    }
    return false;
  }
}
