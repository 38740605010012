import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProfileService } from "ClientApp/app/services/investor/profile.service";
import { ToasterService, Toast } from "angular2-toaster";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ConferenceService } from "ClientApp/app/services/conference.service";
import {
  Invitation,
  InvitationValidationModel
} from "ClientApp/app/models/Invitation";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InvitationPreviewModalComponent } from "../../components/invitation-preview-modal/invitation-preview-modal.component";
import { environment } from "ClientApp/environments/environment";
import { UtilService } from "ClientApp/app/services/common/util.service";

@Component({
  selector: "app-invitation",
  templateUrl: "./invitation.component.html",
  styleUrls: ["./invitation.component.css"]
})
export class InvitationComponent implements OnInit {
  _conferenceId: any;
  bannerContent: any;
  ConferenceName: any;
  conferenceDetail: any;
  cInfo: any;
  invitationForm!: FormGroup;
  submitted = false;
  disableBtn = false;
  invitationModel = new Invitation();
  response: any;
  validationError: boolean = false;
  banner: any;
  vModel = new InvitationValidationModel();
  valid: any;
  result: any;
  conferenceIsActive: any;
  loginuser: any;
  vData: any;
  constructor(
    public cService: ConferenceService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private toasterService: ToasterService,
    private element: ElementRef,
    private utilService: UtilService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    const cId = this.route.snapshot.paramMap.get("id");
    this._conferenceId = cId !== null ? cId : 0;
    this.loginuser = localStorage.getItem("currentUser");
    this.getConferenceDetails(this._conferenceId);

    this.invitationForm = this.formBuilder.group({
      rsalutation: [""],
      rfirstName: ["", Validators.required],
      rnickName: [""],
      rmiddleName: [""],
      rlastName: ["", Validators.required],
      rsuffix: [""],
      rtitle: ["", Validators.required],
      rcontactEmail: ["", [Validators.required, Validators.email]],
      rcompanyName: ["", Validators.required],
      rcompanyWebsite: ["", Validators.required],
      rAddress1: ["", Validators.required],
      rAddress2: [""],
      city: ["", Validators.required],
      state: [""],
      country: ["", Validators.required],
      zip: ["", Validators.required],
      rPhone: ["", Validators.required],
      rassistantEmail: [""],
      rassistantPhone: [""],
      rConferenceFeedBack: ["", Validators.required],
    });
  }

  validateConference(confId: any) {
    this.cService.validateConference(confId).subscribe((data: {}) => {
      this.vData = data;
      console.log(this.vData);
      if (this.vData && this.vData.Message.expFlag && !this.loginuser) {
        this.router.navigate(["/conference/status/" + this._conferenceId]);
      }
    });
  }

  getConferenceDetails(id: number) {
    console.log(id);
    this._conferenceId = id;
    return this.cService.getConferenceById(id).subscribe((data: {}) => {
      this.conferenceDetail = data;

      if (
        this.conferenceDetail.Conference &&
        this.conferenceDetail.Conference.ConforanceImage
      ) {
        this.banner =
          environment.imagePath +
          this.conferenceDetail.Conference.ConforanceImage;
      }
      this.cInfo = this.conferenceDetail.ConferenceCategory;
      this.ConferenceName = this.conferenceDetail.Conference.ConferenceName;
      if (this.cInfo.length > 0) {
        this.cInfo.forEach((item: any) => {
          if (item.CTypeId == 5) {
            this.bannerContent = item.BannerContents; // Conference Description
          }
        });
      }
      let confId = this.conferenceDetail.Conference.ConferenceId;
      this.validateConference(confId);
    });
  }

  get fc() {
    // get field control(fc)
    return this.invitationForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.validationError = this.invitationForm.invalid;
    // stop here if form is invalid
    if (this.invitationForm.invalid) {
      console.log("Invalid");
      this.utilService.showValidationDiv();
      // let el = this.element.nativeElement.querySelector(".validation-errors");
      // el.scrollIntoView();
      return;
    } else {
      this.validationError = false;
      this.invitationModel.PrefixName = this.invitationForm.value.rsalutation;
      this.invitationModel.FirstName = this.invitationForm.value.rfirstName;
      this.invitationModel.NickName = this.invitationForm.value.rnickName;
      this.invitationModel.MiddleName = this.invitationForm.value.rmiddleName;
      this.invitationModel.Title = this.invitationForm.value.rtitle;
      this.invitationModel.LastName = this.invitationForm.value.rlastName;
      this.invitationModel.SuffixName = this.invitationForm.value.rsuffix;

      this.invitationModel.ConferenceID = this._conferenceId;

      this.invitationModel.Email = this.invitationForm.value.rcontactEmail;

      this.invitationModel.CompanyName = this.invitationForm.value.rcompanyName;

      this.invitationModel.Website = this.invitationForm.value.rcompanyWebsite;
      this.invitationModel.Address1 = this.invitationForm.value.rAddress1;
      this.invitationModel.Address2 = this.invitationForm.value.rAddress2;
      this.invitationModel.City = this.invitationForm.value.city;
      this.invitationModel.State = this.invitationForm.value.state;
      this.invitationModel.Country = this.invitationForm.value.country;
      this.invitationModel.PostalCode = this.invitationForm.value.zip;
      this.invitationModel.Phone = this.invitationForm.value.rPhone;
      this.invitationModel.AssistantEmail = this.invitationForm.value.rassistantEmail;
      this.invitationModel.AssistantPhone = this.invitationForm.value.rassistantPhone;
      this.invitationModel.ConferenceFeedBack = this.invitationForm.value.rConferenceFeedBack;
      // Check Email, CompanyName, Phone already exists.
      this.checkIsValid();

      //this.openPreviewModal(this.invitationModel, this.invitationForm.value);
      //this.createInvitation(this.invitationModel);
    }
  }

  // Check Email, CompanyName, Phone already exists.
  checkIsValid() {
    //this.invalidStatus = false;
    this.vModel.ConferenceID = this._conferenceId;
    this.vModel.Email = this.invitationForm.value.rcontactEmail;
    this.vModel.CompanyName = this.invitationForm.value.rcompanyName;
    this.vModel.Phone = this.invitationForm.value.rPhone;
    if (
      this.vModel.ConferenceID != "" &&
      this.vModel.Email != "" &&
      this.vModel.CompanyName != "" &&
      this.vModel.Phone != ""
    ) {
      return this.profileService
        .checkIsValidInvitation(this.vModel)
        .subscribe((data: {}) => {
          this.result = data;
          this.valid = this.result.Code;
          if (this.valid == "400") {
            //this.invalidStatus = true;
            var toast: Toast = {
              type: "error",
              title: this.result.Message,
              showCloseButton: true
            };
            this.toasterService.pop(toast);
          } else {
            this.openPreviewModal(
              this.invitationModel,
              this.invitationForm.value
            );
          }
        });
      this.vModel = new InvitationValidationModel();
    }
  }

  /* Modal popup for preview */
  openPreviewModal(model: any, form: any) {
    const modalRef = this.modalService.open(InvitationPreviewModalComponent, {
      windowClass: "modalWidth",
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.invItem = model;
    modalRef.componentInstance.invForm = form;
    modalRef.componentInstance.Conference = this.ConferenceName;
    modalRef.result
      .then((result: any) => {
        console.log(result);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
}
