import { Component, OnInit, Input } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { ToasterService, Toast } from "angular2-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "ClientApp/app/services/user.service";
import { User } from "ClientApp/app/models/User";
import { PasswordValidator } from "ClientApp/app/core/validators/password.validator";
import { UserRoleService } from "ClientApp/app/services/user-role.service";

@Component({
    selector: "app-user-edit-modal",
    templateUrl: "./user-edit-modal.component.html",
    styleUrls: ["./user-edit-modal.component.css"]
})
export class UserEditModalComponent implements OnInit {
    @Input() userId: number = 0;
    submitted = false;
    response: any;
    userEditForm!: FormGroup;
    user = new User();
    roles: any;
    constructor(
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        public activeModal: NgbActiveModal,
        private userService: UserService,
        private userRoleService: UserRoleService
    ) { }

    ngOnInit() {
        this.userRoleService.getUserRoleNames().subscribe((data: {}) => {
            this.roles = data;
        });

        this.loadDefault(this.userId);

        this.userEditForm = this.formBuilder.group(
            {
                name: ["", Validators.required],
                email: ["", Validators.required],
                password: ["", Validators.required],
                cpassword: ["", Validators.required],
                role: ["", Validators.required]
            },
            {
                validator: PasswordValidator("password", "cpassword")
            }
        );
    }

    get fc() {
        // get field control(fc)
        return this.userEditForm.controls;
    }

    loadDefault(userId: number) {
        this.userService.getUserById(userId).subscribe((result: {}) => {
            this.response = result;
            if (this.response && this.response.Message.Code == 200) {
                this.userEditForm.patchValue({
                    name: this.response.Info.LoginName,
                    email: this.response.Info.EmailId,
                    password: this.response.Info.Password,
                    cpassword: this.response.Info.Password,
                });
                if (this.response.Info.RoleId > 0)
                    this.userEditForm.patchValue({ role: this.response.Info.RoleId});
            }
        });
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.userEditForm.invalid) {
            return;
        }
        this.user.UserId = this.userId;
        this.user.LoginName = this.userEditForm.value.name;
        this.user.EmailId = this.userEditForm.value.email;
        this.user.Password = this.userEditForm.value.password;
        this.user.UserName = "";
        this.user.RoleId = this.userEditForm.value.role;

        this.updateUser(this.user);
    }

    updateUser(user: User) {
        this.userService.updateUser(user).subscribe((result: {}) => {
            this.response = result;
            if (this.response.Code == 200) {
                var toast: Toast = {
                    type: "success",
                    title: this.response.Message,
                    showCloseButton: true
                };

                this.toasterService.pop(toast);
                this.activeModal.close();
            } else {
                var toast: Toast = {
                    type: "error",
                    title: this.response.Message,
                    showCloseButton: true
                };
                this.toasterService.pop(toast);
            }
        });
    }
}
